import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";

export default function AgregarItemPresupuesto(props) {


    const { open, handleCloseDialog, itemPresupuesto, presupuesto, padre, proyecto, handleSubmit } = props;
    const [suggestionsTitulo, setSuggestionsTitulo] = useState([]);
    const [formik, setFormik] = useState(null);
    const validationSchema = Yup.object({
        item: Yup.string().matches(/^[A-Za-z0-9]+$/, "Solo pueden ser letras y números, sin espacios ni carácteres especiales").required("Campo requerido"),
        nombre: Yup.string().required("Campo requerido"),
        cantidad: Yup.number().typeError("Debe ingresar un valor númerico"),
        precio_unitario: Yup.number().typeError("Debe ingresar un valor númerico"),
    });
    const values = {
        titulo: '',
        item: '',
        nombre: '',
        cantidad: 0,
        unidad: '',
        precio_unitario: 0,
        precio_total: 0,
        is_hoja: true,
        padre: null

    };

    const handleEnterDialog = async () => {
        let suggestionTitulo = { label: "Sín Título", value: "", item: "" };
        const suggestions = [{ label: "Sín Título", value: "", item: "" }];
        const agregarSugerencia = (data) => {
            if (itemPresupuesto && itemPresupuesto._id === data._id) return;
            const suggestion = { label: data.item + " " + data.nombre, value: data._id, item: data.item };
            if (padre && data._id === padre._id) suggestionTitulo = suggestion;
            suggestions.push(suggestion);
            if (data.itemPresupuestoHijos) {
                for (let i = 0; i < data.itemPresupuestoHijos.length; i++) {
                    if (itemPresupuesto && itemPresupuesto._id === data.itemPresupuestoHijos[i]._id) continue;
                    agregarSugerencia(data.itemPresupuestoHijos[i]);
                }
            }
        }

        for (let i = 0; i < presupuesto.listadoTitulos.length; i++) {
            agregarSugerencia(presupuesto.listadoTitulos[i]);
        }
        if (!itemPresupuesto) {
            formik.resetForm(values);
        } else {
            let item = itemPresupuesto.item;
            if (itemPresupuesto.padre) {
                const itemPadre = presupuesto.listadoItems.find(a => a._id === itemPresupuesto.padre);
                if (itemPresupuesto.padre) suggestionTitulo = suggestions.find(a => a.value === itemPresupuesto.padre);
                if (itemPadre && itemPadre.item) item = item.replace(itemPadre.item + ".", "");
            }
            formik.resetForm({
                titulo: suggestionTitulo,
                item: item,
                nombre: itemPresupuesto.nombre,
                cantidad: itemPresupuesto.cantidad,
                unidad: itemPresupuesto.unidad,
                precio_unitario: itemPresupuesto.precio_unitario,
                precio_total: itemPresupuesto.precio_total,
                padre: null,
                is_hoja: !(itemPresupuesto.hijos && itemPresupuesto.hijos.length > 0)
            });
        }
        setSuggestionsTitulo(suggestions);
    }

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const handleChangePrecioCantidad = ({ name, value }, values) => {
        if (name === "cantidad") {
            if (!value || !values.precio_unitario) {
                formik.setFieldValue("precio_total", 0);
                return;
            }
            const precio_total = value * values.precio_unitario;
            formik.setFieldValue("precio_total", precio_total);
        }
        if (name === "precio_unitario") {
            const precio_total = values.cantidad * value;
            if (!value || !values.cantidad) {
                formik.setFieldValue("precio_total", 0);
                return;
            }
            formik.setFieldValue("precio_total", precio_total);
        }
    }

    const handleOnSubmit = (values, formikBag) => {
        values["proyecto_ref"] = proyecto._id;
        values["presupuesto_ref"] = presupuesto._id;
        // values["is_hoja"] = true;
        if (values.titulo.value || values.titulo.value === 0) {
            values["padre"] = values.titulo.value;

            values.item = values.titulo.item + "." + values.item;
        }
        handleSubmit(values);
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={itemPresupuesto ? true : false}
                render={props =>
                    <Page
                        open={open}
                        titulo="Nuevo Ítem"
                        handleEnterDialog={handleEnterDialog}
                        handleCloseDialog={handleCloseDialog}
                        handleChangePrecioCantidad={handleChangePrecioCantidad}
                        suggestionsTitulo={suggestionsTitulo}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}