/**
 * Funciones de consulta a base de datos para dias lineas base
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import Axios from "axios";
/**
 * Obtener un dia linea base de la base de datos
 * @param {String} id id del dia linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getDiaLineaBase = (id, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/dias-lineas-base/${id}`;
    const promise = Axios.get(url, {
        canceltoken: cancelToken
    });
    return promise
}
/**
 * Obtener todos los dias lineas base de la base de datos
 * @param {Object} filters filtros para aplicar a los resultados de la búsqueda
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getAllDiaLineaBase = (filters, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/dias-lineas-base/`;
    const promise = Axios.get(url, {
        params: filters,
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Actualizar los datos un dia linea base en la base de datos
 * @param {String} id id del dia linea base
 * @param {Object} data campos actualizados
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const updateDiaLineaBase = (id, data, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/dias-lineas-base/${id}`;
    const promise = Axios.put(url, data, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Eliminar un dia linea base de la base de datos
 * @param {String} id id del dia linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deleteDiaLineaBase = (id, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/dias-lineas-base/${id}`;
    const promise = Axios.delete(url, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Crear un nuevo dia linea base en la base de datos
 * @param {Object} data Campos del nuevo dia linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postDiaLineaBase = (data, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/dias-lineas-base/`;
    const promise = Axios.post(url, data, {
        canceltoken: cancelToken
    });
    return promise
}