import {
  SET_INICIO_SEMANA_REPORTE_POD,
  OPEN_GENERAR_POD,
  CLOSE_GENERAR_POD,
  SET_FECHA_GENERAR_POD,
  OPEN_EDITAR_HH_POD,
  CLOSE_EDITAR_HH_POD
} from './actionsPod';
import moment from 'moment';

const defaultState = {
  inicioSemanaReportePod: moment().startOf('week').format("YYYY-MM-DD HH:mm:ss.SSS"),
  openGenerarPod: false,
  openEditarHHPod: false,
  fechaNuevoPod: null,
  podSeleccionado: null
};

export default  function reducerPod(state = defaultState, { type, inicioSemanaReportePod, fechaNuevoPod, podSeleccionado }) {

  switch (type) {
    case SET_INICIO_SEMANA_REPORTE_POD: {
      return { ...state, inicioSemanaReportePod: inicioSemanaReportePod };
    }
    case SET_FECHA_GENERAR_POD: {
      return { ...state, fechaNuevoPod: fechaNuevoPod };
    }
    case OPEN_GENERAR_POD:{
      return { ...state, openGenerarPod: true };
    }
    case CLOSE_GENERAR_POD:{
      return { ...state, openGenerarPod: false };
    }
    case OPEN_EDITAR_HH_POD:{
      return { ...state, openEditarHHPod: true, podSeleccionado: podSeleccionado };
    }
    case CLOSE_EDITAR_HH_POD:{
      return { ...state, openEditarHHPod: false, podSeleccionado: null };
    }
    default:
      return state;
  }
}