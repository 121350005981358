import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Page from './page';
import { useHistory } from 'react-router';
import moment from 'moment';
moment.locale('es');


export default function ProgramacionSemanal(props) {
    const history = useHistory();
    const {
        proyecto
    } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto
    }));

    // useEffect(() => {
    //     console.log(proyecto);
    // }, [proyecto]);

    return (
        <Page
            goTo={(path) => history.push(path)}
            proyecto={proyecto}
        />
    );
}