import React, { useEffect, useState } from 'react';
import Page from './page';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAvancesPeriodoHH } from '../../../services/database/programacion';
import { getEstadosPagosProyecto, getAvancesPeriodoPodProyectoHH } from '../../../services/database/proyecto';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function IndicadoresHH(props) {


    const { proyecto } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto
    }));
    const notistack = useSnackbar();
    const { montoContrato, montoModificadoContrato } = props;
    const [montoAcumulado, setMontoAcumulado] = useState(0);
    const [avancesPeriodoPodProyecto, setAvancesPeriodoPodProyecto] = useState({});
    const [conHorasHombre, setConHorasHombre] = useState(true);
    const [data, setData] = useState(null);
    const [inicioProgramado, setInicioProgramado] = useState(moment());
    const [inicioSemana, setInicioSemana] = useState(moment().startOf("week"));
    const [isLoading, setIsLoading] = useState(true);
    const [terminoProgramado, setTerminoProgramado] = useState(moment());
    const [hhGastadas, setHHGastadas] = useState(0);
    const loadData = () => {
        if (proyecto && proyecto.programa_actual_ref) {
            const CancelToken = Axios.CancelToken;
            let cancelRequest = null;
            let cancelToken = new CancelToken(function executor(c) {
                cancelRequest = c;
            });
            getAvancesPeriodoHH(proyecto.programa_actual_ref, cancelToken)
                .then(async (response) => {
                    console.log(response);
                    if (response.status === 204) {
                        setData(null);
                        setMontoAcumulado(0);
                        setInicioProgramado(moment());
                        setTerminoProgramado(moment());
                        setIsLoading(false);
                    } else if (response.status == 200) {
                        const avancesPeriodo = response.data;
                        const monto = await getDataEstadosPago(proyecto);
                        setData(avancesPeriodo);
                        setMontoAcumulado(monto);
                        setInicioProgramado(moment(avancesPeriodo.inicioPrograma).utc());
                        setTerminoProgramado(moment(avancesPeriodo.terminoPrograma).utc());
                        setIsLoading(false);
                    } else {
                        
                        setData(null);
                        setMontoAcumulado(0);
                        setInicioProgramado(moment());
                        setTerminoProgramado(moment());
                        setIsLoading(false);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la información del proyecto. Recargue la página o contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    setData(null);
                    setMontoAcumulado(0);
                    setInicioProgramado(moment());
                    setTerminoProgramado(moment());
                    setIsLoading(false);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la información del proyecto. Recargue la página o contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                });

            return cancelRequest;
        } else {
            setData(null);
            setMontoAcumulado(0);
            setInicioProgramado(moment());
            setTerminoProgramado(moment());
            setIsLoading(false);
        }
    }

    const getDataEstadosPago = async (proyecto) => {

        let monto = 0;
        if (proyecto) {
            const estadosPagoProyecto = await getEstadosPagosProyecto(proyecto._id);
            for (let key in estadosPagoProyecto) {
                monto = monto + estadosPagoProyecto[key].monto;
            }
        }
        return monto;
    }

    const loadDataPodSemana = async () => {
        setAvancesPeriodoPodProyecto({});
        if (proyecto && proyecto.programa_actual_ref) {
            const avancesPeriodoPod = await getAvancesPeriodoPodProyectoHH(
                proyecto._id,
                inicioSemana.format("YYYY-MM-DD"),
                inicioSemana.clone().endOf("week").format("YYYY-MM-DD")
            );
            let diasTranscurridos = moment().diff(inicioSemana, 'days') + 1;
            if (diasTranscurridos > 7) diasTranscurridos = 7;
            if (avancesPeriodoPod) {
                avancesPeriodoPod["indicadores"] = avancesPeriodoPod.avancesSemana.reduce(
                    (b, a) => {
                        b.avanceRealPod = b.avanceRealPod + a.avanceRealPod;
                        b.avanceProgramadoPod = b.avanceProgramadoPod + a.avanceProgramadoPod;
                        b.avanceProgramado = b.avanceProgramado + a.avanceProgramado;
                        b.cumplimiento = b.cumplimiento + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
                        b.cumplimientoProg = b.cumplimientoProg + (a.cumplimientoProg ? parseFloat(a.cumplimientoProg) : 0);

                        return b;
                    }, {
                    avanceRealPod: 0,
                    avanceProgramadoPod: 0,
                    avanceProgramado: 0,
                    cumplimiento: 0,
                    cumplimientoProg: 0
                });

                if (diasTranscurridos > 0) {
                    avancesPeriodoPod.indicadores.cumplimiento = avancesPeriodoPod.indicadores.cumplimiento / diasTranscurridos;
                    avancesPeriodoPod.indicadores.cumplimientoProg = avancesPeriodoPod.indicadores.cumplimientoProg / diasTranscurridos;
                } else {
                    avancesPeriodoPod.indicadores.cumplimiento = 0;
                    avancesPeriodoPod.indicadores.cumplimientoProg = 0;
                }
                setAvancesPeriodoPodProyecto(avancesPeriodoPod);
                setHHGastadas(avancesPeriodoPod.hhGastadas + (proyecto.horas_hombre_base_real ? proyecto.horas_hombre_base_real : 0));
            }


        }
    }

    useEffect(() => {
        setIsLoading(true);
        const cancelRequest = loadData();
        return () => {
            if (cancelRequest) cancelRequest();
            setIsLoading(false);
        }
    }, [proyecto]);

    useEffect(() => {
        loadDataPodSemana();
    }, [proyecto, inicioSemana]);

    return (
        <Page
            avancesPeriodoPodProyecto={avancesPeriodoPodProyecto}
            avanceProgramado={props.avanceProgramado}
            avanceReal={props.avanceReal}
            conHorasHombre={conHorasHombre}
            data={data}
            duracionProgramada={props.duracionProgramada}
            duracionReal={props.duracionReal}
            hhGastadas={hhGastadas}
            isLoading={isLoading}
            inicioProgramado={inicioProgramado}
            inicioSemana={inicioSemana}
            montoContrato={montoContrato}
            montoModificadoContrato={montoModificadoContrato}
            montoAcumulado={montoAcumulado}
            proyecto={proyecto}
            setConHorasHombre={setConHorasHombre}
            setInicioProgramado={setInicioProgramado}
            setInicioSemana={setInicioSemana}
            setTerminoProgramado={setTerminoProgramado}
            terminoProgramado={terminoProgramado}
        />
    );
}