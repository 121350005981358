import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
    accionOpenAgregarReprogramacionArchivo, accionOpenEliminarReprogramacion
} from '../actionsReprogramacion';
import { getReprogramaciones } from '../requestsReprogramacion';
import { downloadUrlAsPromise } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
moment.locale('es');
export default function ListadoReprogramaciones(props) {

    const history = useHistory();
    const notisnack = useSnackbar();
    const dispatch = useDispatch();
    const {
        usuarioSesion,
        proyecto,
    } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
    }));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (proyecto && proyecto.programa_actual_ref) {
            const reprogramaciones = await getReprogramaciones({ programa_ref: proyecto.programa_actual_ref });
            if (reprogramaciones) {
                setData(reprogramaciones);
            } else {
                setData([]);
            }

        } else {
            setData([]);
        }
        setIsLoading(false);
    }

    const handleOpenAgregarReprogramacion = () => {
        dispatch(accionOpenAgregarReprogramacionArchivo());
    }

    const handleSubmitAgregarReprogramacion = () => {
        cargarData();
    }

    const handleOpenEliminarReprogramacion = (rowData) => {
        dispatch(accionOpenEliminarReprogramacion(rowData));
    }

    const handleDescargarFormato = async () => {
        const nombre = "Formato reprogramacion.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/1fEUNfHSC5oRTWhpN_AKYkUN9zX_lMigH?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    const handleViewArchivo = async (rowData) => {
        if (rowData && rowData.archivo) {
            const nombre = rowData.archivo.nombre;
            const response = await downloadUrlAsPromise(`https://www.googleapis.com/drive/v3/files/${rowData.archivo.archivo_id}?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung`, nombre);
            if (response) saveAs(response.blob, nombre);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        cargarData();
    }, [proyecto]);

    return (
        <Page
            data={data}
            goTo={(path) => history.push(path)}
            handleDescargarFormato={handleDescargarFormato}
            handleOpenAgregarReprogramacion={handleOpenAgregarReprogramacion}
            handleOpenEliminarReprogramacion={handleOpenEliminarReprogramacion}
            handleSubmitAgregarReprogramacion={handleSubmitAgregarReprogramacion}
            handleViewArchivo={handleViewArchivo}
            isLoading={isLoading}
            usuarioSesion={usuarioSesion}
        />
    );
}