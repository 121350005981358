export default class RegistroAvance {
    constructor(json = null) {
        this.avance_colacion = 0;
        this.avance_programado_colacion = 0;
        this.baglog = false;
        this.cantidad = 0;
        this.cantidad_programada = 0;
        this.comentario_baglog = "";
        this.cumplimiento_equipos = 0;
        this.cumplimiento_mano_obra = 0;
        this.desvio_avance_colacion = "";
        this.desvio_inicio = "";
        this.desvio_termino = "";
        this.equipo = "";
        this.equipos = 0;
        this.equipos_programados = 0;
        this.estado = "";
        this.fecha = "";
        this.fecha_ingreso = "";
        this.hora_colacion = "";
        this.hora_colacion_programado = "";
        this.hora_inicio = "";
        this.hora_inicio_programado = "";
        this.hora_termino = "";
        this.hora_termino_programado = "";
        this.horas_hombre = 0;
        this.horas_hombre_programadas = 0;
        this.id = "";
        this.mano_obra = 0;
        this.mano_obra_programada = 0;
        this.metodo_accion = "";
        this.observacion = "";
        this.pod = null;
        this.porcentaje_avance = 0;
        this.responsable_accion = "";
        this.responsable_contratista = "";
        this.responsable_otro = "";
        this.tiempo_efectivo_jornada = "";
        this.tiempo_real_jornada = "";
        this.tipo_avance = "";
        this.turno = "";
        if (json) this.build(json);
    }
    build(json) {
        this.avance_colacion = json.avance_colacion ? json.avance_colacion : 0;
        this.avance_programado_colacion = json.avance_programado_colacion ? json.avance_programado_colacion : 0;
        this.baglog = json.baglog ? json.baglog : false;
        this.cantidad = json.cantidad ? json.cantidad : 0;
        this.cantidad_programada = json.cantidad_programada ? json.cantidad_programada : 0;
        this.comentario_baglog = json.comentario_baglog ? json.comentario_baglog : "";
        this.cumplimiento_equipos = json.cumplimiento_equipos ? json.cumplimiento_equipos : 0;
        this.cumplimiento_mano_obra = json.cumplimiento_mano_obra ? json.cumplimiento_mano_obra : 0;
        this.desvio_avance_colacion = json.desvio_avance_colacion ? json.desvio_avance_colacion : "";
        this.desvio_inicio = json.desvio_inicio ? json.desvio_inicio : "";
        this.desvio_termino = json.desvio_termino ? json.desvio_termino : "";
        this.equipo = json.equipo ? json.equipo : "";
        this.equipos = json.equipos ? json.equipos : 0;
        this.equipos_programados = json.equipos_programados ? json.equipos_programados : 0;
        this.estado = json.estado ? json.estado : "";
        this.fecha = json.fecha ? json.fecha : "";
        this.fecha_ingreso = json.fecha_ingreso ? json.fecha_ingreso : "";
        this.hora_colacion = json.hora_colacion ? json.hora_colacion : "";
        this.hora_colacion_programado = json.hora_colacion_programado ? json.hora_colacion_programado : "";
        this.hora_inicio = json.hora_inicio ? json.hora_inicio : "";
        this.hora_inicio_programado = json.hora_inicio_programado ? json.hora_inicio_programado : "";
        this.hora_termino = json.hora_termino ? json.hora_termino : "";
        this.hora_termino_programado = json.hora_termino_programado ? json.hora_termino_programado : "";
        this.horas_hombre = json.horas_hombre ? json.horas_hombre : 0;
        this.horas_hombre_programadas = json.horas_hombre_programadas ? json.horas_hombre_programadas : 0;
        this.id = (json.id || json.id === 0) ? json.id : "";
        this.mano_obra = json.mano_obra ? json.mano_obra : 0;
        this.mano_obra_programada = json.mano_obra_programada ? json.mano_obra_programada : 0;
        this.metodo_accion = json.metodo_accion ? json.metodo_accion : "";
        this.observacion = json.observacion ? json.observacion : "";
        this.pod = json.pod ? json.pod : null;
        this.porcentaje_avance = json.porcentaje_avance ? json.porcentaje_avance : 0;
        this.responsable_accion = json.responsable_accion ? json.responsable_accion : "";
        this.responsable_contratista = json.responsable_contratista ? json.responsable_contratista : "";
        this.responsable_otro = json.responsable_otro ? json.responsable_otro : "";
        this.tiempo_efectivo_jornada = json.tiempo_efectivo_jornada ? json.tiempo_efectivo_jornada : "";
        this.tiempo_real_jornada = json.tiempo_real_jornada ? json.tiempo_real_jornada : "";
        this.tipo_avance = json.tipo_avance ? json.tipo_avance : "";
        this.turno = json.turno ? json.turno : "";
    }
}