import Axios from "../axios";

export const getReporteSemanalPod = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/reportes-semanales-pod/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const getReportesSemanalesPod = async (query = {}) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/reportes-semanales-pod/`, {
            params: query
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const updateReporteSemanalPod = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/reportes-semanales-pod/${id}`, params)
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

export const deleteReporteSemanalPod = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/reportes-semanales-pod/${id}`, {
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

export const postReporteSemanalPod = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/reportes-semanales-pod/`, body)
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}