import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from './page';
import { getEstadosPagosProyecto, agregarEstadosPagosProyecto, quitarEstadosPagosProyecto } from '../../services/database/proyecto';
import { uploadFileToDrive, viewDriveFile } from '../../services/fileUpload';
import { postEstadosPago, updateEstadosPago } from '../../services/database/estadosPagos';

export default function EstadosPago(props) {

    const [data, setData] = useState(null);
    const [estadoPagoSeleccionado, setEstadoPagoSeleccionado] = useState(null);
    const [openAgregarEstadoPago, setOpenAgregarEstadoPago] = useState(false);
    const [openEditarEstadoPago, setOpenEditarEstadoPago] = useState(false);
    const [openEliminarEstadoPago, setOpenEliminarEstadoPago] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarVariant, setSnackbarVariant] = useState("default");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [hideSnackbarButton, setHideSnackbarButton] = useState(false);
    const [montoAcumulado, setMontoAcumulado] = useState("");

    const { usuarioSesion, contrato, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto
    }));

    useEffect(() => {
        cargarData();
    }, [proyecto]);
    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (proyecto) {
            const estadosPagoProyecto = await getEstadosPagosProyecto(proyecto._id);
            if (estadosPagoProyecto) {
                let acumulado = 0;
                for(let key in estadosPagoProyecto){
                    acumulado = acumulado + estadosPagoProyecto[key].monto;
                }
                setData(estadosPagoProyecto);
                setMontoAcumulado(acumulado);
                return;
            }
        }
        setData([]);
    }
    //EVENTOS AGREGAR ESTADO PAGO
    const handleOpenAgregarEstadoPago = (e, estadoPago) => {
        setOpenAgregarEstadoPago(true);
    }
    const handleCloseAgregarEstadoPago = (e, estadoPago) => {
        setOpenAgregarEstadoPago(false);
    }
    const handleSubmitAgregarEstadoPago = async (values) => {
        try {
            const carpeta = proyecto.carpetas.find(a => a.nombre === "Estados de Pago");
            if (carpeta) {
                setSnackbarMessage("Subiendo Archivo");
                setSnackbarVariant("default");
                setOpenSnackbar(true);
                setHideSnackbarButton(true);
                handleCloseAgregarEstadoPago();
                await uploadFileToDrive(values.adjunto, carpeta.carpeta_id, values.adjunto.name, (event) => {
                    if (event.lengthComputable) {
                        const porcentaje = Math.round(event.loaded / event.total * 100);
                        setSnackbarMessage(`Subiendo Archivo ${porcentaje}%`);
                    }
                }).then(async (response) => {
                    setSnackbarMessage("Estado de pago almacenado");
                    setSnackbarVariant("success");
                    setHideSnackbarButton(false);
                    setOpenSnackbar(true);
                    values.adjunto = {
                        nombre: response.name,
                        archivo_id: response.id,
                        carpeta_id: carpeta.id,
                        size: values.adjunto.size
                    }
                    const nuevoEstadoPago = await postEstadosPago(values);
                    await agregarEstadosPagosProyecto(proyecto._id, [nuevoEstadoPago]);
                    cargarData();
                })
                .catch(error =>{
                    setSnackbarMessage("Error al intentar subir el estado de pago");
                    setSnackbarVariant("error");
                    setHideSnackbarButton(false);
                    setOpenSnackbar(true);
                });

            }
        }
        catch (error) {
            console.log(error);
            handleCloseAgregarEstadoPago();
        }

    }
    //EVENTOS EDITAR ESTADO PAGO
    const handleOpenEditarEstadoPago = (e, estadoPago) => {
        setOpenEditarEstadoPago(true);
        setEstadoPagoSeleccionado(estadoPago);
    }
    const handleCloseEditarEstadoPago = (e, estadoPago) => {
        setOpenEditarEstadoPago(false);
        setEstadoPagoSeleccionado(null);
    }
    const handleSubmitEditarEstadoPago = async (values) => {
        await updateEstadosPago(estadoPagoSeleccionado._id, values);
        handleCloseEditarEstadoPago();
        cargarData();
    }
    //EVENTOS ELIMINAR ESTADO PAGO
    const handleOpenEliminarEstadoPago = (e, estadoPago) => {
        setOpenEliminarEstadoPago(true);
        setEstadoPagoSeleccionado(estadoPago);
    }
    const handleCloseEliminarEstadoPago = (e, estadoPago) => {
        setOpenEliminarEstadoPago(false);
        setEstadoPagoSeleccionado(null);
    }
    const handleSubmitEliminarEstadoPago = async () => {
        await updateEstadosPago(estadoPagoSeleccionado._id, {is_eliminado: true});
        await quitarEstadosPagosProyecto(proyecto._id, [estadoPagoSeleccionado._id]);
        handleCloseEliminarEstadoPago();
        cargarData();
    }

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const handleViewEstadoPago = (event, rowData) => viewDriveFile(rowData.adjunto.archivo_id, rowData.adjunto.nombre);

    return (
        <Page
            proyecto={proyecto}
            data={data}
            usuarioSesion={usuarioSesion}
            montoAcumulado={montoAcumulado}
            estadoPagoSeleccionado={estadoPagoSeleccionado}
            openAgregarEstadoPago={openAgregarEstadoPago}
            openEditarEstadoPago={openEditarEstadoPago}
            openEliminarEstadoPago={openEliminarEstadoPago}
            handleCloseAgregarEstadoPago={handleCloseAgregarEstadoPago}
            handleCloseEditarEstadoPago={handleCloseEditarEstadoPago}
            handleCloseEliminarEstadoPago={handleCloseEliminarEstadoPago}
            handleOpenAgregarEstadoPago={handleOpenAgregarEstadoPago}
            handleOpenEditarEstadoPago={handleOpenEditarEstadoPago}
            handleOpenEliminarEstadoPago={handleOpenEliminarEstadoPago}
            handleSubmitAgregarEstadoPago={handleSubmitAgregarEstadoPago}
            handleSubmitEditarEstadoPago={handleSubmitEditarEstadoPago}
            handleSubmitEliminarEstadoPago={handleSubmitEliminarEstadoPago}
            openSnackbar={openSnackbar}
            snackbarVariant={snackbarVariant}
            snackbarMessage={snackbarMessage}
            hideSnackbarButton={hideSnackbarButton}
            handleCloseSnackbar={handleCloseSnackbar}
            handleViewEstadoPago={handleViewEstadoPago}
        />
    );
}