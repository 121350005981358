/**
 * Vista de listado reportes diarios pod
 * @since 1.2.0
 * @author Luis San Martín S.
 * @property    {Object}    Data    Listado de reportes diarios
 * @property    {function}  gotTo   Función para redirigir a otra ruta
 * @property    {Boolean}   isLoading   Indicador para saber si aún se están cargando o no los reportes diarios
 * @property    {function}  handleOpenAgregarReportePodDiario   Abrir dialogo para agregar reportes pod diarios
 * @property    {function}  handleOpenEliminarReportePodDiario Abrir dialogo para eliminar reportes pod diarios
 * @property    {Object}    usuarioSesion   Objecto que contiene los datos de la sesión de usuario
 */

import React, { useState, Fragment } from 'react';
import {
    Button
} from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';

import { makeStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert } from '@material-ui/icons';
import Moment from 'react-moment';
import AgregarLineaBase from '../agregarLineaBase';
import EliminarLineaBase from '../eliminarLineaBase';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    }
}));

export default function Page(props) {

    const classes = useStyles();

    const {
        data,
        isLoading,
        handleOpenAgregarLineaBase,
        handleOpenEliminarLineaBase,
        usuarioSesion,
    } = props;
    const [pageSize, setPageSize] = useState(10);

    return (
        <Fragment>
            <MaterialTable
                title={`Listado de Reportes Diarios`}
                options={{
                    filtering: false,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false,
                    columnsButton: true,
                    maxBodyHeight: "70vh",
                    headerStyle: {
                        textAlign: 'center',
                        border: "1px solid #E0E0E0",
                        fontWeight: 'bold',
                        color: '#731f1f',
                        position: 'sticky'
                    },
                    rowStyle: {
                        border: "1px solid #E0E0E0"
                    },
                    padding: 'dense',
                    actionsCellStyle: {
                        width: "2%",
                        textAlign: "center",
                        border: "1px solid #E0E0E0",
                    },
                    cellStyle:{
                        border: "1px solid #E0E0E0",
                    }
                }}
                onChangeRowsPerPage={size => { setPageSize(size) }}
                localization={location}
                isLoading={isLoading}
                actions={[
                    {
                        icon: "agregar",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        icon: "settings",
                        onClick: (evt) => { }
                    },
                ]}
                components={{
                    Action:
                        props => {
                            const rowData = props.data;
                            if (props.action.icon === 'agregar') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleOpenAgregarLineaBase()}
                                    // hidden={!(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_POD])}
                                    >
                                        Cargar Línea Base
                                    </Button>
                                );
                            }
                            // if (props.action.icon === 'formato_programa') {
                            //     return (
                            //         <Button
                            //             variant="outlined"
                            //             onClick={(evt) => handleDescargarFormato(evt, null)}
                            //         >
                            //             Descargar Formato
                            //         </Button>
                            //     );
                            // }
                            if (props.action.icon === 'settings') {
                                const accionesConfiguracion = [
                                    // {
                                    //     content: 'Ver Programa',
                                    //     onClick: (e) => goTo(`/programacion-semanal/${rowData._id}`)
                                    // }
                                ];
                                // if (rowData.archivo) accionesConfiguracion.push({
                                //     content: 'Ver Archivo',
                                //     // onClick: (e) => { }
                                //     onClick: (e) => handleViewArchivo(rowData)
                                // });
                                if (usuarioSesion && usuarioSesion.permisos) {
                                    accionesConfiguracion.push({
                                        content: 'Eliminar',
                                        onClick: (e) => handleOpenEliminarLineaBase(rowData)
                                    });
                                }
                                if (accionesConfiguracion.length > 0) return (
                                    <MenuDropdown
                                        icon={
                                            <MoreVert />
                                        }
                                        tooltip="Opciones"
                                        items={accionesConfiguracion}
                                        hidden={accionesConfiguracion.length <= 0}
                                    />
                                );
                            }
                            return (<MTableAction {...props} />);
                        }
                }}
                columns={[
                    {
                        title: 'Fecha',
                        field: 'fecha',
                        render: rowData => generarFechaView(rowData.fecha, "DD-MM-YY"),
                        cellStyle: {
                            textAlign: 'center',
                            border: "1px solid #E0E0E0",
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Inicio',
                        field: 'inicio_programado',
                        render: rowData => generarFechaView(rowData.inicio_programado, "DD-MM-YY"),
                        cellStyle: {
                            textAlign: 'center',
                            border: "1px solid #E0E0E0",
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Termino',
                        field: 'termino_programado',
                        render: rowData => generarFechaView(rowData.termino_programado, "DD-MM-YY"),
                        cellStyle: {
                            textAlign: 'center',
                            border: "1px solid #E0E0E0",
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'HH Programado',
                        field: 'horas_hombre_programadas',
                        render: rowData => numberFormater(rowData.horas_hombre_programadas)
                    },
                    {
                        title: 'HH Ganada',
                        field: 'horas_hombre_ganadas',
                        render: rowData => numberFormater(rowData.horas_hombre_ganadas)
                    },
                ]}
                data={data}
            />
            <AgregarLineaBase />
            <EliminarLineaBase />
        </Fragment>
    );
}

/**
 * Función para generar vista de fechas usadas por material table con formato indicado
 * @param {*} date Fecha
 * @param {*} format Formato de Fecha
 */

function generarFechaView(date, format) {
    if (date) {
        date = new Date(date);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }
}