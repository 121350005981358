import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import Page from './page';
import moment from 'moment';
import { uploadFileToDrive, downloadUrlAsPromise } from '../../../../services/fileUpload';
import { postReporteSemanalPod } from '../../../../services/database/reportesSemanalesPod';
import { getAvancesPeriodoPodProyecto, updateProyecto, getHorasEfectivasPeriodoProyecto } from '../../../../services/database/proyecto';
import { postArchivo } from '../../../../services/database/archivos';
import { crearCarpetaDrive, postCarpeta } from '../../../../services/database/carpetas';
import axios from 'axios';
import formatNumber from 'format-number';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });


export default function AgregarReporteSemanalPod(props) {

  const [formik, setFormik] = useState(null);
  const notisnack = useSnackbar();
  const validationSchema = Yup.object({
    // fecha_inicio: Yup.date().required("Campo requerido"),
    plan_accion: Yup.string(),
  });
  const { proyecto, inicioSemanaReportePod, usuarioSesion } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion,
    proyecto: state.contratoSesion.proyecto,
    inicioSemanaReportePod: state.reducerPod.inicioSemanaReportePod
  }));

  const values = {
    // fecha_inicio: moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
    plan_accion: "",
  };

  const handleChange = (name, e) => {
    formik.setFieldValue(name, e.target.value);
    formik.setFieldTouched(name, true, false);
  };

  // const handleChangeDateWeek = (name, e) => {
  //   formik.setFieldTouched(name, true, false);
  //   // console.log(e);
  //   if (e) formik.setFieldValue(name, moment(e).startOf("week").toDate());
  //   else formik.setFieldValue(name, null);
  // };


  const handleOnSubmit = async (values, formikBag) => {
    const nuevoReporteSemanal = {
      fecha_inicio: inicioSemanaReportePod,
      proyecto_ref: proyecto._id,
      usuario_ref: usuarioSesion.ref,
      archivo_excel_ref: null,
      archivo_pdf_ref: null,
      plan_accion: values.plan_accion
    };
    const inicioSemana = moment(inicioSemanaReportePod);
    const key = notisnack.enqueueSnackbar("Generando Informe...", {
      persist: true,
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom"
      }
    });
    try {
      const avancesPeriodoPodProyecto = await getAvancesPeriodoPodProyecto(
        proyecto._id,
        inicioSemana.clone().subtract(7, 'day').format("YYYY-MM-DD"),
        inicioSemana.clone().endOf("week").format("YYYY-MM-DD")
      );
      const avancesPeriodoPodProyectoS1 = await getAvancesPeriodoPodProyecto(
        proyecto._id,
        inicioSemana.clone().subtract(7, 'day').format("YYYY-MM-DD"),
        inicioSemana.clone().subtract(7, 'day').endOf("week").format("YYYY-MM-DD")
      );
      const horaEfectivasProyecto = await getHorasEfectivasPeriodoProyecto(
        proyecto._id,
        moment(inicioSemana).subtract(7, 'day').format("YYYY-MM-DD"),
        moment(inicioSemana).endOf("week").format("YYYY-MM-DD")
      );
      let cumplimientoPeriodoActual = 0;
      let indiceEficienciaHorasEfectivasActual = 0;
      let diasCumplimientoActual = 0;
      avancesPeriodoPodProyecto["indicadores"] = avancesPeriodoPodProyecto.avancesSemana.reduce(
        (b, a, index) => {
          b.avanceRealCostosPod = b.avanceRealCostosPod + a.avanceRealCostosPod;
          b.avanceRealHHPod = b.avanceRealHHPod + a.avanceRealHHPod;
          b.avanceProgramadoCostosPod = b.avanceProgramadoCostosPod + a.avanceProgramadoCostosPod;
          b.avanceProgramadoHHPod = b.avanceProgramadoHHPod + a.avanceProgramadoHHPod;
          b.avanceProgramadoCostos = b.avanceProgramadoCostos + a.avanceProgramadoCostos;
          b.avanceProgramadoHH = b.avanceProgramadoHH + a.avanceProgramadoHH;
          b.cumplimiento = b.cumplimiento + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
          b.cumplimientoProgHH = b.cumplimientoProgHH + (a.cumplimientoProgHH ? parseFloat(a.cumplimientoProgHH) : 0);
          b.cumplimientoProgCostos = b.cumplimientoProgCostos + (a.cumplimientoProgCostos ? parseFloat(a.cumplimientoProgCostos) : 0);
          b.horasHombreProgramadas = b.horasHombreProgramadas + (a.horasHombreProgramadas ? parseFloat(a.horasHombreProgramadas) : 0);
          b.horasHombreReales = b.horasHombreReales + (a.horasHombreReales ? parseFloat(a.horasHombreReales) : 0);
          b.horasReales = b.horasReales + horaEfectivasProyecto[index].horas_efectivas;
          b.horasMeta = b.horasMeta + 10;
          const indicadorHorasProgMeta = (a.horasHombreProgramadas && 10 ? parseFloat(a.horasHombreProgramadas) / 10 : 0);
          const indicadorHorasGanReal = (a.horasHombreReales && horaEfectivasProyecto[index].horas_efectivas ? parseFloat(a.horasHombreReales) / parseFloat(horaEfectivasProyecto[index].horas_efectivas) : 0);
          const indiceEficienciaHorasEfectivas = (indicadorHorasProgMeta ? (indicadorHorasGanReal / indicadorHorasProgMeta) : 0)
          horaEfectivasProyecto[index]["indiceEficienciaHorasEfectivas"] = indiceEficienciaHorasEfectivas;
          b.indiceEficienciaHorasEfectivas = b.indiceEficienciaHorasEfectivas + indiceEficienciaHorasEfectivas;
          if (moment(a.fecha).isSameOrAfter(inicioSemana) && moment(a.fecha).isSameOrAfter(moment().endOf('day'))) {
            indiceEficienciaHorasEfectivasActual = indiceEficienciaHorasEfectivasActual + indiceEficienciaHorasEfectivas;
            cumplimientoPeriodoActual = cumplimientoPeriodoActual + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
            diasCumplimientoActual++;
          }
          return b;
        }, {
        avanceRealCostosPod: 0,
        avanceRealHHPod: 0,
        avanceProgramadoCostosPod: 0,
        avanceProgramadoHHPod: 0,
        avanceProgramadoCostos: 0,
        avanceProgramadoHH: 0,
        cumplimiento: 0,
        cumplimientoProgHH: 0,
        cumplimientoProgHHCostos: 0,
        horasHombreProgramadas: 0,
        horasHombreReales: 0,
        horasReales: 0,
        horasMeta: 0,
        indiceEficienciaHorasEfectivas: 0
      });

      if (avancesPeriodoPodProyecto.avancesSemana.length > 0) {
        avancesPeriodoPodProyecto.indicadores.cumplimiento = avancesPeriodoPodProyecto.indicadores.cumplimiento / avancesPeriodoPodProyecto.avancesSemana.length;
        avancesPeriodoPodProyecto.indicadores.cumplimientoProgHH = avancesPeriodoPodProyecto.indicadores.cumplimientoProgHH / avancesPeriodoPodProyecto.avancesSemana.length;
        avancesPeriodoPodProyecto.indicadores.cumplimientoProgCostos = avancesPeriodoPodProyecto.indicadores.cumplimientoProgCostos / avancesPeriodoPodProyecto.avancesSemana.length;
        avancesPeriodoPodProyecto.indicadores.indiceEficienciaHorasEfectivas = avancesPeriodoPodProyecto.indicadores.indiceEficienciaHorasEfectivas / avancesPeriodoPodProyecto.avancesSemana.length;
      }
      if (diasCumplimientoActual) {
        indiceEficienciaHorasEfectivasActual = indiceEficienciaHorasEfectivasActual / diasCumplimientoActual;
        cumplimientoPeriodoActual = cumplimientoPeriodoActual / diasCumplimientoActual;
      }
      const chartData = {
        avanceProgramadoPOD: [],
        avanceRealPOD: [],
        avanceProgramado: []
      }
      let maxValue = 1;
      avancesPeriodoPodProyecto.avancesSemana.map(a => {
        if (a.avanceProgramadoHHPod > maxValue) maxValue = a.avanceProgramadoHHPod;
        if (a.avanceRealHHPod > maxValue) maxValue = a.avanceRealHHPod;
        if (a.avanceProgramadoHH > maxValue) maxValue = a.avanceProgramadoHH;
        chartData.avanceProgramadoPOD.push([
          moment(a.fecha).valueOf(),
          a.avanceProgramadoHHPod
        ]);
        chartData.avanceRealPOD.push([
          moment(a.fecha).valueOf(),
          a.avanceRealHHPod
        ]);
        chartData.avanceProgramado.push([
          moment(a.fecha).valueOf(),
          a.avanceProgramadoHH
        ]);
        return a;
      });

      if (maxValue > 100) maxValue = 100;

      const chartOptions = {
        xAxis: {
          labels: {
            step: 1
          },
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            day: '%e %b',
            month: '%e %b %Y',
            year: '%b %Y'
          },
          title: {
            text: 'Semana'
          },
          // plotLines: [
          //     xPlotLine
          // ],
          plotBands: [{
            color: "#cecece",
            from: inicioSemana.clone().utc().startOf('day').subtract(12, "hours").valueOf(),
            to: inicioSemana.clone().utc().startOf('day').subtract(7, "days").subtract(12, "hours").valueOf()
          }]
        },
        yAxis: [{
          min: 0,
          max: maxValue,
          title: {
            text: 'Porcentaje Avance'
          },
          labels: {
            format: '{value}%',
          },
        }
        ],
        title: {
          text: ""
        },
        series: [
          {
            name: "Avance Programado L.B.",
            type: 'column',
            data: chartData.avanceProgramado,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
            },
          },
          {
            name: "Avance Programado POD",
            type: 'column',
            data: chartData.avanceProgramadoPOD,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
            },
          },
          {
            name: "Avance Ganado",
            type: 'spline',
            data: chartData.avanceRealPOD,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          }

        ],
        plotOptions: {
          area: {
            fillOpacity: 0.2,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        margin: 0,
        exporting: {
          scale: 1,
          sourceWidth: 1424,
          sourceHeight: 400
        }


      };
      const data = {
        fecha: inicioSemana.format("DD-MM-YYYY"),
        nombre_proyecto: proyecto.nombre,
        codigo_proyecto: proyecto.codigo,
        avances: JSON.stringify(avancesPeriodoPodProyecto.avancesSemana.map((a, index) => ({
          fecha: moment(a.fecha).format("DD MMM"),
          avance_programado: `${numberFormater((a.avanceProgramadoHH).toFixed(2))}%`,
          avance_programado_pod: `${numberFormater((a.avanceProgramadoHHPod).toFixed(2))}%`,
          avance_real_pod: `${numberFormater((a.avanceRealHHPod).toFixed(2))}%`,
          cumplimiento_programado: `${numberFormater((a.avanceRealHHPod).toFixed(2))}%`,
          cumplimiento_programado_pod: `${numberFormater(parseFloat(a.cumplimientoProgHH).toFixed(2))}%`,
          categorizacion: a.categorizacion ? a.categorizacion : "",
          causa_cumplimiento: a.causa_cumplimiento ? a.causa_cumplimiento : "",
          horasHombreProgramadas: numberFormater(a.horasHombreProgramadas.toFixed(1)),
          horasHombreReales: numberFormater(horaEfectivasProyecto[index].horas_efectivas.toFixed(1)),
          horasReales: numberFormater(horaEfectivasProyecto[index].indiceEficienciaHorasEfectivas.toFixed(1)),
          horasMeta: numberFormater((10).toFixed(1)),
          indiceEficienciaHorasEfectivas: horaEfectivasProyecto[index].indiceEficienciaHorasEfectivas
        }))),
        avance_programado_hh_s1: numberFormater(parseFloat(avancesPeriodoPodProyectoS1.avanceProgramadoHH).toFixed(2)),
        avance_real_hh_s1: numberFormater(parseFloat(avancesPeriodoPodProyectoS1.avanceRealHH).toFixed(2)),
        avance_programado_costos_s1: numberFormater(parseFloat(avancesPeriodoPodProyectoS1.avanceProgramadoCostos).toFixed(2)),
        avance_real_costos_s1: numberFormater(parseFloat(avancesPeriodoPodProyectoS1.avanceRealCostos).toFixed(2)),
        avance_programado_hh_s2: numberFormater(parseFloat(avancesPeriodoPodProyecto.avanceProgramadoHH).toFixed(2)),
        avance_real_hh_s2: numberFormater(parseFloat(avancesPeriodoPodProyecto.avanceRealHH).toFixed(2)),
        avance_programado_costos_s2: numberFormater(parseFloat(avancesPeriodoPodProyecto.avanceProgramadoCostos).toFixed(2)),
        avance_real_costos_s2: numberFormater(parseFloat(avancesPeriodoPodProyecto.avanceRealCostos).toFixed(2)),
        avance_programado_semana: `${numberFormater((avancesPeriodoPodProyecto.indicadores.avanceProgramadoHH).toFixed(2))}%`,
        avance_programado_pod_semana: `${numberFormater((avancesPeriodoPodProyecto.indicadores.avanceProgramadoHHPod).toFixed(2))}%`,
        avance_real_pod_semana: `${numberFormater((avancesPeriodoPodProyecto.indicadores.avanceRealHHPod).toFixed(2))}%`,
        cumplimiento_programado_semana: `${numberFormater((avancesPeriodoPodProyecto.indicadores.cumplimientoProgHH).toFixed(1))}%`,
        cumplimiento_programado_pod_semana: `${numberFormater(avancesPeriodoPodProyecto.indicadores.cumplimiento.toFixed(1))}%`,
        cumplimiento_promedio_pod_semana: `${numberFormater((avancesPeriodoPodProyecto.cumplimientoPromedio ? avancesPeriodoPodProyecto.cumplimientoPromedio : 0).toFixed(1))}%`,
        plan_accion: values.plan_accion,
        horasHombreProgramadas: numberFormater(avancesPeriodoPodProyecto.indicadores.horasHombreProgramadas.toFixed(1)),
        horasHombreReales: numberFormater(avancesPeriodoPodProyecto.indicadores.horasHombreReales.toFixed(1)),
        horasReales: numberFormater(avancesPeriodoPodProyecto.indicadores.horasReales.toFixed(1)),
        horasMeta: numberFormater(avancesPeriodoPodProyecto.indicadores.horasMeta.toFixed(1)),
        indiceEficienciaHorasEfectivas: numberFormater(avancesPeriodoPodProyecto.indicadores.indiceEficienciaHorasEfectivas.toFixed(2)),
        cumplimientoPeriodoActual: numberFormater(cumplimientoPeriodoActual),
        indiceEficienciaHorasEfectivasActual: numberFormater(indiceEficienciaHorasEfectivasActual),
      };
      const bodyFormData = new FormData();
      for (let key in data) {
        bodyFormData.set(key, data[key]);
      }
      let carpeta = proyecto.carpetas.find(a => a.nombre === "Reportes");
      if (!carpeta) {
        const carpetaDriveApp = await crearCarpetaDrive({
          name: `Reportes`,
          parent: proyecto.carpeta_proyecto.carpeta_id
        });
        carpeta = await postCarpeta({
          nombre: `Reportes`,
          descripción: ``,
          padre_ref: proyecto.carpeta_proyecto._id,
          carpeta_id: carpetaDriveApp.id
        });
        proyecto.carpetas.push(carpeta);
        await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
      }

      // const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" }
      bodyFormData.append("parent", carpeta.carpeta_id);
      const exportUrl = 'https://export.highcharts.com/';
      axios.post(exportUrl,
        {
          options: chartOptions,
          fileName: "chart",
          type: 'image/png',
          async: true
        })
        .then(response => {
          return downloadUrlAsPromise(exportUrl + response.data, "chart");
        })
        .then(response => {
          return uploadFileToDrive(new File([response.blob], "chart.png"), "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", "chart.png");
        })
        .then(response => {
          bodyFormData.append("file", response.id);
          return axios({
            method: 'post',
            url: "https://script.google.com/macros/s/AKfycbyM2diEmBqL9f7MWc7D2VpuU9GmfbvT2XpIocXYshdc7BCBf1c/exec",
            data: bodyFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });
        })
        .then(async response => {
          notisnack.closeSnackbar(key);
          if (response.data) {
            const archivoPdf = await postArchivo({
              archivo_id: response.data.pdf.id,
              carpeta_id: carpeta.carpeta_id,
              carpeta_ref: carpeta._id,
              descripcion: "Archivo PDF en Drive",
              nombre: response.data.pdf.nombre,
              repositorio: "DRIVE",
              mime_type: response.data.pdf.mimeType,
              url: response.data.pdf.url
            });
            const archivoExcel = await postArchivo({
              archivo_id: response.data.excel.id,
              carpeta_id: carpeta.carpeta_id,
              carpeta_ref: carpeta._id,
              descripcion: "Archivo Google Spreadsheet en Drive",
              nombre: response.data.excel.nombre,
              repositorio: "DRIVE",
              mime_type: response.data.excel.mimeType,
              url: response.data.excel.url
            });
            nuevoReporteSemanal.archivo_pdf_ref = archivoPdf;
            nuevoReporteSemanal.archivo_excel_ref = archivoExcel;
            await postReporteSemanalPod(nuevoReporteSemanal);
            props.handleSubmit(nuevoReporteSemanal);
            const successkey = notisnack.enqueueSnackbar("Reporte Semanal generado", {
              variant: 'success',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
            });
          } else {
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible generar el Reporte semanal. Intente de nuevo o contacte con soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    catch (error) {
      console.log(error);
      notisnack.closeSnackbar(key);
      const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible generar el Reporte semanal. Intente de nuevo o contacte con soporte", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
      });

    }
  }

  const handleEnterDialog = async () => {
    formik.resetForm(values);
  }

  return (

    <React.Fragment>
      <Formik
        ref={(ref) => setFormik(ref)}
        onSubmit={handleOnSubmit}
        initialValues={values}
        validationSchema={validationSchema}
        isInitialValid={true}
        render={formikProps =>
          <Page
            {...formikProps}
            handleChange={handleChange}
            inicioSemanaReportePod={inicioSemanaReportePod}
            handleClose={props.handleClose}
            handleEnterDialog={handleEnterDialog}
            open={props.open}
          />
        }
      />
    </React.Fragment>
  );
}