import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { getItemsPrograma } from '../../../../services/database/programacion';
import Page from './page';

export default function EditarActividadPod(props) {

  const [formik, setFormik] = useState(null);
  const [actividades, setActividades] = useState([]);
  const [metodosAccion, setMetodosAccion] = useState([
    "",
    "Ingeniería",
    "Adquisiciones",
    "Seguridad",
    "Medio Ambiente",
    "QA/QC",
    "Construcción",
    "Recursos",
    "Rendimiento"
  ]);
  const [responsables, setResponsables] = useState([
    "",
    "MLP",
    "EECC",
  ]);
  const validationSchema = Yup.object({
    cantidad: Yup.number().typeError("Debe ingresar un valor númerico").required("Campo requerido").min(0, "Debe ser un valor mayor o igual que 0"),
    horas_hombre: Yup.number().typeError("Debe ingresar un valor númerico").min(0, "Debe ser un valor mayor o igual que 0").required("Campo requerido")
  });
  const { usuarioSesion, proyecto } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion,
    proyecto: state.contratoSesion.proyecto
  }));

  const values = {
    _id: null,
    actividadSeleccionada: "",
    baglog:false,
    cantidad:0,
    cantidad_programada: 0,
    cantidadTotal: 0,
    comentario_baglog: "",
    horas_hombre: 0,
    horas_hombre_programadas: 0,
    horasHombreTotal: 0,
    metodo_accion: "",
    observacion: "",
    responsable_accion: ""
  };


  const handleChange = (name, e) => {
    formik.setFieldValue(name, e.target.value);
    formik.setFieldTouched(name, true, false);
  };

  const handleChangeSwitch = (name, event) => {
    formik.setFieldValue(name, event.target.checked);
    if (name === "baglog" && event.target.checked) {
      formik.setFieldValue("comentario_baglog", "");
    }
    formik.setFieldTouched(name, true, false);
  }


  const handleOnSubmit = (values, formikBag) => {
    props.handleSubmit(values);
  }

  const handleEnterDialog = async () => {
    if (props.data) {
      const actividad = props.data;
      // console.log(actividad);
      if(actividad.avancePod){
        const avancePod = actividad.avancePod;
        formik.resetForm({
          _id: avancePod._id,
          actividadSeleccionada: `[${actividad.item}] [${actividad.id_item}] ${actividad.nombre}`,
          baglog: avancePod.baglog ? avancePod.baglog : false,
          cantidad: avancePod.cantidad ? avancePod.cantidad : 0,
          cantidad_programada: avancePod.cantidad_programada ? avancePod.cantidad_programada : 0,
          cantidadTotal: actividad.cantidad ? actividad.cantidad : 0,
          comentario_baglog: avancePod.comentario_baglog ? avancePod.comentario_baglog : "",
          horas_hombre: avancePod.horas_hombre ? avancePod.horas_hombre : 0,
          horas_hombre_programadas: avancePod.horas_hombre_programadas ? avancePod.horas_hombre_programadas : 0,
          horasHombreTotal: actividad.horas_hombre ? actividad.horas_hombre : 0,
          metodo_accion: avancePod.metodo_accion ? avancePod.metodo_accion :"",
          observacion: avancePod.observacion ? avancePod.observacion : "",
          responsable_accion: avancePod.responsable_accion ? avancePod.responsable_accion : ""
        });
      }
      else{
        formik.resetForm({
          ...values,
          actividadSeleccionada: `[${actividad.item}] [${actividad.id_item}] ${actividad.nombre}`
        });
      }
    }
  }

  return (

    <React.Fragment>
      <Formik
        ref={(ref) => setFormik(ref)}
        onSubmit={handleOnSubmit}
        initialValues={values}
        validationSchema={validationSchema}
        isInitialValid={true}
        render={formikProps =>
          <Page
            {...formikProps}
            actividades={actividades}
            handleChange={handleChange}
            handleChangeSwitch={handleChangeSwitch}
            handleClose={props.handleClose}
            handleEnterDialog={handleEnterDialog}
            metodosAccion={metodosAccion}
            open={props.open}
            responsables={responsables}
          />
        }
      />
    </React.Fragment>
  );
}