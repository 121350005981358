/**
 * Vista principal de contendor de reportes diarios pod
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Tooltip,
    IconButton,
    Container,
    Tabs,
    Grid,
    Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as ROUTES from '../../constants/routes';
import './style.css';
import ListadoReportesDiariosPod from './listadoReportesPodDiarios';
import { ArrowBack } from '@material-ui/icons';
import IndicadoresReportesPodDiarios from './indicadoresReportesPodDiarios';
import ListadoLineaBase from './listadoLineaBase';
import ListadoDiaLineaBase from './listadoDiaLineaBase';
const useStyles = makeStyles(theme => ({
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    }

}));

function Page(props) {
    const classes = useStyles();
    const {
        goTo,
        proyecto,
    } = props;
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Container className={classes.main}>
            <Card className={classes.card}>
                <CardHeader
                    action={
                        <Tooltip title="Ir al Programa">
                            <IconButton onClick={() => goTo(`${ROUTES.PROGRAMA}`)} aria-label="settings">
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                    }
                    titleTypographyProps={{
                        align: "center"
                    }}
                    title={proyecto ? (proyecto.codigo ? `[${proyecto.codigo}] ` : '') + (proyecto.nombre ? proyecto.nombre : '') : ""}
                />
                <CardContent>
                    <IndicadoresReportesPodDiarios />
                </CardContent>
            </Card>
            <Card className={classes.mainPrograma}>
                <CardContent>
                    <Grid container>
                        <Grid item lg>
                            <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} aria-label="content-tabs">
                                <Tab label="Reportes Diarios" id="tabReportesDiarios" aria-controls="paneReportesDiarios" />
                                <Tab label="Líneas Base" id="tabLineaBase" aria-controls="panelLineaBase" />
                                <Tab label="Días Línea Base" id="tabDiasLineaBase" aria-controls="paneDiaslLineaBase" />
                            </Tabs>
                        </Grid>
                    </Grid>
                    {selectedTab === 0 && <div id="paneReportesDiarios"><ListadoReportesDiariosPod /></div>}
                    {selectedTab === 1 && <div id="panelLineaBase"><ListadoLineaBase /></div>}
                    {selectedTab === 2 && <div id="paneDiaslLineaBase"><ListadoDiaLineaBase /></div>}
                </CardContent>
            </Card>
        </Container >
    );
}

export default Page;