import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    InputLabel,
    InputAdornment,
} from '@material-ui/core';
import ReactSelect from 'react-select';
import NumberInput from '../../../components/numberInput';
import formatNumber from 'format-number';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    dialogPaper: {
        overflow: 'visible'
    }
}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        open,
        isValid,
        handleEnterDialog,
        handleSubmit,
        errors,
        touched,
        handleCloseDialog,
        suggestionsTitulo
    } = props;

    const change = (name, e) => {
        const { setFieldTouched, handleChange, handleChangePrecioCantidad, values, setFieldValue } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        if (name === "titulo") {
            setFieldValue(name, e);
            return;
        }
        e.persist();
        handleChangePrecioCantidad(e.target, values);
    };
    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleCloseDialog}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <InputLabel htmlFor="titulo">Título</InputLabel>
                            <ReactSelect
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                searchable={true}
                                value={values.titulo}
                                onChange={change.bind(null, "titulo")}
                                name="titulo"
                                placeholder="Elegir a un título"
                                options={suggestionsTitulo}
                                noOptionsMessage={() => "No hay resultados"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg={4}>
                            <TextField
                                name="item"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                helperText={touched.item ? errors.item : ""}
                                error={touched.item && Boolean(errors.item)}
                                label="Ítem"
                                margin="normal"
                                value={values.item}
                                fullWidth
                                variant="outlined"
                                onChange={change.bind(null, "item")}

                            />
                        </Grid>
                        <Grid item lg>
                            <TextField
                                name="nombre"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                helperText={touched.nombre ? errors.nombre : ""}
                                error={touched.nombre && Boolean(errors.nombre)}
                                label="Nombre"
                                margin="normal"
                                value={values.nombre}
                                fullWidth
                                variant="outlined"
                                onChange={change.bind(null, "nombre")}

                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="precio_unitario"
                                type="text"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                meta={{
                                    touched: touched.precio_unitario,
                                    error: errors.precio_unitario
                                }}
                                label="Precio Unitario"
                                value={(values.precio_unitario)}
                                textVariant="outlined"
                                custom={{
                                    margin: "normal",
                                    InputProps: {
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }

                                }}
                                handleOnChange={change.bind(null, "precio_unitario")}

                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="cantidad"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                meta={{
                                    touched: touched.cantidad,
                                    error: errors.cantidad

                                }}
                                label="Cantidad"
                                textVariant="outlined"
                                value={(values.cantidad)}
                                custom={{
                                    margin: "normal"
                                }}
                                handleOnChange={change.bind(null, "cantidad")}

                            />
                        </Grid>
                        <Grid item lg>
                            <TextField
                                name="unidad"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                helperText={touched.unidad ? errors.unidad : ""}
                                error={touched.unidad && Boolean(errors.unidad)}
                                label="Unidad"
                                margin="normal"
                                value={values.unidad}
                                fullWidth
                                variant="outlined"
                                onChange={change.bind(null, "unidad")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="precio_total"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                label="Precio Total"
                                value={(values.precio_total)}
                                disabled={true}
                                textVariant="outlined"
                                custom={{
                                    margin: "normal",
                                    InputProps: {
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }

                                }}
                                handleOnChange={change.bind(null, "precio_total")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
