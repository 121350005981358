import Axios from "../axios";

export const getArchivo = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/archivos/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const getArchivos = async (query = {}) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/archivos/`, {
            params: query
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const updateArchivo = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/archivos/${id}`, params)
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

export const deleteArchivo = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/archivos/${id}`, {
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

export const postArchivo = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/archivos/`, body)
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}