import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { getItemsPrograma } from '../../../../services/database/programacion';
import Page from './page';

export default function EditarActividadPod(props) {

  const [formik, setFormik] = useState(null);
  const [actividades, setActividades] = useState([]);
  const validationSchema = Yup.object({
    cantidad_programada: Yup.number().typeError("Debe ingresar un valor númerico").required("Campo requerido").min(0, "Debe ser un valor mayor o igual que 0"),
    horas_hombre_programadas: Yup.number().typeError("Debe ingresar un valor númerico").min(0, "Debe ser un valor mayor o igual que 0").required("Campo requerido")
  });
  const { usuarioSesion, proyecto } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion,
    proyecto: state.contratoSesion.proyecto
  }));

  const values = {
    actividadSeleccionada: "",
    cantidadTotal: 0,
    horasHombreTotal: 0,
    cantidad_programada: 0,
    horas_hombre_programadas: 0,
    baglog: false,
    comentario_baglog: ""
  };


  const handleChange = (name, e) => {
    formik.setFieldValue(name, e.target.value);
    formik.setFieldTouched(name, true, false);
    e.persist();
  };

  const handleChangeSwitch = (name, event) => {
    formik.setFieldValue(name, event.target.checked);
    if (name === "baglog" && event.target.checked) {
      formik.setFieldValue("comentario_baglog", "");
    }
    formik.setFieldTouched(name, true, false);
  }


  const handleOnSubmit = (values, formikBag) => {
    props.handleSubmit(values);
  }

  const handleEnterDialog = async () => {
    if (props.data) {
      const actividad = props.data;
      formik.resetForm({
        actividadSeleccionada: `[${actividad.item}] [${actividad.id_item}] ${actividad.nombre}`,
        cantidadTotal: actividad.cantidad ? actividad.cantidad : 0,
        horasHombreTotal: actividad.horas_hombre ? actividad.horas_hombre : 0,
        cantidad_programada: actividad.cantidad_programada ? actividad.cantidad_programada : 0,
        horas_hombre_programadas: actividad.horas_hombre_programadas ? actividad.horas_hombre_programadas : 0,
        baglog: actividad.baglog ? actividad.baglog : false,
        comentario_baglog: actividad.comentario_baglog ? actividad.comentario_baglog : ""
      });
    }
  }

  return (

    <React.Fragment>
      <Formik
        ref={(ref) => setFormik(ref)}
        onSubmit={handleOnSubmit}
        initialValues={values}
        validationSchema={validationSchema}
        isInitialValid={true}
        render={formikProps =>
          <Page
            {...formikProps}
            actividades={actividades}
            handleChange={handleChange}
            handleChangeSwitch={handleChangeSwitch}
            handleClose={props.handleClose}
            handleEnterDialog={handleEnterDialog}
            open={props.open}

          />
        }
      />
    </React.Fragment>
  );
}