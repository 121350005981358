import React, { Component } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';

export default class DialogoConfirmar extends Component {
  constructor(props) {
    super(props);

    this.state = { fileName: this.props.fileName };

  }

  render() {
    const { open, handleClose, action, message, title } = this.props;
    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle>{title ? title : "Confirmar"}</DialogTitle>
        <DialogContent>
          {message ? message : ""}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={action}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog >
    )
  }
}