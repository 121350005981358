import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';

const logo = require('../../logo.svg');
const useStyles = makeStyles(theme => ({
    center: {
        textAlign: "center",
        paddingTop: theme.spacing(11)
    },
    img: {
        height: 100,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    linearProgress: {
        marginLeft: '60vh', marginRight: '60vh'
    },
    title: {
        margin: theme.spacing(3, 1, 3, 1)
    }
}));
function Page(props) {
    const classes = useStyles();
    return (
        <Fragment>
            <Grid container alignContent="center" alignItems="center">
                <Grid item lg className={classes.center}>
                    <img className={classes.img} src={logo} alt="logo" />
                    <Typography className={classes.title} variant="h5">Redirigiendo a {props.url ? props.url : "nuevo destino"}</Typography>
                    <LinearProgress color="primary" className={classes.linearProgress} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Page;
