/**
 * Lógica de listado reportes diarios pod
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { useHistory } from 'react-router';
import moment from 'moment';
import Axios from 'axios';
// import { openAgregarReportePodDiario, openEliminarReportePodDiario, closeEliminarReportePodDiario, closeAgregarReportePodDiario, actualizarGraficoLineaBaseSemanal } from '../actionsReportePodDiario';
import { getAllDiaLineaBase } from '../requestsDiaLineaBase';
import { openAgregarDiaLineaBase, closeAgregarDiaLineaBase, actualizarGraficoLineaBaseSemanal, openEditarDiaLineaBase, closeEditarDiaLineaBase, openEliminarDiaLineaBase, closeEliminarDiaLineaBase, actualizarTablaDiaLineaBase } from '../actionsReportePodDiario';
moment.locale('es');
export default function ListadoDiaLineaBase() {

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        proyecto,
        usuarioSesion,
        actualizar
    } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto,
        usuarioSesion: state.usuarioSesion,
        actualizar: state.reducerReportePodDiario.actualizarTablaDiaLineaBase
    }));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Cargar reportes pod diarios de un proyecto
     * @param {Object} proyecto
     */
    const cargarData = (proyecto) => {
        if (proyecto) {
            const CancelToken = Axios.CancelToken;
            let cancelRequest = null;
            let cancelToken = new CancelToken(function executor(c) {
                cancelRequest = c;
            });
            getAllDiaLineaBase({ proyecto_ref: proyecto._id }, cancelToken)
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 204) {
                        setData([]);
                        setIsLoading(false);
                    } else if (response.status == 200) {
                        console.log(response.data);
                        setData(response.data);
                        setIsLoading(false);
                    } else {
                        setData([]);
                        setIsLoading(false);
                    }
                })
                .catch(e => {
                    setData([]);
                    console.log(e);
                    setIsLoading(false);
                });
            return cancelRequest;
        }
        setData([]);
        setIsLoading(false);
        return null;
    }

    /**
     * Abrir dialogo para agregar un reporte pod diario
     */
    const handleOpenAgregarDiaLineaBase = () => dispatch(openAgregarDiaLineaBase(() => {
        dispatch(closeAgregarDiaLineaBase());
        dispatch(actualizarGraficoLineaBaseSemanal());
        cargarData(proyecto);
    }));

    /**
     * Abrir dialogo para editar un reporte pod diario
     * @param {Object} data Reporte pod diario a eliminar
     */
    const handleOpenEditarDiaLineaBase = (data) => dispatch(openEditarDiaLineaBase(data, () => {
        dispatch(closeEditarDiaLineaBase());
        dispatch(actualizarGraficoLineaBaseSemanal());
        cargarData(proyecto);
    }));
    /**
     * Abrir dialogo para eliminar un reporte pod diario
     * @param {Object} data Reporte pod diario a eliminar
     */
    const handleOpenEliminarDiaLineaBase = (data) => dispatch(openEliminarDiaLineaBase(data, () => {
        dispatch(closeEliminarDiaLineaBase());
        dispatch(actualizarGraficoLineaBaseSemanal());
        cargarData(proyecto);
    }));



    useEffect(() => {
        setIsLoading(true);
        const cancelRequest = cargarData(proyecto);
        return () => {
            if (cancelRequest) cancelRequest();
            setIsLoading(false);
        }
    }, [proyecto]);


    // useEffect(() => {
    //     if (actualizar) {
    //         setIsLoading(true);
    //         const cancelRequest = cargarData(proyecto);
    //         return () => {
    //             if (cancelRequest) cancelRequest();
    //             setIsLoading(false);
    //         }
    //     }

    // }, [actualizar]);


    return (
        <Page
            data={data}
            goTo={(path) => history.push(path)}
            isLoading={isLoading}
            handleOpenAgregarDiaLineaBase={handleOpenAgregarDiaLineaBase}
            handleOpenEditarDiaLineaBase={handleOpenEditarDiaLineaBase}
            handleOpenEliminarDiaLineaBase={handleOpenEliminarDiaLineaBase}
            usuarioSesion={usuarioSesion}
        />
    );
}