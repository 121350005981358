import React, { useState, Fragment } from 'react';
import MaterialTable, { MTableAction } from 'material-table';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import { Description, ArrowBack, MoreVert } from '@material-ui/icons';
import formatNumber from 'format-number';
import Moment from 'react-moment';
import moment from 'moment';
import {
    Table,
    TableHead,
    TableCell as MaterialTableCell,
    TableRow,
    TableBody,
    Card,
    CardContent,
    Grid,
    Container,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';
import MenuDropdown from '../../../components/menuDropDown';
import EliminarItemProgramacionSemanal from '../eliminarItemProgramacionSemanal';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    },
    gridCardTitle: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    }
}));
const TableCell = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        border: "1px solid #E0E0E0",
        fontSize: 14,
    },
}))(MaterialTableCell);
const TableCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
    },
    body: {
        fontSize: 14,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
    },

}))(MaterialTableCell);
const TableCell2 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
    },
}))(MaterialTableCell);
const BlankCell = withStyles(theme => ({
    head: {
        border: "none",
        minWidth: "8px"
    },
    body: {
        border: "none",
        minWidth: "8px"
    }
}))(MaterialTableCell);
export default function Page(props) {

    const classes = useStyles();

    const {
        data,
        diasSemana,
        handleOpenEliminarItemProgramaSemanal,
        handleSubmitEliminarItemProgramaSemanal,
        isLoading,
        title,
        usuarioSesion
    } = props;
    const [pageSize, setPageSize] = useState(10);
    

    return (
        <Container className={classes.main}>

            <Card className={classes.gridCardTitle}>
                <CardContent>
                    <Grid container
                        alignContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item lg style={{ textAlign: "left" }}>
                            <Typography
                                align="left"
                                variant={"h5"}
                                className={classes.cardHeaderTypography}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item lg={2} style={{ textAlign: "right" }}>
                            <Tooltip title="Volver">
                                <IconButton component={Link} to={`${ROUTES.PROGRAMACION_SEMANAL}`} aria-label="settings">
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
            <MaterialTable
                title={`Listado de Items`}
                options={{
                    filtering: false,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false,
                    columnsButton: true,
                    maxBodyHeight: "70vh",
                    headerStyle: {
                        textAlign: 'center',
                        border: "1px solid #E0E0E0",
                        fontWeight: 'bold',
                        color: '#731f1f',
                        position: 'sticky'
                    },
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'center'
                    },
                    rowStyle: {
                        border: "1px solid #E0E0E0"
                    },
                    padding: 'dense',
                    actionsCellStyle: {
                        width: "2%",
                        textAlign: "center"
                    },
                    detailPanelType: 'single'
                }}
                detailPanel={
                    [
                        {
                            icon: () => <Description />,
                            openIcon: () => <Description style={{ color: "#000" }} />,
                            tooltip: "Detalles",
                            render: rowData => {
                                return (
                                    <div style={{
                                        margin: "16px"
                                    }}>
                                        <Table padding="none" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {diasSemana.map((dia, index) => (
                                                        <Fragment key={index}>
                                                            <TableCell1 align="center" colSpan="4"><b>{dia}</b></TableCell1>
                                                            <BlankCell />
                                                        </Fragment>))}
                                                </TableRow>
                                                <TableRow>
                                                    {diasSemana.map((dia, index) => (
                                                        <Fragment key={index}>
                                                            <TableCell2 align="center" colSpan="2"><b>Cant.</b></TableCell2>
                                                            <TableCell2 align="center" colSpan="2"><b>Avance</b></TableCell2>
                                                            {/* <TableCell2 align="center" colSpan="2"><b>HH</b></TableCell2> */}
                                                            <BlankCell />
                                                        </Fragment>
                                                    ))}
                                                </TableRow>
                                                <TableRow>
                                                    {diasSemana.map((dia, index) => (
                                                        <Fragment key={index}>
                                                            <TableCell align="center"><b>Prog.</b></TableCell>
                                                            <TableCell align="center"><b>Real</b></TableCell>
                                                            <TableCell align="center"><b>Prog.</b></TableCell>
                                                            <TableCell align="center"><b>Real</b></TableCell>
                                                            <BlankCell />
                                                            {/* <TableCell align="center"><b>Prog.</b></TableCell>
                                                            <TableCell align="center"><b>Real</b></TableCell> */}
                                                        </Fragment>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    {diasSemana.map((dia, index) => (
                                                        <Fragment key={index}>
                                                            <TableCell align="center">{rowData.dias && rowData.dias[index] && rowData.dias[index].cantidad_programada}</TableCell>
                                                            <TableCell align="center">{rowData.dias && rowData.dias[index] && rowData.dias[index].cantidad_real}</TableCell>
                                                            <TableCell align="center">{rowData.dias && rowData.dias[index] && rowData.dias[index].avance_programado}%</TableCell>
                                                            <TableCell align="center">{rowData.dias && rowData.dias[index] && rowData.dias[index].avance_real}%</TableCell>
                                                            <BlankCell />
                                                            {/* <TableCell align="center">{rowData.dias && rowData.dias[index] && rowData.dias[index].horas_hombre_programadas}</TableCell>
                                                            <TableCell align="center">{rowData.dias && rowData.dias[index] && rowData.dias[index].horas_hombre_reales}</TableCell> */}
                                                        </Fragment>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                )

                            }
                        }
                    ]
                }
                onChangeRowsPerPage={size => { setPageSize(size) }}
                localization={location}
                isLoading={isLoading}
                // actions={[
                //     {
                //         tooltip: 'Administrar',
                //         icon: 'settings',
                //         onClick: () => { }
                //     },
                // ]}
                // components={{
                //     Action:
                //         props => {
                //             const rowData = props.data;
                //             if (props.action.icon === 'settings') {
                //                 const accionesConfiguracion = [];
                //                 if (usuarioSesion && usuarioSesion.permisos) {
                //                     accionesConfiguracion.push({
                //                         content: 'Eliminar',
                //                         onClick: (e) => handleOpenEliminarItemProgramaSemanal(rowData)
                //                     });
                //                 }
                //                 if (accionesConfiguracion.length > 0) return (
                //                     <MenuDropdown
                //                         icon={
                //                             <MoreVert />
                //                         }
                //                         tooltip="Opciones"
                //                         items={accionesConfiguracion}
                //                         hidden={accionesConfiguracion.length <= 0}
                //                     />
                //                 );
                //             }
                //             return (<MTableAction {...props} />);
                //         }
                // }}
                columns={[
                    {
                        title: "Id",
                        field: 'id_item_programa',
                        defaultSort: 'asc'
                    },
                    {
                        title: "HH Totales",
                        field: 'horas_hombre_totales',
                        render: rowData => {
                            return numberFormater(parseFloat(rowData.horas_hombre_totales).toFixed(2));
                        },
                    },
                    {
                        title: "Rendimiento HH",
                        field: 'rendimientos_horas_hombre',
                        render: rowData => {
                            return numberFormater(parseFloat(rowData.rendimientos_horas_hombre).toFixed(2));
                        },
                    },
                    {
                        title: "Cant. Remanentes",
                        field: 'cantidad_restante',
                        render: rowData => {
                            return numberFormater(parseFloat(rowData.cantidad_restante).toFixed(2));
                        },
                    },
                    {
                        title: "Unidad",
                        field: 'unidad'
                    },
                    {
                        title: "HH Programadas",
                        field: 'horas_hombre_programadas_totales',
                        render: rowData => {
                            return numberFormater(parseFloat(rowData.horas_hombre_programadas_totales).toFixed(2));
                        },
                    },
                    {
                        title: "Avance Diario",
                        field: 'cantidad_diaria',
                        render: rowData => {
                            return numberFormater(parseFloat(rowData.cantidad_diaria).toFixed(2));
                        },
                    },
                    {
                        title: "Duración",
                        field: 'duracion',
                        render: rowData => {
                            return numberFormater(parseFloat(rowData.duracion).toFixed(2));
                        },
                    },
                    
                    {
                        title: 'Inicio',
                        field: 'inicio',
                        render: rowData => {
                            return rowData.inicio ? moment(rowData.inicio).format("DD/MM/YYYY") : "";
                        }
                    },
                    {
                        title: 'Término',
                        field: 'termino',
                        render: rowData => {
                            return rowData.termino ? moment(rowData.termino).format("DD/MM/YYYY") : "";
                        }
                    },
                    {
                        title: `${diasSemana[0]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[0] && (rowData.dias[0].avance_programado || rowData.dias[0].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[0].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: `${diasSemana[1]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[1] && (rowData.dias[1].avance_programado || rowData.dias[1].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[1].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: `${diasSemana[2]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[2] && (rowData.dias[2].avance_programado || rowData.dias[2].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[2].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: `${diasSemana[3]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[3] && (rowData.dias[3].avance_programado || rowData.dias[3].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[3].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },

                    {
                        title: `${diasSemana[4]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[4] && (rowData.dias[4].avance_programado || rowData.dias[4].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[4].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: `${diasSemana[5]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[5] && (rowData.dias[5].avance_programado || rowData.dias[5].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[5].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: `${diasSemana[6]} (Av. Prog.)`,
                        field: 'dias',
                        render: rowData => {
                            if (rowData.dias && rowData.dias[6] && (rowData.dias[6].avance_programado || rowData.dias[6].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias[6].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    }

                ]}
                data={data.items_programa_semanal_ref}
            />
            <EliminarItemProgramacionSemanal
                handleSubmit={handleSubmitEliminarItemProgramaSemanal}
            />
        </Container>
    );
}

function generarFechaView(data, format) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }

}