import Axios from "../axios";

export const getRecurso = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/recursos/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const getRecursos = async () => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/recursos/`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}


export const updateRecurso = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/recursos/${id}`, params)
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

export const deleteRecurso = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/recursos/${id}`, {
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

export const postRecurso = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/recursos/`, body)
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}