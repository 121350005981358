import React, { useState, useEffect } from 'react';
import Page from './page';
import { Formik } from "formik";
import * as Yup from "yup";
import XLSX from 'xlsx';
import CustomizedSnackbars from '../../../components/customizedSnackbar';
import { postPrograma, postItemPrograma, updateItemPrograma, updatePrograma, getPrograma, getItemPrograma, getItemsPrograma, getItemProgramaByItem } from '../../../services/database/programacion';
import moment from 'moment';
import { updateProyecto, agregarProgramaProyecto, getProyecto } from '../../../services/database/proyecto';
import { useSelector, useDispatch } from 'react-redux';
import { downloadUrlAsPromise } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import { accionSetProyecto } from '../../../redux/actions/contratoUsuario';
import ProyectoModel from '../../../model/ProyectoModel';
import { useHistory } from 'react-router';

export default function CargaProgramacion(props) {
    const dispatch = useDispatch();
    const { usuarioSesion, proyecto } = useSelector(state => ({ usuarioSesion: state.usuarioSesion, proyecto: state.contratoSesion.proyecto }));
    const [snackbar, setSnackBar] = useState({
        snackbarMessage: "",
        snackbarVariant: "info",
        snackbarOpen: false,
        snackbarHideButton: false
    });
    const [openVistaPrevia, setOpenVistaPrevia] = useState(false);
    const [loadingVistaPrevia, setLoadingVistaPrevia] = useState(true);
    const [titulos, setTitulos] = useState([]);
    const [actividades, setActividades] = useState([]);
    const [formik, setFormik] = useState(null);
    const history = useHistory();

    const verVistaPrevia = async (values) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            const binary = new Uint8Array(e.target.result);
            const workbook = XLSX.read(binary, { type: 'array' });
            let programacionNueva = {};
            let titulosExcel = [];
            let actividadesExcel = [];

            //CARGA PROGRAMA

            let sheet_name = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheet_name];
            let desired_row = 2;
            let desired_cell = worksheet["A" + desired_row];
            let desired_value = (desired_cell ? desired_cell.v : undefined);
            let fechaInicioProyecto = new Date();
            let fechaTerminoProyecto = new Date();
            while (desired_value) {
                if (desired_value) {
                    let rowData = {
                        item: (worksheet["A" + desired_row]) ? worksheet["A" + desired_row].w : "",
                        id_item: (worksheet["B" + desired_row]) ? worksheet["B" + desired_row].w.replace(/\s/g, "") : "",
                        nombre: (worksheet["C" + desired_row]) ? worksheet["C" + desired_row].w : "",
                        is_hoja: true,
                        hijos: [],
                        padre_item: null,
                        horas_hombre: 0
                    };

                    let itemSplit = rowData.item.split(".");
                    if (itemSplit.length > 1) {
                        itemSplit.pop();
                        let idPadre = itemSplit.join(".");
                        if (programacionNueva[idPadre]) {
                            rowData.padre_item = idPadre;
                            programacionNueva[idPadre].hijos.push(rowData.item);
                        }
                    }

                    let next_row_item = worksheet["A" + (desired_row + 1)];
                    if (next_row_item && next_row_item.w && next_row_item.w.includes(rowData.item)) {
                        rowData["is_hoja"] = false;
                    } else {
                        rowData["inicio_temprano"] = (worksheet["D" + desired_row]) ? (new Date(worksheet["D" + desired_row].w)) : null;
                        rowData["inicio_ideal"] = (worksheet["E" + desired_row]) ? (new Date(worksheet["E" + desired_row].w)) : null;
                        rowData["inicio_tardio"] = (worksheet["F" + desired_row]) ? (new Date(worksheet["F" + desired_row].w)) : null;
                        rowData["termino_temprano"] = (worksheet["G" + desired_row]) ? (new Date(worksheet["G" + desired_row].w)) : null;
                        rowData["termino_ideal"] = (worksheet["H" + desired_row]) ? (new Date(worksheet["H" + desired_row].w)) : null;
                        rowData["termino_tardio"] = (worksheet["I" + desired_row]) ? (new Date(worksheet["I" + desired_row].w)) : null;
                        rowData["horas_hombre"] = (worksheet["J" + desired_row]) ? parseFloat(worksheet["J" + desired_row].w) : 0;
                        if (rowData.padre_item) {
                            actualizarFechasProgramadas(rowData.padre_item, programacionNueva, {
                                inicio_ideal: rowData.inicio_ideal,
                                inicio_temprano: rowData.inicio_temprano,
                                inicio_tardio: rowData.inicio_tardio,
                                termino_temprano: rowData.termino_temprano,
                                termino_ideal: rowData.termino_ideal,
                                termino_tardio: rowData.termino_tardio
                            });
                            actualizarHH(rowData.padre_item, programacionNueva, rowData.horas_hombre);
                        }
                        if (rowData.inicio_ideal && moment(rowData.inicio_ideal).isBefore(moment(fechaInicioProyecto))) fechaInicioProyecto = rowData.inicio_ideal;
                        if (rowData.termino_ideal && moment(rowData.termino_ideal).isAfter(moment(fechaTerminoProyecto))) fechaTerminoProyecto = rowData.termino_ideal;
                    }

                    if (!rowData.padre_item) titulosExcel.push(rowData);
                    actividadesExcel.push(rowData);
                    if (worksheet["E" + desired_row] && worksheet["H" + desired_row]) {
                        let duracion = moment(new Date(worksheet["H" + desired_row].w)).diff(moment(new Date(worksheet["E" + desired_row].w)), 'days');
                        rowData.duracion = (duracion + 1);
                    }
                    programacionNueva[rowData.item] = rowData;
                } else {
                    break;
                }
                desired_row++;
                desired_cell = worksheet["A" + desired_row];
                desired_value = (desired_cell ? desired_cell.v : undefined);
            }
            setTitulos(titulosExcel);
            setActividades(actividadesExcel);
            setOpenVistaPrevia(true);
            setLoadingVistaPrevia(true);
        };
        fileReader.readAsArrayBuffer(values.file);
    }

    const actualizarHH = (actual, array, hh) => {
        try {
            const item = array[actual];
            item.horas_hombre = item.horas_hombre + hh;
            if (item.padre_item) actualizarHH(item.padre_item, array, hh);
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    const actualizarFechasProgramadas = async (actual, array, fechas) => {
        try {
            const item = array[actual];
            if (fechas.inicio_ideal) {
                fechas.inicio_ideal = (!item.inicio_ideal || moment(fechas.inicio_ideal).isBefore(moment(item.inicio_ideal))) ? fechas.inicio_ideal : item.inicio_ideal;
                item["inicio_ideal"] = fechas.inicio_ideal;
            }
            if (fechas.termino_ideal) {
                fechas.termino_ideal = (!item.termino_ideal || moment(fechas.termino_ideal).isAfter(moment(item.termino_ideal))) ? fechas.termino_ideal : item.termino_ideal;
                item["termino_ideal"] = fechas.termino_ideal;
            }
            if (fechas.inicio_temprano) item["inicio_temprano"] = fechas.inicio_temprano = (!item.inicio_temprano || moment(fechas.inicio_temprano).isBefore(moment(item.inicio_temprano))) ? fechas.inicio_temprano : item.inicio_temprano;
            if (fechas.termino_temprano) item["termino_temprano"] = fechas.termino_temprano = (!item.termino_temprano || moment(fechas.termino_temprano).isAfter(moment(item.termino_temprano))) ? fechas.termino_temprano : item.termino_temprano;
            if (fechas.inicio_tardio) item["inicio_tardio"] = fechas.inicio_tardio = (!item.inicio_tardio || moment(fechas.inicio_tardio).isBefore(moment(item.inicio_tardio))) ? fechas.inicio_tardio : item.inicio_tardio;
            if (fechas.termino_tardio) item["termino_tardio"] = fechas.termino_tardio = (!item.termino_tardio || moment(fechas.termino_tardio).isAfter(moment(item.termino_tardio))) ? fechas.termino_tardio : item.termino_tardio;
            if (fechas.inicio_ideal && fechas.termino_ideal) {
                let inicio = moment(fechas.inicio_ideal);
                let termino = moment(fechas.termino_ideal);
                item["duracion"] = fechas.duracion = ((termino.diff(inicio, "days")) + 1);
            }
            if (item.padre_item) actualizarFechasProgramadas(item.padre_item, array, fechas);
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    const handleSubmit = async (values) => {
        let fileReader = new FileReader();
        let fechaInicioProyecto = new Date();
        let fechaTerminoProyecto = new Date();
        fileReader.onload = async (e) => {
            const binary = new Uint8Array(e.target.result);
            const workbook = XLSX.read(binary, { type: 'array' });
            let programacionNueva = {};
            let titulosExcel = [];
            let actividadesExcel = [];

            //CARGA PROGRAMA

            let sheet_name = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheet_name];
            let desired_row = 2;
            let desired_cell = worksheet["A" + desired_row];
            let desired_value = (desired_cell ? desired_cell.v : undefined);

            while (desired_value) {
                if (desired_value) {
                    let rowData = {
                        item: (worksheet["A" + desired_row]) ? worksheet["A" + desired_row].w : "",
                        id_item: (worksheet["B" + desired_row]) ? (worksheet["B" + desired_row].w).replace(/\s/g, "") : "",
                        nombre: (worksheet["C" + desired_row]) ? worksheet["C" + desired_row].w : "",
                        is_hoja: true,
                        hijos_items: [],
                        actividadesHijas: [],
                        padre_item: null,
                        horas_hombre: 0,
                        padre: null,
                        item_presupuesto: (worksheet["M" + desired_row]) ? worksheet["M" + desired_row].w : "",
                        costo_directo: (worksheet["N" + desired_row]) ? worksheet["N" + desired_row].v : 0
                    };
                    let itemSplit = rowData.item.split(".");
                    if (itemSplit.length > 1) {
                        itemSplit.pop();
                        let idPadre = itemSplit.join(".");
                        if (programacionNueva[idPadre]) {
                            rowData.padre_item = idPadre;
                            programacionNueva[idPadre].is_hoja = false;
                            programacionNueva[idPadre].hijos_items.push(rowData.item);
                            programacionNueva[idPadre].actividadesHijas.push(rowData);
                        }
                    }
                    // let next_row_item = worksheet["A" + (desired_row + 1)];
                    // if (next_row_item && next_row_item.w && next_row_item.w.includes(rowData.item)) {
                    //     rowData["is_hoja"] = false;
                    // } else {
                        rowData["inicio_temprano"] = (worksheet["D" + desired_row]) ? (new Date(worksheet["D" + desired_row].w)) : null;
                        rowData["inicio_ideal"] = (worksheet["E" + desired_row]) ? (new Date(worksheet["E" + desired_row].w)) : null;
                        rowData["inicio_tardio"] = (worksheet["F" + desired_row]) ? (new Date(worksheet["F" + desired_row].w)) : null;
                        rowData["termino_temprano"] = (worksheet["G" + desired_row]) ? (new Date(worksheet["G" + desired_row].w)) : null;
                        rowData["termino_ideal"] = (worksheet["H" + desired_row]) ? (new Date(worksheet["H" + desired_row].w)) : null;
                        rowData["termino_tardio"] = (worksheet["I" + desired_row]) ? (new Date(worksheet["I" + desired_row].w)) : null;
                        rowData["horas_hombre"] = (worksheet["J" + desired_row]) ? parseFloat(worksheet["J" + desired_row].w) : 0;
                        // if (rowData.padre_item) {
                        //     actualizarFechasProgramadas(rowData.padre_item, programacionNueva, {
                        //         inicio_ideal: rowData.inicio_ideal,
                        //         inicio_temprano: rowData.inicio_temprano,
                        //         inicio_tardio: rowData.inicio_tardio,
                        //         termino_temprano: rowData.termino_temprano,
                        //         termino_ideal: rowData.termino_ideal,
                        //         termino_tardio: rowData.termino_tardio
                        //     });
                        //     actualizarHH(rowData.padre_item, programacionNueva, rowData.horas_hombre);
                        // }
                        // if (rowData.inicio_ideal && moment(rowData.inicio_ideal).isBefore(moment(fechaInicioProyecto))) fechaInicioProyecto = rowData.inicio_ideal;
                        // if (rowData.termino_ideal && moment(rowData.termino_ideal).isAfter(moment(fechaTerminoProyecto))) fechaTerminoProyecto = rowData.termino_ideal;
                    // }
                    if (!rowData.padre_item) titulosExcel.push(rowData);
                    actividadesExcel.push(rowData);
                    if (rowData.inicio_ideal && rowData.termino_ideal) {
                        let duracion = moment(rowData.termino_ideal).diff(moment(rowData.inicio_ideal), 'days');
                        rowData.duracion = duracion;
                    }
                    programacionNueva[rowData.item] = rowData;
                } else {
                    break;
                }
                desired_row++;
                desired_cell = worksheet["A" + desired_row];
                desired_value = (desired_cell ? desired_cell.v : undefined);
            }

            setSnackBar({
                snackbarMessage: "Subiendo Programa",
                snackbarVariant: "default",
                snackbarOpen: true,
                snackbarHideButton: false
            });
            try {
                // console.log("titulosExcel", titulosExcel);
                console.log("actividadesExcel", actividadesExcel);
                if (proyecto.programa_actual_ref) {
                    actualizarPrograma(titulosExcel, actividadesExcel);
                } else {
                    crearNuevoPrograma(titulosExcel, actividadesExcel);
                }

            }
            catch (error) {
                console.log(error);
                setSnackBar({
                    snackbarMessage: "Error en carga de Programa",
                    snackbarVariant: "error",
                    snackbarOpen: true,
                    snackbarHideButton: false
                });
            }


        };
        fileReader.readAsArrayBuffer(values.file);

    }

    const crearNuevoPrograma = async (titulos, actividades) => {
        const nuevoPrograma = await postPrograma({
            items_programas_ref: [],
            proyecto_ref: proyecto._id
        });
        for (let key = 0; key < titulos.length; key++) {
            const data = titulos[key];
            await insertData(data, nuevoPrograma, 0, titulos.length);
        }
        await updateProyecto(proyecto._id, {
            programa_actual_ref: nuevoPrograma._id
        });
        await agregarProgramaProyecto(proyecto._id, [nuevoPrograma._id]);
        const proyectoDB = await getProyecto(proyecto._id);
        if (proyectoDB) dispatch(accionSetProyecto(new ProyectoModel(proyectoDB)));
        setSnackBar({
            snackbarMessage: "Operación Existosa",
            snackbarVariant: "success",
            snackbarOpen: true,
            snackbarHideButton: false,
        });
        history.push("/programa");
    }

    const actualizarPrograma = async (titulos, actividades) => {
        const programa = await getPrograma(proyecto.programa_actual_ref);
        for (let key = 0; key < titulos.length; key++) {
            const data = titulos[key];
            await insertData(data, programa, 0, titulos.length);
        }
        const proyectoDB = await getProyecto(proyecto._id);
        if (proyectoDB) dispatch(accionSetProyecto(new ProyectoModel(proyectoDB)));
        setSnackBar({
            snackbarMessage: "Operación Existosa",
            snackbarVariant: "success",
            snackbarOpen: true,
            snackbarHideButton: false,
        });
    }

    const insertData = async (data, programa, actual = 0, total) => {
        // console.log(data);
        // let newData = {_id: data.item};
        let newData = null;
        if (!proyecto.programa_actual_ref) {
            newData = await postItemPrograma({
                item: data.item,
                id_item: data.id_item,
                nombre: data.nombre,
                is_hoja: data.is_hoja,
                horas_hombre: data.horas_hombre,
                padre: data.padre,
                inicio_ideal: data.inicio_ideal,
                termino_ideal: data.termino_ideal,
                inicio_ideal: data.inicio_ideal,
                inicio_temprano: data.inicio_temprano,
                termino_temprano: data.termino_temprano,
                inicio_tardio: data.inicio_tardio,
                termino_tardio: data.termino_tardio,
                programa_ref: programa._id,
                proyecto_ref: proyecto._id,
                costo_directo: data.costo_directo,
                item_presupuesto: data.item_presupuesto
            });
        } else {
            const oldData = proyecto.programa_actual_ref ? (await getItemProgramaByItem(proyecto.programa_actual_ref, data.item)) : null;
            if (oldData && oldData[0]) {
                newData = await updateItemPrograma(oldData[0]._id, {
                    // item: data.item,
                    // id_item: data.id_item,
                    nombre: data.nombre,
                    // is_hoja: data.is_hoja,
                    horas_hombre: data.horas_hombre,
                    // padre: data.padre,
                    inicio_ideal: data.inicio_ideal,
                    termino_ideal: data.termino_ideal,
                    inicio_ideal: data.inicio_ideal,
                    inicio_temprano: data.inicio_temprano,
                    termino_temprano: data.termino_temprano,
                    inicio_tardio: data.inicio_tardio,
                    termino_tardio: data.termino_tardio,
                    // programa_ref: programa._id,
                    // proyecto_ref: proyecto._id,
                    costo_directo: data.costo_directo,
                    // item_presupuesto: data.item_presupuesto
                });
            }
            else {
                newData = await postItemPrograma({
                    item: data.item,
                    id_item: data.id_item,
                    nombre: data.nombre,
                    is_hoja: data.is_hoja,
                    horas_hombre: data.horas_hombre,
                    padre: data.padre,
                    inicio_ideal: data.inicio_ideal,
                    termino_ideal: data.termino_ideal,
                    inicio_ideal: data.inicio_ideal,
                    inicio_temprano: data.inicio_temprano,
                    termino_temprano: data.termino_temprano,
                    inicio_tardio: data.inicio_tardio,
                    termino_tardio: data.termino_tardio,
                    programa_ref: programa._id,
                    proyecto_ref: proyecto._id,
                    costo_directo: data.costo_directo,
                    item_presupuesto: data.item_presupuesto
                });
            }
        }


        setSnackBar({
            snackbarMessage: `Subiendo Programa`,
            snackbarVariant: "default",
            snackbarOpen: true,
            snackbarHideButton: false,
        });
        if (data.actividadesHijas) {
            for (let i = 0; i < data.actividadesHijas.length; i++) {
                data.actividadesHijas[i].padre = newData._id;
                await insertData(data.actividadesHijas[i], programa, actual++, total);
            }
        }

        return;
    }

    const handleOnChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const snackbarHandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar({
            snackbarMessage: "",
            snackbarVariant: "info",
            snackbarOpen: false,
            snackbarHideButton: false
        });
    };

    const handleEnterVistaPrevia = () => {
        setLoadingVistaPrevia(false);
    }

    const handleCloseVistaPrevia = () => {
        setOpenVistaPrevia(false);
    };

    const handleDescargarFormato = async () => {
        const nombre = "Formato Programa.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/13gae9Iupfs4EIp3qTj8LrX57DGyuMVLZ?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }
    const validationSchema = Yup.object(
        {
            file: Yup.mixed().required("Campo requerido")
        });
    const values = {
        file: ""
    };

    return (
        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleOnChange}
                render={props =>
                    <Page
                        handleOnChange={handleOnChange}
                        openVistaPrevia={openVistaPrevia}
                        handleEnterVistaPrevia={handleEnterVistaPrevia}
                        actividades={actividades}
                        handleCloseVistaPrevia={handleCloseVistaPrevia}
                        loadingVistaPrevia={loadingVistaPrevia}
                        verVistaPrevia={verVistaPrevia}
                        handleDescargarFormato={handleDescargarFormato}
                        {...props}
                    />}
            />
            <CustomizedSnackbars
                open={snackbar.snackbarOpen}
                variant={snackbar.snackbarVariant}
                handleClose={snackbarHandleClose}
                message={snackbar.snackbarMessage}
                hideButton={snackbar.snackbarHideButton}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </React.Fragment>
    );

}