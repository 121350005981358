import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import NumberInput from '../../../components/numberInput';
/**
 * Vista de componente para agregar lineas base
 * @since 1.2.0
 * @author Luis San Martín S.
 * @property {Object} errors Errores del formulario
 * @property {function} handleChange Manejo de cambio de valores en el formulario
 * @property {function} handleClose Funcion para cerrar dialogo
 * @property {function} handleDateChange Manejo de cambio de valores de fecha en el formulario
 * @property {function} handleEnterDialog Manejo de evento de apertura del dialogo
 * @property {function} handleSubmit Manejo de evento submit del formulario
 * @property {Boolean} isValid Son validos o no los valores del formulario
 * @property {Boolean} open Abrir o no el dialogo
 * @property {String} titulo texto para mostrar en el titulo
 * @property {Object} touched Campos modificados del formulario
 * @property {Object} valores Valores de campos del formulario
 */
function Page(props) {
    const {
        errors,
        handleChange,
        handleClose,
        handleDateChange,
        handleEnterDialog,
        handleSubmit,
        isValid,
        open,
        titulo,
        touched,
        values
    } = props;

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}{}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    name="fecha"
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de control"
                                    format="dd/MM/yyyy"
                                    helperText={touched.fecha ? errors.fecha : ""}
                                    error={touched.fecha && Boolean(errors.fecha)}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={handleDateChange.bind(null, "fecha")}
                                    value={values.fecha}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_programadas"
                                type="text"
                                label="HH Programadas"
                                value={(values.horas_hombre_programadas)}
                                meta={{ touched: touched.horas_hombre_programadas, error: errors.horas_hombre_programadas }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "horas_hombre_programadas")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_programadas_acumuladas"
                                type="text"
                                label="HH Programadas Acumuladas"
                                value={(values.horas_hombre_programadas_acumuladas)}
                                meta={{ touched: touched.horas_hombre_programadas_acumuladas, error: errors.horas_hombre_programadas_acumuladas }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "horas_hombre_programadas_acumuladas")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_programadas_pod"
                                type="text"
                                label="HH Programadas POD"
                                value={(values.horas_hombre_programadas_pod)}
                                meta={{ touched: touched.horas_hombre_programadas_pod, error: errors.horas_hombre_programadas_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "horas_hombre_programadas_pod")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_ganadas"
                                type="text"
                                label="HH Ganadas"
                                value={(values.horas_hombre_ganadas)}
                                meta={{ touched: touched.horas_hombre_ganadas, error: errors.horas_hombre_ganadas }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "horas_hombre_ganadas")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_ganadas_pod"
                                type="text"
                                label="HH Ganadas POD"
                                value={(values.horas_hombre_ganadas_pod)}
                                meta={{ touched: touched.horas_hombre_ganadas_pod, error: errors.horas_hombre_ganadas_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "horas_hombre_ganadas_pod")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="cumplimiento_pod"
                                type="text"
                                label="Cumplimiento POD"
                                value={(values.cumplimiento_pod)}
                                meta={{ touched: touched.cumplimiento_pod, error: errors.cumplimiento_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "cumplimiento_pod")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
