export const ACCESS_ERROR = '/accessError';
export const AVANCES_ACTIVIDAD = '/programa/:id/avances';
export const CARGAR_PROGRAMA = '/programa/cargar-programa';
export const CARGAR_PRESUPUESTO = '/presupuesto/cargar-presupuesto';
export const ESTADOS_PAGO = '/estados-de-pago';
export const GENERAR_POD = '/generar-pod';
export const LANDING = '/';
export const LOGIN = '/login';
export const POD = '/pods';
export const VER_POD = '/pods/:id';
export const CERRAR_POD = '/pods/:id/cerrar';
export const PRESUPUESTO = '/presupuesto';
export const PROGRAMA = '/programa';
export const PROGRAMACION_SEMANAL = '/programacion-semanal';
export const VER_PROGRAMACION_SEMANAL = '/programacion-semanal/:id';
export const RECURSOS = '/recursos';
export const REDIRECT = '/redireccionar';
export const REPORTABILIDAD = '/reportabilidad';
export const REPORTES_POD_DIAROS = '/reportes-diarios';
export const REPROGRAMACION = '/reprogramacion';

export const PDF = '/pdf';
