import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography,
    ListSubheader
} from '@material-ui/core/';
import {
    AssignmentOutlined,
    BarChart,
    MonetizationOnOutlined,
    SettingsApplicationsOutlined,
    SettingsApplications,
    BusinessCenterOutlined,
    ExpandLess,
    ExpandMore,
    ListAlt,
    FolderOpen,
    AccessTime as AccessTimeIcon,
    Today,
    AccountBalance
} from '@material-ui/icons/';
import * as PERMISSIONS from '../../constants/permissions';
import * as ROUTES from '../../constants/routes';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import * as URLS from '../../constants/urls';

const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: 26,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerMargin: {
        marginTop: "48px"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: "hidden",
        width: theme.spacing(0) + 1,
        // width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {

        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }
}));

export default function MainDrawer(props) {

    const { open, handleClose, usuarioSesion } = props;

    const [openCollapseLogs, setOpenCollapseLogs] = useState(false);
    const [openCollapsePrograma, setOpenCollapsePrograma] = useState(false);

    const classes = useStyles();

    return (
        <Fragment>
            <Drawer
                variant="permanent"
                anchor="left"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.drawerMargin,
                        {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                }}
                onClose={handleClose}
                open={open}>
                <Divider />
                <List>
                    <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.CONFIGURADOR}`} target="_blank" rel="noopener">
                        <ListItemText disableTypography primary={<Typography variant="h6">Ir al Configurador de Proyectos</Typography>} />
                    </ListItem>

                    <ListItem button component={Link} to={ROUTES.REPORTABILIDAD}  >
                        <ListItemIcon >
                            <BarChart color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary="Reportabilidad" />
                    </ListItem>
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.VER_ACTIVIDAD] &&
                        // <ListItem button component={Link} to={ROUTES.PROGRAMA}  >
                        //     <ListItemIcon >
                        //         <AssignmentOutlined color="secondary" />
                        //     </ListItemIcon>
                        //     <ListItemText primary="Programa" />                            
                        // </ListItem>
                        <ListItem button onClick={() => (openCollapsePrograma) ? setOpenCollapsePrograma(false) : setOpenCollapsePrograma(true)}>
                        <ListItemIcon>
                            <AssignmentOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Programación" />
                        {(openCollapsePrograma) ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    }
                    <Collapse in={openCollapsePrograma} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to={`${ROUTES.PROGRAMA}`}>
                                <ListItemText inset secondary="Programa Proyecto" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.PROGRAMACION_SEMANAL}`}>
                                <ListItemText inset secondary="Programa Semanal" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.POD}`}>
                                <ListItemText inset secondary="POD" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.REPORTES_POD_DIAROS}`}>
                                <ListItemText inset secondary="Reportes Diarios" />
                            </ListItem>
                        </List>
                    </Collapse>
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.VER_ITEM_PRESUPUESTO] &&
                        <ListItem button component={Link} to={ROUTES.PRESUPUESTO}  >
                            <ListItemIcon >
                                <MonetizationOnOutlined color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Presupuesto" />
                        </ListItem>
                    }
                    {/* {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.VER_ACTIVIDAD] &&
                        <ListItem button component={Link} to={ROUTES.POD}  >
                            <ListItemIcon >
                                <Today color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="POD" />
                        </ListItem>
                    } */}
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.VER_ITEM_PRESUPUESTO] &&
                        <ListItem button component={Link} to={ROUTES.ESTADOS_PAGO}  >
                            <ListItemIcon >
                                <AccountBalance color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Estados de Pago" />
                        </ListItem>
                    }
                    {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.VER_RECURSO] &&
                        <ListItem button component={Link} to={ROUTES.RECURSOS} onClick={handleClose}>
                            <ListItemIcon >
                                <BusinessCenterOutlined color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Recursos" />
                        </ListItem>
                    }
                    <Divider />
                    <ListSubheader>Aplicaciones</ListSubheader>
                    <ListItem button onClick={() => (openCollapseLogs) ? setOpenCollapseLogs(false) : setOpenCollapseLogs(true)}>
                        <ListItemIcon>
                            <ListAlt></ListAlt>
                        </ListItemIcon>
                        <ListItemText primary="Logs" />
                        {(openCollapseLogs) ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openCollapseLogs} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.CARTAS}`} target="_blank" rel="noopener">
                                <ListItemText inset secondary="Cartas" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.INCIDENTES}`} target="_blank" rel="noopener">
                                <ListItemText inset secondary="Incidentes" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.CALIDAD}`} target="_blank" rel="noopener">
                                <ListItemText inset secondary="Calidad" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.SDI}`} target="_blank" rel="noopener">
                                <ListItemText inset secondary="Solicitudes de Información" />
                            </ListItem>
                            <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.INSPECCIONES}`} target="_blank" rel="noopener">
                                <ListItemText inset secondary="Log App Inspección" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.SGI}`} target="_blank" rel="noopener">
                        <ListItemIcon >
                            <FolderOpen color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary="Gestor de Información " />
                    </ListItem>
                    <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.TAREAS}`} target="_blank" rel="noopener">
                        <ListItemIcon >
                            <PlaylistAddCheckIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary="Asignación de Tareas" />
                    </ListItem>
                    <ListItem button component={Link} to={`${ROUTES.REDIRECT}/?destiny=${URLS.BITACORA}`} target="_blank" rel="noopener">
                        <ListItemIcon >
                            <AccessTimeIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary="Bitácora" />
                    </ListItem>
                    {/* <ListItem button className={classes.nested} onClick={() => { redirecTo("https://libro-de-obra.cydocs.cl/") }}>
                            <ListItemIcon >
                                <AccessTimeIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Libro" />
                        </ListItem> */}
                </List>

            </Drawer>

        </Fragment>
    );
}