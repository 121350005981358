export const SET_CONTRATO = 'SET_CONTRATO';
export const SET_PROYECTO = 'SET_PROYECTO';

export const accionSetContrato = (contrato, proyecto) => ({
    type: SET_CONTRATO,
    contrato: contrato,
    proyecto: proyecto
});

export const accionSetProyecto = (proyecto) => ({
    type: SET_PROYECTO,
    proyecto: proyecto
});