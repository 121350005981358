export const ADMINISTRACION_USUARIOS = 'https://api-usuarios.cydocs.cl';
export const LOGIN = 'https://account.cydocs.cl';
export const REDIRECT_BASE = 'https://api-usuarios.cydocs.cl/login';
export const CONFIGURADOR = 'https://configurador-proyecto.cydocs.cl';
export const CARTAS = 'https://cartas.cydocs.cl';
export const INCIDENTES = 'https://logincidentes.cydocs.cl';
export const CALIDAD = 'https://logcalidad.cydocs.cl';
export const SDI = 'https://logsdi.cydocs.cl';
export const INSPECCIONES = 'https://inspecciones.cydocs.cl';
export const SGI = 'https://sgi.cydocs.cl';
export const TAREAS = 'http://cyd.legav.cl/proyecto';
export const BITACORA = 'https://bitacora.cydocs.cl';
export const LIBRO_OBRA = 'https://libro-de-obra.cydocs.cl';