import { SET_CONTRATO, SET_PROYECTO } from '../actions/contratoUsuario';
const defaultState = {
  contrato: null,
  proyecto: null
};

function reducer(state = defaultState, { type, contrato, proyecto }) {

  switch (type) {
    case SET_CONTRATO: {
      return { contrato: contrato, proyecto: proyecto };
    }
    case SET_PROYECTO: {
      return { ...state, proyecto: proyecto };
    }
    default:
      return state;
  }
}

export default reducer;