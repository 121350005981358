import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";

function AgregarAvance(props) {
    const { handleSubmit, open, handleCloseDialog, itemPrograma, avance } = props;

    const [formik, setFormik] = useState(null);
    const [restante, setRestante] = useState(100);
    const [disableAvance, setDisableAvance] = useState(false);

    // const validationSchema = Yup.object({
    //     fecha: Yup.string().required("Campo requerido"),
    //     porcentaje_avance: Yup.number().typeError("Debe ingresar un valor númerico").min(0, "Valor debe mayor que 0").max(restante, `Valor debe ser menor que ${restante}`),
    //     horas_hombre: Yup.number().typeError("Debe ingresar un valor númerico").min(0, "Valor debe mayor que 0")
    // });
    const validationSchema = Yup.object({
        fecha: Yup.string().required("Campo requerido"),
        porcentaje_avance: Yup.number().typeError("Debe ingresar un valor númerico"),
        horas_hombre: Yup.number().typeError("Debe ingresar un valor númerico")
    });
    const values = {
        cantidad: 0,
        cantidad_programada: 0,
        con_hh: true,
        estado: "",
        fecha: new Date(),
        horas_hombre: 0,
        horas_hombre_programadas: 0,
        metodo_accion: "",
        observacion: '',
        porcentaje_avance: 0,
        responsable_accion: "",
        tipo_avance: "",
        turno: "",
    };

    const handleEnterDialog = () => {
        // console.log(avance);
        // console.log(itemPrograma);
        if (!avance) {
            formik.resetForm(values);
            setRestante(100 - (itemPrograma && itemPrograma.avance_real ? itemPrograma.avance_real : 0));
        } else {
            formik.resetForm({
                cantidad: avance.cantidad,
                cantidad_programada: avance.cantidad_programada,
                con_hh: avance.tipo_avance === "CON_HH" ? true : false,
                estado: avance.estado,
                fecha: new Date(avance.fecha),
                horas_hombre: avance.horas_hombre,
                horas_hombre_programadas: avance.horas_hombre_programadas,
                metodo_accion: avance.metodo_accion,
                observacion: avance.observacion,
                porcentaje_avance: avance.porcentaje_avance,
                responsable_accion: avance.responsable_accion,
                tipo_avance: avance.tipo_avance ? avance.tipo_avance : "",
                turno: avance.turno ? avance.turno : "Día"
            });
            let avanceRestante = 100 - (itemPrograma.avance_real - (avance.estado === "Activo" ? avance.porcentaje_avance : 0));
            setRestante(avanceRestante);
            setDisableAvance(avance.tipo_avance === "CON_HH" ? true : false);
        }
    }

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    return (
        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={avance ? true : false}
                render={props =>
                    <Page
                        handleEnterDialog={handleEnterDialog}
                        handleCloseDialog={handleCloseDialog}
                        open={open}
                        titulo="Agregar Avance"
                        itemPrograma={itemPrograma}
                        restante={restante}
                        disableAvance={disableAvance}
                        setDisableAvance={setDisableAvance}
                        {...props}
                    />}
            />
        </React.Fragment>
    );

}

export default AgregarAvance;