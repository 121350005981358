import React, { useState } from 'react';
import {
    Container,
    Card,
    CardContent
} from '@material-ui/core';
import { makeStyles, } from '@material-ui/styles';
import ListadoProgramasSemanales from './listadoProgramasSemanales';

const useStyles = makeStyles(theme => ({
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
}));

function Page(props) {
    const classes = useStyles();
    const {
        goTo
    } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Container className={classes.main}>
            <Card>
                <CardContent>
                    <ListadoProgramasSemanales />
                </CardContent>
            </Card>
        </Container >
    );
}

export default Page;