export const CLOSE_GENERAR_POD = "CLOSE_GENERAR_POD";
export const OPEN_GENERAR_POD = "OPEN_GENERAR_POD";
export const SET_INICIO_SEMANA_REPORTE_POD = 'SET_INICIO_SEMANA_REPORTE_POD';
export const SET_FECHA_GENERAR_POD = 'SET_FECHA_GENERAR_POD';
export const OPEN_EDITAR_HH_POD = "OPEN_EDITAR_HH_POD";
export const CLOSE_EDITAR_HH_POD = "CLOSE_EDITAR_HH_POD";

export const accionSetInicioSemanaReportePod = (inicioSemanaReportePod) => ({
    type: SET_INICIO_SEMANA_REPORTE_POD,
    inicioSemanaReportePod: inicioSemanaReportePod
});
export const accionSetFechaGenerarPod = (fechaNuevoPod) => ({
    type: SET_FECHA_GENERAR_POD,
    fechaNuevoPod: fechaNuevoPod
});
export const accionOpenGenerarPod = () => ({
    type: OPEN_GENERAR_POD
});
export const accionCloseGenerarPod = () => ({
    type: CLOSE_GENERAR_POD
});
export const accionOpenEditarHHPod = (podSeleccionado) => ({
    type: OPEN_EDITAR_HH_POD,
    podSeleccionado: podSeleccionado
});
export const accionCloseEditarHHPod = () => ({
    type: CLOSE_EDITAR_HH_POD
});