import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getProgramaSemanal } from '../requestsProgramasSemanales';
import { getPrograma } from '../../../services/database/programacion';
import * as ROUTES from '../../../constants/routes';
import {
    accionOpenEliminarItemProgramaSemanal,
    accionCloseEliminarItemProgramaSemanal
} from '../actionsProgramaSemanal';
moment.locale('es');
export default function VerProgramaSemanal(props) {

    const history = useHistory();
    const notisnack = useSnackbar();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [diasSemana, setDiasSemana] = useState([
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo"
    ]);
    const [title, setTitle] = useState("Programa Semanal");
    const {
        usuarioSesion,
        proyecto,
    } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
    }));
    const [data, setData] = useState({ items_programa_semanal_ref: [] });
    const [isLoading, setIsLoading] = useState(true);

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (id && proyecto && proyecto.programa_actual_ref) {
            const programaSemanal = await getProgramaSemanal(id);
            console.log(programaSemanal);
            if (!programaSemanal || proyecto._id !== programaSemanal.proyecto_ref) {
                history.push(ROUTES.PROGRAMACION_SEMANAL);
            } else {
                const programaProyecto = await getPrograma(proyecto.programa_actual_ref);
                setData(programaSemanal);
                if (programaSemanal.inicio && programaSemanal.termino) {
                    const dias = [];
                    for (let i = 0; i < 7; i++) {
                        let dia = moment(programaSemanal.inicio).add('days', i).format('dddd');
                        dia = dia.charAt(0).toUpperCase() + dia.substr(1);
                        dias.push(dia);
                    }
                    let diaInicio = moment(programaSemanal.inicio).format('dddd');
                    diaInicio = diaInicio.charAt(0).toUpperCase() + diaInicio.substr(1);
                    let diaTermino = moment(programaSemanal.termino).format('dddd');
                    diaTermino = diaTermino.charAt(0).toUpperCase() + diaTermino.substr(1);
                    setTitle(`Programa Semanal: ${diaInicio} ${moment(data.inicio).format("DD/MM/YYYY")} - ${diaTermino} ${moment(data.termino).format("DD/MM/YYYY")}`);
                    setDiasSemana(dias);
                }
            }
        } else {
            setData({ items_programa_semanal_ref: [] });
        }
        setIsLoading(false);
    }

    const handleOpenEliminarItemProgramaSemanal = async (rowData) => {
        dispatch(accionOpenEliminarItemProgramaSemanal(rowData));
    }

    const handleSubmitEliminarItemProgramaSemanal = async () => {
        dispatch(accionCloseEliminarItemProgramaSemanal());
        setIsLoading(true);
        cargarData();
    }

    useEffect(() => {
        setIsLoading(true);
        cargarData();
    }, [proyecto]);

    return (
        <Page
            data={data}
            diasSemana={diasSemana}
            isLoading={isLoading}
            usuarioSesion={usuarioSesion}
            handleOpenEliminarItemProgramaSemanal={handleOpenEliminarItemProgramaSemanal}
            handleSubmitEliminarItemProgramaSemanal={handleSubmitEliminarItemProgramaSemanal}
            title={title}
        />
    );
}