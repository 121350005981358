
import moment from 'moment';
import RegistroAvance from "../avances/avance_model"
import {
    updateItemPrograma,
    agregarHijoItemPrograma,
    quitarHijoItemPrograma,
    getItemPrograma,
    agregarItemsPrograma,
    postItemPrograma
} from '../../services/database/programacion';

/**
 * @author Javier Malebran
 */


export default class ItemProgramaModel {

    constructor(json) {
        this._id = -1;
        this.avances = [];
        this.cantidad = 0;
        this.costo_directo = 0;
        this.es_hito = false;
        this.es_reprogramada = false;
        this.estado = "No terminada";
        this.duracion_reprogramada = 0;
        this.horas_hombre = 0;
        this.hijos = [];
        this.id_item = "";
        this.inicio_ideal = null;
        this.inicio_tardio = null;
        this.inicio_temprano = null;
        this.inicio_reprogramado = null;
        this.inicio_real = null;
        this.intervalos = [];
        this.is_eliminado = false;
        this.is_hoja = false;
        this.item_presupuesto = "";
        this.item_presupuesto_ref = null;
        this.item = "";
        this.nombre = "";
        this.padre = null;
        this.programa_ref = "";
        this.proyecto_ref = "";
        this.termino_ideal = null;
        this.termino_real = null;
        this.termino_tardio = null;
        this.termino_temprano = null;
        this.unidad = "";

        this.avance_real = 0;
        this.avance_programado = 0;
        this.avanceFinancieroProgramado = 0;
        this.avanceProgramadoHH = 0;
        this.avanceRealHH = 0;
        this.avanceProgramadoCostos = 0;
        this.avanceRealCostos = 0;
        this.duracionTemprana = 0;
        this.duracionTardia = 0;
        this.horasHombreProgramadas = 0;
        this.horasHombreProgramadasTotales = 0;
        this.horasHombreReales = 0;
        this.incidencia = 0;
        this.incidenciaHorasHombre = 0;
        this.itemPadre = "";
        this.itemPresupuesto = null;
        this.listaActividadesHijas = [];
        this.listaAvances = [];
        this.proyecto = {};
        this.programa = {};
        this._duracionProgramada = null;
        this._duracionReal = null;
        this._porcentajeAvanceAvanceReal = null;

        if (json) this.build(json);
    }

    build(json) {
        this._id = json._id ? json._id : "";
        this.avances = json.avances ? json.avances : [];
        this.cantidad = json.cantidad ? json.cantidad : 0;
        this.costo_directo = json.costo_directo ? json.costo_directo : 0;
        this.duracion_reprogramada = json.duracion_reprogramada ? json.duracion_reprogramada : 0;
        this.es_hito = json.es_hito ? json.es_hito : false;
        this.es_reprogramada = json.es_reprogramada ? json.es_reprograma : false;
        this.estado = json.estado ? json.estado : "No terminada";
        this.hijos = json.hijos ? json.hijos : null;
        this.horas_hombre = json.horas_hombre ? json.horas_hombre : 0;
        this.id_item = json.id_item ? json.id_item : "";
        this.inicio_ideal = json.inicio_ideal ? json.inicio_ideal : null;
        this.inicio_real = json.inicio_real ? json.inicio_real : null;
        this.inicio_reprogramado = json.inicio_reprogramado ? json.inicio_reprogramado : null;
        this.inicio_temprano = json.inicio_temprano ? json.inicio_temprano : null;;
        this.inicio_tardio = json.inicio_tardio ? json.inicio_tardio : null;;
        this.intervalos = json.intervalos ? json.intervalos : [];
        this.is_eliminado = json.is_eliminado ? json.is_eliminado : false;
        this.is_hoja = (json.is_hoja || json.is_hoja === false) ? json.is_hoja : true;
        this.item_presupuesto = json.item_presupuesto ? json.item_presupuesto : "";
        this.item_presupuesto_ref = json.item_presupuesto_ref ? json.item_presupuesto_ref : null;
        this.item = json.item ? json.item : null;
        this.nombre = json.nombre ? json.nombre : null;
        this.padre = json.padre ? json.padre : null;
        this.programa_ref = json.programa_ref ? json.programa_ref : null;
        this.proyecto_ref = json.proyecto_ref ? json.proyecto_ref : null;
        this.termino_ideal = json.termino_ideal ? json.termino_ideal : null;;
        this.termino_real = json.termino_real ? json.termino_real : null;
        this.termino_temprano = json.termino_temprano ? json.termino_temprano : null;
        this.termino_tardio = json.termino_tardio ? json.termino_tardio : null;
        this.unidad = json.unidad ? json.unidad : "";

        this.avanceProgramadoHH = json.avanceProgramadoHH ? json.avanceProgramadoHH : 0;
        this.avanceRealHH = json.avanceRealHH ? json.avanceRealHH : 0;
        this.avanceProgramadoCostos = json.avanceProgramadoCostos ? json.avanceProgramadoCostos : 0;
        this.avanceRealCostos = json.avanceRealCostos ? json.avanceRealCostos : 0;
        this.horasHombreProgramadas = json.horasHombreProgramadas ? json.horasHombreProgramadas : 0;
        this.horasHombreProgramadasTotales = json.horasHombreProgramadasTotales ? json.horasHombreProgramadasTotales : 0;
        this.horasHombreReales = json.horasHombreReales ? json.horasHombreReales : 0;
        this.incidencia = json.incidencia ? json.incidencia : 0;
        this.incidenciaHorasHombre = json.incidenciaHorasHombre ? json.incidenciaHorasHombre : 0;
        this.proyecto = json.proyecto ? json.proyecto : {};
        this.programa = json.programa ? json.programa : {};
        if (json.avances) {
            for (let i = 0; i < json.avances.length; i++) {
                let element = json.avances[i];
                element["id"] = i;
                this.listaAvances.push(new RegistroAvance(element));
            }
        }
    }

    buildListadoActividadesHijas(programa) {
        if (!this.is_hoja) {
            let inicioProgramado = this.inicio_ideal;
            let inicioReal = this.inicio_real;
            let inicioTardio = this.inicio_tardio;
            let inicioTemprano = this.inicio_temprano;
            let terminoProgramado = this.termino_ideal;
            let terminoReal = this.termino_real;
            let terminoTardio = this.termino_tardio;
            let terminoTemprano = this.termino_temprano
            this.costo_directo = 0;
            this.horas_hombre = 0;
            this.avanceProgramadoHH = 0;
            this.avanceRealHH = 0;
            this.avanceProgramadoCostos = 0;
            this.avanceRealCostos = 0;
            this.horasHombreProgramadas = 0;
            this.horasHombreProgramadasTotales = 0;
            this.horasHombreReales = 0;
            this.estado = "Terminada";
            if (this.hijos) {
                console.log("actividad", this.item);
                console.log("avanceProgramadoHH", this.avanceProgramadoHH);
                for (let i = 0; i < this.hijos.length; i++) {
                    const itemHijo = programa.listaActividades.find(a => a._id === this.hijos[i]);
                    this.listaActividadesHijas.push(itemHijo);
                    itemHijo.buildListadoActividadesHijas(programa);
                    console.log("itemHijo", itemHijo.item);
                    console.log("itemHijo.avanceProgramadoHH", itemHijo.avanceProgramadoHH);
                    console.log("itemHijo.incidenciaHorasHombre", itemHijo.incidenciaHorasHombre);
                    this.costo_directo = this.costo_directo + itemHijo.costo_directo;
                    this.horas_hombre = this.horas_hombre + itemHijo.horas_hombre;
                    this.avanceProgramadoHH = this.avanceProgramadoHH + (itemHijo.avanceProgramadoHH * itemHijo.incidenciaHorasHombre);
                    this.avanceRealHH = this.avanceRealHH + (itemHijo.avanceRealHH * itemHijo.incidenciaHorasHombre);
                    this.avanceProgramadoCostos = this.avanceProgramadoCostos + (itemHijo.avanceProgramadoCostos * itemHijo.incidencia);
                    this.avanceRealCostos = this.avanceRealCostos + (itemHijo.avanceRealCostos * itemHijo.incidencia);
                    this.horasHombreProgramadas = this.horasHombreProgramadas + itemHijo.horasHombreProgramadas;
                    this.horasHombreProgramadasTotales = this.horasHombreProgramadasTotales + itemHijo.horasHombreProgramadasTotales;
                    this.horasHombreReales = this.horasHombreReales + itemHijo.horasHombreReales;

                    if (!inicioProgramado || (itemHijo.inicio_ideal && moment(itemHijo.inicio_ideal).isBefore(moment(inicioProgramado)))) inicioProgramado = itemHijo.inicio_ideal;
                    if (!inicioReal || (itemHijo.inicio_real && moment(itemHijo.inicio_real).isBefore(moment(inicioReal)))) inicioReal = itemHijo.inicio_real;
                    if (!inicioTardio || (itemHijo.inicio_tardio && moment(itemHijo.inicio_tardio).isBefore(moment(inicioTardio)))) inicioTardio = itemHijo.inicio_tardio;
                    if (!inicioTemprano || (itemHijo.inicio_temprano && moment(itemHijo.inicio_temprano).isBefore(moment(inicioTemprano)))) inicioTemprano = itemHijo.inicio_temprano;
                    if (!terminoProgramado || (itemHijo.termino_ideal && moment(itemHijo.termino_ideal).isAfter(moment(terminoProgramado)))) terminoProgramado = itemHijo.termino_ideal;
                    if (!terminoReal || (itemHijo.termino_real && moment(itemHijo.termino_real).isAfter(moment(terminoReal)))) terminoReal = itemHijo.termino_real;
                    if (!terminoTardio || (itemHijo.termino_tardio && moment(itemHijo.termino_tardio).isAfter(moment(terminoTardio)))) terminoTardio = itemHijo.termino_tardio;
                    if (!terminoTemprano || (itemHijo.termino_temprano && moment(itemHijo.termino_temprano).isAfter(moment(terminoTemprano)))) terminoTemprano = itemHijo.termino_temprano;
                    if (this.estado === "Terminada" && itemHijo.estado === "No terminada") {
                        this.estado = "No terminada";
                    }
                    if (itemHijo.estado === "Conflicto") {
                        this.estado = "Conflicto";
                    }
                    if (itemHijo.estado === "HH requieren revisión") {
                        this.estado = "HH requieren revisión";
                    }
                    if (itemHijo.estado === "Cantidad requiere revisión") {
                        this.estado = "Cantiadad requiere revisión";
                    }
                }
                console.log("avanceProgramadoHH", this.avanceProgramadoHH);
            }
            this.inicio_ideal = inicioProgramado;
            this.inicio_real = inicioReal;
            this.inicio_tardio = inicioTardio;
            this.inicio_temprano = inicioTemprano;
            this.termino_ideal = terminoProgramado;
            this.termino_real = terminoReal;
            this.termino_tardio = terminoTardio;
            this.termino_temprano = terminoTemprano;
            this.incidencia = (this.costo_directo && programa.costoTotal) ? this.costo_directo / programa.costoTotal : 0;
            this.incidenciaHorasHombre = (this.horas_hombre && programa.horasHombreTotal) ? this.horas_hombre / programa.horasHombreTotal : 0;
            // this.avanceProgramadoHH = this.incidenciaHorasHombre ? this.avanceProgramadoHH / this.incidenciaHorasHombre : 0;
            // this.avanceRealHH = this.incidenciaHorasHombre ? this.avanceRealHH / this.incidenciaHorasHombre : 0;
            // this.avanceProgramadoCostos = this.incidencia ? this.avanceProgramadoCostos / this.incidencia : 0;
            // this.avanceRealCostos = this.incidencia ? this.avanceRealCostos / this.incidencia : 0;
        } else {
            this.horasHombreProgramadas = 0;
            this.horasHombreProgramadasTotales = 0;
            this.horasHombreReales = 0
            let actual = moment();
            let avanceProgramado = 0;
            if (actual.isAfter(moment(this.termino_ideal))) {
                // actual = moment(this.termino_ideal);
                if (this.es_hito) avanceProgramado = 100;
            }
            this.incidencia = (this.costo_directo && programa.costoTotal) ? this.costo_directo / programa.costoTotal : 0;
            this.incidenciaHorasHombre = (this.horas_hombre && programa.horasHombreTotal) ? this.horas_hombre / programa.horasHombreTotal : 0;
            let inicioProgramado = moment(new Date(this.inicio_ideal));
            let duracionProgramada = actual.clone().diff(inicioProgramado, 'days') + 1;

            let avanceReal = 0;
            if (duracionProgramada > 0 && duracionProgramada <= this.duracionProgramada) {
                avanceProgramado = Math.round(duracionProgramada / this.duracionProgramada);
                this.horasHombreProgramadas = this.horas_hombre * avanceProgramado;
                avanceProgramado = avanceProgramado * 100;
            }
            if (duracionProgramada > this.duracionProgramada) {
                avanceProgramado = 100;
                this.horasHombreProgramadas = this.horas_hombre;
            }
            this.horasHombreProgramadasTotales = this.horas_hombre ? this.horas_hombre : 0;
            if (programa.avancesSemana) {
                // console.log("Actividad", this.item);
                // console.log("Actividad", this.inicio_ideal);
                // console.log("Actividad", actual.format("YYYY-MM-DD"));
                let termino = actual.clone();
                if (termino.isAfter(moment(this.termino_ideal))) termino = moment(this.termino_ideal);
                for (let d = moment(this.inicio_ideal); d.isBefore(termino); d.add(1, 'days')) {
                    const avanceDia = programa.avancesSemana.find(a => moment(a.fecha).format("YYYY-MM-DD") === d.format("YYYY-MM-DD"));
                    if (avanceDia) {
                        const avanceProgramadoDia = this.duracionProgramada ? (100 / this.duracionProgramada) : 0;
                        // if (d.format("YYYY-MM-DD") === "2020-02-27" && this.incidenciaHorasHombre > 0 && avanceProgramadoDia > 0) {
                        //     console.log("Actividad", this.item);
                        //     console.log("Inicio", inicioProgramado.format("YYYY-MM-DD"));
                        //     console.log("Termino", termino.format("YYYY-MM-DD"));
                        //     console.log("diaSemana", avanceDia);
                        //     console.log("dia", d.format("YYYY-MM-DD"));
                        //     console.log("incidencia", this.incidencia);
                        //     console.log("incidencia", this.incidenciaHorasHombre);
                        //     console.log("avance", avanceProgramadoDia);
                        //     console.log("avance prog", avanceDia.avanceProgramadoHH);
                        // }
                        if (avanceDia.avanceProgramadoCostos < 100) {
                            avanceDia.avanceProgramadoCostos = avanceDia.avanceProgramadoCostos + (avanceProgramadoDia > 100 ? 100 : avanceProgramadoDia) * this.incidencia;
                            if (avanceDia.avanceProgramadoCostos > 100) avanceDia.avanceProgramadoCostos = 100;
                        }
                        if (avanceDia.avanceProgramadoHH < 100) {
                            avanceDia.avanceProgramadoHH = avanceDia.avanceProgramadoHH + (avanceProgramadoDia > 100 ? 100 : avanceProgramadoDia) * this.incidenciaHorasHombre;
                            if (avanceDia.avanceProgramadoHH > 100) avanceDia.avanceProgramadoHH = 100;
                        }
                        // if (d.format("YYYY-MM-DD") === "2020-02-27" && this.incidenciaHorasHombre > 0 && avanceProgramadoDia > 0) {
                        //     console.log("avance prog", avanceDia.avanceProgramadoHH);
                        // }
                    }
                }
            }
            let inicioReal = null;
            let terminoReal = null;
            const avances = this.listaAvances;

            if (avances.length > 0) {
                inicioReal = avances[0].fecha;
                terminoReal = avances[0].fecha;
                for (let i = 0; i < avances.length; i++) {

                    if (moment(actual).isBefore(moment(inicioReal))) inicioReal = avances[i].fecha;
                    if (moment(avances[i].fecha).isAfter(moment(terminoReal))) terminoReal = avances[i].fecha;
                    this.horasHombreReales = this.horasHombreReales + avances[i].horas_hombre;
                    if (avances[i].tipo_avance && avances[i].tipo_avance === "CON_HH") {
                        avanceReal = avanceReal + (this.horas_hombre ? (((avances[i].horas_hombre ? avances[i].horas_hombre : 0) / this.horas_hombre) * 100) : 0);
                    } else {
                        avanceReal = avanceReal + (avances[i].porcentaje_avance ? avances[i].porcentaje_avance : 0);
                    }
                    if (avanceReal >= 100) {
                        avanceReal = 100;
                        break;
                    }
                    if (programa.avancesSemana) {
                        const avancePod = programa.avancesSemana.find(a => a.pod === avances[i].pod);
                        if (avancePod) {
                            let avanceRealPod = (this.horas_hombre ? (avances[i].horas_hombre / this.horas_hombre) : 0) * 100;
                            let avanceProgramadoPod = (this.horas_hombre ? (avances[i].horas_hombre_programadas / this.horas_hombre) : 0) * 100;
                            avancePod.avanceRealCostosPod = avancePod.avanceRealCostosPod + (avanceRealPod > 100 ? 100 : avanceRealPod) * this.incidencia;
                            avancePod.avanceRealHHPod = avancePod.avanceRealHHPod + (avanceRealPod > 100 ? 100 : avanceRealPod) * this.incidenciaHorasHombre;
                            avancePod.avanceProgramadoCostosPod = avancePod.avanceProgramadoCostosPod + (avanceProgramadoPod > 100 ? 100 : avanceProgramadoPod) * this.incidencia;
                            avancePod.avanceProgramadoHHPod = avancePod.avanceProgramadoHHPod + (avanceProgramadoPod > 100 ? 100 : avanceProgramadoPod) * this.incidenciaHorasHombre;
                        }
                    }
                }
            }
            this.termino_real = terminoReal;
            this.inicio_real = inicioReal;
            this.avanceProgramadoHH = avanceProgramado;
            this.avanceProgramadoCostos = avanceProgramado;
            this.avanceRealHH = avanceReal;
            this.avanceRealCostos = avanceReal;

        }
        return;
    }

    get duracionProgramada() {
        if (this._duracionProgramada === null) {
            this._duracionProgramada = this.calcularDuracionProgramada();
        }
        return this._duracionProgramada;
    }
    calcularDuracionProgramada() {
        let duracion = 0;
        if (this.es_hito) duracion = 0;
        else {
            if (this.inicio_ideal && this.termino_ideal) {
                let inicioProgramado = moment(new Date(this.inicio_ideal));
                let terminoProgramado = moment(new Date(this.termino_ideal));
                duracion = terminoProgramado.diff(inicioProgramado, 'days') + 1;
            }
        }

        return duracion;

    }
    calcularDuracionTemprana() {
        let duracion = 0;
        if (this.es_hito) this.duracionTemprana = 0;
        else {
            if (this.inicio_temprano && this.termino_temprano) {
                let inicio = moment(new Date(this.inicio_temprano));
                let termino = moment(new Date(this.termino_temprano));
                duracion = termino.diff(inicio, 'days') + 1;
                this.duracionTemprana = duracion;
            }
        }

        return duracion;

    }
    calcularDuracionTardia() {
        let duracion = 0;
        if (this.es_hito) this.duracionTardia = 0;
        else {
            if (this.inicio_tardio && this.termino_tardio) {
                let inicio = moment(new Date(this.inicio_tardio));
                let termino = moment(new Date(this.termino_tardio));
                duracion = termino.diff(inicio, 'days') + 1;
                this.duracionTardia = duracion;
            }
        }
        return duracion;

    }
    get duracionReal() {
        if (this._duracionReal === null) {
            this._duracionReal = this.calcularDuracionReal();
        }
        return this._duracionReal;
    }
    calcularDuracionReal() {
        let duracionReal = 0;
        if (this.inicio_real && this.termino_real) {

            let inicioProgramado = moment(new Date(this.inicio_real));
            if (this.inicio_real) {
                if (this.termino_real) {
                    let terminoProgramado = moment(new Date(this.termino_real));
                    duracionReal = terminoProgramado.diff(inicioProgramado, 'days') + 1;
                } else {
                    let terminoProgramado = moment();
                    duracionReal = terminoProgramado.diff(inicioProgramado, 'days') + 1;
                }
            }
        }
        return duracionReal;
    }
    get avanceProgramadoNumber() {

        let inicioProgramado = moment(new Date(this.inicio_ideal));
        let actual = moment();
        let avance = actual.diff(inicioProgramado, 'days') + 1;
        if (avance > 0 && avance <= this.duracionProgramada) {
            return Math.round(avance / this.duracionProgramada * 100);
        }
        if (avance > this.duracionProgramada) {
            return 100
        }
        return 0;
    }
    get porcentajeAvanceAvanceReal() {

        if (this._porcentajeAvanceAvanceReal === null) this._porcentajeAvanceAvanceReal = this.calcularPorcentajeAvanceReal();
        return this._porcentajeAvanceAvanceReal;
    }

    calcularPorcentajeAvanceReal() {
        if (this.is_hoja) {
            if (this.estado === "terminado") {
                return 100;
            } else {
                let sumaAvance = this.listaAvances.reduce(function (valorAnterior, valorActual) {
                    return valorAnterior + valorActual.porcentaje_avance;
                }, 0);
                return sumaAvance <= 100 ? Math.round(sumaAvance) : 100;
            }
        } else {
            let valorSuma = this.listaActividadesHijas.reduce(function (valorAnterior, valorActual) {
                if (valorActual.porcentajeAvanceAvanceReal) {
                    return valorAnterior + (valorActual.porcentajeAvanceAvanceReal * valorActual.incidencia);
                } else {
                    return valorAnterior + 0;
                }
            }, 0);

            return Math.round(valorSuma / (this.incidencia === 0 ? 1 : this.incidencia));
        }
    }

    costoTotal() {
        let acc = 0;
        if (this.is_hoja) {
            return Math.round(this.costo_directo ? this.costo_directo : 0);
        } else {
            for (let i = 0; i < this.listaActividadesHijas.length; i++) {
                if (this.listaActividadesHijas[i]) {
                    acc = acc + this.listaActividadesHijas[i].costoTotal();
                } else {
                    acc = acc + 0;
                }
            }
            this.costo_directo = acc;
            return this.costo_directo;
        }
    }

    calcularHorasHombre(actividades) {
        let acc = 0;
        if (this.is_hoja) {
            return Math.round(this.horas_hombre);
        } else {
            for (let i = 0; i < this.listaActividadesHijas.length; i++) {
                if (this.listaActividadesHijas[i]) {
                    acc = acc + this.listaActividadesHijas[i].calcularHorasHombre(actividades);
                } else {
                    acc = acc + 0;
                }
            }
            this.horas_hombre = acc;
            return this.horas_hombre;
        }
    }

    get avanceProgramadoToString() {

        let inicioProgramado = moment(new Date(this.inicio_ideal));
        let actual = moment();
        let avance = actual.diff(inicioProgramado, 'days') + 1;

        if (this.is_hoja) {
            if (avance >= 0 && avance <= this.duracionProgramada) {
                return Math.round(avance / this.duracionProgramada * 100);
            }

            if (avance > this.duracionProgramada) {
                return 100
            } else {
                return null;
            }
        } else {

            let valorSuma = 0;
            if (this.listaActividadesHijas) {
                valorSuma = this.listaActividadesHijas.reduce(function (valorAnterior, valorActual) {
                    if (valorActual) {
                        if (valorActual.avanceProgramadoToString) {
                            let incidencia = valorActual.incidencia ? valorActual.incidencia : 0;
                            let valorParaSumar = valorActual.avanceProgramadoToString * incidencia;
                            return valorAnterior + valorParaSumar;
                        } else {
                            return valorAnterior + 0;
                        }
                    } else {
                        return valorAnterior + 0;
                    }

                }, 0);
            } else {
                valorSuma = 0;
            }

            let avanceProgramado = Math.round(valorSuma / (this.incidencia === 0 ? 1 : this.incidencia));

            return avanceProgramado >= 100 ? 100 : avanceProgramado;
        }

    }

    getFechas() {
        // let inicio_real = this.inicio_real ? this.inicio_real : "";
        // let inicio_ideal = this.inicio_ideal ? this.inicio_ideal : "";
        // let termino_real = this.termino_real ? this.termino_real : "";
        // let termino_ideal = this.termino_ideal ? this.termino_ideal : "";
        // if (this.inicio_real === null || this.inicio_ideal === null || this.termino_real === null || this.termino_ideal === null) {
        //     for (let i = 0; i < this.listaActividadesHijas.length; i++) {
        //         let actividad = this.listaActividadesHijas[i];
        //         // if(typeof(actividad) !== "ItemProgramaModel"){
        //         //     console.log(typeof(actividad));
        //         // }
        //         if (actividad) {
        //             actividad.getFechas();
        //             inicio_real = inicio_real ? ((actividad.inicio_real && moment(actividad.inicio_real).isBefore(moment(inicio_real))) ? actividad.inicio_real : inicio_real) : actividad.inicio_real;
        //             inicio_ideal = inicio_ideal ? ((actividad.inicio_ideal && moment(actividad.inicio_ideal).isBefore(moment(inicio_ideal))) ? actividad.inicio_ideal : inicio_ideal) : actividad.inicio_ideal;
        //             termino_real = termino_real ? ((actividad.termino_real && moment(actividad.termino_real).isAfter(moment(termino_real))) ? actividad.termino_real : termino_real) : actividad.termino_real;
        //             termino_ideal = termino_ideal ? ((actividad.termino_ideal && moment(actividad.termino_ideal).isAfter(moment(termino_ideal))) ? actividad.termino_ideal : termino_ideal) : actividad.termino_ideal;
        //         }
        //     }
        //     this.inicio_real = inicio_real;
        //     this.inicio_ideal = inicio_ideal;
        //     this.termino_real = termino_real;
        //     this.termino_ideal = termino_ideal;
        // }
    }

    calcularAvanceReal(actual = moment(), conHorasHombre = true) {
        let avance_real = 0;
        if (this.is_hoja) {
            const avances = this.listaAvances;
            if (avances.length > 0) {
                // if(actual.isAfter(moment("2020-01-20"))) {
                // console.log("Item", this.item);
                //     // console.log("avances", this.avances);
                // }
                for (let i = 0; i < avances.length; i++) {
                    if (avances[i].estado !== "Activo") continue;
                    if (actual.isBefore(moment(avances[i].fecha).startOf('day'))) continue;
                    // console.log("Avance", avances[i]);
                    // console.log("Avance Real", avance_real);
                    if (avances[i].tipo_avance === "CON_HH") {
                        avance_real = avance_real + (this.horas_hombre ? Math.round(((avances[i].horas_hombre ? avances[i].horas_hombre : 0) / this.horas_hombre) * 1000000) / 10000 : 0);
                    } else {
                        avance_real = avance_real + (avances[i].porcentaje_avance ? avances[i].porcentaje_avance : 0);
                    }
                    if (avance_real >= 100) {
                        avance_real = 100;
                        break;
                    }
                    // console.log("Avance Real", avance_real);
                }
            }
        } else {
            const actividades = this.listaActividadesHijas;
            let avanceReal = 0;
            for (let i = 0; i < actividades.length; i++) {
                let avance = actividades[i].calcularAvanceReal(actual, conHorasHombre);
                const incidenciaActividad = !conHorasHombre ? (actividades[i].incidencia ? (actividades[i].incidencia) : 0) : (actividades[i].incidenciaHorasHombre ? (actividades[i].incidenciaHorasHombre) : 0);
                avanceReal = avanceReal + (avance * incidenciaActividad);

            }
            const incidencia = !conHorasHombre ? (this.incidencia ? (this.incidencia) : 0) : (this.incidenciaHorasHombre ? (this.incidenciaHorasHombre) : 0);
            avance_real = (incidencia ? (avanceReal / (incidencia)) : 0);
        }
        return avance_real;
    }

    calcularAvanceProgramado(actual = moment(), conHorasHombre = true) {
        let avance_programado = 0;
        if (this.is_hoja) {
            if (actual.isAfter(moment(this.termino_ideal))) {
                actual = moment(this.termino_ideal);
                if (this.es_hito) return 100;
            }
            let inicioProgramado = moment(new Date(this.inicio_ideal));
            let avance = actual.clone().diff(inicioProgramado, 'days') + 1;
            if (avance > 0 && avance <= this.duracionProgramada) {
                avance_programado = Math.round(avance / this.duracionProgramada * 100);
            }
            if (avance > this.duracionProgramada) {
                avance_programado = 100;
            }
        } else {
            const actividades = this.listaActividadesHijas;
            let avanceProgramado = 0;
            for (let i = 0; i < actividades.length; i++) {
                let avance = actividades[i].calcularAvanceProgramado(actual, conHorasHombre);
                const incidenciaActividad = !conHorasHombre ? (actividades[i].incidencia ? (actividades[i].incidencia) : 0) : (actividades[i].incidenciaHorasHombre ? (actividades[i].incidenciaHorasHombre) : 0);
                avanceProgramado = avanceProgramado + (avance * incidenciaActividad);
            }
            const incidencia = !conHorasHombre ? (this.incidencia ? (this.incidencia) : 0) : (this.incidenciaHorasHombre ? (this.incidenciaHorasHombre) : 0);
            avance_programado = (incidencia ? (avanceProgramado / incidencia) : 0);
        }
        return avance_programado;
    }

    calcularAvanceTemprano(actual = moment(), conHorasHombre = true) {
        let avanceTemprano = 0;
        if (this.inicio_temprano) {
            if (this.is_hoja) {
                let inicio = moment(new Date(this.inicio_temprano));
                let avance = actual.clone().diff(inicio, 'days') + 1;
                const duracion = this.calcularDuracionTemprana()
                if (avance > 0 && avance <= duracion) {
                    avanceTemprano = Math.round(avance / duracion * 100);
                }
                if (avance > duracion) {
                    avanceTemprano = 100;
                }
            } else {
                const actividades = this.listaActividadesHijas;
                let avanceTotal = 0;
                for (let i = 0; i < actividades.length; i++) {
                    let avance = actividades[i].calcularAvanceTemprano(actual, conHorasHombre);
                    const incidenciaActividad = !conHorasHombre ? (actividades[i].incidencia ? (actividades[i].incidencia) : 0) : (actividades[i].incidenciaHorasHombre ? (actividades[i].incidenciaHorasHombre) : 0);
                    avanceTotal = avanceTotal + (avance * incidenciaActividad);
                }
                const incidencia = !conHorasHombre ? (this.incidencia ? (this.incidencia) : 0) : (this.incidenciaHorasHombre ? (this.incidenciaHorasHombre) : 0);
                avanceTemprano = (incidencia ? (avanceTotal / (incidencia)) : 0);
            }
        }
        return avanceTemprano;
    }

    calcularAvanceTardio(actual = moment(), conHorasHombre = true) {
        let avanceTardio = 0;
        if (this.inicio_tardio) {
            if (this.is_hoja) {
                let inicio = moment(new Date(this.inicio_tardio));
                let avance = actual.clone().diff(inicio, 'days') + 1;
                const duracion = this.calcularDuracionTardia();
                if (avance > 0 && avance <= duracion) {
                    avanceTardio = Math.round(avance / duracion * 100);
                }
                if (avance > duracion) {
                    avanceTardio = 100;
                }
            } else {
                const actividades = this.listaActividadesHijas;
                let avanceTotal = 0;
                for (let i = 0; i < actividades.length; i++) {
                    let avance = actividades[i].calcularAvanceTardio(actual, conHorasHombre);
                    const incidenciaActividad = !conHorasHombre ? (actividades[i].incidencia ? (actividades[i].incidencia) : 0) : (actividades[i].incidenciaHorasHombre ? (actividades[i].incidenciaHorasHombre) : 0);
                    avanceTotal = avanceTotal + (avance * incidenciaActividad);
                }
                const incidencia = !conHorasHombre ? (this.incidencia ? (this.incidencia) : 0) : (this.incidenciaHorasHombre ? (this.incidenciaHorasHombre) : 0);
                avanceTardio = (incidencia ? (avanceTotal / (incidencia)) : 0);
            }
        }
        return avanceTardio;
    }

    calcularAvanceFinancieroProgramado() {
        this.avanceFinancieroProgramado = 0;
        this.avanceFinancieroProgramado = this.calcularAvanceProgramado() * this.calcularCostoTotal() / 100;
        return this.avanceFinancieroProgramado;
    }

    calcularHorasHombreProgramadas(actual = moment()) {
        let hh = 0;
        if (this.is_hoja) {
            if (this.es_hito) return 0;
            let inicioProgramado = moment(this.inicio_ideal);
            let terminoProgramado = moment(this.termino_ideal);
            if (!actual.isBefore(inicioProgramado) && !actual.isAfter(terminoProgramado)) {
                if (this.duracionProgramada <= 0) return 0;
                hh = Math.round(this.horas_hombre / this.duracionProgramada);
            }
        } else {
            const actividades = this.listaActividadesHijas;
            let hhProgramado = 0;
            for (let i = 0; i < actividades.length; i++) {
                let hhHijo = actividades[i].calcularHorasHombreProgramadas(actual);
                hhProgramado = hhProgramado + hhHijo;
            }
            hh = hhProgramado;
        }
        return hh;
    }

    calcularHorasHombreReales(actual = moment()) {
        let hh = 0;
        if (this.is_hoja) {
            if (this.es_hito) return 0;
            const avances = this.listaAvances;
            for (let i = 0; i < avances.length; i++) {
                if (moment(avances[i].fecha).format("YYYY-MM-DD") === actual.format("YYYY-MM-DD")) {
                    hh = hh + avances[i].horas_hombre;
                }
            }
        } else {
            const actividades = this.listaActividadesHijas;
            let hhActividad = 0;
            for (let i = 0; i < actividades.length; i++) {
                let hhHijo = actividades[i].calcularHorasHombreReales(actual);
                hhActividad = hhActividad + hhHijo;
            }
            hh = hhActividad;
        }
        return hh;
    }

    calcularCostoTotal() {
        let acc = 0;
        if (this.is_hoja) {
            return Math.round(this.costo_directo ? this.costo_directo : 0);
        } else {
            for (let i = 0; i < this.listaActividadesHijas.length; i++) {
                if (this.listaActividadesHijas[i]) {
                    acc = acc + this.listaActividadesHijas[i].calcularCostoTotal();
                } else {
                    acc = acc + 0;
                }
            }
            this.costo_directo = acc;
            return this.costo_directo;
        }
    }

    async update(values) {
        if (values.padre) {
            await agregarHijoItemPrograma(values.padre, [this._id]);
            if (values.padre !== this.padre) {
                await quitarHijoItemPrograma(this.padre, [this._id]);
            }
            // if (this.hijos) {
            //     for (let i = 0; i < this.hijos.length; i++) {
            //         await this.actualizarItemHijos(this.hijos[i], values.item, this.item);
            //     }
            // }
        }
        let response = await updateItemPrograma(this._id, values);
        return response;
    }

    _getFormatDate(date_p) {
        let date = new Date(date_p);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return moment().format(date, "DD/MM/YYYY");
        }
    }

    async actualizarFechasProgramadas(actual, fechas) {
        try {
            const item = await getItemPrograma(actual);
            fechas.inicio_ideal = (moment(fechas.inicio_ideal).isBefore(moment(item.inicio_ideal))) ? fechas.inicio_ideal : item.inicio_ideal;
            fechas.termino_ideal = (moment(fechas.termino_ideal).isAfter(moment(item.termino_ideal))) ? fechas.termino_ideal : item.termino_ideal;
            fechas.inicio_temprano = (!item.inicio_temprano || moment(fechas.inicio_temprano).isBefore(moment(item.inicio_temprano))) ? fechas.inicio_temprano : item.inicio_temprano;
            fechas.termino_temprano = (!item.termino_temprano || moment(fechas.termino_temprano).isAfter(moment(item.termino_temprano))) ? fechas.termino_temprano : item.termino_temprano;
            fechas.inicio_tardio = (!item.inicio_tardio || moment(fechas.inicio_tardio).isBefore(moment(item.inicio_tardio))) ? fechas.inicio_tardio : item.inicio_tardio;
            fechas.termino_tardio = (!item.termino_tardio || moment(fechas.termino_tardio).isAfter(moment(item.termino_tardio))) ? fechas.termino_tardio : item.termino_tardio;
            let inicio = moment(fechas.inicio_ideal);
            let termino = moment(fechas.termino_ideal);
            fechas.duracion = (termino.diff(inicio, "days"));
            await updateItemPrograma(actual, fechas);
            if (item.padre) return this.actualizarFechasProgramadas(item.padre, fechas);
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    async actualizarItemHijos(actual, newItem, oldItem) {
        try {
            const itemPrograma = await getItemPrograma(actual);
            itemPrograma.item = itemPrograma.item.replace(oldItem, newItem);
            await updateItemPrograma(actual, {
                item: itemPrograma.item
            });
            if (itemPrograma.hijos) {
                for (let i = 0; i < itemPrograma.hijos.length; i++) {
                    await this.actualizarItemHijos(itemPrograma.hijos[i], newItem, oldItem);
                }
            }
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    async post(values) {
        const newItem = await postItemPrograma(values);
        this.build(newItem);
        if (values.padre) {
            await agregarHijoItemPrograma(values.padre, [this._id]);
        }
        await agregarItemsPrograma(this.programa_ref, [this._id]);
    }

    async copiarActividad(programa, padre = null) {
        const nuevaActividad = new ItemProgramaModel();
        await nuevaActividad.post({
            padre: padre ? padre._id : null,
            cantidad: this.cantidad,
            estado: this.estado,
            horas_hombre: this.horas_hombre,
            inicio_ideal: this.inicio_ideal,
            inicio_real: this.inicio_real,
            termino_real: this.termino_real,
            termino_ideal: this.termino_ideal,
            inicio_temprano: this.inicio_temprano,
            inicio_tardio: this.inicio_tardio,
            termino_temprano: this.termino_temprano,
            termino_tardio: this.termino_tardio,
            is_eliminado: this.is_eliminado,
            is_hoja: this.is_hoja,
            item: this.item,
            nombre: this.nombre,
            programa_ref: programa._id,
            proyecto_ref: this.proyecto_ref,
            avances: this.avances,
            costo_directo: this.costo_directo,
            item_presupuesto_ref: this.item_presupuesto_ref,
            hijos: []
        });
        // const nuevaActividad = { _id: this._id, hijos: [], listaActividadesHijas: [], padre: padre ? padre._id : null };
        // const actividadObj = new ItemProgramaModel(nuevaActividad);
        if (this.listaActividadesHijas) {
            for (let i = 0; i < this.listaActividadesHijas.length; i++) {
                await this.listaActividadesHijas[i].copiarActividad(programa, nuevaActividad);
            }
        }
        // if (padre) {
        //     padre.hijos.push(nuevaActividad._id);
        //     padre.listaActividadesHijas.push(nuevaActividad);
        // }else{
        //     programa._listaTitulos.push(nuevaActividad);   
        // }
        // await nuevaActividad.update({hijos: nuevaActividad.hijos});
        // programa.items_programas_ref.push(nuevaActividad._id);
        // programa.listaActividades.push(nuevaActividad._id);
    }

    calcularAvances(programa, actual, conHorasHombre) {
        let inicioReal = programa.inicioReal ? moment(new Date(programa.inicioReal)).startOf('day') : moment();
        const incidencia = conHorasHombre ? this.incidenciaHorasHombre : this.incidencia;
        const avances = {
            avanceIdeal: 0,
            avanceReal: 0,
            avanceTemprano: 0,
            avanceTardio: 0,
            hhProgramado: 0,
            hhReal: 0
        }
        const avanceReal = this.calcularAvanceReal(actual, conHorasHombre);
        // if (this.listaAvances && this.listaAvances.length > 0 && actual.isAfter(moment("2020-01-20"))) {
        //     console.log("Inicio Real Programa", inicioReal);
        //     console.log("Item", this.item);
        //     console.log("Incidencia", incidencia);
        //     console.log("Avance Real", avanceReal);
        // }

        avances.avanceIdeal = avances.avanceIdeal + (this.calcularAvanceProgramado(actual, conHorasHombre) * incidencia);
        avances.avanceTemprano = avances.avanceTemprano + (this.calcularAvanceTemprano(actual, conHorasHombre) * incidencia);
        avances.avanceTardio = avances.avanceTardio + (this.calcularAvanceTardio(actual, conHorasHombre) * incidencia);
        avances.hhProgramado = avances.hhProgramado + (this.calcularHorasHombreProgramadas(actual, conHorasHombre));

        if (programa.inicioReal) {
            avances.avanceReal = avances.avanceReal + avanceReal * incidencia;
            avances.hhReal = avances.hhReal + this.calcularHorasHombreReales(actual, conHorasHombre);
        }
        return avances;
    }
}