import Axios from "../axios";

/**
 * Request para obtener la información de un proyecto
 * @id id del proyecto
 */

export const getProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos los items programas de un proyecto
 * * @id id del proyecto
 */

export const getItemsProgramaProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/items-programas`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
* Request para obtener la información de todos los items presupuesto de un proyecto
* * @id id del proyecto
*/

export const getItemsPresupuestoProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/items-presupuestos`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
* Request para obtener la información de todos las solicitudes de información de un proyecto
* * @id id del proyecto
*/

export const getSolicitudesInformacionProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/solicitudes-informacion`, {
        })
            .then((res) => {
                if (res.data && res.data.solicitudes_informacion_ref) resolve(res.data.solicitudes_informacion_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos los incidentes de un proyecto
 * * @id id del proyecto
 */

export const getIncidentesProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/incidentes`, {
        })
            .then((res) => {
                if (res.data && res.data.incidentes_ref) resolve(res.data.incidentes_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos las no conformidades de un proyecto
 * * @id id del proyecto
 */

export const getNoConformidadesProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/no-conformidades`, {
        })
            .then((res) => {
                if (res.data && res.data.no_conformidades_ref) resolve(res.data.no_conformidades_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos las cartas de un proyecto
 * * @id id del proyecto
 */

export const getCartasProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/cartas`, {
        })
            .then((res) => {
                if (res.data && res.data.cartas_ref) resolve(res.data.cartas_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos los recursos de un proyecto
 * * @id id del proyecto
 */

export const getRecursosProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/recursos`, {
        })
            .then((res) => {
                if (res.data && res.data.recursos_ref) resolve(res.data.recursos_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos las cartas de un proyecto
 * * @id id del proyecto
 */

export const getInspeccionesProyecto = async (params) => {
    return new Promise((resolve, reject) => {
        // var base = process.env.REACT_APP_DB_URL;
        try {
            Axios.get(`https://us-central1-cydingenieria-proyectos.cloudfunctions.net/getInspeccionesCerradasAndAbiertaPorIdContrato`,
                {
                    params: params
                }
            )
                .then((res) => {
                    // console.log(res);
                    if (res.data) resolve(res.data);
                    else resolve(null);
                })
                .catch((error) => {
                    // console.log(error);
                    // console.log(error.response);
                    if (error.response && error.response.status === 404) {
                        resolve(null);
                    } else {
                        console.log(error);
                        reject(error);
                    }
                });
            // resolve([]);
        }
        catch (e) {
            console.log(e);
            reject(e);
        }
    });
}

/**
 * Request para obtener la información de todos los estados de pago de un proyecto
 * * @id id del proyecto
 */

export const getEstadosPagosProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/estados-pagos`, {
        })
            .then((res) => {
                if (res.data && res.data.estados_pagos_ref) resolve(res.data.estados_pagos_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos los pods de un proyecto
 * * @id id del proyecto
 */

export const getPodsProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/pods`, {
        })
            .then((res) => {
                if (res.data && res.data.pods_ref) resolve(res.data.pods_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}


/**
 * Request para obtener los avances de POD de un proyecto para un periodo 
 * para un dia especifico
 * @id id del proyecto
 * @fechaInicio dia de inicio del periodo
 * @fechaTermino dia final del periodo
 */

export const getAvancesPeriodoPodProyecto = async (id, fechaInicio, fechaTermino) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/avances-pods-periodo`, {
            params: {
                fechaInicio: fechaInicio,
                fechaTermino: fechaTermino
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}


/**
 * Request para obtener los avances de POD de un proyecto para un periodo 
 * para un dia especifico
 * @id id del proyecto
 * @fechaInicio dia de inicio del periodo
 * @fechaTermino dia final del periodo
 */

export const getAvancesPeriodoPodProyectoHH = async (id, fechaInicio, fechaTermino) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/avances-pods-periodo-hh`, {
            params: {
                fechaInicio: fechaInicio,
                fechaTermino: fechaTermino
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener las actividades de un programa de un proyecto
 * para un dia especifico
 * @id id del proyecto
 * @dia dia seleccionado para las actividades
 */

export const getItemsProgramaDia = async (id, dia) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/items-programas-dia`,{
            params: {
                dia: dia
            }
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

/**
 * Request para obtener las horas efectivas de trabajo de un proyecto por dia
 * para un periodo de tiempo
 * @id id del proyecto
 * @desde dia de inicio del periodo
 * @hasta dia final del periodo
 */

export const getHorasEfectivasPeriodoProyecto = async (id, desde, hasta) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/horas-efectivas-trabajo`,{
            params: {
                desde: desde,
                hasta: hasta
            }
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}


/**
 * Request para calcular el avance para un periodo de un proyecto de los programas semanales
 * para un periodo de tiempo
 * @id id del proyecto
 * @desde dia de inicio del periodo
 * @hasta dia final del periodo
 */

export const getAvancePeriodoProgramaSemanal = async (id, inicio, termino) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/avance-periodo-programas-semanales`,{
            params: {
                inicio: inicio,
                termino: termino
            }
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            reject(error);
        });
    });
}

/**
 * Request para crear un nuevo proyecto
 * * @params campos del proyecto nuevo
 */

export const postProyecto = async (params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de recursos a la lista de recursos de un proyecto
 * * @id id del proyecto
 * * @data conjunto de recursos
 */

export const addRecursosProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/recursos`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de items programas a la lista de items programas de un proyecto
 * * @id id del proyecto
 * * @data conjunto de items programa
 */

export const agregarItemsProgramaProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/items-programas`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de recursos a la lista de recursos de un proyecto
 * * @id id del proyecto
 * * @data conjunto de recursos
 */

export const agregarItemsPrespuestoProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/items-presupuestos`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de programas a la lista de programas de un proyecto
 * * @id id del proyecto
 * * @data conjunto de programas
 */

export const agregarProgramaProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/programas`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de presupuestos a la lista de presupuestos de un proyecto
 * * @id id del proyecto
 * * @data conjunto de presupuestos
 */

export const agregarPresupuestoProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/presupuestos`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de estados de pagos a un proyecto
 * * @id id del proyecto
 * * @data conjunto de estados de pagos
 */

export const agregarEstadosPagosProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/estados-pagos`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de pods a un proyecto
 * * @id id del proyecto
 * * @data conjunto de pods
 */

export const agregarPodsProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/pods`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para actualizar la información de un proyecto
 * * @id id del proyecto
 * * * @params campos actualizados del proyecto
 */

export const updateProyecto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/proyectos/${id}`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para eliminar la información de un proyecto
 * * @id id del proyecto
 */

export const deleteProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar la información de un conjunto de recursos de un proyecto
 * * @id id del proyecto
 * * @data conjunto de recursos
 */

export const removeRecursosProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}/recursos`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar la información de un conjunto de items programa de un proyecto
 * * @id id del proyecto
 * * @data conjunto de items programa
 */

export const quitarItemsProgramaProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}/items-programas`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar la información de un conjunto de items presupuesto de un proyecto
 * * @id id del proyecto
 * * @data conjunto de items presupuesto
 */

export const quitarItemsPresupuestoProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}/items-presupuestos`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar la información de un conjunto de estados de pagos de un proyecto
 * * @id id del proyecto
 * * @data conjunto de estados de pagos
 */

export const quitarEstadosPagosProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}/estados-pagos`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar la información de un conjunto de pods de un proyecto
 * * @id id del proyecto
 * * @data conjunto de pods
 */

export const quitarPodsProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}/pods`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}