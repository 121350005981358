import {
  OPEN_AGREGAR_REPROGRAMACION,
  CLOSE_AGREGAR_REPROGRAMACION,
  OPEN_AGREGAR_REPROGRAMACION_ARCHIVO,
  CLOSE_AGREGAR_REPROGRAMACION_ARCHIVO,
  CLOSE_ELIMINAR_REPROGRAMACION,
  OPEN_ELIMINAR_REPROGRAMACION
} from './actionsReprogramacion';

const defaultState = {
  openAgregarReprogramacion: false,
  openAgregarReprogramacionArchivo: false,
  openEliminarReprogramacion: false,
  reprogramacionSeleccionada: null
};

export default function reducerPod(state = defaultState, {
  type,
  reprogramacionSeleccionada
}) {

  switch (type) {
    case OPEN_AGREGAR_REPROGRAMACION: {
      return { ...state, openAgregarReprogramacion: true };
    }
    case CLOSE_AGREGAR_REPROGRAMACION: {
      return { ...state, openAgregarReprogramacion: false };
    }
    case OPEN_AGREGAR_REPROGRAMACION_ARCHIVO: {
      return { ...state, openAgregarReprogramacionArchivo: true };
    }
    case CLOSE_AGREGAR_REPROGRAMACION_ARCHIVO: {
      return { ...state, openAgregarReprogramacionArchivo: false };
    }
    case OPEN_ELIMINAR_REPROGRAMACION: {
      return { ...state, openEliminarReprogramacion: true, reprogramacionSeleccionada: reprogramacionSeleccionada };
    }
    case CLOSE_ELIMINAR_REPROGRAMACION: {
      return { ...state, openEliminarReprogramacion: false, reprogramacionSeleccionada: null };
    }
    default:
      return state;
  }
}