import React, { Fragment } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    Select,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import FileInput from '../../../components/fileInput';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import NumberInput from '../../../components/numberInput';

/**
 * Vista de componente para agregar reportes pod diarios
 * @since 1.2.0
 * @author Luis San Martín S.
 * @property {Object} errors Errores del formulario
 * @property {function} handleChange Manejo de cambio de valores en el formulario
 * @property {function} handleClose Funcion para cerrar dialogo
 * @property {function} handleDateChange Manejo de cambio de valores de fecha en el formulario
 * @property {function} handleEnterDialog Manejo de evento de apertura del dialogo
 * @property {function} handelFileChange Manejo de cambio de valores de archivo en el formulario
 * @property {function} handleSubmit Manejo de evento submit del formulario
 * @property {Array} hojas Hojas del archivo excel seleccionado
 * @property {Boolean} isValid Son validos o no los valores del formulario
 * @property {Boolean} open Abrir o no el dialogo
 * @property {String} titulo texto para mostrar en el titulo
 * @property {Object} touched Campos modificados del formulario
 * @property {Object} valores Valores de campos del formulario
 */
function Page(props) {
    const {
        errors,
        handleChange,
        handleClose,
        handleDateChange,
        handleEnterDialog,
        handelFileChange,
        handleSubmit,
        hojas,
        isValid,
        open,
        titulo,
        touched,
        values
    } = props;

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}{}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <FormControl
                                fullWidth={true}
                                margin="normal"
                                error={touched.excel && errors.excel ? true : false}
                            >
                                {/* <FormLabel>Adjunto</FormLabel> */}
                                <FileInput
                                    inputName="excel"
                                    buttonColor="primary"
                                    buttonVariant="outlined"
                                    label="Archivo Adjunto"
                                    value={values.excel}
                                    handleOnChange={handelFileChange.bind(null, "excel")}
                                    textVariant="outlined"
                                />
                                {touched.excel && errors.excel &&
                                    <FormHelperText>{errors.excel}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                    {hojas &&
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid
                                item
                                lg
                            >
                                <FormControl
                                    margin="normal"
                                    fullWidth
                                    variant="outlined"
                                    error={touched.hoja && errors.hoja ? true : false}

                                >
                                    <InputLabel>Hoja</InputLabel>
                                    <Select
                                        native
                                        inputProps={{
                                            name: `hoja`,
                                        }}
                                        value={values.hoja}
                                        onChange={handleChange.bind(null, "hoja")}
                                    >
                                        <option value="">Seleccionar hoja</option>
                                        {hojas.map((value, index) => (
                                            <option value={value.nombre} key={index}>{value.nombre}</option>
                                        ))}
                                    </Select>
                                    {touched.hoja && errors.hoja &&
                                        <FormHelperText>{errors.hoja}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    name="fecha"
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de control"
                                    format="dd/MM/yyyy"
                                    helperText={touched.fecha ? errors.fecha : ""}
                                    error={touched.fecha && Boolean(errors.fecha)}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={handleDateChange.bind(null, "fecha")}
                                    value={values.fecha}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item lg>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    name="fecha_emision"
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de emisión"
                                    format="dd/MM/yyyy"
                                    helperText={touched.fecha_emision ? errors.fecha_emision : ""}
                                    error={touched.fecha_emision && Boolean(errors.fecha_emision)}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={handleDateChange.bind(null, "fecha_emision")}
                                    value={values.fecha_emision}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="hh_programado_trisemanal"
                                type="text"
                                label="HH Programado Trisemanal"
                                value={(values.hh_programado_trisemanal)}
                                meta={{ touched: touched.hh_programado_trisemanal, error: errors.hh_programado_trisemanal }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "hh_programado_trisemanal")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="porcentaje_programado_trisemanal"
                                type="text"
                                label="Porcentaje Programado Trisemanal"
                                value={(values.porcentaje_programado_trisemanal)}
                                meta={{ touched: touched.porcentaje_programado_trisemanal, error: errors.porcentaje_programado_trisemanal }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "porcentaje_programado_trisemanal")}
                            />

                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="hh_programado_pod"
                                type="text"
                                label="HH Programado POD"
                                value={(values.hh_programado_pod)}
                                meta={{ touched: touched.hh_programado_pod, error: errors.hh_programado_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "hh_programado_pod")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="porcentaje_programado_pod"
                                type="text"
                                label="Porcentaje Programado POD"
                                value={(values.porcentaje_programado_pod)}
                                meta={{ touched: touched.porcentaje_programado_pod, error: errors.porcentaje_programado_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "porcentaje_programado_pod")}
                            />

                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="hh_ganado_programa"
                                type="text"
                                label="HH Ganado en programa contractual"
                                value={(values.hh_ganado_programa)}
                                meta={{ touched: touched.hh_ganado_programa, error: errors.hh_ganado_programa }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "hh_ganado_programa")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="porcentaje_ganado_programa"
                                type="text"
                                label="Porcentaje Ganado en programa contractual"
                                value={(values.porcentaje_ganado_programa)}
                                meta={{ touched: touched.porcentaje_ganado_programa, error: errors.porcentaje_ganado_programa }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "porcentaje_ganado_programa")}
                            />

                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="hh_ganado_pod"
                                type="text"
                                label="Ganadas POD"
                                value={(values.hh_ganado_pod)}
                                meta={{ touched: touched.hh_ganado_pod, error: errors.hh_ganado_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "hh_ganado_pod")}
                            />
                        </Grid>

                        <Grid item lg>
                            <NumberInput
                                inputName="cumplimiento_trisemanal"
                                type="text"
                                label="Cumplimiento Trisemanal"
                                value={(values.cumplimiento_trisemanal)}
                                meta={{ touched: touched.cumplimiento_trisemanal, error: errors.cumplimiento_trisemanal }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "cumplimiento_trisemanal")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="cumplimiento_pod"
                                type="text"
                                label="Cumplimiento POD"
                                value={(values.cumplimiento_pod)}
                                meta={{ touched: touched.cumplimiento_pod, error: errors.cumplimiento_pod }}
                                textVariant="outlined"
                                custom={{ margin: "normal" }}
                                handleOnChange={handleChange.bind(null, "cumplimiento_pod")}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {/* <Button
                        onClick={() => handleDescargarFormato()}
                        color="secondary"
                        variant="outlined"
                    >
                        Descargar Formato POD
                    </Button> */}
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || !values.excel}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
