import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Dialog
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        isValid,
        handleSubmit,
        errors,
        touched,
        handleClose,
        handleEnterDialog,
        open
    } = props;

    const change = (name, e) => {
        const { setFieldTouched, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        e.persist();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onEnter={handleEnterDialog}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent
                    className={classes.dialogPaper}>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <TextField
                                name="nombre"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                className={classes.textField}
                                helperText={touched.nombre ? errors.nombre : ""}
                                error={touched.nombre && Boolean(errors.nombre)}
                                label="Nombre"
                                margin="normal"
                                value={values.nombre}
                                fullWidth
                                onChange={change.bind(null, "nombre")}

                            />
                        </Grid>
                    </Grid>

                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg={6}>
                            <TextField
                                name="cantidad"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                className={classes.textField}
                                helperText={touched.cantidad ? errors.cantidad : ""}
                                error={touched.cantidad && Boolean(errors.cantidad)}
                                label="Cantidad"
                                margin="normal"
                                value={values.cantidad}
                                fullWidth
                                onChange={change.bind(null, "cantidad")}

                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <TextField
                                name="descripcion"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                className={classes.textField}
                                helperText={touched.descripcion ? errors.descripcion : ""}
                                error={touched.descripcion && Boolean(errors.descripcion)}
                                label="Descripción"
                                margin="normal"
                                value={values.descripcion}
                                fullWidth
                                onChange={change.bind(null, "descripcion")}

                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default Page;
