import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Dialog,
    InputAdornment,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import NumberInput from '../../../components/numberInput';
import formatNumber from 'format-number';
import esLocale from 'date-fns/locale/es';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        isValid,
        handleSubmit,
        errors,
        touched,
        handleEnterDialog,
        handleCloseDialog,
        open
    } = props;

    const change = (name, e) => {
        const { setFieldTouched, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        e.persist();
    };

    const dateChange = (name, e) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        handleChange(name);
        setFieldTouched(name, true, false);
        setFieldValue(name, e);

    };
    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            fullWidth={true}
            onClose={handleCloseDialog}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent
                    className={classes.dialogPaper}>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    name="fecha_base_real"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    inputVariant="outlined"
                                    margin="normal"
                                    label="Fecha inicio de Avance Base Real"
                                    format="dd/MM/yyyy"
                                    helperText={touched.fecha_base_real ? errors.fecha_base_real : ""}
                                    error={touched.fecha_base_real && Boolean(errors.fecha_base_real)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "fecha_base_real")}
                                    value={values.fecha_base_real}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="porcentaje_horas_hombre_base_real"
                                type="text"
                                inputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                textVariant="outlined"
                                error={touched.porcentaje_horas_hombre_base_real && Boolean(errors.porcentaje_horas_hombre_base_real)}
                                label="Porcentaje de Avance Real Base de HH"
                                value={(values.porcentaje_horas_hombre_base_real)}
                                custom={{
                                    margin: "normal",
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }
                                }}
                                meta={{
                                    touched: touched.porcentaje_horas_hombre_base_real,
                                    error: errors.porcentaje_horas_hombre_base_real

                                }}
                                handleOnChange={change.bind(null, "porcentaje_horas_hombre_base_real")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_base_real"
                                type="text"
                                inputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                textVariant="outlined"
                                error={touched.horas_hombre_base_real && Boolean(errors.horas_hombre_base_real)}
                                label="Avance de HH Totales Base"
                                value={(values.horas_hombre_base_real)}
                                custom={{
                                    margin: "normal"
                                }}
                                meta={{
                                    touched: touched.horas_hombre_base_real,
                                    error: errors.horas_hombre_base_real

                                }}
                                handleOnChange={change.bind(null, "horas_hombre_base_real")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="porcentaje_costo_base_real"
                                type="text"
                                inputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                textVariant="outlined"
                                error={touched.porcentaje_costo_base_real && Boolean(errors.porcentaje_costo_base_real)}
                                label="Porcentaje de Avance Real Base de Costos"
                                value={(values.porcentaje_costo_base_real)}
                                custom={{
                                    margin: "normal",
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }
                                }}
                                meta={{
                                    touched: touched.porcentaje_costo_base_real,
                                    error: errors.porcentaje_costo_base_real

                                }}
                                handleOnChange={change.bind(null, "porcentaje_costo_base_real")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="costo_base_real"
                                type="text"
                                inputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                textVariant="outlined"
                                error={touched.costo_base_real && Boolean(errors.costo_base_real)}
                                label="Avance de Costo Total Base"
                                value={(values.costo_base_real)}
                                custom={{
                                    margin: "normal"
                                }}
                                meta={{
                                    touched: touched.costo_base_real,
                                    error: errors.costo_base_real

                                }}
                                handleOnChange={change.bind(null, "costo_base_real")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>

            </form>
        </Dialog >
    );
}

export default Page;
