import React from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { closeEliminarDiaLineaBase } from '../actionsReportePodDiario';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { deleteDiaLineaBase } from '../requestsDiaLineaBase';

/**
 * Lógica de componente para eliminar dia linea base
 * @since 1.2.0
 * @author Luis San Martín S.
 */

export default function EliminarDiaLineaBase(props) {

    const dispatch = useDispatch();
    const notistack = useSnackbar();
    const { open, diaLineaBase, handleSubmit } = useSelector(state => ({
        open: state.reducerReportePodDiario.openEliminarDiaLineaBase,
        diaLineaBase: state.reducerReportePodDiario.diaLineaBaseSeleccionado,
        handleSubmit: state.reducerReportePodDiario.handleSubmitEliminarDiaLineaBase
    }));
    /**
     * Cargar datos del formulario
     */
    const handleOnSubmit = async () => {

        console.log(diaLineaBase);
        if (diaLineaBase) {
            const key = notistack.enqueueSnackbar("Eliminado Día de la Línea Base...", {
                persist: true,
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                }
            });
            deleteDiaLineaBase(diaLineaBase._id)
                .then((response) => {
                    if (response.status === 204) {

                        if (handleSubmit) handleSubmit();
                        notistack.closeSnackbar(key);
                        const successkey = notistack.enqueueSnackbar("Operanción exitosa: Día de la  Línea Base eliminado", {
                            variant: 'success',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
                        });
                    } else {
                        console.log("error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el Día  de la Línea Base. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el Día  de la  Línea Base. Contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                });
        }


    }
    /**
     * Cerrar dialogo
     */
    const handleClose = async () => {
        dispatch(closeEliminarDiaLineaBase());
    }


    return (

        <React.Fragment>
            <Page
                open={open}
                titulo="Eliminar Día de la Línea Base"
                handleClose={handleClose}
                handleOnSubmit={handleOnSubmit}
            />
        </React.Fragment>
    );
}