import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';

function Page(props) {
    const {
        open,
        handleClose,
        handleOnSubmit,
        titulo
    } = props;

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
            <DialogContent>
                ¿Está seguro de eliminar el Programa Semanal?
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleOnSubmit}
                >
                    Aceptar
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default Page;
