import React from 'react';
import Page from './page';
import PropTypes from 'prop-types';
import { deleteReporteSemanalPod } from '../../../../services/database/reportesSemanalesPod';

function EliminarReporteSemanalPod(props) {

  const handleOnSubmit = async () => {
    if (props.reporteSemanal && props.reporteSemanal._id) {
      await deleteReporteSemanalPod(props.reporteSemanal._id);
    }
    if (props.handleSubmit) props.handleSubmit();
  }

  return (

    <React.Fragment>
      <Page
        handleClose={props.handleClose}
        handleOnSubmit={handleOnSubmit}
        open={props.open}
      />
    </React.Fragment>
  );
}


EliminarReporteSemanalPod.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  reporteSemanal: PropTypes.object.isRequired
}

export default EliminarReporteSemanalPod;