import React, { Fragment, useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Tooltip,
    IconButton,
    TableCell,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableRow,
    Container,
    Paper
} from '@material-ui/core';
import MaterialTable from 'material-table';
import './style.css';
import DialogoAgregarItem from './agregar';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../components/menuDropDown';
import { Settings, AccountBalance } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    mainPrograma: {
        flexGrow: 1
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    cardHeaderTypography: {
        // display: "inline-flex",
        minWidth: "360px",
        padding: theme.spacing(2)
    },
    gridCardTitle: {
        marginBottom: theme.spacing(2)
    },
}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

function Tabla(props) {

    const classes = useStyles();

    const {
        data,
        presupuesto,
        handleOpenAgregarItem,
        handleOpenEditarItem,
        handleOpenEliminarItem,
        usuarioSesion
    } = props;
    const [pageSize, setPageSize] = useState(10);

    return (
        <MaterialTable
            title={`Items del Presupuesto`}
            options={{
                filtering: false,
                pageSizeOptions: [5, 10, 20, 50],
                pageSize: pageSize,
                grouping: false,
                padding: 'dense',
                rowStyle: rowData => ({
                    backgroundColor: (rowData.is_hoja ? '#fff' : '#f1f2f6')
                }),

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={location}
            // parentChildData={(row, rows) => rows.find(a => a.item === row.itemPadre)}
            actions={(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ITEM_PRESUPUESTO]) ? [
                {
                    tooltip: 'Agregar Item al Presupuesto',
                    icon: 'add_circle',
                    isFreeAction: true,
                    iconProps: {
                        className: classes.success
                    },
                    onClick: (evt) => handleOpenAgregarItem(null)
                }
            ] : []}
            columns={[
                {
                    title: '',
                    field: '_id',
                    render: rowData => {
                        const accionesConfiguracion = [];
                        if (usuarioSesion && usuarioSesion.permisos) {
                            if (usuarioSesion.permisos[PERMISIONS.AGREGAR_ITEM_PRESUPUESTO]) accionesConfiguracion.push({
                                content: 'Agregar Item',
                                onClick: (e) => handleOpenAgregarItem(e, rowData)
                            });
                            if (usuarioSesion.permisos[PERMISIONS.EDITAR_ITEM_PRESUPUESTO]) accionesConfiguracion.push({
                                content: 'Editar',
                                onClick: (e) => handleOpenEditarItem(e, rowData)
                            });
                            if (usuarioSesion.permisos[PERMISIONS.ELIMINAR_ITEM_PRESUPUESTO]) accionesConfiguracion.push({
                                content: 'Eliminar',
                                onClick: (e) => handleOpenEliminarItem(e, rowData)
                            });
                        }
                        return (<div className={classes.tableActions}>
                            {accionesConfiguracion.length > 0 &&
                                <MenuDropdown
                                    icon={
                                        <Settings />
                                    }
                                    tooltip="Administrar"
                                    items={accionesConfiguracion}
                                />
                            }
                        </div>);
                    },
                    cellStyle: {
                        width: '48px',
                        maxWidth: '48px',
                        minWidth: '48px',
                        padding: '0px'
                    },
                    headerStyle: {
                        width: '48px',
                        maxWidth: '48px',
                        minWidth: '48px'
                    }
                },
                {
                    title: 'Ítem',
                    field: 'item',
                    defaultSort: "asc"
                },
                {
                    title: 'Nombre',
                    field: 'nombre',
                    cellStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px'
                    },
                    headerStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px'
                    }
                },
                {
                    title: 'Precio U.',
                    field: 'precio_unitario',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return `$${numberFormater(data[rowData.tableData.id].precio_unitario)}`
                    }
                },
                {
                    title: 'Cantidad',
                    field: 'cantidad',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return numberFormater(data[rowData.tableData.id].cantidad)
                    }
                },
                {
                    title: 'Unidad',
                    field: 'unidad'
                },
                {
                    title: 'Precio Total',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    field: 'precio_total',
                    render: rowData => {
                        return `$${numberFormater(data[rowData.tableData.id].precio_total)}`
                    }
                }
            ]}
            data={data}
        />
    );
}

function Page(props) {
    const classes = useStyles();
    const {
        data,
        proyecto,
        presupuesto,
        openAgregarItem,
        openEditarItem,
        openEliminarItem,
        handleCloseAgregarItem,
        handleCloseEditarItem,
        handleCloseEliminarItem,
        handleSubmitAgregarItem,
        handleSubmitEditarItem,
        handleSubmitEliminarItem,
        itemSeleccionado,
        usuarioSesion,
        history
    } = props;
    // const [accionesAdministrarPresuesto, setAccionesAdministrarPresupesto] = useState("");
    // useEffect(() => {
    //     if (usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.CARGAR_PRESUPUESTO]) {
    //         setAccionesAdministrarPresupesto(
    //             <Tooltip title="Ver estados de Pago" aria-label="estados_pago">
    //                 <IconButton aria-label="edit" onClick={() => history.push("estados-de-pago")}>
    //                     <AccountBalance />
    //                 </IconButton>
    //             </Tooltip>);
    //     } else {
    //         setAccionesAdministrarPresupesto("");
    //     }
    // }, [usuarioSesion, setAccionesAdministrarPresupesto, history]);
    return (
        <Container className={classes.main}>

            {(data === null && presupuesto === null) &&
                <Paper>
                    <Grid container alignContent="center" alignItems="center">
                        <Grid item lg className={classes.center}>
                            <Typography className={classes.title} variant="h5">Cargando Presupuesto</Typography>
                            <CircularProgress color="primary" size={"70px"} />
                        </Grid>
                    </Grid>
                </Paper>
            }
            {data && presupuesto &&
                <Card className={classes.gridCardTitle}>
                    <CardContent>
                        <Grid
                            alignContent="flex-start"
                            alignItems="flex-start"
                            container>
                            <Grid item lg style={{ textAlign: "left" }}>
                                <Typography
                                    align="left"
                                    variant={"h5"}
                                    className={classes.cardHeaderTypography}
                                >
                                    {(proyecto && proyecto.nombre ? ` ${proyecto.nombre}` : "")}
                                </Typography>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <CustomCell1 align="center"><b>Presupuesto Total</b></CustomCell1>
                                            <CustomCell1 align="right">{`$${numberFormater(presupuesto.totalPresupuesto)}`}</CustomCell1>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>

                            <Grid item lg style={{ textAlign: "right" }}>
                                {(proyecto && usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.CARGAR_PRESUPUESTO]) &&
                                    <Tooltip title="Ver estados de Pago" aria-label="estados_pago">
                                        <IconButton aria-label="edit" onClick={() => history.push("estados-de-pago")}>
                                            <AccountBalance />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {(proyecto && usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.CARGAR_PRESUPUESTO]) &&
                                    <MenuDropdown
                                        // button={
                                        //     "Administrar"
                                        // }
                                        icon={
                                            <Settings />
                                        }
                                        tooltip="Administrar"
                                        padding="default_padding"
                                        items={[
                                            {
                                                content: 'Cargar Presupuesto',
                                                onClick: (e) => history.push(`${ROUTES.CARGAR_PRESUPUESTO}`)
                                            },
                                        ]}
                                        hidden={false}
                                    />
                                }


                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            }
            {data && presupuesto &&
                <Tabla
                    {...props}
                />
            }
            {(Array.isArray(data) && data.length === 0 && presupuesto === null) &&
                <Card className={classes.mainPrograma}>
                    <CardContent className={classes.paperCenter}>
                        <Typography variant={"h4"}> No se ha cargado ningún Presupuesto al Proyecto</Typography>
                        <br />
                        <Button style={{ marginRight: "8px" }} variant="outlined" component={Link} to={ROUTES.CARGAR_PRESUPUESTO}>
                            Cargar Presupuesto
                        </Button>
                        <Button variant="outlined" component={Link} to={ROUTES.ESTADOS_PAGO}>
                            Estados de Pago
                        </Button>
                    </CardContent>
                </Card>
            }
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ITEM_PRESUPUESTO] &&
                <DialogoAgregarItem
                    open={openAgregarItem}
                    proyecto={proyecto}
                    itemPresupuesto={null}
                    presupuesto={presupuesto}
                    itemsPresupuesto={data}
                    padre={itemSeleccionado ? itemSeleccionado : null}
                    handleSubmit={handleSubmitAgregarItem}
                    handleCloseDialog={handleCloseAgregarItem}

                />
            }
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.EDITAR_ITEM_PRESUPUESTO] &&
                <DialogoAgregarItem
                    open={openEditarItem}
                    proyecto={proyecto}
                    presupuesto={presupuesto}
                    itemPresupuesto={itemSeleccionado}
                    itemsPresupuesto={data}
                    padre={itemSeleccionado ? itemSeleccionado.padre : null}
                    handleSubmit={handleSubmitEditarItem}
                    handleCloseDialog={handleCloseEditarItem}
                />
            }
            <Dialog
                open={openEliminarItem}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth={true}
                onClose={handleCloseEliminarItem}
            >
                <DialogTitle>Confirmar</DialogTitle>
                <DialogContent>
                    ¿Esta seguro de eliminar el ítem/título seleccionado?
                    </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSubmitEliminarItem}
                        color="primary"
                        variant="contained"
                    >
                        Aceptar
                    </Button>
                    <Button onClick={handleCloseEliminarItem} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog >
        </Container>
    );
}

export default Page;