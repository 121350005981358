import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { accionCloseEditarHHPod } from '../actionsPod';
import { updatePod } from '../../../services/database/pods';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function EditarHorasHombrePod(props) {
    const [formik, setFormik] = useState(null);
    const dispatch = useDispatch();
    const notistack = useSnackbar();
    const validationSchema = Yup.object({
        horas_hombre_ganadas: Yup.string(),
        horas_hombre_gastadas: Yup.string()
    });
    const { usuarioSesion, proyecto, open, pod } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
        open: state.reducerPod.openEditarHHPod,
        pod: state.reducerPod.podSeleccionado
    }));
    const values = {
        horas_hombre_ganadas: 0,
        horas_hombre_gastadas: 0
    };

    const handleEnterDialog = async () => {
        if(pod) formik.resetForm({
            horas_hombre_ganadas: pod.horas_hombre_ganadas ? pod.horas_hombre_ganadas : 0,
            horas_hombre_gastadas: pod.horas_hombre_gastadas ? pod.horas_hombre_gastadas : 0
        });
    }

    const handleOnChange = (name, e) => {
        formik.setFieldValue(name, e.target.value);
        formik.setFieldTouched(name, true, false);
    };
    const handleOnSubmit = async (values, formikBag) => {
        const { handleSubmit } = props;
        const key = notistack.enqueueSnackbar("Guardando Cambios...", {
            persist: true,
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            }
          });
        try{
            await updatePod(pod._id, values);
            dispatch(accionCloseEditarHHPod());
            if(handleSubmit) handleSubmit(values);
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar("HH actualizadas", {
                variant: 'success',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
              });
        }
        catch(e){
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar las HH del pod. Intente de nuevo o contacte con soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }
    const handleClose = () =>{
        dispatch(accionCloseEditarHHPod());
    }
    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                isInitialValid={true}
                render={props =>
                    <Page
                        open={open}
                        handleOnChange={handleOnChange}
                        titulo="Editar horas hombre"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        validationSchema={validationSchema}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}