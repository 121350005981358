import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { Formik } from "formik";
import * as Yup from "yup";
import XLSX from 'xlsx';
import CustomizedSnackbars from '../../../components/customizedSnackbar';
import { postPresupuesto, postItemPresupuesto, updateItemPresupuesto, updatePresupuesto, getPresupuesto, getItemsPresupuesto, getItemPresupuestoByItem } from '../../../services/database/presupuesto';
import { updateProyecto, agregarPresupuestoProyecto, getProyecto } from '../../../services/database/proyecto';
import formatNumber from 'format-number';
import { auth } from '../../../services/firebase';
import { getUsuario, getContratosUsuario } from '../../../services/database/usuario';
import { getProyectosContrato } from '../../../services/database/contrato';
import { downloadUrlAsPromise } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import { accionSetProyecto } from '../../../redux/actions/contratoUsuario';
import ProyectoModel from '../../../model/ProyectoModel';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const currencyFormater = formatNumber({ prefix: "$", integerSeparator: ".", decimal: "," });

export default function CargaPresupuesto(props) {

    const { usuarioSesion, proyecto } = useSelector(state => ({ usuarioSesion: state.usuarioSesion, proyecto: state.contratoSesion.proyecto }));
    const dispatch = useDispatch();
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVariant, setSnackbarVariant] = useState("default");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarHideButton, setSnackbarHideButton] = useState(false);
    // const [presupuesto, setPresupuesto] = useState(null);
    // const [precioToTal, setPrecioTotal] = useState(null);
    // const [nombre, setNombre] = useState(null);
    const [titulos, setTitulos] = useState([]);
    const [itemsPresupuesto, setItemsPresupuesto] = useState([]);
    // const [dataVistaPrevia, setDataVistaPrevia] = useState(null);
    const [openVistaPrevia, setOpenVistaPrevia] = useState(false);
    const [loadingVistaPrevia, setLoadingVistaPrevia] = useState(true);
    // const [contratos, setContratos] = useState(null);
    // const [proyectos, setProyectos] = useState(null);
    const [formik, setFormik] = useState(null);
    const [presupuesto, setPresupuesto] = useState(null);
    const verVistaPrevia = async (values) => {
        let fileReader = new FileReader();
        // const nombre = values.nombre;
        // const descripcion = values.descripcion;
        fileReader.onload = (e) => {
            const binary = new Uint8Array(e.target.result);
            const workbook = XLSX.read(binary, { type: 'array' });
            let presupuestoExcel = {};
            const titulosExcel = [];
            const itemsPresupuestoExcel = [];
            let sheet_name = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheet_name];
            let desired_row = 2;
            let desired_cell = worksheet["A" + desired_row];
            let desired_value = (desired_cell ? desired_cell.v : undefined);
            while (desired_value) {
                if (desired_value) {
                    let rowData = {
                        item: worksheet["A" + desired_row] ? worksheet["A" + desired_row].w : "",
                        nombre: worksheet["B" + desired_row] ? worksheet["B" + desired_row].w : "",
                        is_hoja: true,
                        hijos_items: [],
                        padre_item: null,
                        precio_total: 0,
                        cantidad: "",
                        precio_unitario: ""
                    };

                    let next_row_item = worksheet["A" + (desired_row + 1)];
                    if (next_row_item && next_row_item.w && next_row_item.w.includes(rowData.item)) {
                        rowData["is_hoja"] = false;
                    } else {
                        rowData.cantidad = worksheet["D" + desired_row] ? worksheet["D" + desired_row].v : "";
                        rowData.precio_unitario = worksheet["E" + desired_row] ? worksheet["E" + desired_row].v : "";
                        if (rowData.cantidad !== "" && rowData.precio_unitario !== "") rowData.precio_total = rowData.cantidad * rowData.precio_unitario;
                        rowData["precio_total_mostrar"] = currencyFormater(Math.round(rowData.precio_total * 100) / 100);
                        rowData["unidad"] = worksheet["C" + desired_row] ? worksheet["C" + desired_row].v : "";
                        rowData["cantidad_mostrar"] = worksheet["D" + desired_row] ? numberFormater(Math.round(worksheet["D" + desired_row].v * 100) / 100) : "";
                        rowData["precio_unitario_mostrar"] = worksheet["E" + desired_row] ? currencyFormater(Math.round(worksheet["E" + desired_row].v * 100) / 100) : "";

                    }
                    let itemSplit = rowData.item.split(".");
                    if (itemSplit.length > 1) {
                        itemSplit.pop();
                        let idPadre = itemSplit.join(".");
                        if (presupuestoExcel[idPadre]) {
                            rowData.padre_item = idPadre;
                            presupuestoExcel[idPadre].hijos_items.push(rowData.item);
                            actualizarPrecioTotalPadres(presupuestoExcel, idPadre, rowData.precio_total);
                        }
                    }
                    // if (!rowData.is_hoja && !rowData.padre) titulos.push(rowData);
                    if (!rowData.padre_item) titulosExcel.push(rowData);
                    itemsPresupuestoExcel.push(rowData);
                    presupuestoExcel[rowData.item] = rowData;
                } else {
                    break;
                }
                desired_row++;
                desired_cell = worksheet["A" + desired_row];
                desired_value = (desired_cell ? desired_cell.v : undefined);
            }
            // let precio_total = worksheet["E" + desired_row] ? worksheet["E" + desired_row].w : null;
            // console.log(presupuestoExcel);
            setPresupuesto(presupuestoExcel);
            setTitulos(titulosExcel);
            setItemsPresupuesto(itemsPresupuestoExcel);
            // setPrecioTotal(precio_total);
            // setNombre(nombre);
            setOpenVistaPrevia(true);
            setLoadingVistaPrevia(true);

        };
        fileReader.readAsArrayBuffer(values.file);
    }

    const cleanNumber = (number) => {
        return number;
    }

    const actualizarPrecioTotalPadres = (presupuestoExcel, item, precio) => {
        presupuestoExcel[item]["precio_total"] = presupuestoExcel[item].precio_total + precio;
        presupuestoExcel[item]["precio_total_mostrar"] = currencyFormater(presupuestoExcel[item].precio_total);
        if (presupuestoExcel[item].padre_item && presupuestoExcel[presupuestoExcel[item].padre_item]) actualizarPrecioTotalPadres(presupuestoExcel, presupuestoExcel[item].padre_item, precio);
        return;
    }

    const handleSubmit = async (values) => {
        let fileReader = new FileReader();
        // const nombre = values.nombre;
        fileReader.onload = async (e) => {
            const binary = new Uint8Array(e.target.result);
            const workbook = XLSX.read(binary, { type: 'array' });
            let presupuestoExcel = {};
            const titulosExcel = [];
            const itemsPresupuestoExcel = [];
            let sheet_name = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheet_name];
            let desired_row = 2;
            let desired_cell = worksheet["A" + desired_row];
            let desired_value = (desired_cell ? desired_cell.v : undefined);
            while (desired_value) {
                if (desired_value) {
                    let rowData = {
                        item: worksheet["A" + desired_row] ? worksheet["A" + desired_row].w : "",
                        nombre: worksheet["B" + desired_row] ? worksheet["B" + desired_row].w : "",
                        is_hoja: true,
                        hijos_items: [],
                        itemsPresupuestoHijos: [],
                        padre_item: null,
                        precio_total: 0,
                        cantidad: "",
                        precio_unitario: "",
                        padre: null,
                        hijos: []
                    };

                    let next_row_item = worksheet["A" + (desired_row + 1)];
                    if (next_row_item && next_row_item.w && next_row_item.w.includes(rowData.item)) {
                        rowData["is_hoja"] = false;
                    } else {
                        rowData["unidad"] = worksheet["C" + desired_row] ? worksheet["C" + desired_row].v : "";
                        rowData["cantidad"] = worksheet["D" + desired_row] ? cleanNumber(worksheet["D" + desired_row].v) : "";
                        rowData["precio_unitario"] = worksheet["E" + desired_row] ? cleanNumber(worksheet["E" + desired_row].v) : "";
                        rowData["precio_total"] = worksheet["F" + desired_row] ? cleanNumber(worksheet["F" + desired_row].v) : "";


                        rowData.cantidad = worksheet["D" + desired_row] ? worksheet["D" + desired_row].v : "";
                        rowData.precio_unitario = worksheet["E" + desired_row] ? worksheet["E" + desired_row].v : "";
                        if (rowData.cantidad !== "" && rowData.precio_unitario !== "") rowData.precio_total = rowData.cantidad * rowData.precio_unitario;
                        rowData["precio_total_mostrar"] = currencyFormater(Math.round(rowData.precio_total * 100) / 100);
                        rowData["unidad"] = worksheet["C" + desired_row] ? worksheet["C" + desired_row].v : "";
                        rowData["cantidad_mostrar"] = worksheet["D" + desired_row] ? numberFormater(Math.round(worksheet["D" + desired_row].v * 100) / 100) : "";
                        rowData["precio_unitario_mostrar"] = worksheet["E" + desired_row] ? currencyFormater(Math.round(worksheet["E" + desired_row].v * 100) / 100) : "";
                    }
                    let itemSplit = rowData.item.split(".");
                    if (itemSplit.length > 1) {
                        itemSplit.pop();
                        let idPadre = itemSplit.join(".");
                        if (presupuestoExcel[idPadre]) {
                            rowData.padre_item = idPadre;
                            presupuestoExcel[idPadre].hijos_items.push(rowData.item);
                            presupuestoExcel[idPadre].itemsPresupuestoHijos.push(rowData);
                            actualizarPrecioTotalPadres(presupuestoExcel, idPadre, rowData.precio_total);
                        }
                    }
                    if (!rowData.padre_item) titulosExcel.push(rowData);
                    itemsPresupuestoExcel.push(rowData);
                    presupuestoExcel[rowData.item] = rowData;
                } else {
                    break;
                }
                desired_row++;
                desired_cell = worksheet["A" + desired_row];
                desired_value = (desired_cell ? desired_cell.v : undefined);
            }
            setSnackbarMessage("Subiendo Presupuesto");
            setSnackbarVariant("default");
            setSnackbarOpen(true);
            setSnackbarHideButton(true);

            try {
                if (proyecto.presupuesto_actual_ref) {
                    actualizarPresupuesto(titulosExcel, itemsPresupuestoExcel);
                } else {
                    crearNuevoPresupuesto(titulosExcel, itemsPresupuestoExcel);
                }

            } catch (error) {
                console.log(error);
                setSnackbarMessage("Error en carga de Presupuesto");
                setSnackbarVariant("error");
                setSnackbarOpen(true);
                setSnackbarHideButton(false);
            }


        };
        fileReader.readAsArrayBuffer(values.file);

    }
    const crearNuevoPresupuesto = async (titulos, actividades) => {
        const nuevoPresupuesto = await postPresupuesto({
            proyecto_ref: proyecto._id,
            items_presupuestos_ref: []
        });
        for (let key = 0; key < titulos.length; key++) {
            insertData(titulos[key], nuevoPresupuesto, 0, actividades.length);
        }
        await updateProyecto(proyecto._id, {
            presupuesto_actual_ref: nuevoPresupuesto._id
        });
        await agregarPresupuestoProyecto(proyecto._id, [nuevoPresupuesto._id]);
        const proyectoDB = await getProyecto(proyecto._id);
        if (proyectoDB) dispatch(accionSetProyecto(new ProyectoModel(proyectoDB)));
        setSnackbarMessage("Operación Existosa");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setSnackbarHideButton(false);
    }

    const actualizarPresupuesto = async (titulos, actividades) => {
        const presupuesto = await getPresupuesto(proyecto.presupuesto_actual_ref);
        for (let key = 0; key < titulos.length; key++) {
            await insertData(titulos[key], presupuesto, 0, actividades.length);
        }
        const proyectoDB = await getProyecto(proyecto._id);
        if (proyectoDB) dispatch(accionSetProyecto(new ProyectoModel(proyectoDB)));
        setSnackbarMessage("Operación Existosa");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setSnackbarHideButton(false);
    }

    const insertData = async (data, presupuesto, actual = 0, total) => {
        const oldData = proyecto.presupuesto_actual_ref ? (await getItemPresupuestoByItem(proyecto.presupuesto_actual_ref, data.item))[0] : null;
        console.log(oldData);
        let newData = null;
        if (!oldData) {
            newData = await postItemPresupuesto({
                item: data.item,
                nombre: data.nombre,
                is_hoja: data.is_hoja,
                hijos: data.hijos,
                padre: data.padre,
                precio_total: data.precio_total,
                cantidad: data.cantidad,
                unidad: data.unidad,
                precio_unitario: data.precio_unitario,
                presupuesto_ref: presupuesto._id,
                proyecto_ref: proyecto._id
            });
        } else {
            console.log(oldData._id);
            newData = await updateItemPresupuesto(oldData._id, {
                item: data.item,
                nombre: data.nombre,
                is_hoja: data.is_hoja,
                hijos: data.hijos,
                padre: data.padre,
                unidad: data.unidad,
                precio_total: data.precio_total,
                cantidad: data.cantidad,
                precio_unitario: data.precio_unitario,
                presupuesto_ref: presupuesto._id,
                proyecto_ref: proyecto._id
            });
        }
        setSnackbarMessage(`Subiendo Presupuesto ${Math.round(actual / total * 100)}%`);
        if (data.itemsPresupuestoHijos) {
            for (let i = 0; i < data.itemsPresupuestoHijos.length; i++) {
                data.itemsPresupuestoHijos[i].padre = newData._id;
                await insertData(data.itemsPresupuestoHijos[i], presupuesto, actual++, total);
            }
        }

        return;
    }

    const handleOnChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const snackbarHandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleEnterVistaPrevia = () => {
        setLoadingVistaPrevia(false);
    }

    const handleCloseVistaPrevia = () => {
        setOpenVistaPrevia(false);
    };

    const handleDescargarFormato = async () => {
        const nombre = "Formato Presupuesto.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/1L0Y8-R8Syx8tAe0Y4WxY8qhff0X1DyI3?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    const validationSchema = Yup.object(
        {
            file: Yup.mixed().required("Campo requerido")
        });
    const values = {
        file: ""
    };

    return (
        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleOnChange}
                render={props =>
                    <Page
                        handleOnChange={handleOnChange}
                        openVistaPrevia={openVistaPrevia}
                        handleEnterVistaPrevia={handleEnterVistaPrevia}
                        itemsPresupuesto={itemsPresupuesto}
                        handleCloseVistaPrevia={handleCloseVistaPrevia}
                        loadingVistaPrevia={loadingVistaPrevia}
                        verVistaPrevia={verVistaPrevia}
                        handleDescargarFormato={handleDescargarFormato}
                        {...props}
                    />}
            />
            <CustomizedSnackbars
                open={snackbarOpen}
                variant={snackbarVariant}
                handleClose={snackbarHandleClose}
                message={snackbarMessage}
                hideButton={snackbarHideButton}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </React.Fragment>
    );
    // }
}