import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl,
    FormLabel
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es'
import FileInput from '../../../components/fileInput';

const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        paddingTop: theme.spacing(3)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    }

}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        open,
        isValid,
        handleEnterDialog,
        handleSubmit,
        errors,
        touched,
        handleClose,
        estadoPago
    } = props;

    const change = (name, e) => {
        const { setFieldTouched, setFieldValue, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        e.persist();
        if (name === "adjunto") {
            setFieldValue('adjunto', e.target.files[0]);
        }
    };

    const dateChange = (name, e) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        handleChange(name);
        setFieldTouched(name, true, false);
        setFieldValue(name, e);

    };

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="periodo"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Mes/Año"
                                    format="MM/yyyy"
                                    views={["month"]}
                                    helperText={touched.periodo ? errors.periodo : ""}
                                    error={touched.periodo && Boolean(errors.periodo)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "periodo")}
                                    value={values.periodo}
                                />
                            </Grid>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="inicio"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Desde"
                                    format="dd/MM/yyyy"
                                    helperText={touched.inicio ? errors.inicio : ""}
                                    error={touched.inicio && Boolean(errors.inicio)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "inicio")}
                                    value={values.inicio}
                                />
                            </Grid>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="termino"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Hasta"
                                    format="dd/MM/yyyy"
                                    helperText={touched.termino ? errors.termino : ""}
                                    error={touched.termino && Boolean(errors.termino)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "termino")}
                                    value={values.termino}
                                />
                            </Grid>

                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg={4}>
                                <TextField
                                    name="monto"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    className={classes.textField}
                                    helperText={touched.monto ? errors.monto : ""}
                                    error={touched.monto && Boolean(errors.monto)}
                                    label="Monto"
                                    margin="normal"
                                    value={values.monto}
                                    fullWidth
                                    variant="outlined"
                                    onChange={change.bind(null, "monto")}
                                    disabled={values.es_hito}
                                />
                            </Grid>
                            <Grid item lg className={estadoPago ? classes.hidden : ""}>
                                <FormControl
                                    fullWidth={true}
                                    margin="normal"
                                >
                                    {/* <FormLabel>Adjunto</FormLabel> */}
                                    <FileInput
                                        inputName="adjunto"
                                        meta={{ touched: touched.adjunto, errors: errors.adjunto }}
                                        buttonColor="primary"
                                        buttonVariant="outlined"
                                        label="Archivo Adjunto"
                                        value={values.adjunto}
                                        handleOnChange={change.bind(null, "adjunto")}
                                        textVariant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || !values.adjunto}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
