import React, { useState } from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { accionCloseEliminarItemProgramaSemanal } from '../actionsProgramaSemanal';
import { deleteItemProgramaSemanal } from '../requestsItemProgramaSemanal';

export default function EliminarItemProgramacionSemanal(props) {

    const dispatch = useDispatch();

    const { open, itemProgramaSemanal } = useSelector(state => ({
        open: state.reducerProgramaSemanal.openEliminarItemProgramaSemanal,
        itemProgramaSemanal: state.reducerProgramaSemanal.itemProgramaSemanalSeleccionado
    }));

    const handleOnSubmit = async () => {
        const { handleSubmit } = props;
        console.log(itemProgramaSemanal);
        if (itemProgramaSemanal) {
            const response = await deleteItemProgramaSemanal(itemProgramaSemanal._id);
            console.log(response);
        }
        if (handleSubmit) handleSubmit();


    }

    const handleClose = async () => {
        dispatch(accionCloseEliminarItemProgramaSemanal());
    }


    return (

        <React.Fragment>
            <Page
                open={open}
                titulo="Eliminar Item del Programa Semanal"
                handleClose={handleClose}
                handleOnSubmit={handleOnSubmit}
            />
        </React.Fragment>
    );
}