import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from './page';
import { getPresupuesto, getItemsPresupuesto } from '../../services/database/presupuesto';
import ItemPresupuestoModel from '../../model/presupuesto/item_presupuesto_model';
import PresupuestoModel from '../../model/presupuesto/presupuesto_model';
import { useHistory } from 'react-router';

export default function Presupuesto(props) {

    const history = useHistory();
    const [data, setData] = useState(null);
    const [presupuesto, setPresupuesto] = useState(null);
    const [itemSeleccionado, setItemSeleccionado] = useState(null);
    const [openAgregarItem, setOpenAgregarItem] = useState(false);
    const [openEditarItem, setOpenEditarItem] = useState(false);
    const [openEliminarItem, setOpenEliminarItem] = useState(false);

    const { usuarioSesion, contrato, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto
    }))

    useEffect(() => {
        setData(null);
        setPresupuesto(null);
        cargarData();
    }, [proyecto])

    const cargarData = async () => {
        if (proyecto && proyecto.presupuesto_actual_ref) {
            const presupuesto = await getPresupuesto(proyecto.presupuesto_actual_ref);
            const items_presupuesto = await getItemsPresupuesto(proyecto.presupuesto_actual_ref);
            if (items_presupuesto && presupuesto) {
                const presupuestoObj = new PresupuestoModel(presupuesto);
                for (let i = 0; i < items_presupuesto.length; i++) {
                    let padre_item = items_presupuesto.find(a => a._id === items_presupuesto[i].padre);
                    if (padre_item) items_presupuesto[i].itemPadre = padre_item.item;
                    const item = new ItemPresupuestoModel(items_presupuesto[i]);
                    if (item.is_hoja) presupuestoObj.totalPresupuesto = presupuestoObj.totalPresupuesto + item.precio_total;
                    presupuestoObj.pushItem(item);
                }
                const listaTitulos = presupuestoObj.listadoItems.filter(a => !a.padre);
                for (let i = 0; i < listaTitulos.length; i++) {
                    listaTitulos[i].buildHijos(presupuestoObj.listadoItems);
                    presupuestoObj.pushTitulo(listaTitulos[i]);
                }
                setData(presupuestoObj.listadoItems);
                setPresupuesto(presupuestoObj);
                return;
            }
        }
        setData([]);
    }

    const handleOpenAgregarItem = (e, item) => {
        setOpenAgregarItem(true);
        setItemSeleccionado(new ItemPresupuestoModel(item));
    }

    const handleCloseAgregarItem = () => {
        setOpenAgregarItem(false);
        setItemSeleccionado(null);
    }

    const handleSubmitAgregarItem = async (values) => {
        await ItemPresupuestoModel.post(values);
        handleCloseAgregarItem();
        cargarData();
    }

    const handleOpenEditarItem = (e, item) => {
        setOpenEditarItem(true);
        setItemSeleccionado(new ItemPresupuestoModel(item));
    }

    const handleCloseEditarItem = () => {
        setOpenEditarItem(false);
        setItemSeleccionado(null);
    }

    const handleSubmitEditarItem = async (values) => {
        await itemSeleccionado.update(values);
        handleCloseEditarItem();
        cargarData();
    }

    const handleOpenEliminarItem = (e, item) => {
        setOpenEliminarItem(true);
        setItemSeleccionado(new ItemPresupuestoModel(item));
    }

    const handleSubmitEliminarItem = async (data) => {
        await itemSeleccionado.update({ is_eliminado: true });
        cargarData();
        handleCloseEliminarItem();
    }

    const handleCloseEliminarItem = (data) => {
        setOpenEliminarItem(false);
        setItemSeleccionado(null);
    }

    return (
        <Page
            proyecto={proyecto}
            presupuesto={presupuesto}
            data={data}
            openAgregarItem={openAgregarItem}
            openEditarItem={openEditarItem}
            openEliminarItem={openEliminarItem}
            handleOpenAgregarItem={handleOpenAgregarItem}
            handleOpenEditarItem={handleOpenEditarItem}
            handleOpenEliminarItem={handleOpenEliminarItem}
            handleCloseAgregarItem={handleCloseAgregarItem}
            handleCloseEditarItem={handleCloseEditarItem}
            handleCloseEliminarItem={handleCloseEliminarItem}
            handleSubmitAgregarItem={handleSubmitAgregarItem}
            handleSubmitEditarItem={handleSubmitEditarItem}
            handleSubmitEliminarItem={handleSubmitEliminarItem}
            usuarioSesion={usuarioSesion}
            itemSeleccionado={itemSeleccionado}
            history={history}
        />
    );
}