export const CLOSE_AGREGAR_REPROGRAMACION = "CLOSE_AGREGAR_REPROGRAMACION";
export const OPEN_AGREGAR_REPROGRAMACION = "OPEN_AGREGAR_REPROGRAMACION";
export const CLOSE_AGREGAR_REPROGRAMACION_ARCHIVO = "CLOSE_AGREGAR_REPROGRAMACION_ARCHIVO";
export const OPEN_AGREGAR_REPROGRAMACION_ARCHIVO = "OPEN_AGREGAR_REPROGRAMACION_ARCHIVO";
export const CLOSE_ELIMINAR_REPROGRAMACION = "CLOSE_ELIMINAR_REPROGRAMACION";
export const OPEN_ELIMINAR_REPROGRAMACION = "OPEN_ELIMINAR_REPROGRAMACION";

export const accionOpenAgregarReprogramacion = () => ({
    type: OPEN_AGREGAR_REPROGRAMACION
});
export const accionCloseAgregarReprogramacion = () => ({
    type: CLOSE_AGREGAR_REPROGRAMACION
});

export const accionOpenAgregarReprogramacionArchivo = () => ({
    type: OPEN_AGREGAR_REPROGRAMACION_ARCHIVO
});
export const accionCloseAgregarReprogramacionArchivo = () => ({
    type: CLOSE_AGREGAR_REPROGRAMACION_ARCHIVO
});

export const accionCloseEliminarReprogramacion= () => ({
    type: CLOSE_ELIMINAR_REPROGRAMACION
});
export const accionOpenEliminarReprogramacion= (reprogramacionSeleccionada) => ({
    type: OPEN_ELIMINAR_REPROGRAMACION,
    reprogramacionSeleccionada: reprogramacionSeleccionada
});