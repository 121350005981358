import React, { Fragment, useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Typography,
    Container
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { green, red, blue, amber } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles'
import { AgregarRecurso } from './index.js';
const location = require('../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    successButton: {
        backgroundColor: green[500],
        color: "#FFF",
        borderRadius: "50%",
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    },
}));

function Tabla(props) {

    const classes = useStyles();

    const {
        data,
        handleOpenAgregar,
        handleEliminar,
        handleEditar
    } = props;
    const [pageSize, setPageSize] = useState(5);
    // const [currentPage, setCurrentPage] = useState(0);

    return (
        <MaterialTable
            title={`Listado de Recursos`}
            options={{
                filtering: true,
                pageSizeOptions: [5, 10, 20, 50],
                pageSize: pageSize,
                grouping: false,
                paging: true,
                padding: 'dense'

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={location}
            actions={[
                {
                    tooltip: 'Agregar',
                    icon: 'add_circle',
                    isFreeAction: true,
                    iconProps: {
                        className: classes.success
                    },
                    onClick: (event) => { handleOpenAgregar() }
                },

                {
                    tooltip: 'Eliminar',
                    icon: 'delete',
                    iconProps: {
                        className: classes.error
                    },
                    onClick: (evt, data) => handleEliminar(data)
                },
                {
                    tooltip: 'Editar',
                    icon: 'edit',
                    iconProps: {
                        className: classes.warning
                    },
                    onClick: (evt, data) => handleEditar(data)
                }
            ]}
            // components={{
            //     Action: props => {
            //         if(props.action.isFreeAction){
            //             return (
            //                 <div className={classes.successButton}>
            //                     <MTableAction {...props}/>
            //                 </div>
            //             );
            //         }else{
            //             return (<MTableAction {...props}/>);
            //         }
            //     },
            // }}                
            columns={[
                {
                    title: 'Nombre',
                    field: 'nombre',
                },
                {
                    title: 'Descrición',
                    field: 'descripcion',
                },
                {
                    title: 'Cantidad',
                    field: 'cantidad',
                }


            ]}
            data={data}
        />
    );
}

function Page(props) {
    const classes = useStyles();
    const {
        data,
        openAgregar,
        handleOpenAgregar,
        handleSubmitAgregar,
        handleCloseAgregar,
        handleEliminar,
        handleEditar,
    } = props;

    useEffect(() => {
        const { data, handleLoadData } = props;
        if (!data) handleLoadData();
    });
    return (
        <Container className={classes.main}>
            {(!data) &&
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Cargando Recursos</Typography>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {data &&
                <Grid container>
                    <Grid item lg>
                        <Card className={classes.card}>
                            <CardHeader
                                titleTypographyProps={{
                                    align: "center"
                                }}
                                title={("Recursos")}
                            />
                        </Card>
                    </Grid>
                </Grid>
            }
            {data &&
                <Tabla
                    {...props}
                />
            }

            <AgregarRecurso
                open={openAgregar}
                handleClose={handleCloseAgregar}
                handleSubmit={handleSubmitAgregar}
            />
        </Container>
    );
}

export default Page;