import {
  OPEN_AGREGAR_PROGRAMA_SEMANAL,
  CLOSE_AGREGAR_PROGRAMA_SEMANAL,
  OPEN_ELIMINAR_PROGRAMA_SEMANAL,
  CLOSE_ELIMINAR_PROGRAMA_SEMANAL,
  OPEN_ELIMINAR_ITEM_PROGRAMA_SEMANAL,
  CLOSE_ELIMINAR_ITEM_PROGRAMA_SEMANAL
} from './actionsProgramaSemanal';

const defaultState = {
  openAgregarProgramaSemanal: false,
  openEliminarProgramaSemanal: false,
  openEliminarItemProgramaSemanal: false,
  programaSemanalSeleccionado: null,
  itemProgramaSemanalSeleccionado: null,

};

export default  function reducerPod(state = defaultState, {
  type,
  programaSemanalSeleccionado,
  itemProgramaSemanalSeleccionado }) {

  switch (type) {
    case OPEN_AGREGAR_PROGRAMA_SEMANAL: {
      return { ...state, openAgregarProgramaSemanal: true };
    }
    case CLOSE_AGREGAR_PROGRAMA_SEMANAL: {
      return { ...state, openAgregarProgramaSemanal: false };
    }
    case OPEN_ELIMINAR_PROGRAMA_SEMANAL: {
      return { ...state, openEliminarProgramaSemanal: true, programaSemanalSeleccionado: programaSemanalSeleccionado };
    }
    case CLOSE_ELIMINAR_PROGRAMA_SEMANAL: {
      return { ...state, openEliminarProgramaSemanal: false, programaSemanalSeleccionado: null };
    }
    case OPEN_ELIMINAR_ITEM_PROGRAMA_SEMANAL: {
      return { ...state, openEliminarItemProgramaSemanal: true, itemProgramaSemanalSeleccionado: itemProgramaSemanalSeleccionado };
    }
    case CLOSE_ELIMINAR_ITEM_PROGRAMA_SEMANAL: {
      return { ...state, openEliminarItemProgramaSemanal: false, itemProgramaSemanalSeleccionado: null };
    }
    default:
      return state;
  }
}