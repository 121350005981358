/**
 * Funciones de consulta a base de datos para reportes pod diarios
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import Axios from "axios";
/**
 * Obtener un reporte de reporte diario de la base de datos
 * @param {String} id id del reporte diario
 * @return {Object} handler handler con promesa y token de cancelacion
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getReportePodDiario = (id, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/reportes-pod-diarios/${id}`;
    const promise = Axios.get(url, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Obtener todos los reporte pod diarios de la base de datos
 * @param {Object} filters filtros para aplicar a los resultados de la búsqueda
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getReportesPodDiarios = (filters, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/reportes-pod-diarios/`;
    const promise = Axios.get(url, {
        params: filters,
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Actualizar los datos un reporte diario pod en la base de datos
 * @param {String} id id del reporte diario a actualizar
 * @param {Object} data campos actualizados del dia del reporte diario
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const updateReportePodDiario = (id, data, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/reportes-pod-diarios/${id}`;
    const promise = Axios.put(url, data, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Eliminar un reporte diario de pod de la base de datos
 * @param {String} id id del reporte diario a actualizar
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deleteReportePodDiario = (id, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/reportes-pod-diarios/${id}`;
    const promise = Axios.delete(url, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Crear un nuevo reporte pod diario en la base de datos
 * @param {Object} data Campos del nuevo reporte pod diario
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postReportePodDiario = (data, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/reportes-pod-diarios/`;
    const promise = Axios.post(url, data, {
        canceltoken: cancelToken
    });
    return promise;
}