import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    LinearProgress,
    Tooltip,
    IconButton
} from '@material-ui/core';
import MaterialTable from 'material-table';
import './style.css';
import FileInput from '../../../components/fileInput';
import Moment from 'react-moment';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
const style = makeStyles(theme => ({
    main: {
        flexGrow: 1
    },
    actionButtons: {
        marginRight: theme.spacing(2)
    },
    gridCenter: {
        textAlign: "center"
    }
}));

const generarFechaView = (data) => {

    let date = new Date(data);

    if (date.getFullYear() === 2100) {
        return "indefinido";
    } else {
        return <Moment format="DD/MM/YYYY">{date}</Moment>
    }
}

function TablaVistaPrevia(props) {
    const {
        actividades
    } = props;
    const [pageSize, setPageSize] = useState(10);
    return (
        <div>

            <MaterialTable
                title="Programa"
                options={{
                    filtering: true,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false

                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar"
                    },
                    pagination: {
                        labelRowsSelect: "Filas",
                        labelDisplayedRows: '{from}-{to} de {count}'
                    },
                    grouping: {
                        placeholder: " Arrastre los encabezados aquí para agrupar "
                    }

                }}
                parentChildData={(row, rows) => rows.find(a => a.item === row.padre_item)}
                onChangeRowsPerPage={size => { setPageSize(size) }}
                columns={[
                    {
                        title: 'Ítem',
                        field: 'item',
                    },
                    {
                        title: 'ID',
                        field: 'id_item',
                    },
                    {
                        title: 'Nombre',
                        field: 'nombre',
                    },
                    {
                        title: 'Inicio Temprano',
                        field: 'inicio_temprano',
                        render: rowData => rowData.inicio_temprano ? generarFechaView(rowData.inicio_temprano) : ""
                    },
                    {
                        title: 'Inicio Programado',
                        field: 'inicio_ideal',
                        render: rowData => rowData.inicio_ideal ? generarFechaView(rowData.inicio_ideal) : ""
                    },
                    {
                        title: 'Inicio Tardío',
                        field: 'inicio_tardio',
                        render: rowData => rowData.inicio_tardio ? generarFechaView(rowData.inicio_tardio) : ""
                    },
                    {
                        title: 'Termino Temprano',
                        field: 'termino_temprano',
                        render: rowData => rowData.termino_temprano ? generarFechaView(rowData.termino_temprano) : ""
                    },
                    {
                        title: 'Termino Programado',
                        field: 'termino_ideal',
                        render: rowData => rowData.termino_ideal ? generarFechaView(rowData.termino_ideal) : ""
                    },
                    {
                        title: 'Termino Tardío',
                        field: 'termino_tardio',
                        render: rowData => rowData.termino_tardio ? generarFechaView(rowData.termino_tardio) : ""
                    },
                    {
                        title: 'Duración Programada',
                        field: 'duracion',
                    },
                    {
                        title: 'HH',
                        field: 'horas_hombre',
                    }

                ]}
                data={actividades}
            />
        </div>
    );
}

function Page(props) {
    const classes = style();

    const {
        handleSubmit,
        isValid,
        errors,
        touched,
        values,
        openVistaPrevia,
        handleEnterVistaPrevia,
        handleCloseVistaPrevia,
        loadingVistaPrevia,
        actividades,
        verVistaPrevia,
        // proyectos,
        // contratos,
        handleDescargarFormato
    } = props;

    const change = (name, e) => {
        const {
            setFieldTouched,
            handleChange,
            setFieldValue,
            // handleChangeContrato
        } = props;
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        // handleChangeContrato(e.target);
        if (name === "file") {
            setFieldValue('file', e.target.files[0]);
        }
    };

    return (
        <Card className={classes.main}>
            <CardHeader
                title="Nuevo Programa"
                subheader="Formulario para la carga de Programa desde archivo Excel"
                action={
                    <Tooltip title="Ir al Programa">
                        <IconButton component={Link} to={ROUTES.PROGRAMA} aria-label="settings">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent className={classes.main}>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Typography variant="h6" gutterBottom>
                        Archivo XLSX
                    </Typography>
                    <Grid container
                        direction="row"
                        spacing={10}>
                        <Grid item lg>
                            <FileInput
                                type="submit"
                                buttonColor="primary"
                                buttonVariant="outlined"
                                textVariant="outlined"
                                inputName="file"
                                label="Seleccionar Archivo XLSX"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                handleOnChange={change.bind(null, "file")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}>
                        <Grid item xs className={classes.gridCenter}>
                            <Button
                                onClick={() => handleDescargarFormato()}
                                color="secondary"
                                variant="outlined"
                                className={classes.actionButtons}
                            >
                                Descargar Formato
                                    </Button>
                            <Button
                                className={classes.actionButtons}
                                onClick={() => verVistaPrevia(values)}
                                variant="outlined"
                                color="secondary"
                                disabled={!isValid}
                            >
                                Vista Previa
                                    </Button>
                            <Button
                                className={classes.actionButtons}
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!isValid}
                            >
                                Guardar
                                    </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <Dialog
                onClose={handleCloseVistaPrevia}
                open={openVistaPrevia}
                onEntered={handleEnterVistaPrevia}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"lg"}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Vista Previa
                    </DialogTitle>
                <DialogContent>
                    {loadingVistaPrevia &&
                        <LinearProgress color="primary" />
                    }
                    {!loadingVistaPrevia &&
                        <TablaVistaPrevia
                            actividades={actividades}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleCloseVistaPrevia}
                    >
                        Cerrar
                        </Button>
                </DialogActions>
            </Dialog >

        </Card>
    );
}

export default Page;