import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Card,
    CircularProgress,
    CardHeader,
    CardContent,
    IconButton,
    Grid,
    Typography,
    TableRow,
    Table,
    TableCell,
    TableBody,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Container
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { ArrowBack, Description, Error as ErrorIcon } from '@material-ui/icons';
import DialogoAgregar from './agregarAvance';
import RevisarAvance from './revisarAvance';
import moment from 'moment';
import Moment from 'react-moment';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as ROUTES from '../../../constants/routes';
import formatNumber from 'format-number';
import DialogoConfirmar from '../../../components/dialogoConfirmar';
import { Link } from 'react-router-dom';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    dialogPaper: {
        overflow: 'auto'
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    center: {
        textAlign: "center",
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    gridCardTitle: {
        // width: "100%",
        // position: "fixed",
        // top: theme.spacing(9)
        marginBottom: theme.spacing(2)
    },
}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

const CustomCell2 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        color: red[500],
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

function generarFechaView(data) {
    if (data) {
        let date = new Date(data);
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        let utcDate = new Date(date.getTime() + userTimezoneOffset);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format="DD/MM/YYYY">{utcDate}</Moment>
        }
    } else {
        return null;
    }

}

function Tabla(props) {

    const classes = useStyles();
    const {
        data,
        handleOpenDialogoAgregar,
        handleOpenDialogoEditar,
        handleOpenEliminar,
        handleOpenResolverConflicto,
        handleOpenRevisar,
        itemPrograma
    } = props;
    const [pageSize, setPageSize] = useState(10);



    return (
        <MaterialTable
            title={"Lista de Avances"}
            options={{
                filtering: false,
                pageSizeOptions: [5, 10, 20, 50],
                pageSize: pageSize,
                grouping: false,
                search: true,
                // toolbarButtonAlignment: 'left',
                searchFieldAlignment: 'left',
                padding: 'dense',

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={{

                toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                    nRowsSelected: '{0} fila(s) selecionadas'
                },
                pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstAriaLabel: "Primera Página",
                    firstTooltip: "Primera Página",
                    previousAriaLabel: "Página Anterior",
                    previousTooltip: "Página Anterior",
                    nextAriaLabel: "Siguiente Página",
                    nextTooltip: "Siguiente Página",
                    lastAriaLabel: "Última Página",
                    lastTooltip: "Última Página"

                },

                grouping: {
                    placeholder: " Arrastre los encabezados aquí para agrupar "
                },
                header: {
                    actions: "Acciones"
                },
                body: {
                    filterRow: {
                        filterTooltip: "Filtrar"
                    },
                    emptyDataSourceMessage: "No se han ingresado avances"
                }

            }}

            actions={[
                {
                    tooltip: "Agregar Avance",
                    icon: "add_circle",
                    isFreeAction: true,
                    onClick: (e, rowData) => handleOpenDialogoAgregar()
                },
                {
                    tooltip: "Editar",
                    icon: "edit",
                    onClick: (e, rowData) => handleOpenDialogoEditar(rowData)
                },
                {
                    tooltip: "Eliminar",
                    icon: "delete",
                    onClick: (e, rowData) => handleOpenEliminar(rowData)
                },
                rowData => ({
                    tooltip: "Revisar",
                    iconProps: {
                        className: classes.error
                    },
                    icon: "error",
                    hidden: (rowData.estado === "HH requieren revisión" || rowData.estado === "Cantidad requiere revisión") ? false : true,
                    onClick: (e, rowData) => handleOpenRevisar(rowData)
                }),
                rowData => ({
                    tooltip: "Resolver Conflicto",
                    iconProps: {
                        className: classes.error
                    },
                    icon: "error",
                    hidden: (rowData.estado === "Conflicto") ? false : true,
                    onClick: (e, rowData) => handleOpenResolverConflicto(rowData)
                })
            ]}
            columns={[
                {
                    title: 'Fecha',
                    field: 'fecha',
                    render: rowData => generarFechaView(rowData.fecha)
                },
                {
                    title: 'Turno',
                    field: 'turno',
                },
                {
                    title: 'Avance (%)',
                    field: 'porcentaje_avance'
                },
                {
                    title: `Cantidad ${(itemPrograma && itemPrograma.unidad) ? "(" + itemPrograma.unidad + ")" : ""}`,
                    field: 'cantidad'
                },
                {
                    title: 'HH',
                    field: 'horas_hombre',
                    render: rowData => rowData.horas_hombre ? Math.round(rowData.horas_hombre * 1000) / 1000 : 0
                },
                {
                    title: 'Estado',
                    field: 'estado'
                },
                {
                    title: 'Observación',
                    field: 'observacion'
                }
            ]}
            data={data}
        />
    );
}


function Page(props) {
    const classes = useStyles();
    const {
        avanceSeleccionado,
        data,
        goTo,
        handleCloseDialogoAgregar,
        handleCloseDialogoEditar,
        handleCloseEliminar,
        handleCloseMarcarRevisado,
        handleCloseResolverConflicto,
        handleCloseRevisar,
        handleOpenEliminar,
        handleOpenMarcarRevisado,
        handleSubmitAgregar,
        handleSubmitEditar,
        handleSubmitEliminar,
        handleSubmitMarcarRevisado,
        handleSubmitResolverConflicto,
        handleSubmitRevisar,
        itemPrograma,
        openDialogoAgregar,
        openDialogoEditar,
        openEliminar,
        openMarcarRevisado,
        openResolverConflicto,
        openRevisar
    } = props;
    return (
        <Container className={classes.main}>
            <Card className={classes.gridCardTitle}>
                <CardHeader
                    action={
                        <IconButton component={Link} to={ROUTES.PROGRAMA}>
                            <ArrowBack />
                        </IconButton>
                    }
                    titleTypographyProps={{
                        align: "center"
                    }}
                    title={(itemPrograma && data ? ` ${itemPrograma.item} - ${itemPrograma.nombre}` : "Cargando Actividad")}
                />
                <CardContent>
                    {!data &&
                        // <CircularProgress color="primary"></CircularProgress>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                    }
                    {itemPrograma &&
                        <div>
                            <Grid container>
                                <Grid item lg={6}>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <CustomCell1 colSpan="4" align="center"><b>Rango Programado</b></CustomCell1>
                                            </TableRow>
                                            <TableRow>
                                                <CustomCell1 align="center"><b>Inicio</b></CustomCell1>
                                                <CustomCell1>{itemPrograma.inicio_ideal ? `${moment(itemPrograma.inicio_ideal).utc().format("DD/MM/YYYY")}` : "--"}</CustomCell1>
                                                <CustomCell1 align="center"><b>Término</b></CustomCell1>
                                                <CustomCell1>{itemPrograma.termino_ideal ? `${moment(itemPrograma.termino_ideal).utc().format("DD/MM/YYYY")}` : "--"}</CustomCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item lg={6}>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <CustomCell1 colSpan="4" align="center"><b>Rango Real</b></CustomCell1>
                                            </TableRow>
                                            <TableRow>
                                                <CustomCell1 align="center"><b>Inicio</b></CustomCell1>
                                                <CustomCell1>{itemPrograma.inicio_real ? `${moment(itemPrograma.inicio_real).utc().format("DD/MM/YYYY")}` : "--"}</CustomCell1>
                                                <CustomCell1 align="center"><b>Término</b></CustomCell1>
                                                <CustomCell1>{itemPrograma.termino_real ? `${moment(itemPrograma.termino_real).utc().format("DD/MM/YYYY")}` : "--"}</CustomCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item lg={6}>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <CustomCell1 colSpan="4" align="center"><b>Avance</b></CustomCell1>
                                            </TableRow>
                                            <TableRow>
                                                <CustomCell1 align="center"><b>Programado</b></CustomCell1>
                                                <CustomCell1>{(itemPrograma.avance_programado || itemPrograma.avance_programado === 0) ? `${numberFormater(itemPrograma.avance_programado)}%` : "0%"}</CustomCell1>
                                                <CustomCell1 align="center"><b>Real</b></CustomCell1>
                                                <CustomCell1>{(itemPrograma.avance_real || itemPrograma.avance_real === 0) ? `${numberFormater(itemPrograma.avance_real)}%` : "0%"}</CustomCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item lg={6}>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <CustomCell1 colSpan="4" align="center"><b>HH</b></CustomCell1>
                                            </TableRow>
                                            <TableRow>
                                                <CustomCell1 align="center"><b>Programado</b></CustomCell1>
                                                <CustomCell1>{(itemPrograma.horas_hombre || itemPrograma.horas_hombre === 0) ? `${numberFormater(itemPrograma.horas_hombre)}` : "--"}</CustomCell1>
                                                <CustomCell1 align="center"><b>Real</b></CustomCell1>
                                                <CustomCell1>{(itemPrograma.horasHombreReales || itemPrograma.horasHombreReales === 0) ? `${numberFormater(itemPrograma.horasHombreReales)}` : "--"}</CustomCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item lg>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <CustomCell1 align="center"><b>Estado</b></CustomCell1>
                                            </TableRow>
                                            <TableRow>
                                                <CustomCell1 align="center">
                                                    {itemPrograma.estado}
                                                    {
                                                        (itemPrograma.estado === "HH requieren revisión" || itemPrograma.estado === "Cantidad requiere revisión" || itemPrograma.estado === "Conflicto") &&
                                                        <Tooltip
                                                            title="Resolver"
                                                        >
                                                            <IconButton onClick={handleOpenMarcarRevisado}>
                                                                <ErrorIcon className={classes.error}></ErrorIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </CustomCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </CardContent>
            </Card>
            {data &&
                <Tabla
                    {...props}
                />
            }
            <DialogoAgregar
                open={openDialogoAgregar}
                itemPrograma={itemPrograma}
                handleCloseDialog={handleCloseDialogoAgregar}
                handleSubmit={handleSubmitAgregar}
            />
            <DialogoAgregar
                open={openDialogoEditar}
                itemPrograma={itemPrograma}
                handleCloseDialog={handleCloseDialogoEditar}
                handleSubmit={handleSubmitEditar}
                avance={avanceSeleccionado}
            />
            <RevisarAvance
                open={openRevisar}
                itemPrograma={itemPrograma}
                handleCloseDialog={handleCloseRevisar}
                handleSubmit={handleSubmitRevisar}
                avance={avanceSeleccionado}
            />
            <DialogoConfirmar
                open={openEliminar}
                message={"¿Está seguro de eliminar el avance?"}
                action={handleSubmitEliminar}
                handleClose={handleCloseEliminar}
            />
            {/* <DialogoConfirmar
                    open={openMarcarRevisado}
                    message={"Se volvera a marcar la actividad como Activa ¿Está seguro de continuar?"}
                    action={handleSubmitMarcarRevisado}
                    handleClose={handleCloseMarcarRevisado}
                /> */}
            <Dialog
                open={openResolverConflicto}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth={true}
                onClose={handleCloseResolverConflicto}
            >
                <DialogTitle>Resolver Conflicto</DialogTitle>
                <DialogContent>
                    Debe elegir entre dejar el avance como "Activo" o eliminarlo.
                    </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSubmitResolverConflicto({ ...avanceSeleccionado, estado: "Activo" })}
                        color="primary"
                        variant="contained"
                    >
                        Activar Avance
                        </Button>
                    <Button
                        onClick={() => {
                            handleCloseResolverConflicto();
                            handleOpenEliminar(avanceSeleccionado);
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Eliminar
                        </Button>
                    <Button onClick={handleCloseResolverConflicto} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </Dialog >
            <Dialog
                open={openMarcarRevisado}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth={true}
                onClose={handleCloseMarcarRevisado}
            >
                <DialogTitle>Resolver Conflicto</DialogTitle>
                <DialogContent>
                    Debe marcar la actividad como "Terminada" o "No terminada".
                    </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSubmitMarcarRevisado({ estado: "Terminada" })}
                        color="primary"
                        variant="contained"
                    >
                        Terminada
                        </Button>
                    <Button
                        onClick={() => handleSubmitMarcarRevisado({ estado: "No terminada" })}
                        color="primary"
                        variant="contained"
                    >
                        No terminada
                        </Button>
                    <Button onClick={handleCloseMarcarRevisado} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </Dialog >
        </Container>
    );
}

export default Page;
