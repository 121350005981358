import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import moment from 'moment';
import { getIndicadoresPeriodoLineaBase } from '../requestsLineaBase';
import Axios from 'axios';
import { toDate } from 'date-fns';
import { actualizarGraficoLineaBaseSemanal } from '../actionsReportePodDiario';
moment.locale('es');
export default function IndicadoresReportesPodDiarios() {
    const dispatch = useDispatch();
    const {
        proyecto,
        actualizar
    } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto,
        actualizar: state.reducerReportePodDiario.actualizarGraficoLineabaseSemanal

    }));

    const [configChart, setConfigChart] = useState(null);
    const [isloading, setIsLoading] = useState(true);
    const [inicioSemana, setInicioSemana] = useState(
        moment().utc().startOf('week').startOf('day')
    )
    const [avancesPeriodo, setAvancesPeriodo] = useState(null);
    const [indicadores, setIndicadores] = useState(null);

    const loadDataPodSemana = (proyecto, inicioSemana) => {
        if (proyecto) {
            const CancelToken = Axios.CancelToken;
            let cancelRequest = null;
            let cancelToken = new CancelToken(function executor(c) {
                cancelRequest = c;
            });
            getIndicadoresPeriodoLineaBase(proyecto._id, moment(inicioSemana).clone().subtract(7, 'days').toDate(), moment(inicioSemana).clone().endOf('week').endOf('day').toDate(), cancelToken)
                .then((response) => {
                    if (response.status === 204) {
                        setConfigChart(null);
                        setAvancesPeriodo(null);
                        setIndicadores(null);
                        setIsLoading(false);
                    } else if (response.status == 200) {
                        loadChart(response.data);
                        setAvancesPeriodo(response.data);
                        setIsLoading(false);
                    } else {
                        setConfigChart(null);
                        setAvancesPeriodo(null);
                        setIndicadores(null);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setConfigChart(null);
                    setAvancesPeriodo(null);
                    setIndicadores(null);
                    setIsLoading(false);
                });
            dispatch(actualizarGraficoLineaBaseSemanal(false));
            return cancelRequest;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (actualizar) {
            setIsLoading(true);
            const cancelRequest = loadDataPodSemana(proyecto, inicioSemana);
            return () => cancelRequest ? cancelRequest() : null;
        }
    }, [proyecto, inicioSemana, actualizar]);

    /**
     * Preparar datos y configuracion del grafico
     * @param {} avancesPeriodo 
     */
    const loadChart = async (avancesPeriodo) => {
        if (avancesPeriodo !== null) {
            // const chartData = {
            //     avanceProgramado: [],
            //     avanceReal: [],
            //     hhProgramado: [],
            //     hhReal: []
            // }
            const indicadoresPeriodo = {
                totalAvanceProgramado: 0,
                totalAvanceReal: 0,
                totalHHProgramado: 0,
                totalHHReal: 0,
                promedioCumplimientoPod: 0
            }
            // console.log(avancesPeriodo);
            let diasCumplimiento = 0;
            avancesPeriodo.avanceProgramado.map((a, index) => {
                indicadoresPeriodo.totalAvanceProgramado = indicadoresPeriodo.totalAvanceProgramado + (avancesPeriodo.avanceProgramado[index][1] ? avancesPeriodo.avanceProgramado[index][1] : 0);
                indicadoresPeriodo.totalAvanceReal = indicadoresPeriodo.totalAvanceReal + (avancesPeriodo.avanceReal[index][1] ? avancesPeriodo.avanceReal[index][1] : 0);
                indicadoresPeriodo.totalHHProgramado = indicadoresPeriodo.totalHHProgramado + (avancesPeriodo.hhProgramado[index][1] ? avancesPeriodo.hhProgramado[index][1] : 0);
                indicadoresPeriodo.totalHHReal = indicadoresPeriodo.totalHHReal + (avancesPeriodo.hhReal[index][1] ? avancesPeriodo.hhReal[index][1] : 0);
                if (avancesPeriodo.cumplimientoPod[index][1]) {
                    diasCumplimiento++;
                    indicadoresPeriodo.promedioCumplimientoPod = indicadoresPeriodo.promedioCumplimientoPod + avancesPeriodo.cumplimientoPod[index][1];
                }
            });

            indicadoresPeriodo.promedioCumplimientoPod = diasCumplimiento ? indicadoresPeriodo.promedioCumplimientoPod / diasCumplimiento : 0;

            const options = {
                xAxis: {
                    labels: {
                        step: 1
                    },
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        day: '%e %b',
                        month: '%e %b %Y',
                        year: '%b %Y'
                    },
                    title: {
                        text: 'Semana'
                    },
                    plotBands: [{
                        color: "#cecece",
                        from: moment(inicioSemana).utc().startOf('day').subtract(12, "hours").valueOf(),
                        to: moment(inicioSemana).utc().startOf('day').subtract(7, "days").subtract(12, "hours").valueOf()
                    }]
                },
                yAxis: [
                    {
                        min: 0,
                        title: {
                            text: 'Porcentaje Avance'
                        },
                        labels: {
                            format: '{value}%',
                        },

                    },
                    {
                        min: 0,
                        title: {
                            text: 'Horas Hombre (HH)'
                        },
                        labels: {
                            format: '{value}',
                        },
                        opposite: true
                    }
                ],
                title: {
                    text: ""
                },
                series: [
                    {
                        name: "Avance Programado L.B.",
                        type: 'column',
                        yAxis: 0,
                        data: avancesPeriodo.avanceProgramado,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                        color: 'rgb(124, 181, 236)'
                    },
                    {
                        name: "Avance Ganado",
                        type: 'column',
                        yAxis: 0,
                        data: avancesPeriodo.avanceReal,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                        color: 'rgb(144, 237, 125)'
                    },
                    {
                        name: "HH Programadas",
                        type: 'spline',
                        yAxis: 1,
                        data: avancesPeriodo.hhProgramado,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                        color: 'rgb(67, 67, 72)'
                    },
                    {
                        name: "HH Ganadas",
                        type: 'spline',
                        yAxis: 1,
                        data: avancesPeriodo.hhReal,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                        color: 'rgb(247, 163, 92)'
                    },
                    // {
                    //     name: "Cumplimiento POD",
                    //     type: 'spline',
                    //     yAxis: 0,
                    //     data: avancesPeriodo.cumplimientoPod,
                    //     tooltip: {
                    //         headerFormat: '<b>{series.name}</b><br>',
                    //         pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                    //     },
                    //     color: 'rgb(128, 133, 233)'
                    // }

                ],
                plotOptions: {
                    area: {
                        fillOpacity: 0.2,
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                margin: 0,
                exporting: {
                    scale: 1,
                    sourceWidth: 1424,
                    sourceHeight: 400
                }


            };
            setConfigChart(options);
            setIndicadores(indicadoresPeriodo);
            console.log(indicadoresPeriodo);
            setIsLoading(false);
        }
    }

    return (
        <Page
            avancesPeriodo={avancesPeriodo}
            configChart={configChart}
            isloading={isloading}
            inicioSemana={inicioSemana}
            indicadores={indicadores}
            setInicioSemana={setInicioSemana}
        />
    );
}