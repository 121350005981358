import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Page from './page';
import { updateItemPrograma, deleteItemPrograma, updatePrograma, getBuildPrograma } from '../../services/database/programacion';
import { getItemPresupuestoByItem } from '../../services/database/presupuesto';
import { updateProyecto, getProyecto } from '../../services/database/proyecto';
import { accionSetContrato } from '../../redux/actions/contratoUsuario';
import ProyectoModel from '../../model/ProyectoModel';
import ContratoModel from '../../model/ContratoModel';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import XLSX from 'xlsx';
import moment from 'moment';
import formatNumber from 'format-number';
import { accionOpenAgregarReprogramacionArchivo } from '../reprogramacion/actionsReprogramacion';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
export default function Programa(props) {

    const { proyecto, contrato, usuarioSesion } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto
    }));
    const [programa, setPrograma] = useState({});
    const [presupuesto, setPresupuesto] = useState(null);
    const [itemSeleccionado, setItemSeleccionado] = useState(null);
    const [openSelectorPresupuesto, setOpenSelectorPresupuesto] = useState(false);
    const [openAgregarActividad, setOpenAgregarActividad] = useState(false);
    const [openEditarActividad, setOpenEditarActividad] = useState(false);
    const [openEliminarActividad, setOpenEliminarActividad] = useState(false);
    const [openConfigurarBaseRealPrograma, setOpenConfigurarBaseRealPrograma] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const notisnack = useSnackbar();
    useEffect(() => {
        setPrograma({});
        cargarData();
    }, [proyecto]);

    const cargarData = async () => {
        if (proyecto && proyecto.programa_actual_ref) {
            const programaActual = await getBuildPrograma(proyecto.programa_actual_ref);
            if (programaActual) {
                setPrograma(programaActual);
                // dispatch(actionSetInicioPrograma(programaActual.inicioProgramado));
                return;
            } else {
                setPrograma({});
            }
        } else {
            setPrograma({});
        }

    }
    const handleOpenSelectorPresupuesto = (e, item) => {
        setOpenSelectorPresupuesto(true);
        setItemSeleccionado(item);
    }
    const handleCloseSelectorPresupuesto = () => {
        setOpenSelectorPresupuesto(false);
        setItemSeleccionado(null);
    }
    const handleOpenAgregarActividad = (e, item) => {
        setOpenAgregarActividad(true);
        setItemSeleccionado(item);
    }
    const handleCloseAgregarActividad = () => {
        setOpenAgregarActividad(false);
        setItemSeleccionado(null);
    }
    const handleOpenEditarActividad = (e, item) => {
        setOpenEditarActividad(true);
        setItemSeleccionado(item);
    }
    const handleCloseEditarActividad = () => {
        setOpenEditarActividad(false);
        setItemSeleccionado(null);
    }

    const handleOpenEliminarActividad = (e, item) => {
        setOpenEliminarActividad(true);
        setItemSeleccionado(item);
    }

    const handleCloseEliminarActividad = () => {
        setOpenEliminarActividad(false);
        setItemSeleccionado(null);
    }

    const handleOpenConfigurarBaseRealPrograma = (e) => {
        setOpenConfigurarBaseRealPrograma(true);
    }
    const handleCloseConfigurarBaseRealPrograma = (e) => {
        setOpenConfigurarBaseRealPrograma(false);
    }
    const handleSubmitConfigurarBaseRealPrograma = async (values) => {
        await updateProyecto(proyecto._id, values);
        handleCloseConfigurarBaseRealPrograma();
        const proyectoDB = await getProyecto(proyecto._id);
        const contratoDB = await getProyecto(contrato._id);
        const proyectoObj = new ProyectoModel(proyectoDB);
        const contratoObj = new ContratoModel(contratoDB);
        dispatch(accionSetContrato(contratoObj, proyectoObj));
    }

    const handleVerAvances = (event, item) => {
        history.push(`/programa/${item._id}/avances`);
    }

    const handleSubmitSelectorPresupuesto = async (values) => {
        handleCloseSelectorPresupuesto();
        cargarData();
    }

    const handleSubmitAgregarActividad = async (values) => {
        handleCloseAgregarActividad();
        cargarData();
    }

    const handleSubmitEditarActividad = async (values, id) => {
        handleCloseEditarActividad();
        cargarData();

    }

    const handleSubmitEliminarActividad = async () => {
        const key = notisnack.enqueueSnackbar("Guardando Cambios", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            await deleteItemPrograma(itemSeleccionado._id);
            notisnack.closeSnackbar(key);
            const successkey = notisnack.enqueueSnackbar("Operación exitosa: Actividad eliminada", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            handleCloseEliminarActividad();
            cargarData();
        }
        catch (error) {
            console.log(error);
            notisnack.closeSnackbar(key);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible eliminar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    const cerrarPrograma = async () => {
        updateProyecto(proyecto._id, { programa_cerrado: true });
        proyecto.programa_cerrado = true;
        cargarData();
    }

    const abrirPrograma = async () => {
        await programa.generarNuevaVersion();
        cargarData();
    }

    const handleSubmitEditarPrograma = async (values) => {
        await updatePrograma(programa._id, values);
        cargarData();
    }

    const handleDescargarExcel = async () => {
        const wb = XLSX.utils.book_new();
        const key = notisnack.enqueueSnackbar("Generando Excel", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        const wbdata = programa.items_programas_ref.map(a => {
            return {
                "Ítem": a.item,
                "ID": a.id_item,
                "Actividad": a.nombre,
                "Estado": a.estado,
                "Inicio Temprano": a.inicio_temprano ? moment(a.inicio_temprano).format("DD/MM/YYYY") : "",
                "Inicio Programado": a.inicio_reprogramado ? (a.inicio_ideal ? moment(a.inicio_ideal).format("DD/MM/YYYY") : "") : moment(a.inicio_ideal).format("DD/MM/YYYY"),
                "Inicio Tardío": a.inicio_tardio ? moment(a.inicio_tardio).format("DD/MM/YYYY") : "",
                "Término Temprano": a.termino_temprano ? moment(a.termino_temprano).format("DD/MM/YYYY") : "",
                "Término Programado": a.termino_reprogramado ? (a.termino_ideal ? moment(a.termino_ideal).format("DD/MM/YYYY") : "") : moment(a.termino_ideal).format("DD/MM/YYYY"),
                "Inicio Real": a.inicio_real ? moment(a.inicio_real).format("DD/MM/YYYY") : "",
                "Término Real": a.termino_real ? moment(a.termino_real).format("DD/MM/YYYY") : "",
                "Duración Programada": a.duracionProgramada ? numberFormater(a.duracionProgramada) : 0,
                "Duración Real": a.duracionReal ? numberFormater(a.duracionReal) : a.duracionProgramada,
                "Costo": numberFormater(a.costo_directo),
                "I. Presupuesto": a.itemPresupuesto,
                "Incidencia (Costos)": a.incidencia ? numberFormater(a.incidencia.toFixed(2)) : a.incidencia,
                "HH": numberFormater(a.horas_hombre),
                "Incidencia (HH)": a.incidenciaHorasHombre ? numberFormater(a.incidenciaHorasHombre.toFixed(2)) : 0,
                "Av. HH Prog.": a.avanceProgramadoHH ? numberFormater(a.avanceProgramadoHH.toFixed(2)) : 0,
                "Av. HH Real.": a.avanceRealHH ? numberFormater(a.avanceRealHH.toFixed(2)) : 0,
                "Av. Costos Prog.": a.avanceProgramadoCostos ? numberFormater(a.avanceProgramadoCostos.toFixed(2)) : 0,
                "Av. Costos Real": a.avanceRealCostos ? numberFormater(a.avanceRealCostos.toFixed(2)) : 0
            };
        });
        const headers = [
            "Ítem",
            "ID",
            "Actividad",
            "Estado",
            "Inicio Temprano",
            "Inicio Programado",
            "Inicio Tardío",
            "Término Temprano",
            "Término Programado",
            "Término Tardío",
            "Inicio Real",
            "Término Real",
            "Duración Programada",
            "Duración Real",
            "Costo",
            "I. Presupuesto",
            "Incidencia (Costos)",
            "HH",
            "Incidencia (HH)",
            "Av. HH Prog.",
            "Av. HH Real.",
            "Av. Costos Prog.",
            "Av. Costos Real"
        ];
        const ws = XLSX.utils.json_to_sheet(wbdata, headers);
        XLSX.utils.book_append_sheet(wb, ws, "Programa");
        XLSX.writeFile(wb, 'Programa.xlsx');
        notisnack.closeSnackbar(key);
        const successkey = notisnack.enqueueSnackbar("Descargando Archivo", {
            variant: 'success',
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            },
            action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
        });

    }

    const cargaRapidaDePresupuesto = async () => {
        if (!proyecto.presupuesto_actual_ref) {
            const key = notisnack.enqueueSnackbar("No hay un presupuesto asignado al proyecto", {
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(key)}><Close /></IconButton>
            });
        } else {
            const key = notisnack.enqueueSnackbar("Preparando carga de presupuesto", {
                persist: true,
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                }
            });
            try {
                notisnack.closeSnackbar(key);
                let progressKey = notisnack.enqueueSnackbar(`Actualizando actividad ${0} de ${programa.listaActividades.length}`, {
                    persist: true,
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    }
                });
                for (let i = 0; i < programa.listaActividades.length; i++) {
                    notisnack.closeSnackbar(progressKey);
                    progressKey = notisnack.enqueueSnackbar(`Actualizando actividad ${(i + 1)} de ${programa.listaActividades.length}`, {
                        persist: true,
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        }
                    });
                    if (programa.listaActividades[i].is_hoja) {
                        const result = await getItemPresupuestoByItem(proyecto.presupuesto_actual_ref, programa.listaActividades[i].item_presupuesto ? programa.listaActividades[i].item_presupuesto : programa.listaActividades[i].item);
                        if (result) {
                            const itemPresupuesto = result[0];
                            const cantidad = programa.listaActividades[i].costo_directo ? (itemPresupuesto.precio_total ? (programa.listaActividades[i].costo_directo / itemPresupuesto.precio_total) : programa.listaActividades[i].costo_directo) : itemPresupuesto.cantidad;
                            const update = {
                                item_presupuesto: itemPresupuesto.item,
                                item_presupuesto_ref: itemPresupuesto._id,
                                costo_directo: programa.listaActividades[i].costo_directo ? programa.listaActividades[i].costo_directo : itemPresupuesto.precio_total,
                                cantidad: cantidad,
                                unidad: itemPresupuesto.unidad,
                            };
                            await updateItemPrograma(programa.listaActividades[i]._id, update);
                        }
                    }
                }
                notisnack.closeSnackbar(progressKey);
                const successkey = notisnack.enqueueSnackbar("Operación exitosa: Actividades actualizadas", {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    },
                    action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
                });
                cargarData();
            }
            catch (e) {
                console.log(e);
                notisnack.closeSnackbar(key);
                const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible realizar la carga rapida de presupuesto. Intente de nuevo o contacte con soporte", {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    },
                    action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
                });
            }
        }
    }

    const handleOpenReprogramacion = () => {
        dispatch(accionOpenAgregarReprogramacionArchivo());
    };

    const handleSubmitReprogramacion = () => {
        cargarData();
    };

    return (

        <Page
            abrirPrograma={abrirPrograma}
            cerrarPrograma={cerrarPrograma}

            itemSeleccionado={itemSeleccionado}
            handleCloseAgregarActividad={handleCloseAgregarActividad}
            handleCloseConfigurarBaseRealPrograma={handleCloseConfigurarBaseRealPrograma}
            handleCloseEditarActividad={handleCloseEditarActividad}
            handleCloseEliminarActividad={handleCloseEliminarActividad}
            handleCloseSelectorPresupuesto={handleCloseSelectorPresupuesto}
            handleDescargarExcel={handleDescargarExcel}
            handleOpenAgregarActividad={handleOpenAgregarActividad}
            handleOpenConfigurarBaseRealPrograma={handleOpenConfigurarBaseRealPrograma}
            handleOpenEditarActividad={handleOpenEditarActividad}
            handleOpenEliminarActividad={handleOpenEliminarActividad}
            handleOpenReprogramacion={handleOpenReprogramacion}
            handleOpenSelectorPresupuesto={handleOpenSelectorPresupuesto}
            handleSubmitAgregarActividad={handleSubmitAgregarActividad}
            handleSubmitConfigurarBaseRealPrograma={handleSubmitConfigurarBaseRealPrograma}
            handleSubmitEditarActividad={handleSubmitEditarActividad}
            handleSubmitEditarPrograma={handleSubmitEditarPrograma}
            handleSubmitEliminarActividad={handleSubmitEliminarActividad}
            handleSubmitReprogramacion={handleSubmitReprogramacion}
            handleSubmitSelectorPresupuesto={handleSubmitSelectorPresupuesto}
            handleVerAvances={handleVerAvances}
            history={history}
            openAgregarActividad={openAgregarActividad}
            openConfigurarBaseRealPrograma={openConfigurarBaseRealPrograma}
            openEditarActividad={openEditarActividad}
            openEliminarActividad={openEliminarActividad}
            openSelectorPresupuesto={openSelectorPresupuesto}
            programa={programa}
            presupuesto={presupuesto}
            proyecto={proyecto}
            usuarioSesion={usuarioSesion}
            cargaRapidaDePresupuesto={cargaRapidaDePresupuesto}
        />
    );
}