import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell as MaterialTableCell,
} from '@material-ui/core';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import formatNumber from 'format-number';

const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const TableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    border: "1px solid #E0E0E0",
    backgroundColor: "#ddd",
    color: theme.palette.primary.main,
  },
  body: {
    border: "1px solid #E0E0E0",
    fontSize: 14,
  },
}))(MaterialTableCell);
const TableCell1 = withStyles(theme => ({
  head: {
    fontSize: 14,
    border: "1px solid #E0E0E0",
    backgroundColor: "#ddd",
    color: theme.palette.primary.main,
  },
  body: {
    fontSize: 14,
    border: "1px solid #E0E0E0",
    backgroundColor: "#ddd",
    color: theme.palette.primary.main,
  },
}))(MaterialTableCell);

function Page(props) {
  const {
    indicadores,
    isLoading,
    configChart
  } = props;

  return (
    <Fragment>
      {(isLoading) &&
        <Grid container justify="center" alignItems="center">
          <Grid item lg style={{ textAlign: "center" }}>
            <Typography variant="h5">Cargando Información</Typography>
            <br />
            <CircularProgress size="88px" color="primary"></CircularProgress>
          </Grid>
        </Grid>
      }
      {(!isLoading) && configChart &&
        <Grid container
          direction="row">
          <Grid
            item
            lg
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center"><b>Avance Físico</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell align="center"><b>Avance Progamado</b></TableCell>
                  <TableCell>
                    {numberFormater(indicadores.avanceProgramado)}%
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center"><b>Avance Ganado</b></TableCell>
                  <TableCell>
                    {numberFormater(indicadores.avanceGanado)}%
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center"><b>HH Programadas</b></TableCell>
                  <TableCell>
                    {numberFormater(indicadores.hhProgramadas)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="center"><b>HH Ganadas</b></TableCell>
                  <TableCell>{numberFormater(indicadores.hhGanadas)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center"><b>HH Totales</b></TableCell>
                  <TableCell>{numberFormater(indicadores.hhTotales)}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell align="center"><b>Cump. Programa</b></TableCell>
                  <TableCell>{numberFormater(indicadores.cumplimientoPod)}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center"><b>Cump. Pod</b></TableCell>
                  <TableCell>{numberFormater(indicadores.cumplimientoProgramado)}%</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell align="center"><b>Duración Programa</b></TableCell>
                  <TableCell>{numberFormater(indicadores.duracionProgramada)} días</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center"><b>Tiempo Transcurrido</b></TableCell>
                  <TableCell>{numberFormater(indicadores.tiempoTranscurrido)} días</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid
            item
            lg={8}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={
                configChart
              }
              containerProps={{ style: { width: "100%" } }}
            >
            </HighchartsReact>
          </Grid>
        </Grid>
      }
      {/* {(!isLoading) && configChart &&
        <Grid container
          direction="row">
          <Grid
            item
            lg
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={
                {
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: 250
                  },
                  title: {
                    text: `Cumplimiento<br>Progr ${cumplimientoProgramado}%`,
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  },
                  accessibility: {
                    point: {
                      valueSuffix: '%'
                    }
                  },
                  plotOptions: {
                    pie: {
                      dataLabels: {
                        enabled: false,
                      },
                      startAngle: -90,
                      endAngle: 90,
                      center: ['50%', '75%'],
                      size: '100%'
                    }
                  },
                  series: [{
                    type: 'pie',
                    name: 'Cumplimiento Prog.',
                    innerSize: '50%',
                    data: [
                      ['Cumplimiento Prog.', cumplimientoProgramado],
                      ['', ((100 - cumplimientoProgramado > 0) ? 100 - cumplimientoProgramado : 0)],
                    ]
                  }],
                  exporting: {
                    enabled: false
                  }
                }
              }
            >
            </HighchartsReact>
          </Grid>

          <Grid
            item
            lg
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={
                {
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: 250
                  },
                  title: {
                    text: `Cumplimiento<br>POD ${cumplimientoPod}%`,
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  },
                  accessibility: {
                    point: {
                      valueSuffix: '%'
                    }
                  },
                  plotOptions: {
                    pie: {
                      dataLabels: {
                        enabled: false
                      },
                      startAngle: -90,
                      endAngle: 90,
                      center: ['50%', '75%'],
                      size: '100%'
                    }
                  },
                  series: [{
                    type: 'pie',
                    name: 'Cumplimiento POD',
                    innerSize: '50%',
                    data: [
                      ['Cumplimiento POD', cumplimientoPod],
                      ['', ((100 - cumplimientoPod > 0) ? 100 - cumplimientoPod : 0)],
                    ]
                  }],
                  exporting: {
                    enabled: false
                  }
                }
              }
            >
            </HighchartsReact>
          </Grid>
        </Grid>
      } */}
      {
        (!isLoading) && !configChart &&
        <Grid container justify="center" alignItems="center">
          <Grid item lg style={{ textAlign: "center" }}>
            <Typography variant="h5">No se ha cargado la línea base para este proyecto</Typography>
          </Grid>
        </Grid>
      }

    </Fragment >

  );
}

export default Page;