import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from './page';
import { getPod, updatePod } from '../../../services/database/pods';
import { useHistory, useParams } from 'react-router';
import { getItemsPrograma, getItemPrograma, getPrograma, getAvancesPeriodo, updateAvanceItemPrograna } from '../../../services/database/programacion';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { auth } from 'firebase';

export default function CerrarPod(props) {
    const history = useHistory();
    const { id } = useParams();
    const notisnack = useSnackbar();

    const [actividadSeleccionada, setActividadSeleccionada] = useState(null);
    const [avanceAcumulado, setAvanceAcumulado] = useState(0);
    const [data, setData] = useState(null);
    const [openCierrePod, setOpenCierrePod] = useState(false);
    const [openEditarActividad, setOpenEditarActividad] = useState(false);
    const [pod, setPod] = useState(null);
    const [turnoPod, setTurnoPod] = useState('Día');
    const [turnosPod, setTurnosPod] = useState([]);
    const { usuarioSesion, contrato, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto
    }));

    useEffect(() => {
        cargarData();
    }, [proyecto]);

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (id && proyecto && proyecto.programa_actual_ref) {
            const pod = await getPod(id);
            // return;
            if (pod && pod.items && proyecto.programa_actual_ref) {
                const programaActual = await getPrograma(proyecto.programa_actual_ref);
                const avances = await getAvancesPeriodo(proyecto.programa_actual_ref, { fechaInicio: moment(pod.fecha).subtract(1, 'days').format("YYYY-MM-DD"), fechaTermino: moment(pod.fecha).format("YYYY-MM-DD") });
                pod["costoTotalPrograma"] = 0;
                pod["horasHombreTotalPrograma"] = 0;
                pod["avanceProgramadoLineaBase"] = (avances.avanceProgramadoHH[1] ? avances.avanceProgramadoHH[1] : 1) - (avances.avanceProgramadoHH[0] ? avances.avanceProgramadoHH[0] : 0);
                pod["cumplimientoProgramado"] = 0;
                pod["horasHombreProgramadas"] = 0;
                pod["horasHombreReales"] = 0;
                pod["cantidadTotalProgramada"] = 0;
                pod["cantidadTotalReal"] = 0;
                pod["costoProgramado"] = 0;
                pod["costoReal"] = 0;
                if (programaActual) {
                    let listadoActividades = await getItemsPrograma(proyecto.programa_actual_ref);
                    for (var a in listadoActividades) {
                        const actividad = listadoActividades[a];
                        if (actividad.is_hoja) {

                            pod.costoTotalPrograma = pod.costoTotalPrograma + (actividad.costo_directo ? parseFloat(actividad.costo_directo) : 0);
                            pod.horasHombreTotalPrograma = pod.horasHombreTotalPrograma + (actividad.horas_hombre ? parseFloat(actividad.horas_hombre) : 0);
                        }
                    }
                }
                pod["avanceProgramadoHH"] = 0;
                pod["avanceRealHH"] = 0;
                pod["avanceProgramadoCostos"] = 0;
                pod["avanceRealCostos"] = 0;
                // console.log(pod);
                pod["listoCierre"] = true;
                for (let key = 0; key < pod.items.length; key++) {
                    pod.items[key]["listoCierre"] = true;
                    if (pod.items[key].avances) {
                        const indexAvance = pod.items[key].avances.findIndex(a => a.pod === id);


                        if (indexAvance > -1) {
                            const avancePod = pod.items[key].avances[indexAvance];
                            // console.log(avancePod);
                            avancePod["_id"] = indexAvance;
                            avancePod["avanceProgramadoHH"] = 0;
                            avancePod["avanceRealHH"] = 0;
                            avancePod["avanceProgramadoCostos"] = 0;
                            avancePod["avanceRealCostos"] = 0;
                            avancePod["actividad"] = pod.items[key];
                            if (avancePod.cantidad_programada && avancePod.cantidad) {
                                avancePod["porcentajeCumplimiento"] = (avancePod.cantidad / avancePod.cantidad_programada * 100);
                            } else if (avancePod.horas_hombre_programadas && avancePod.horas_hombre) {
                                avancePod["porcentajeCumplimiento"] = (avancePod.horas_hombre / avancePod.horas_hombre_programadas * 100);
                            }
                            const incidenciaCostos = pod.costoTotalPrograma ? avancePod.actividad.costo_directo / pod.costoTotalPrograma : 0;
                            const incidenciaHH = pod.horasHombreTotalPrograma ? avancePod.actividad.horas_hombre / pod.horasHombreTotalPrograma : 0;
                            if (avancePod) {
                                avancePod.avanceProgramadoCostos = avancePod.actividad.horas_hombre && incidenciaCostos && avancePod.horas_hombre_programadas ? (avancePod.horas_hombre_programadas / avancePod.actividad.horas_hombre * (incidenciaCostos) * 100) : 0;
                                avancePod.avanceRealCostos = avancePod.actividad.horas_hombre && incidenciaCostos && avancePod.horas_hombre ? (avancePod.horas_hombre / avancePod.actividad.horas_hombre * (incidenciaCostos) * 100) : 0;
                                avancePod.avanceProgramadoHH = avancePod.actividad.horas_hombre && incidenciaHH && avancePod.horas_hombre_programadas ? (avancePod.horas_hombre_programadas / avancePod.actividad.horas_hombre * (incidenciaHH) * 100) : 0;
                                avancePod.avanceRealHH = avancePod.actividad.horas_hombre && incidenciaHH && avancePod.horas_hombre ? (avancePod.horas_hombre / avancePod.actividad.horas_hombre * (incidenciaHH) * 100) : 0;
                            }


                            if (!avancePod.baglog || avancePod.horas_hombre) {
                                pod.horasHombreReales = pod.horasHombreReales + (avancePod.horas_hombre ? avancePod.horas_hombre : 0);
                                pod.horasHombreProgramadas = pod.horasHombreProgramadas + (avancePod.horas_hombre_programadas ? avancePod.horas_hombre_programadas : 0);
                            }
                            if (!avancePod.baglog || avancePod.cantidad) {
                                pod.cantidadTotalReal = pod.cantidadTotalReal + (avancePod.cantidad ? avancePod.cantidad : 0);
                                pod.cantidadTotalProgramada = pod.cantidadTotalProgramada + (avancePod.cantidad_programada ? avancePod.cantidad_programada : 0);
                            }

                            let cantidadAvanceProgramado = avancePod.cantidad_programada ? avancePod.cantidad_programada : 0;
                            let costoAvanceProgramado = (pod.items[key].cantidad ? cantidadAvanceProgramado / pod.items[key].cantidad : 0) * (pod.items[key].costo_directo ? pod.items[key].costo_directo : 0);
                            pod.costoProgramado = pod.costoProgramado + costoAvanceProgramado;
                            let cantidadAvanceReal = avancePod.cantidad ? avancePod.cantidad : 0;
                            let costoAvanceReal = (pod.items[key].cantidad ? cantidadAvanceReal / pod.items[key].cantidad : 0) * (pod.items[key].costo_directo ? pod.items[key].costo_directo : 0);
                            pod.costoReal = pod.costoReal + costoAvanceReal;

                            pod.items[key]["avancePod"] = avancePod;
                            pod.avanceProgramadoHH = pod.avanceProgramadoHH + avancePod.avanceProgramadoHH;
                            pod.avanceRealHH = pod.avanceRealHH + avancePod.avanceRealHH;
                            pod.avanceProgramadoCostos = pod.avanceProgramadoCostos + avancePod.avanceProgramadoCostos;
                            pod.avanceRealCostos = pod.avanceRealCostos + avancePod.avanceRealCostos;
                            if (pod.items[key].is_hoja) {
                                const porcentajeCantidad = avancePod.cantidad_programada ? ((avancePod.cantidad ? avancePod.cantidad : 0) / avancePod.cantidad_programada) : 1;
                                const porcentajeHorasHombre = avancePod.horas_hombre_programadas ? ((avancePod.horas_hombre ? avancePod.horas_hombre : 0) / avancePod.horas_hombre_programadas) : 1;
                                const comentarios = avancePod.responsable_accion && avancePod.metodo_accion && avancePod.observacion;
                                if (porcentajeHorasHombre < 0.9 && !comentarios) {
                                    pod.items[key].listoCierre = false;
                                    pod.listoCierre = false;
                                }
                            }
                        }
                    }
                    if (pod.items[key].padre) {
                        let padre = pod.items.find(a => a._id === pod.items[key].padre);
                        if (!padre) {
                            const padreItem = await getItemPrograma(pod.items[key].padre);
                            pod.items.push({
                                padre: padreItem.padre,
                                item: padreItem.item,
                                nombre: padreItem.nombre,
                                id_item: padreItem.id_item,
                                _id: padreItem._id,
                                itemPadre: "",
                                is_hoja: false,
                                itemsHijos: [pod.items[key]._id]

                            });
                            pod.items[key]["itemPadre"] = padreItem.item;
                        } else {
                            if (!padre.itemsHijos) padre.itemsHijos = [];
                            padre.itemsHijos.push(pod.items[key]._id);
                            pod.items[key]["itemPadre"] = padre.item;
                        }

                    }
                }
                pod["cumplimientoProgramado"] = pod.avanceProgramadoLineaBase ? pod.avanceRealHH / pod.avanceProgramadoLineaBase : 0;
                setData(pod.items);
                setPod(pod);
                return;
            } else {
                history.push("/pods");
            }
        } else {
            history.push("/pods");
        }
        setData([]);
        setPod(null);
    }

    const handleOpenEditarActividad = (e, rowData) => {
        setActividadSeleccionada(rowData);
        setOpenEditarActividad(true);
    }

    const handleSubmitCierrePod = async () => {
        await updatePod(pod._id, {
            estado: "Cerrado"
        });
        setOpenCierrePod(false);
        history.push("/pods");
    }

    const handleSubmitEditarActividad = async (values) => {
        const key = notisnack.enqueueSnackbar("Guardando cambios", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            const dataAvance = actividadSeleccionada.avancePod ? actividadSeleccionada.avancePod : {};
            // console.log(values);
            let {
                totalCantidadProgramadas,
                totalCantidadGanadas,
                totalHHProgramadas,
                totalHHGanadas
            } = pod.items.reduce((accumulator, currentValue) => {
                if (currentValue.is_hoja && currentValue.avancePod && currentValue._id !== actividadSeleccionada._id) {
                    const avancePod = currentValue.avancePod;;
                    if (!avancePod.baglog || avancePod.horas_hombre) {
                        accumulator.totalHHGanadas = accumulator.totalHHGanadas + (avancePod.horas_hombre ? avancePod.horas_hombre : 0);
                        accumulator.totalHHProgramadas = accumulator.totalHHProgramadas + (avancePod.horas_hombre_programadas ? avancePod.horas_hombre_programadas : 0);
                    }
                    if (!avancePod.baglog || avancePod.cantidad) {
                        accumulator.totalCantidadGanadas = accumulator.totalCantidadGanadas + (avancePod.cantidad ? avancePod.cantidad : 0);
                        accumulator.totalCantidadProgramadas = accumulator.totalCantidadProgramadas + (avancePod.cantidad_programada ? avancePod.cantidad_programada : 0);
                    }
                }
                return accumulator;
            }, {
                totalCantidadProgramadas: 0,
                totalCantidadGanadas: 0,
                totalHHProgramadas: 0,
                totalHHGanadas: 0
            });
            // console.log("totalCantidadProgramadas", totalCantidadProgramadas);
            // console.log("totalCantidadGanadas", totalCantidadGanadas);
            // console.log("totalHHProgramadas", totalHHProgramadas);
            // console.log("totalHHGanadas", totalHHGanadas);
            if (!values.baglog || values.horas_hombre) {
                totalHHGanadas = totalHHGanadas + (values.horas_hombre ? parseFloat(values.horas_hombre) : 0);
                totalHHProgramadas = totalHHProgramadas + (values.horas_hombre_programadas ? values.horas_hombre_programadas : 0);
            }
            if (!values.baglog || values.cantidad) {
                totalCantidadGanadas = totalCantidadGanadas + (values.cantidad ? parseFloat(values.cantidad) : 0);
                totalCantidadProgramadas = totalCantidadProgramadas + (values.cantidad_programada ? values.cantidad_programada : 0);
            }
            // console.log("totalCantidadProgramadas", totalCantidadProgramadas);
            // console.log("totalCantidadGanadas", totalCantidadGanadas);
            // console.log("totalHHProgramadas", totalHHProgramadas);
            // console.log("totalHHGanadas", totalHHGanadas);
            const porcentajeCumplimiento = (totalHHProgramadas ? (totalHHGanadas / totalHHProgramadas) : (totalCantidadProgramadas ? totalCantidadGanadas / totalCantidadProgramadas : 0)) * 100;
            // console.log("porcentajeCumplimiento", porcentajeCumplimiento);
            await updateAvanceItemPrograna(actividadSeleccionada._id, values._id, {
                ...dataAvance,
                pod: pod._id,
                baglog: values.baglog,
                cantidad: values.cantidad,
                cantidad_programada: values.cantidad_programada,
                comentario_baglog: values.comentario_baglog,
                horas_hombre: values.horas_hombre,
                horas_hombre_programadas: values.horas_hombre_programadas,
                metodo_accion: values.metodo_accion,
                observacion: values.observacion,
                responsable_accion: values.responsable_accion,
                usuario_ref: usuarioSesion.ref
            });
            await updatePod(pod._id, {
                porcentaje_cumplimiento: porcentajeCumplimiento,
                horas_hombre_ganadas: totalHHGanadas
            });
            notisnack.closeSnackbar(key);
            const successkey = notisnack.enqueueSnackbar("Operación exitosa: Actividad actualizada", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            setOpenEditarActividad(false);
            cargarData();
        }
        catch (error) {
            console.log(error);
            notisnack.closeSnackbar(key);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible modificar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    return (
        <Page
            actividadSeleccionada={actividadSeleccionada}
            avanceAcumulado={avanceAcumulado}
            data={data}
            goTo={(path) => history.push(path)}
            handleOpenEditarActividad={handleOpenEditarActividad}
            handleSubmitEditarActividad={handleSubmitEditarActividad}
            id={id}
            openCierrePod={openCierrePod}
            openEditarActividad={openEditarActividad}
            pod={pod}
            proyecto={proyecto}
            setOpenCierrePod={setOpenCierrePod}
            setOpenEditarActividad={setOpenEditarActividad}
            setTurnoPod={setTurnoPod}
            turnoPod={turnoPod}
            turnosPod={turnosPod}
            usuarioSesion={usuarioSesion}
            handleSubmitCierrePod={handleSubmitCierrePod}
        />
    );
}