import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { getItemsPrograma } from '../../../../services/database/programacion';
import Page from './page';

export default function AgregarActividadPod(props) {

  const [formik, setFormik] = useState(null);
  const [actividades, setActividades] = useState([]);
  const validationSchema = Yup.object({
    actividad: Yup.object().required("Campo requerido"),
    cantidad_programada: Yup.number().typeError("Debe ingresar un valor númerico").required("Campo requerido").min(0, "Debe ser un valor mayor o igual que 0"),
    horas_hombre_programadas: Yup.number().typeError("Debe ingresar un valor númerico").min(0, "Debe ser un valor mayor o igual que 0").required("Campo requerido")
  });
  const { usuarioSesion, proyecto } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion,
    proyecto: state.contratoSesion.proyecto
  }));

  const values = {
    actividad: null,
    cantidadTotal: 0,
    horasHombreTotal: 0,
    cantidad_programada: 0,
    horas_hombre_programadas: 0,
    baglog: false,
    comentario_baglog: ""
  };

  const handleChange = (name, e) => {
    formik.setFieldValue(name, e.target.value);
    formik.setFieldTouched(name, true, false);
  };

  const handleChangeSelect = (name, e) => {
    formik.setFieldValue(name, e);
    formik.setFieldTouched(name, true, false);
    if (name === "actividad" && e.value) {
      formik.setFieldValue("cantidadTotal", e.value.cantidad ? e.value.cantidad : 0);
      formik.setFieldValue("horasHombreTotal", e.value.horas_hombre ? e.value.horas_hombre : 0);
    }
  };

  const handleChangeSwitch = (name, event) => {
    formik.setFieldValue(name, event.target.checked);
    if (name === "baglog" && event.target.checked) {
      formik.setFieldValue("comentario_baglog", "");
    }
    formik.setFieldTouched(name, true, false);
  }

  const handleOnSubmit = (values, formikBag) => {
    // console.log(values);
    props.handleSubmit(values)
  }

  const handleEnterDialog = async () => {
    if (proyecto && proyecto.programa_actual_ref) {
      const actividadesDB = await getItemsPrograma(proyecto.programa_actual_ref, { is_hoja: true });
      const suggestions = actividadesDB.map((value, index) => ({
        value: value,
        label: `[${value.item}] [${value.id_item ? value.id_item : "Sin ID"}] ${value.nombre}`
      }));
      setActividades(suggestions);
    }
  }

  return (

    <React.Fragment>
      <Formik
        ref={(ref) => setFormik(ref)}
        onSubmit={handleOnSubmit}
        initialValues={values}
        validationSchema={validationSchema}
        render={formikProps =>
          <Page
            {...formikProps}
            actividades={actividades}
            handleChange={handleChange}
            handleChangeSelect={handleChangeSelect}
            handleChangeSwitch={handleChangeSwitch}
            handleClose={props.handleClose}
            handleEnterDialog={handleEnterDialog}
            open={props.open}
          />
        }
      />
    </React.Fragment>
  );
}