import React, { useEffect, useState } from 'react';
import Page from './page';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getIndicadoresAcumuladosPeriodoLineaBase } from '../../reportesPodDiarios/requestsLineaBase';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function IndicadoresLineaBase(props) {


  const { proyecto } = useSelector(state => ({
    proyecto: state.contratoSesion.proyecto
  }));
  const notistack = useSnackbar();
  const [configChart, setConfigChart] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [indicadores, setIndicadores] = useState({
    avanceGanado: 0,
    avanceProgramado: 0,
    cumplimientoPod: 0,
    cumplimientoProgramado: 0,
    duracionProgramada: 0,
    hhGanadas: 0,
    hhProgramadas: 0,
    hhTotales: 0,
    tiempoTranscurrido: 0,
  });

  const loadData = () => {
    if (proyecto) {
      const CancelToken = Axios.CancelToken;
      let cancelRequest = null;
      let cancelToken = new CancelToken(function executor(c) {
        cancelRequest = c;
      });
      getIndicadoresAcumuladosPeriodoLineaBase(proyecto._id, null, null, cancelToken)
        .then(async (response) => {
          console.log(response);
          if (response.status === 204) {
            setConfigChart(null);
            setIsLoading(false);
          } else if (response.status == 200) {
            const avancesPeriodo = response.data;
            loadChart(avancesPeriodo);
            setIsLoading(false);
          } else {

            setConfigChart(null);
            setIsLoading(false);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la información del proyecto. Recargue la página o contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          }
        })
        .catch(error => {
          console.log(error);
          setConfigChart(null);
          setIsLoading(false);
          const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la información del proyecto. Recargue la página o contacte con soporte el equipo de soporte", {
            variant: 'error',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
          });
        });

      return cancelRequest;
    } else {
      setConfigChart(null);
      setIsLoading(false);
    }
  }

  const loadChart = async (avancePeriodo) => {
    if (avancePeriodo !== null) {
      let xPlotLine = {};
      //   if (plotLine && plotLine < termino.valueOf()) xPlotLine = {
      //     color: '#FF0000', // Red
      //     width: 2,
      //     value: plotLine
      //   };
      const options = {
        exporting: {
          chartOptions: { // specific options for the exported image
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            }
          },
          fallbackToExportServer: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            day: '%e %b',
            month: '%e %b %Y',
            year: '%b %Y'
          },
          title: {
            text: 'Tiempo'
          },
          plotLines: [
            xPlotLine
          ]
        },
        yAxis: [{
          min: 0,
          //   max: data.valorMaximoGrafico ? data.valorMaximoGrafico : 100,
          title: {
            text: 'Avance/Cumplimiento %'
          },
          labels: {
            format: '{value}%',
          },
        },
        {
          min: 0,
          title: {
            text: 'HH'
          },
          labels: {
            format: '{value}',
          },
          opposite: true
        }
        ],
        title: {
          text: 'Curva de Avance Físico'
        },
        series: [
          {
            name: "Avance Programado",
            type: 'spline',
            yAxis: 0,
            data: avancePeriodo.avanceProgramado,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          },
          {
            name: "Avance Real",
            type: 'spline',
            yAxis: 0,
            data: avancePeriodo.avanceReal,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          }
          ,
          {
            name: "HH Programadas",
            type: 'area',
            yAxis: 1,
            data: avancePeriodo.hhProgramado,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          },
          {
            name: "HH Ganadas",
            type: 'area',
            yAxis: 1,
            data: avancePeriodo.hhReal,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          },
          // {
          //   name: "Cumplimiento Programa",
          //   type: 'area',
          //   yAxis: 1,
          //   data: avancePeriodo.cumplimientoPrograma,
          //   tooltip: {
          //     headerFormat: '<b>{series.name}</b><br>',
          //     pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
          //   },
          // },
          {
            name: "Cumplimiento POD",
            yAxis: 0,
            type: 'spline',
            data: avancePeriodo.cumplimientoPod,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          }

        ],
        plotOptions: {
          area: {
            fillOpacity: 0.2,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        margin: 0,

      };
      setIndicadores({
        avanceGanado: parseFloat(avancePeriodo.avanceGanadoActual.toFixed(2)),
        avanceProgramado: parseFloat(avancePeriodo.avanceProgramadoActual.toFixed(2)),
        cumplimientoPod: parseFloat(avancePeriodo.totalCumplimientoPod.toFixed(2)),
        cumplimientoProgramado: parseFloat(avancePeriodo.totalCumplimientoPrograma.toFixed(2)),
        duracionProgramada: avancePeriodo.duracionProgramada,
        hhGanadas: parseFloat(avancePeriodo.hhGanadasAcumuladas.toFixed(2)),
        hhProgramadas: parseFloat(avancePeriodo.hhProgramadas.toFixed(2)),
        hhTotales: parseFloat(avancePeriodo.hhTotales.toFixed(2)),
        tiempoTranscurrido: avancePeriodo.tiempoTranscurrido,
      });
      if (setConfigChart) setConfigChart(options);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    const cancelRequest = loadData();
    return () => {
      if (cancelRequest) cancelRequest();
      setIsLoading(false);
    }
  }, [proyecto]);

  return (
    <Page
      indicadores={indicadores}
      configChart={configChart}
      isLoading={isLoading}
    />
  );
}