import React, { Fragment } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    Select,
    InputLabel,
    FormHelperText,
    TextField
} from '@material-ui/core';
import FileInput from '../../../components/fileInput';

/**
 * Vista de componente para agregar lineas base
 * @since 1.2.0
 * @author Luis San Martín S.
 * @property {Object} errors Errores del formulario
 * @property {function} handleChange Manejo de cambio de valores en el formulario
 * @property {function} handleClose Funcion para cerrar dialogo
 * @property {function} handleEnterDialog Manejo de evento de apertura del dialogo
 * @property {function} handelFileChange Manejo de cambio de valores de archivo en el formulario
 * @property {function} handleSubmit Manejo de evento submit del formulario
 * @property {Array} hojas Hojas del archivo excel seleccionado
 * @property {Boolean} isValid Son validos o no los valores del formulario
 * @property {Boolean} open Abrir o no el dialogo
 * @property {String} titulo texto para mostrar en el titulo
 * @property {Object} touched Campos modificados del formulario
 * @property {Object} valores Valores de campos del formulario
 */
function Page(props) {
    const {
        errors,
        handleChange,
        handleClose,
        handleEnterDialog,
        handelFileChange,
        handleSubmit,
        hojas,
        isValid,
        open,
        titulo,
        touched,
        values
    } = props;

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}{}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <FormControl
                                fullWidth={true}
                                margin="normal"
                                error={touched.excel && errors.excel ? true : false}
                            >
                                {/* <FormLabel>Adjunto</FormLabel> */}
                                <FileInput
                                    inputName="excel"
                                    buttonColor="primary"
                                    buttonVariant="outlined"
                                    label="Archivo Adjunto"
                                    value={values.excel}
                                    handleOnChange={handelFileChange.bind(null, "excel")}
                                    textVariant="outlined"
                                />
                                {touched.excel && errors.excel &&
                                    <FormHelperText>{errors.excel}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                    {hojas &&
                        <Fragment>
                            <Grid container
                                direction="row"
                                spacing={1}>
                                <Grid
                                    item
                                    lg
                                >
                                    <FormControl
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        error={touched.hoja && errors.hoja ? true : false}

                                    >
                                        <InputLabel>Hoja</InputLabel>
                                        <Select
                                            native
                                            inputProps={{
                                                name: `hoja`,
                                            }}
                                            value={values.hoja}
                                            onChange={handleChange.bind(null, "hoja")}
                                        >
                                            <option value="">Seleccionar hoja</option>
                                            {hojas.map((value, index) => (
                                                <option value={value.value} key={index}>{value.nombre}</option>
                                            ))}
                                        </Select>
                                        {touched.hoja && errors.hoja &&
                                            <FormHelperText>{errors.hoja}</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container
                                direction="row"
                                spacing={1}>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`fila_inicio`}
                                        variant="outlined"
                                        label={"Fila inicial"}
                                        value={values.fila_inicio}
                                        onChange={handleChange.bind(null, `fila_inicio`)}
                                        error={touched.fila_inicio && errors.fila_inicio && Boolean(errors.fila_inicio)}
                                        helperText={touched.fila_inicio && errors.fila_inicio ? errors.fila_inicio : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`fila_final`}
                                        variant="outlined"
                                        label={"Fila final"}
                                        value={values.fila_final}
                                        onChange={handleChange.bind(null, `fila_final`)}
                                        error={touched.fila_final && errors.fila_final && Boolean(errors.fila_final)}
                                        helperText={touched.fila_final && errors.fila_final ? errors.fila_final : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`columna_curva_programada`}
                                        variant="outlined"
                                        label={"Columna Curva Prog."}
                                        value={values.columna_curva_programada}
                                        onChange={handleChange.bind(null, `columna_curva_programada`)}
                                        error={touched.columna_curva_programada && errors.columna_curva_programada && Boolean(errors.columna_curva_programada)}
                                        helperText={touched.columna_curva_programada && errors.columna_curva_programada ? errors.columna_curva_programada : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`columna_curva_programada_acumulada`}
                                        variant="outlined"
                                        label={"Columna Curva Prog. Acumulada"}
                                        value={values.columna_curva_programada_acumulada}
                                        onChange={handleChange.bind(null, `columna_curva_programada_acumulada`)}
                                        error={touched.columna_curva_programada_acumulada && errors.columna_curva_programada_acumulada && Boolean(errors.columna_curva_programada_acumulada)}
                                        helperText={touched.columna_curva_programada_acumulada && errors.columna_curva_programada_acumulada ? errors.columna_curva_programada_acumulada : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`columna_hh_ganada`}
                                        variant="outlined"
                                        label={"Columna HH Ganadas"}
                                        value={values.columna_hh_ganada}
                                        onChange={handleChange.bind(null, `columna_hh_ganada`)}
                                        error={touched.columna_hh_ganada && errors.columna_hh_ganada && Boolean(errors.columna_hh_ganada)}
                                        helperText={touched.columna_hh_ganada && errors.columna_hh_ganada ? errors.columna_hh_ganada : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container
                                direction="row"
                                spacing={1}>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`columna_curva_programada_pod`}
                                        variant="outlined"
                                        label={"Columna HH Prog. POD"}
                                        value={values.columna_curva_programada_pod}
                                        onChange={handleChange.bind(null, `columna_curva_programada_pod`)}
                                        error={touched.columna_curva_programada_pod && errors.columna_curva_programada_pod && Boolean(errors.columna_curva_programada_pod)}
                                        helperText={touched.columna_curva_programada_pod && errors.columna_curva_programada_pod ? errors.columna_curva_programada_pod : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg
                                >
                                    <TextField
                                        name={`columna_hh_ganada_pod`}
                                        variant="outlined"
                                        label={"Columna HH Ganadas POD"}
                                        value={values.columna_hh_ganada_pod}
                                        onChange={handleChange.bind(null, `columna_hh_ganada_pod`)}
                                        error={touched.columna_hh_ganada_pod && errors.columna_hh_ganada_pod && Boolean(errors.columna_hh_ganada_pod)}
                                        helperText={touched.columna_hh_ganada_pod && errors.columna_hh_ganada_pod ? errors.columna_hh_ganada_pod : ""}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    {/* <Button
                        onClick={() => handleDescargarFormato()}
                        color="secondary"
                        variant="outlined"
                    >
                        Descargar Formato POD
                    </Button> */}
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || !values.excel}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
