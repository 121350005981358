import { getItemPresupuesto, updateItemPresupuesto, agregarHijosItemPresupuesto, quitarHijosItemPresupuesto, postItemPresupuesto, agregarItemsPresupuesto, quitarItemsPresupuesto } from "../../services/database/presupuesto"
import { agregarItemsPrespuestoProyecto } from "../../services/database/proyecto"
import { updateItemPrograma } from "../../services/database/programacion";

export default class ItemPresupuestoModel {
    constructor(json) {
        this._id = "";
        this.cantidad = 0;
        this.hijos = [];
        this.is_eliminado = false;
        this.is_hoja = true;
        this.item = "";
        this.items_programas_ref = [];
        this.nombre = "";
        this.padre = null;
        this.precio_total = 0;
        this.precio_unitario = 0;
        this.presupuesto_ref = "";
        this.proyecto_ref = "";
        this.unidad = "";
        
        this.cantidadDisponible = 0;
        this.listadoItemPrograma = [];
        this.itemPresupuestoHijos = [];
        this.itemPadre = "";
        this.presuspuestoDisponible = 0;

        if(json) this.build(json);
    }

    static buildFromJson(json) {
        let itemPresupuestoModel = new ItemPresupuestoModel();
        itemPresupuestoModel.id = json["_id"];
        itemPresupuestoModel._id = json["_id"];
        itemPresupuestoModel.precio_unitario = json["precio_unitario"];
        itemPresupuestoModel.precio_total = json["precio_total"];
        itemPresupuestoModel.is_hoja = json["is_hoja"];
        itemPresupuestoModel.is_eliminado = json["is_eliminado"];
        itemPresupuestoModel.item = json["item"];
        itemPresupuestoModel.nombre = json["nombre"];
        itemPresupuestoModel.cantidad = json["cantidad"];
        itemPresupuestoModel.presupuesto_ref = json["presupuesto_ref"];
        itemPresupuestoModel.proyecto_ref = json["proyecto_ref"];
        itemPresupuestoModel.padre = json["padre"];

        json["items_programas_ref"].forEach(element => {
            itemPresupuestoModel.listadoItemPrograma.push(element);
        });

        json["hijos"].forEach(element => {
            itemPresupuestoModel.itemPresupuestoHijos.push(element);
        });
        return itemPresupuestoModel;
    }

    build (json){
        this._id = json ? json._id : "";
        this.cantidad = json.cantidad? json.cantidad : 0;
        this.hijos = json.hijos ? json.hijos : [];
        this.is_eliminado = json.is_eliminado ? json.is_eliminado : false;
        this.is_hoja = (json.is_hoja || json.is_hoja === false) ? json.is_hoja : true;
        this.item = json.item ? json.item : "";
        this.items_programas_ref = json.items_programas_ref ? json.items_programas_ref : [];
        this.nombre = json.nombre ? json.nombre : "";
        this.padre = json.padre ? json.padre : null;
        this.precio_total = json.precio_total ? json.precio_total : 0;
        this.precio_unitario = json.precio_unitario ? json.precio_unitario : 0;
        this.presupuesto_ref = json.presupuesto_ref ? json.presupuesto_ref : "";
        this.proyecto_ref = json.proyecto_ref ? json.proyecto_ref : "";
        this.unidad = json.unidad ? json.unidad : "";

        this.cantidadDisponible = json.cantidadDisponible ? json.cantidadDisponible :0;
        this.listadoItemPrograma = json.listadoItemPrograma ? json.listadoItemPrograma :[];
        this.itemPresupuestoHijos = json.itemPresupuestoHijos ? json.itemPresupuestoHijos :[];
        this.itemPadre = json.itemPadre ? json.itemPadre :"";
        this.presuspuestoDisponible = json.presuspuestoDisponible ? json.presuspuestoDisponible :0;
        
        this.calcularPresupuestoDisponible();
        this.calcularCantidadDisponible();
    }

    static async post(values) {
        const newItem = await postItemPresupuesto(values);
        if (values.padre) {
            await agregarHijosItemPresupuesto(values.padre, [newItem._id]);
        }
        await agregarItemsPresupuesto(values.presupuesto_ref, [newItem._id]);
        await agregarItemsPrespuestoProyecto(values.proyecto_ref, [newItem._id]);
        return this.buildFromJson(newItem);
    }

    async update(values) {
        const response = await updateItemPresupuesto(this._id, values);
        if (values.padre) {
            if (this.padre) await quitarHijosItemPresupuesto(this.padre, [this._id]);
            await agregarHijosItemPresupuesto(values.padre, [this._id]);
        }
        if (this.hijos) {
            await this.actualizarItemHijos(this._id, values.item, this.item);
        }
        return response;
    }

    async delete() {
        await this.recursionEliminarItem(this._id);
        if (this.padre) {
            await quitarHijosItemPresupuesto(this.padre, [this._id]);
        }
        return;
    }

    async actualizarItemHijos(actual, newItem, oldItem) {
        try {
            const itemPresupuesto = await getItemPresupuesto(actual);
            itemPresupuesto.item = itemPresupuesto.item.replace(oldItem, newItem);
            await updateItemPresupuesto(actual, {
                item: itemPresupuesto.item
            });
            if (itemPresupuesto.hijos) {
                for (let i = 0; i < itemPresupuesto.hijos.length; i++) {
                    await this.actualizarItemHijos(itemPresupuesto.hijos[i], newItem, oldItem);
                }
            }
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    recursionEliminarItem = async (id) => {
        const item_presupuesto = await getItemPresupuesto(id);
        await updateItemPresupuesto(id, { is_eliminado: true });
        await quitarItemsPresupuesto(item_presupuesto.presupuesto_ref, [id]);
        if (item_presupuesto.items_programas_ref && item_presupuesto.items_programas_ref.length > 0) {
            for (let i = 0; i < item_presupuesto.items_programas_ref.length; i++) {
                await updateItemPrograma(item_presupuesto.items_programas_ref[i], { item_presupuesto_ref: null, costo_directo: null });
            }
        }
        if (item_presupuesto.hijos && item_presupuesto.hijos.length > 0) {
            for (let i = 0; i < item_presupuesto.hijos.length; i++) {
                await this.recursionEliminarItem(item_presupuesto.hijos[i]);
            }
        }
        return;
    }

    buildHijos(items){
        if(this.is_hoja) return;
        if(this.hijos){
            for(let i = 0; i < this.hijos.length; i++){
                const itemHijo = items.find(a => a._id === this.hijos[i]);
                this.pushItemHijo(itemHijo);
                itemHijo.buildHijos(items);
            }
        }
        return;
    }

    pushItemHijo(hijo){
        this.itemPresupuestoHijos.push(hijo);
    }

    calcularPresupuestoDisponible(){
        this.presuspuestoDisponible = this.precio_total;
        if(this.is_hoja){
            for(let i = 0; i < this.listadoItemPrograma.length; i++){
                this.presuspuestoDisponible = this.presuspuestoDisponible - this.listadoItemPrograma[i].costo_directo;
            }
        }else{
            for(let i = 0; i < this.itemPresupuestoHijos.length; i++){
                this.presuspuestoDisponible = this.presuspuestoDisponible - this.itemPresupuestoHijos[i].calcularPresupuestoDisponible();
            }
        }
        return this.presuspuestoDisponible;
    }

    calcularCantidadDisponible(){
        this.cantidadDisponible = this.cantidad;
        if(this.is_hoja){
            for(let i = 0; i < this.listadoItemPrograma.length; i++){
                this.cantidadDisponible = this.cantidadDisponible - this.listadoItemPrograma[i].cantidad;
            }
        }else{
            for(let i = 0; i < this.itemPresupuestoHijos.length; i++){
                this.cantidadDisponible = this.cantidadDisponible + this.itemPresupuestoHijos[i].calcularCantidadDisponible();
            }
        }
        return this.cantidadDisponible;
    }
}