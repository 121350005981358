import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid
} from '@material-ui/core';

import formatNumber from 'format-number';

const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });

export default function NumberInput(props) {

  const { input, label, meta, custom, style, textVariant, inputLabelProps, inputName, disabled, value, handleOnChange } = props;

  const [maskedValue, setMaskedValue] = useState(0);
  // const [initialValue, setInitialValue] = useState(0);

  useEffect(() => {
    const stringValue = typeof value === "string" ? value : value.toString();
    setMaskedValue(stringValue ? numberFormater(stringValue) : 0);
  }, [value]);

  // useEffect(() => {
  //   const masked = numberFormater(initialValue.toString().replace(/\./g, "").replace(/,/g, "."));
  //   if (masked !== maskedValue) {
  //     setMaskedValue(masked);
  //   }
  // }, [initialValue, setMaskedValue, numberFormater]);

  const onChange = (e) => {
    setMaskedValue(e.target.value);
    // e.target.value = e.target.value.replace(/\./g, "").replace(/,/g, ".");
    // if (handleOnChange) handleOnChange(e.target.value);
  }

  const onBlur = (e) => {
    // setMaskedValue(e.target.value ? numberFormater(e.target.value.replace(/\./g, "").replace(/,/g, ".")) : 0);
    e.target.value = e.target.value.replace(/\./g, "").replace(/,/g, ".");
    if (handleOnChange) handleOnChange(e);
  }

  return (
    <TextField
      {...input}
      {...custom}
      style={{
        ...style,
      }}
      name={inputName}
      InputLabelProps={inputLabelProps}
      label={label ? label : ""}
      value={maskedValue}
      onChange={onChange}
      onBlur={onBlur}
      error={meta && (meta.touched && (meta.error ? true : false))}
      helperText={meta && meta.touched && meta.error}
      fullWidth={true}
      disabled={disabled ? true : false}
      variant={textVariant ? textVariant : "standard"}
    />
  );
}