/**
 * Lógica de listado reportes diarios pod
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { useHistory } from 'react-router';
import moment from 'moment';
import Axios from 'axios';
import { getReportesPodDiarios } from '../requestsResportesPodDiarios';
import { openAgregarReportePodDiario, openEliminarReportePodDiario, closeEliminarReportePodDiario, closeAgregarReportePodDiario, actualizarGraficoLineaBaseSemanal } from '../actionsReportePodDiario';
moment.locale('es');
export default function ListadoReportesPodDiarios() {

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        proyecto,
        usuarioSesion
    } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto,
        usuarioSesion: state.usuarioSesion
    }));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Cargar reportes pod diarios de un proyecto
     * @param {Object} proyecto
     */
    const cargarData = (proyecto) => {
        if (proyecto) {
            const CancelToken = Axios.CancelToken;
            let cancelRequest = null;
            let cancelToken = new CancelToken(function executor(c) {
                cancelRequest = c;
            });
            getReportesPodDiarios({ proyecto_ref: proyecto._id }, cancelToken)
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 204) {
                        setData([]);
                        setIsLoading(false);
                    } else if (response.status == 200) {
                        console.log(response.data);
                        setData(response.data);
                        setIsLoading(false);
                    } else {
                        setData([]);
                        setIsLoading(false);
                    }
                })
                .catch(e => {
                    setData([]);
                    console.log(e);
                    setIsLoading(false);
                });
            return cancelRequest;
        }
        setData([]);
        setIsLoading(false);
        return null;
    }

    /**
     * Abrir dialogo para agregar un reporte pod diario
     */
    const handleOpenAgregarReportePodDiario = () => {
        dispatch(openAgregarReportePodDiario(
            () => {
                dispatch(actualizarGraficoLineaBaseSemanal());
                dispatch(closeAgregarReportePodDiario());
                cargarData(proyecto);
            }
        ));
    }
    /**
     * Abrir dialogo para eliminar un reporte pod diario
     * @param {Object} data Reporte pod diario a eliminar
     */
    const handleOpenEliminarReportePodDiario = (data) => dispatch(openEliminarReportePodDiario(data,
        () => {
            dispatch(actualizarGraficoLineaBaseSemanal());
            dispatch(closeEliminarReportePodDiario());
            cargarData(proyecto);
        }
    ));



    useEffect(() => {
        setIsLoading(true);
        const cancelRequest = cargarData(proyecto);
        return () => {
            if (cancelRequest) cancelRequest();
            setIsLoading(false);
        }
    }, [proyecto]);


    return (
        <Page
            data={data}
            goTo={(path) => history.push(path)}
            isLoading={isLoading}
            handleOpenAgregarReportePodDiario={handleOpenAgregarReportePodDiario}
            handleOpenEliminarReportePodDiario={handleOpenEliminarReportePodDiario}
            usuarioSesion={usuarioSesion}
        />
    );
}