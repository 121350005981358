import React, { Fragment, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    CircularProgress,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tabs,
    Tab
} from '@material-ui/core';
// import './style.css';
import DetallesItemPrograma from './detallesPrograma';
import { Link as LinkIcon, CloudDownload } from '@material-ui/icons';
import CurvaAvanceFisico from './curvaAvanceFisico';
import html2canvas from 'html2canvas';
import moment from 'moment';
import 'moment/locale/es';
import esLocale from 'date-fns/locale/es';
import OnOffSwitch from '../../components/onOffSwitch';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'react-moment';
import formatNumber from 'format-number';
import * as ROUTES from '../../constants/routes';
import * as URLS from '../../constants/urls';
import { Link } from 'react-router-dom';
import { viewDriveFile } from '../../services/fileUpload';
import IndicadoresHH from './indicadoresHH';
import IndicadoresCostos from './indicadoresCostos';
import IndicadoresLineaBase from './indicadoresLineabase';
moment.locale('es');
const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const TableCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
    },
    body: {
        border: "1px solid #E0E0E0",
        fontSize: 14,
    },
}))(TableCell);
const TableCell2 = withStyles(theme => ({
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
        borderBottomColor: "#FFF"
    },
}))(TableCell);

const style = makeStyles(theme => ({
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    // card: {
    //     height: "100%"
    // },
    title: {
        width: "100%",
        color: theme.palette.primary.main,
        backgroundColor: "#FFF"

    },
    cardContainer: {
        minHeight: "76px"
    },
    progress: {
        margin: theme.spacing(2),
    },
    fullwith: {
        width: "100%",
        height: "100%"
    },
    loadDetalles: {
        // height: "100%",
        // width: "100%",
        paddingLeft: "50%"
    },
    button: {
        marginLeft: 'auto',
    },
    appsHeader: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

const cardHeaderTitleTypographyProps = {
    align: "center"
}

function Page(props) {
    const classes = style();
    const {
        cartas,
        cargarIndicadores,
        generarReporteSemanal,
        incidentes,
        inspecciones,
        noConformidades,
        openReporteSemanal,
        proyecto,
        reporteSemanal,
        setOpenReporteSemanal,
        solicitudesInformacion
    } = props;

    const ref = React.createRef();
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Fragment>
            <Container className={classes.main} ref={ref} >
                {(proyecto) &&

                    <Card style={{ width: "100%" }} className={classes.card}>
                        <CardHeader className={classes.title}
                            title={proyecto.nombre}
                            titleTypographyProps={{
                                align: "center",
                                variante: "h4"
                            }}
                            action={
                                <IconButton aria-label="settings" onClick={() => generarReporteSemanal()}>
                                    <CloudDownload />
                                </IconButton>
                            }
                        />
                    </Card>

                }

                {(proyecto) &&

                    <Card className={classes.card}>
                        <CardContent className={classes.cardContainer}>
                            <Grid
                                container
                                direction="row"
                            >
                                <Grid item lg>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell2 colSpan={2} align="center">
                                                    <b>Proyecto</b>
                                                </TableCell2>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center">{proyecto.codigo}</TableCell1>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 style={{ height: "67.0334px" }} align="center">{proyecto.nombre}</TableCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item lg>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item lg>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell2 colSpan={2} align="center">
                                                            <b>Superintendencia</b>
                                                        </TableCell2>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell1 align="center">{proyecto.superintendencia_ref ? proyecto.superintendencia_ref.nombre : ""}</TableCell1>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item lg>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell2 colSpan={2} align="center">
                                                            <b>Ubicación</b>
                                                        </TableCell2>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell1 align="center">{proyecto.area}</TableCell1>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item lg>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell2 colSpan={2} align="center">
                                                            <b>Contratista</b>
                                                        </TableCell2>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell1 align="center">{proyecto.contratista_ref ? proyecto.contratista_ref.nombre : ""}</TableCell1>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item lg>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell2 colSpan={2} align="center">
                                                            <b>Monto contrato</b>
                                                        </TableCell2>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell1 align="center">${numberFormater(proyecto.monto_original)}</TableCell1>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item lg>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell2 colSpan={2} align="center">
                                                            <b>Fecha Inicio</b>
                                                        </TableCell2>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell1 align="center">{proyecto.fecha_inicio_original ? moment(new Date(proyecto.fecha_inicio_original)).format("DD-MM-YYYY") : "-"}</TableCell1>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item lg>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell2 colSpan={2} align="center">
                                                            <b>Fecha Término</b>
                                                        </TableCell2>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell1 align="center">{proyecto.fecha_termino_original ? moment(new Date(proyecto.fecha_termino_original)).format("DD-MM-YYYY") : "-"}</TableCell1>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }

                {/* <DetallesItemPrograma /> */}

                {/* <IndicadoreCostos/> */}

                <Card style={{ width: "100%" }} className={classes.card}>
                    <CardHeader
                        title="Programación y Control"
                        className={classes.appsHeader}
                        titleTypographyProps={cardHeaderTitleTypographyProps}
                    // action={
                    // <OnOffSwitch
                    //   onText="HH"
                    //   offText="Costo"
                    //   handleChange={() => setConHorasHombre(!conHorasHombre)}
                    // />
                    // }
                    />
                    <CardContent style={{ width: "100%" }} className={classes.card}>
                        <Grid container>
                            <Grid item lg>
                                <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} aria-label="content-tabs">
                                    <Tab label="Reportes Diarios" id="tabReportesDiarios" aria-controls="paneReportesDiarios" />
                                    <Tab label="Resportes por Actividad" id="tabReportesActividad" aria-controls="panelReportesActividad" />
                                </Tabs>
                            </Grid>
                        </Grid>
                        {selectedTab === 0 && <div id="paneReportesDiarios"><IndicadoresLineaBase/></div>}
                        {selectedTab === 1 && <div id="panelReportesActividad"><IndicadoresHH /></div>}
                        
                    </CardContent >
                </Card>
                {(cargarIndicadores) &&
                    <Card>
                        <CardContent className={classes.cardContainer}>

                            <Grid
                                container
                                direction="row"
                            >
                                <Grid item lg>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell2 colSpan={2} align="center">
                                                    <b>Cartas</b>
                                                    <IconButton
                                                        component={AdapterLink} to={`${ROUTES.REDIRECT}/?destiny=${URLS.CARTAS}`} target="_blank" rel="noopener"
                                                        className={classes.button}
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                </TableCell2>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>Abiertas</b></TableCell1>
                                                <TableCell1>{cartas.abiertas}</TableCell1>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>Emitidas</b></TableCell1>
                                                <TableCell1>{cartas.cantidad}</TableCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item lg>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell2 colSpan={2} align="center">
                                                    <b>Calidad (No Conformidades)</b>
                                                    <IconButton className={classes.button}
                                                        component={AdapterLink} to={`${ROUTES.REDIRECT}/?destiny=${URLS.CALIDAD}`} target="_blank" rel="noopener"
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                </TableCell2>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>Abiertas</b></TableCell1>
                                                <TableCell1>{noConformidades.abiertas}</TableCell1>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>Emitidas</b></TableCell1>
                                                <TableCell1>{noConformidades.cantidad}</TableCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item lg>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell2 colSpan={2} align="center">
                                                    <b>Solicitudes de información</b>
                                                    <IconButton className={classes.button}
                                                        component={AdapterLink} to={`${ROUTES.REDIRECT}/?destiny=${URLS.SDI}`} target="_blank" rel="noopener"
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                </TableCell2>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>En Espera de Respuesta</b></TableCell1>
                                                <TableCell1>{solicitudesInformacion.espera_respuesta}</TableCell1>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>Emitidas</b></TableCell1>
                                                <TableCell1>{solicitudesInformacion.cantidad}</TableCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item lg>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell2 colSpan={2} align="center">
                                                    <b>Incidentes</b>
                                                    <IconButton className={classes.button}
                                                        component={AdapterLink} to={`${ROUTES.REDIRECT}/?destiny=${URLS.INCIDENTES}`} target="_blank" rel="noopener"
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                </TableCell2>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell1 align="center"><b>Reportados</b></TableCell1>
                                                <TableCell1>{incidentes.cantidad}</TableCell1>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                {inspecciones &&
                                    <Grid item lg>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell2 colSpan={2} align="center">
                                                        <b>Inspecciones</b>
                                                        <IconButton className={classes.button}
                                                            component={AdapterLink} to={`${ROUTES.REDIRECT}/?destiny=${URLS.INSPECCIONES}`} target="_blank" rel="noopener"
                                                        >
                                                            <LinkIcon />
                                                        </IconButton>
                                                    </TableCell2>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell1 align="center"><b>Abiertas</b></TableCell1>
                                                    <TableCell1>{inspecciones.inspecciones_abiertas}</TableCell1>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell1 align="center"><b>Emitidas</b></TableCell1>
                                                    <TableCell1>{inspecciones.inspecciones_cerradas + inspecciones.inspecciones_abiertas}</TableCell1>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </Grid>
                                }
                            </Grid>

                        </CardContent>
                    </Card>
                }
                <Dialog
                    open={openReporteSemanal}
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">Reporte Semanal</DialogTitle>
                    <DialogContent>
                        Elegir formato de reporte
                    </DialogContent>
                    <DialogActions>
                        {reporteSemanal &&
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                component={AdapterLink}
                                to={`${ROUTES.REDIRECT}/?destiny=${reporteSemanal.excel.url}&external=true`} target="_blank" rel="noopener"
                            >
                                Excel
                            </Button>
                        }
                        {reporteSemanal &&
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={() => viewDriveFile(reporteSemanal.pdf.id, reporteSemanal.pdf.nombre)}
                            >
                                PDF
                            </Button>
                        }
                        <Button onClick={() => setOpenReporteSemanal(false)} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Fragment >
    );
}

export default Page;