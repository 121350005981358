export const CLOSE_AGREGAR_PROGRAMA_SEMANAL = "CLOSE_AGREGAR_PROGRAMA_SEMANAL";
export const OPEN_AGREGAR_PROGRAMA_SEMANAL = "OPEN_AGREGAR_PROGRAMA_SEMANAL";
export const CLOSE_ELIMINAR_PROGRAMA_SEMANAL = "CLOSE_ELIMINAR_PROGRAMA_SEMANAL";
export const OPEN_ELIMINAR_PROGRAMA_SEMANAL = "OPEN_ELIMINAR_PROGRAMA_SEMANAL";
export const CLOSE_ELIMINAR_ITEM_PROGRAMA_SEMANAL = "CLOSE_ELIMINAR_ITEM_PROGRAMA_SEMANAL";
export const OPEN_ELIMINAR_ITEM_PROGRAMA_SEMANAL = "OPEN_ELIMINAR_ITEM_PROGRAMA_SEMANAL";

export const accionOpenAgregarProgramaSemanal = () => ({
    type: OPEN_AGREGAR_PROGRAMA_SEMANAL
});
export const accionCloseAgregarProgramaSemanal = () => ({
    type: CLOSE_AGREGAR_PROGRAMA_SEMANAL
});

export const accionOpenEliminarProgramaSemanal = (programaSemanalSeleccionado) => ({
    type: OPEN_ELIMINAR_PROGRAMA_SEMANAL,
    programaSemanalSeleccionado: programaSemanalSeleccionado
});
export const accionCloseEliminarProgramaSemanal = () => ({
    type: CLOSE_ELIMINAR_PROGRAMA_SEMANAL
});

export const accionOpenEliminarItemProgramaSemanal = (itemProgramaSemanalSeleccionado) => ({
    type: OPEN_ELIMINAR_ITEM_PROGRAMA_SEMANAL,
    itemProgramaSemanalSeleccionado: itemProgramaSemanalSeleccionado
});
export const accionCloseEliminarItemProgramaSemanal = () => ({
    type: CLOSE_ELIMINAR_ITEM_PROGRAMA_SEMANAL
});