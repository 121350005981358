import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { Paper } from '@material-ui/core';


const style = makeStyles(theme => ({
    contenedor: {
        display: 'flex',
        flex: 1
    },
    main: {
        display: 'flex',
        width: "100%",
        height: "100%"
    },
    center: {
        textAlign: "center"
    }
}))


function Page(props) {
    const classes = style();


    return (
        <Fragment>
            {/* <main className={classes.main}> */}
                {/* <CssBaseline /> */}
                <Paper
                    className={classes.contenedor}
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                    >
                        <Grid
                            item
                            className={classes.center}
                            lg
                        >
                            <VerifiedUser style={{ fontSize: 70 }} color="primary"></VerifiedUser>
                            <Typography variant="h6" gutterBottom align='center'>
                                Tenemos problemas con los permisos asignado por favor póngase en contacto con el equipo Cydocs.cl
                        </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            {/* </main> */}
        </Fragment >
    );
}

export default Page;