import React, { useState } from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { accionCloseEliminarProgramaSemanal } from '../actionsProgramaSemanal';
import { deleteProgramaSemanal } from '../requestsProgramasSemanales';

export default function EliminarProgramacionSemanal(props) {

    const dispatch = useDispatch();

    const { open, programaSemanal } = useSelector(state => ({
        open: state.reducerProgramaSemanal.openEliminarProgramaSemanal,
        programaSemanal: state.reducerProgramaSemanal.programaSemanalSeleccionado
    }));

    const handleOnSubmit = async () => {
        const { handleSubmit } = props;
        if (programaSemanal) {
            const response = await deleteProgramaSemanal(programaSemanal._id);
        }
        if (handleSubmit) handleSubmit();


    }

    const handleClose = async () => {
        dispatch(accionCloseEliminarProgramaSemanal());
    }


    return (

        <React.Fragment>
            <Page
                open={open}
                titulo="Eliminar Programa Semanal"
                handleClose={handleClose}
                handleOnSubmit={handleOnSubmit}
            />
        </React.Fragment>
    );
}