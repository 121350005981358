import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { downloadUrlAsPromise, uploadFileToDrive } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { accionCloseAgregarProgramaSemanal } from '../actionsProgramaSemanal';
import { postProgramaSemanal } from '../requestsProgramasSemanales';
import { crearCarpetaDrive, postCarpeta } from '../../../services/database/carpetas';
import { updateProyecto } from '../../../services/database/proyecto';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}
function stringtoNumber(value) {
    if (value) {
        const nospaces = value.replace(/\s/g, "");
        const number = parseFloat(nospaces);
        if (number) return parseFloat(number.toFixed(4));
    }
    return 0;

}

export default function AgregarProgramacionSemanal(props) {

    const dispatch = useDispatch();
    const [formik, setFormik] = useState(null);
    const notistack = useSnackbar();
    const [hojas, setHojas] = useState(null);
    const validationSchema = Yup.object({
        nombre_hoja: Yup.array().min(1, "Debe al menos seleccionar una hoja"),
        inicio: Yup.array().of(
            Yup.date().required("Campo requerido").typeError("Debe ingresar una fecha válida")
        ).required("Campo requerido").min(1, "Debe al menos seleccionar una hoja"),
        termino: Yup.array().of(
            Yup.date().required("Campo requerido").typeError("Debe ingresar una fecha válida")
        ).required("Campo requerido").min(1, "Debe al menos seleccionar una hoja"),
    });
    const { usuarioSesion, proyecto, open } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
        open: state.reducerProgramaSemanal.openAgregarProgramaSemanal
    }));
    const values = {
        inicio: [],
        inicio_programa: [],
        termino: [],
        nombre_hoja: [],
        excel: null
    };

    const handleEnterDialog = async () => {
        formik.resetForm({
            inicio: [],
            inicio_programa: [],
            termino: [],
            termino_programa: [],
            nombre_hoja: [],
            excel: null
        });
        setHojas(null);

    }

    const handleChange = (e, { name, value }) => {
        props.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = async (values, formikBag) => {
        const key = notistack.enqueueSnackbar("Guardando Programa semanal...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            values["programasSemanales"] = [];
            if (values.excel && usuarioSesion.ref) {
                let fileReader = new FileReader();
                fileReader.onload = async (e) => {
                    const binary = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(binary, { type: 'array' });
                    const programas = [];

                    for (var n in values.nombre_hoja) {

                        const worksheet = workbook.Sheets[values.nombre_hoja[n]];
                        if (worksheet) {
                            let inicio_programa = (!values.inicio_programa[n] && values.inicio_programa[n] !== 0) ? 8 : values.inicio_programa[n];
                            let termino_programa = (values.termino_programa[n]) ? values.termino_programa[n] : "";
                            let desired_row = inicio_programa;
                            let desired_cell = worksheet["B" + desired_row];
                            let desired_value = (desired_cell ? desired_cell.v : undefined);

                            const items = [];
                            const diasPrograma = [];
                            if (termino_programa && termino_programa > inicio_programa) {
                                while (desired_row < termino_programa) {
                                    if (desired_value && desired_value !== "-") {
                                        const nuevoItem = {
                                            cantidad_diaria: worksheet["I" + desired_row] ? stringtoNumber(worksheet["I" + desired_row].w) : 0,
                                            cantidad_restante: worksheet["G" + desired_row] ? stringtoNumber(worksheet["G" + desired_row].w) : 0,
                                            cantidad_programada_total: 0,
                                            duracion: worksheet["J" + desired_row] ? stringtoNumber(worksheet["J" + desired_row].w) : 0,
                                            // horas_hombre: worksheet["H" + desired_row] ? stringtoNumber(worksheet["K" + desired_row].w) : 0,
                                            horas_hombre_programadas_totales: worksheet["H" + desired_row] ? stringtoNumber(worksheet["H" + desired_row].w) : 0,
                                            horas_hombre_totales: worksheet["D" + desired_row] ? stringtoNumber(worksheet["D" + desired_row].w) : 0,
                                            id_item_programa: worksheet["B" + desired_row] ? worksheet["B" + desired_row].w : "",
                                            inicio: worksheet["K" + desired_row] ? new Date(worksheet["K" + desired_row].w) : null,
                                            // item: worksheet["C" + desired_row] ? worksheet["C" + desired_row].w : "",
                                            nombre: worksheet["C" + desired_row] ? worksheet["C" + desired_row].w : "",
                                            // porcentaje_reprogramar: 100,
                                            rendimientos_horas_hombre: worksheet["E" + desired_row] ? worksheet["E" + desired_row].v : 0,
                                            termino: worksheet["L" + desired_row] ? new Date(worksheet["L" + desired_row].w) : null,
                                            unidad: worksheet["F" + desired_row] ? worksheet["F" + desired_row].w : "",
                                            dias: [],
                                            proyecto_ref: proyecto._id
                                        };

                                        let fin = moment(values.termino[n]);
                                        let column = 12;
                                        for (let actual = moment(values.inicio[n]); actual.isSameOrBefore(fin); actual.add(1, 'days')) {
                                            const dia = {
                                                cantidad_programada: worksheet[numToAlpha(column) + desired_row] ? stringtoNumber(worksheet[numToAlpha(column) + desired_row].w) : 0,
                                                horas_hombre_programadas: 0,
                                                avance_programado: worksheet[numToAlpha(column + 2) + desired_row] ? stringtoNumber(worksheet[numToAlpha(column + 2) + desired_row].w) : 0,
                                                fecha: actual.toDate(),
                                                proyecto_ref: proyecto._id
                                            };
                                            dia.horas_hombre_programadas = dia.cantidad_programada * nuevoItem.rendimientos_horas_hombre;
                                            dia.horas_hombre_programadas = parseFloat(dia.horas_hombre_programadas.toFixed(4));
                                            nuevoItem.cantidad_programada_total = nuevoItem.cantidad_programada_total + dia.cantidad_programada;
                                            // nuevoItem.horas_hombre_programadas_totales = nuevoItem.horas_hombre_programadas_totales + dia.horas_hombre_programadas;
                                            nuevoItem.dias.push(dia);
                                            const diaPrograma = diasPrograma.find(a => moment(a.fecha).isSame(moment(dia.fecha)));
                                            if (diaPrograma) {
                                                // diaPrograma.cantidad_programada = diaPrograma.cantidad_programada + (dia.cantidad_programada * nuevoItem.rendimientos_horas_hombre);
                                                diaPrograma.horas_hombre_programadas = diaPrograma.horas_hombre_programadas + (dia.horas_hombre_programadas);
                                                // diaPrograma.avance_programado = diaPrograma.avance_programado + (dia.avance_programado * nuevoItem.rendimientos_horas_hombre);
                                            } else {
                                                diasPrograma.push({
                                                    // cantidad_programada: (dia.cantidad_programada * nuevoItem.rendimientos_horas_hombre),
                                                    horas_hombre_programadas: (dia.horas_hombre_programadas),
                                                    // avance_programado: (dia.avance_programado * nuevoItem.rendimientos_horas_hombre),
                                                    fecha: dia.fecha,
                                                    proyecto_ref: proyecto._id
                                                });
                                            }
                                            column = column + 4;
                                        }
                                        items.push(nuevoItem);
                                    }
                                    desired_row++;
                                    desired_cell = worksheet["B" + desired_row];
                                    desired_value = (desired_cell ? desired_cell.v : undefined);
                                }
                            } else {
                                while (desired_value) {
                                    if (desired_value !== "-") {
                                        const nuevoItem = {
                                            cantidad_diaria: worksheet["I" + desired_row] ? stringtoNumber(worksheet["I" + desired_row].w) : 0,
                                            cantidad_restante: worksheet["G" + desired_row] ? stringtoNumber(worksheet["G" + desired_row].w) : 0,
                                            cantidad_programada_total: 0,
                                            duracion: worksheet["J" + desired_row] ? stringtoNumber(worksheet["J" + desired_row].w) : 0,
                                            // horas_hombre: worksheet["H" + desired_row] ? stringtoNumber(worksheet["K" + desired_row].w) : 0,
                                            horas_hombre_programadas_totales: worksheet["H" + desired_row] ? stringtoNumber(worksheet["H" + desired_row].w) : 0,
                                            horas_hombre_totales: worksheet["D" + desired_row] ? stringtoNumber(worksheet["D" + desired_row].w) : 0,
                                            id_item_programa: worksheet["B" + desired_row] ? worksheet["B" + desired_row].w : "",
                                            inicio: worksheet["K" + desired_row] ? new Date(worksheet["K" + desired_row].w) : null,
                                            // item: worksheet["C" + desired_row] ? worksheet["C" + desired_row].w : "",
                                            nombre: worksheet["C" + desired_row] ? worksheet["C" + desired_row].w : "",
                                            // porcentaje_reprogramar: 100,
                                            rendimientos_horas_hombre: worksheet["E" + desired_row] ? worksheet["E" + desired_row].v : 0,
                                            termino: worksheet["L" + desired_row] ? new Date(worksheet["L" + desired_row].w) : null,
                                            unidad: worksheet["F" + desired_row] ? worksheet["F" + desired_row].w : "",
                                            dias: [],
                                            proyecto_ref: proyecto._id
                                        };

                                        let fin = moment(values.termino[n]);
                                        let column = 12;
                                        for (let actual = moment(values.inicio[n]); actual.isSameOrBefore(fin); actual.add(1, 'days')) {
                                            const dia = {
                                                cantidad_programada: worksheet[numToAlpha(column) + desired_row] ? stringtoNumber(worksheet[numToAlpha(column) + desired_row].w) : 0,
                                                horas_hombre_programadas: 0,
                                                avance_programado: worksheet[numToAlpha(column + 2) + desired_row] ? stringtoNumber(worksheet[numToAlpha(column + 2) + desired_row].w) : 0,
                                                fecha: actual.toDate(),
                                                proyecto_ref: proyecto._id
                                            };
                                            dia.horas_hombre_programadas = dia.cantidad_programada * nuevoItem.rendimientos_horas_hombre;
                                            dia.horas_hombre_programadas = parseFloat(dia.horas_hombre_programadas.toFixed(4));
                                            nuevoItem.cantidad_programada_total = nuevoItem.cantidad_programada_total + dia.cantidad_programada;
                                            // nuevoItem.horas_hombre_programadas_totales = nuevoItem.horas_hombre_programadas_totales + dia.horas_hombre_programadas;
                                            nuevoItem.dias.push(dia);
                                            const diaPrograma = diasPrograma.find(a => moment(a.fecha).isSame(moment(dia.fecha)));
                                            if (diaPrograma) {
                                                // diaPrograma.cantidad_programada = diaPrograma.cantidad_programada + (dia.cantidad_programada * nuevoItem.rendimientos_horas_hombre);
                                                diaPrograma.horas_hombre_programadas = diaPrograma.horas_hombre_programadas + (dia.horas_hombre_programadas);
                                                // diaPrograma.avance_programado = diaPrograma.avance_programado + (dia.avance_programado * nuevoItem.rendimientos_horas_hombre);
                                            } else {
                                                diasPrograma.push({
                                                    // cantidad_programada: (dia.cantidad_programada * nuevoItem.rendimientos_horas_hombre),
                                                    horas_hombre_programadas: (dia.horas_hombre_programadas),
                                                    // avance_programado: (dia.avance_programado * nuevoItem.rendimientos_horas_hombre),
                                                    fecha: dia.fecha,
                                                    proyecto_ref: proyecto._id
                                                });
                                            }
                                            column = column + 4;
                                        }
                                        items.push(nuevoItem);
                                    }
                                    desired_row++;
                                    desired_cell = worksheet["B" + desired_row];
                                    desired_value = (desired_cell ? desired_cell.v : undefined);
                                }

                            }
                            const nuevoPrograma = {
                                inicio: values.inicio[n],
                                nombre_hoja: values.nombre_hoja[n],
                                proyecto_ref: proyecto._id,
                                termino: values.termino[n],
                                items: items,
                                diasPrograma: diasPrograma
                            };
                            programas.push(nuevoPrograma);
                        }

                    }
                    await initSubmit(programas, values.excel)
                    notistack.closeSnackbar(key);
                    const successkey = notistack.enqueueSnackbar("Operanción exitosa: Programa semanal guardado", {
                        variant: 'success',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
                    });
                };
                fileReader.readAsArrayBuffer(values.excel);
            }
        }
        catch (error) {
            console.log(error);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible realizar la reprogramación. Contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });

        }
    }

    const initSubmit = async (data, file) => {

        const { handleSubmit } = props;
        let carpeta = proyecto.carpetas.find(a => a.nombre === "Programas Semanales");
        if (!carpeta) {
            const carpetaDriveApp = await crearCarpetaDrive({
                name: `Programas Semanales`,
                parent: proyecto.carpeta_proyecto.carpeta_id
            });
            carpeta = await postCarpeta({
                nombre: `Programas Semanales`,
                descripción: ``,
                padre_ref: proyecto.carpeta_proyecto._id,
                carpeta_id: carpetaDriveApp.id
            });
            proyecto.carpetas.push(carpeta);
            await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
        }
        // const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" }
        const uploadedFile = await uploadFileToDrive(file, carpeta.carpeta_id, file.name);
        for (let p in data) {
            await postProgramaSemanal({
                ...data[p],
                archivo: {
                    nombre: uploadedFile.name,
                    archivo_id: uploadedFile.id,
                    carpeta_id: carpeta.carpeta_id,
                    carpeta_ref: carpeta._id,
                    size: file.size,
                    repositorio: "DRIVE",
                    url: uploadedFile.url
                }
            });
        }
        if (handleSubmit) handleSubmit(values);
    }

    const previewXLSX = async (file) => {
        if (file) {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                const binary = new Uint8Array(e.target.result);
                const workbook = XLSX.read(binary, { type: 'array' });

                //CARGA PREVIA POD

                const hojasXLSX = workbook.SheetNames.map((value, index) => ({
                    nombre: value,
                }));
                const nombreHoja = workbook.SheetNames;
                const inicio = workbook.SheetNames.map((value, index) => (moment().startOf('week').toDate()));
                const termino = workbook.SheetNames.map((value, index) => (moment().endOf('week').toDate()));
                const inicio_programa = workbook.SheetNames.map((value, index) => (8));
                const termino_programa = workbook.SheetNames.map((value, index) => {
                    const ws = workbook.Sheets[value];
                    let range = XLSX.utils.decode_range(ws['!ref']);
                    let num_rows = range.e.r;
                    return num_rows;
                });
                formik.setFieldValue("nombre_hoja", nombreHoja);
                formik.setFieldValue("inicio", inicio);
                formik.setFieldValue("termino", termino);
                // formik.setFieldValue("fin_pod", fin_pod);
                formik.setFieldValue("inicio_programa", inicio_programa);
                formik.setFieldValue("termino_programa", termino_programa);
                setHojas(hojasXLSX);
            };
            fileReader.readAsArrayBuffer(file);
        } else {
            setHojas(null);
        }
    }

    const handleDescargarFormato = async () => {
        const nombre = "Formato Programa Semanal.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/1DzLSQNnspSjGFiwsVlS5xvAvBWCoK3OW?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    const handleClose = async () => {
        dispatch(accionCloseAgregarProgramaSemanal());
    }

    function alphaToNum(alpha) {

        let i = 0,
            num = 0,
            len = alpha.length;

        for (; i < len; i++) {
            num = num * 26 + alpha.charCodeAt(i) - 0x40;
        }

        return num - 1;
    }

    function numToAlpha(num) {

        let alpha = '';

        for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
            alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
        }

        return alpha;
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                render={props =>
                    <Page
                        open={open}
                        titulo="Nuevo Programa Semanal"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        previewXLSX={previewXLSX}
                        hojas={hojas}
                        setHojas={setHojas}
                        validationSchema={validationSchema}
                        handleDescargarFormato={handleDescargarFormato}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}