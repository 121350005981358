import React from 'react';
import {
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Grid,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  InputLabel,
  Select
} from '@material-ui/core';
import NumberInput from '../../../../components/numberInput';
export default function Page(props) {


  const {
    errors,
    handleChange,
    handleChangeSwitch,
    handleClose,
    handleEnterDialog,
    handleSubmit,
    isValid,
    touched,
    values
  } = props;

  return (
    <Dialog
      open={props.open}
      onEntered={handleEnterDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
      fullWidth={true}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Editar Actividad POD</DialogTitle>
        <DialogContent>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <TextField
                value={values.actividadSeleccionada}
                name="actividadSeleccionada"
                label="Actividad Seleccionada"
                helperText={errors.actividadSeleccionada && touched.actividadSeleccionada ? errors.actividadSeleccionada : ""}
                error={(errors.actividadSeleccionada && touched.actividadSeleccionada) ? true : false}
                margin="normal"
                fullWidth={true}
                disabled={true}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <TextField
                value={values.cantidadTotal}
                name="cantidadTotal"
                label="Cantidad Total Actividad"
                helperText={errors.cantidadTotal && touched.cantidadTotal ? errors.cantidadTotal : ""}
                error={(errors.cantidadTotal && touched.cantidadTotal) ? true : false}
                margin="normal"
                fullWidth={true}
                disabled={true}
                variant="outlined"

              />
            </Grid>
            <Grid item lg>
              <NumberInput
                inputName="cantidad_programada"
                value={values.cantidad_programada}
                label="Cantidad Programada del Día"
                meta={{
                  touched: touched.cantidad_programada,
                  error: errors.cantidad_programada

                }}
                custom={{
                  margin: "normal"
                }}
                textVariant="outlined"
                handleOnChange={handleChange.bind(null, 'cantidad_programada')}
              />
            </Grid>
          </Grid>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <TextField
                value={values.horasHombreTotal}
                name="horasHombreTotal"
                label="HH Total Actividad"
                helperText={errors.horasHombreTotal && touched.horasHombreTotal ? errors.horasHombreTotal : ""}
                error={(errors.horasHombreTotal && touched.horasHombreTotal) ? true : false}
                margin="normal"
                fullWidth={true}
                disabled={true}
                variant="outlined"
              />
            </Grid>
            <Grid item lg>
              <NumberInput
                inputName="horas_hombre_programadas"
                value={values.horas_hombre_programadas}
                label="HH Programadas del Día"
                meta={{
                  touched: touched.horas_hombre_programadas,
                  error: errors.horas_hombre_programadas

                }}
                custom={{
                  margin: "normal"
                }}
                textVariant="outlined"
                handleOnChange={handleChange.bind(null, 'horas_hombre_programadas')}
              />
            </Grid>
          </Grid>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <TextField
                value={values.observacion}
                name="observacion"
                label="Causa Raíz"
                helperText={errors.observacion && touched.observacion ? errors.observacion : ""}
                error={(errors.observacion && touched.observacion) ? true : false}
                margin="normal"
                fullWidth={true}
                rows={3}
                variant="outlined"
                onChange={handleChange.bind(null, 'observacion')}
              />
            </Grid>
          </Grid>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <FormControl>
                {/* <FormLabel component="legend">Baglog</FormLabel> */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="baglog"
                        color="primary"
                        checked={values.baglog}
                        onChange={e => handleChangeSwitch('baglog', e)}
                        value={values.baglog}
                      />
                    }
                    label="Baglog" />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <TextField
                value={values.comentario_baglog}
                name="comentario_baglog"
                label="Comentario"
                helperText={errors.comentario_baglog && touched.comentario_baglog ? errors.comentario_baglog : ""}
                error={(errors.comentario_baglog && touched.comentario_baglog) ? true : false}
                margin="normal"
                fullWidth={true}
                rows={3}
                variant="outlined"
                disabled={!values.baglog}
                onChange={handleChange.bind(null, 'comentario_baglog')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Aceptar
                </Button>
          <Button onClick={handleClose} color="primary">
            Cancelar
                </Button>
        </DialogActions>
      </form>
    </Dialog >
  );
}