import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

export default function Page(props) {
  const {
    errors,
    handleChange,
    inicioSemanaReportePod,
    handleClose,
    handleEnterDialog,
    handleSubmit,
    isValid,
    touched,
    values
  } = props;

  return (
    <Dialog
      open={props.open}
      onEntered={handleEnterDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
      fullWidth={true}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Generar Reporte Semanal POD - {moment(inicioSemanaReportePod).format("DD/MM/YYYY")} </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <b>Atención:</b> Se va a generar un informe para la semana y proyectos seleccionados. Si ya existe un reporte generado para la fecha, este será reemplazado.
            Si lo desea, puede agregar un Plan de acción al reporte ingresando la información a continuación.
          </Typography>
          <br/>
          <Grid container
            spacing={1}
            direction="row">
            <Grid item lg>
              <TextField
                value={values.plan_accion}
                name="plan_accion"
                label="Plan de acción"
                helperText={errors.plan_accion && touched.plan_accion ? errors.plan_accion : ""}
                error={(errors.plan_accion && touched.plan_accion) ? true : false}
                margin="normal"
                fullWidth={true}
                rows={3}
                variant="outlined"
                multiline={true}
                onChange={handleChange.bind(null, 'plan_accion')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Aceptar
                </Button>
          <Button onClick={handleClose} color="primary">
            Cancelar
                </Button>
        </DialogActions>
      </form>
    </Dialog >
  );
}