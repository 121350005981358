/**
 * Componente con formulario para agregar un nuevo dia linea base
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {  closeEditarDiaLineaBase } from '../actionsReportePodDiario';
import moment from 'moment';
import { updateDiaLineaBase } from '../requestsDiaLineaBase';


export default function EditarDiaLineaBase(props) {
    const { proyecto, open, handleSubmit, diaLineabase } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto,
        open: state.reducerReportePodDiario.openEditarDiaLineaBase,
        handleSubmit: state.reducerReportePodDiario.handleSubmitEditarDiaLineaBase,
        diaLineabase: state.reducerReportePodDiario.diaLineaBaseSeleccionado,
    }));

    const notistack = useSnackbar();
    const dispatch = useDispatch();
    const [formik, setFormik] = useState(null);
    const validationSchema = Yup.object({
        cumplimiento_pod: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerico"),
        fecha: Yup.date().nullable().required("Debe seleccionar una fehca"),
        horas_hombre_ganadas: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerico"),
        horas_hombre_ganadas_pod: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerico"),
        horas_hombre_programadas: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerico"),
        horas_hombre_programadas_pod: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerico"),
        horas_hombre_programadas_acumuladas: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerico"),
    });
    /**
     * Campos del formulario
     */
    const values = {
        cumplimiento_pod: 0,
        fecha: moment().startOf('day').toDate(),
        horas_hombre_ganadas: 0,
        horas_hombre_ganadas_pod: 0,
        horas_hombre_programadas: 0,
        horas_hombre_programadas_pod: 0,
        horas_hombre_programadas_acumuladas: 0,
        linea_base_ref: null,
        proyecto_ref: null
    };

    /**
     * Al mostrar el dialago, se limpian los campos del formulario
     */
    const handleEnterDialog = async () => {
        if (diaLineabase) {
            formik.resetForm({
                cumplimiento_pod: diaLineabase.cumplimiento_pod,
                fecha: diaLineabase.fecha,
                horas_hombre_ganadas: diaLineabase.horas_hombre_ganadas,
                horas_hombre_ganadas_pod: diaLineabase.horas_hombre_ganadas_pod,
                horas_hombre_programadas: diaLineabase.horas_hombre_programadas,
                horas_hombre_programadas_pod: diaLineabase.horas_hombre_programadas_pod,
                horas_hombre_programadas_acumuladas: diaLineabase.horas_hombre_programadas_acumuladas,
                linea_base_ref: diaLineabase.linea_base_ref,
                proyecto_ref: diaLineabase.proyecto_ref
            });
        } else {
            formik.resetForm({
                cumplimiento_pod: 0,
                fecha: moment().startOf('day').toDate(),
                horas_hombre_ganadas: 0,
                horas_hombre_ganadas_pod: 0,
                horas_hombre_programadas: 0,
                horas_hombre_programadas_pod: 0,
                horas_hombre_programadas_acumuladas: 0,
                linea_base_ref: proyecto && proyecto.linea_base_ref ? proyecto.linea_base_ref : null,
                proyecto_ref: proyecto ? proyecto._id : null
            });
        }
    }
    /**
     * Manejar cambio de valores de campo de formulario
     * @param {String} name nombre del campo modificado
     * @param {Event} e event que gatilla el cambio de un valor
     * @param {*} e.target.value valor nuevo del campo
     */
    const handleChange = (name, e) => {

        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, e.target.value);
    };
    /**
     * Manejar cambio de valores de fechas de formulario
     * @param {String} name nombre del campo modificado
     * @param {Date} e nuevo valor de fecha
     */
    const handleDateChange = (name, e) => {
        formik.setFieldValue(name, e);
        formik.setFieldTouched(name, true);
    };

    /**
     * Carga de formulario a base de datos
     * @param {Object} values valores del formulario
     * @param {Object} formikBag
     */
    const handleOnSubmit = async (values, formikBag) => {
        console.log(values);
        const key = notistack.enqueueSnackbar("Guardando datos...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        return updateDiaLineaBase(diaLineabase._id, values)
            .then(async (response) => {
                if (response.status === 500) {
                    console.log("error", response);
                    notistack.closeSnackbar(key);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar modificaciones. Contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                } else if (response.status === 422) {
                    console.log("error", response);
                    notistack.closeSnackbar(key);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible v. Contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                } else if (response.status === 200) {
                    notistack.closeSnackbar(key);
                    const successkey = notistack.enqueueSnackbar("Operanción exitosa: Día Línea base modificado", {
                        variant: 'success',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
                    });
                    if (handleSubmit) handleSubmit();
                } else {
                    console.log("unhandled error", response);
                    notistack.closeSnackbar(key);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar modificaciones. Contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                }
            }).catch(error => {
                console.log(error);
                notistack.closeSnackbar(key);
                const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar modificaciones. Contacte con soporte el equipo de soporte", {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    },
                    action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                });
            });

    }


    /**
     * Cerrar dialogo
     */
    const handleClose = () => dispatch(closeEditarDiaLineaBase());
    // const handleClose = () => console.log("close");

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                isInitialValid={true}
                initialValues={values}
                validationSchema={validationSchema}
                render={formikProps =>
                    <Page
                        {...formikProps}
                        open={open}
                        titulo="Día Línea Base"
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        handleClose={handleClose}
                        handleEnterDialog={handleEnterDialog}
                    />}
            />
        </React.Fragment>
    );
}