import Axios from 'axios';

export const getCarpetaRoot = async () => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/carpetas/root`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const crearCarpetaDrive = async (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(`https://cydocs-drive-api.herokuapp.com/createFolder`, data)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const postCarpeta = async (data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/carpetas/`, data)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}