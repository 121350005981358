import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { accionCloseAgregarReprogramacionArchivo, accionOpenAgregarReprogramacion } from '../actionsReprogramacion';
import { getItemProgramaByItem, reprogramarItemsProgramaDesdeArchivo, updatePrograma, postItemPrograma, getItemsPrograma } from '../../../services/database/programacion';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import XLSX from 'xlsx';
import { postReprogramacion } from '../requestsReprogramacion';
import { postItemReprogramacion } from '../requestsItemReprogramacion';
import { uploadFileToDrive } from '../../../services/fileUpload';

export default function AgregarReprogramacionDesdeArchivo(props) {

    const notistack = useSnackbar();
    const dispatch = useDispatch();
    const { open, proyecto } = useSelector(state => ({
        open: state.reducerReprogramacion.openAgregarReprogramacionArchivo,
        proyecto: state.contratoSesion.proyecto
    }));

    const [formik, setFormik] = useState(null);

    const validationSchema = Yup.object({
        fecha: Yup.date()
    });

    const values = {
        fecha: new Date(),
        file: null
    };

    const handleEnterDialog = async () => {
        formik.resetForm(values);
    }

    const handleChangeDate = (name, e) => {
        formik.setFieldTouched(name, true, false);
        if (e) formik.setFieldValue(name, e);
        else formik.setFieldValue(name, null);

    };

    const handleFileChange = (name, e) => {
        formik.setFieldTouched(name, true, false);
        formik.setFieldValue(name, e.target.files[0]);
    };

    const handleDesdeFecha = () => {
        dispatch(accionOpenAgregarReprogramacion());
        dispatch(accionCloseAgregarReprogramacionArchivo());
    }

    const handleOnSubmit = async (values, formikBag) => {
        const { handleSubmit } = props;
        const key = notistack.enqueueSnackbar("Reprogramación en proceso...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            const listaItems = [];
            // const listaItemsNuevos = [];

            if (values.file) {
                // let carpeta = proyecto.carpetas.find(a => a.nombre === "Programas Semanales");
                // if (!carpeta) {
                //     const carpetaDriveApp = await crearCarpetaDrive({
                //         name: `Programas Semanales`,
                //         parent: proyecto.carpeta_proyecto.carpeta_id
                //     });
                //     carpeta = await postCarpeta({
                //         nombre: `Programas Semanales`,
                //         descripción: ``,
                //         padre_ref: proyecto.carpeta_proyecto._id,
                //         carpeta_id: carpetaDriveApp.id
                //     });
                //     proyecto.carpetas.push(carpeta);
                //     await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
                // }
                const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" }
                const uploadedFile = await uploadFileToDrive(values.file, carpeta.carpeta_id, values.file.name);
                let fileReader = new FileReader();
                fileReader.onload = async (e) => {
                    const binary = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(binary, { type: 'array' });
                    let sheet_name = workbook.SheetNames[0];
                    let worksheet = workbook.Sheets[sheet_name];
                    let desired_row = 2;
                    let desired_cell = worksheet["A" + desired_row];
                    let desired_value = (desired_cell ? desired_cell.v : undefined);
                    const nuevaReprogramacion = await postReprogramacion({
                        archivo: {
                            nombre: uploadedFile.name,
                            archivo_id: uploadedFile.id,
                            carpeta_id: carpeta.carpeta_id,
                            carpeta_ref: carpeta._id,
                            size: values.file.size,
                            repositorio: "DRIVE",
                            url: uploadedFile.url
                        },
                        fecha: values.fecha,
                        programa_ref: proyecto.programa_actual_ref,
                        proyecto_ref: proyecto._id,
                        inicio_reprogramacion: values.fecha,
                        termino_reprogramacion: values.fecha,
                    });
                    while (desired_value) {
                        if (desired_value) {
                            const item = {
                                id_item: worksheet["B" + desired_row] ? worksheet["B" + desired_row].v : "",
                                item: typeof desired_value === "string" ? desired_value : (desired_value).toString(),
                                costo_directo: worksheet["L" + desired_row] ? worksheet["L" + desired_row].v : null,
                                fecha_reprogramacion: values.fecha,
                                horas_hombre: worksheet["J" + desired_row] ? worksheet["J" + desired_row].v : null,
                                horas_hombre_ganadas: worksheet["P" + desired_row] ? worksheet["P" + desired_row].v : null,
                                horas_hombre_proyectadas: worksheet["K" + desired_row] ? worksheet["K" + desired_row].v : null,
                                inicio_ideal: worksheet["D" + desired_row] ? new Date(worksheet["D" + desired_row].w) : null,
                                inicio_real: worksheet["M" + desired_row] ? new Date(worksheet["M" + desired_row].w) : null,
                                inicio_tardio: worksheet["H" + desired_row] ? new Date(worksheet["H" + desired_row].w) : null,
                                inicio_temprano: worksheet["F" + desired_row] ? new Date(worksheet["F" + desired_row].w) : null,
                                is_hoja: true,
                                item_programa_ref: null,
                                nombre: worksheet["C" + desired_row] ? worksheet["C" + desired_row].v : null,
                                porcentaje_avance: worksheet["O" + desired_row] ? worksheet["O" + desired_row].v : null,
                                padre: null,
                                programa_ref: proyecto.programa_actual_ref,
                                proyecto_ref: proyecto._id,
                                reprogramacion_ref: nuevaReprogramacion._id,
                                termino_ideal: worksheet["E" + desired_row] ? new Date(worksheet["E" + desired_row].w) : null,
                                termino_real: worksheet["N" + desired_row] ? new Date(worksheet["N" + desired_row].w) : null,
                                termino_tardio: worksheet["I" + desired_row] ? new Date(worksheet["I" + desired_row].w) : null,
                                termino_temprano: worksheet["G" + desired_row] ? new Date(worksheet["G" + desired_row].w) : null,
                            };
                            item.id_item = typeof item.id_item === "string" ? item.id_item : (item.id_item).toString();
                            item.id_item = item.id_item.replace(/\s/g, "");
                            let itemDB = await getItemProgramaByItem(proyecto.programa_actual_ref, desired_value);
                            if (!itemDB || !itemDB[0]) itemDB = await getItemsPrograma(proyecto.programa_actual_ref, { id_item: item.id_item });
                            listaItems.push(item);
                            if (itemDB && itemDB[0]) {
                                item.item_programa_ref = itemDB[0]._id;
                                if (itemDB[0].is_hoja) {
                                    await postItemReprogramacion(item);
                                }
                            } else {
                                let itemSplit = item.item.split(".");
                                if (itemSplit.length > 1) {
                                    itemSplit.pop();
                                    let id = itemSplit.join(".");
                                    let padreIndex = listaItems.findIndex(a => a.item == id);
                                    if (padreIndex > -1) {
                                        if (listaItems[padreIndex].item_programa_ref) {
                                            item.padre = listaItems[padreIndex].item_programa_ref;
                                        }
                                    } else {
                                        let padreRef = await getItemProgramaByItem(proyecto.programa_actual_ref, id);
                                        if (padreRef) item.padre = padreRef[0]._id;
                                    }
                                }
                                const newItem = await postItemPrograma(item);
                                item.item_programa_ref = newItem._id;
                            }

                        }
                        desired_row++;
                        desired_cell = worksheet["A" + desired_row];
                        desired_value = (desired_cell ? desired_cell.v : undefined);
                    }
                    handleClose();
                    if (handleSubmit) handleSubmit();
                    notistack.closeSnackbar(key);
                    const successkey = notistack.enqueueSnackbar("Reprogramación exitosa", {
                        variant: 'success',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
                    });
                };
                fileReader.readAsArrayBuffer(values.file);
            }
        }
        catch (error) {
            console.log(error);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible realizar la reprogramación. Contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });

        }
    }


    const handleClose = () => {
        dispatch(accionCloseAgregarReprogramacionArchivo());
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                isInitialValid={true}
                render={props =>
                    <Page
                        open={open}
                        titulo="Reprogramación desde Archivo"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        handleChangeDate={handleChangeDate}
                        handleDesdeFecha={handleDesdeFecha}
                        handleFileChange={handleFileChange}
                        // categorizaciones={categorizaciones}
                        validationSchema={validationSchema}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}