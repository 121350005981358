import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import XLSX from 'xlsx';
import { auth } from 'firebase';
import { useSelector } from 'react-redux';
import { downloadUrlAsPromise } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import moment from 'moment';
function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}
function stringtoNumber(value) {
    if (value) {
        const nospaces = value.replace(/\s/g, "");
        const number = parseFloat(nospaces);
        if (number) return reducirDecimales(number);
    }
    return 0;

}

function reducirDecimales(num) {
    if (num) return parseFloat(num.toFixed(4));
    else return null;
}

export default function AgregarPod(props) {
    const { open, handleClose, pod } = props;
    const [formik, setFormik] = useState(null);
    const [hojas, setHojas] = useState(null);
    const validationSchema = Yup.object({
        nombre_hoja: Yup.array().min(1, "Debe al menos seleccionar una hoja"),
        fecha: Yup.array().of(
            Yup.date().required("Campo requerido").typeError("Debe ingresar una fecha válida")
        ).required("Campo requerido").min(1, "Debe al menos seleccionar una hoja"),
        // fin_pod: Yup.array().of(
        //     Yup.number().nullable().transform(emptyStringToNull).typeError("Debe ingresar un número de fila").min(0, "Debe ser un valor mayor que 0")
        // ),
        inicio_baglog: Yup.array().of(
            Yup.number().nullable().transform(emptyStringToNull).typeError("Debe ingresar un número de fila").min(0, "Debe ser un valor mayor que 0")
        ),
        horas_hombre_gastadas: Yup.array().of(
            Yup.number().nullable().transform(emptyStringToNull).typeError("Debe ingresar un número de fila").min(0, "Debe ser un valor mayor que 0")
        ),
    });
    const { usuarioSesion, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto
    }));
    const values = {
        fecha: [],
        turno: [],
        horas_hombre_gastadas: [],
        inicio_baglog: [],
        excel: null
    };

    const handleEnterDialog = async () => {
        if (!pod) {
            formik.resetForm(values);
        } else {
            formik.resetForm({
                fecha: [],
                turno: [],
                // fin_pod: [],
                inicio_baglog: [],
                excel: null
            });
        }
        setHojas(null);

    }

    const handleChange = (e, { name, value }) => {
        props.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = (values, formikBag) => {
        const { handleSubmit } = props;
        values["pods"] = [];
        if (values.excel && usuarioSesion.ref) {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                const binary = new Uint8Array(e.target.result);
                const workbook = XLSX.read(binary, { type: 'array' });

                for (var n in values.nombre_hoja) {
                    const worksheet = workbook.Sheets[values.nombre_hoja[n]];
                    if (worksheet) {
                        let desired_row = 6;
                        let desired_cell = worksheet["C" + desired_row];
                        let desired_value = (desired_cell ? desired_cell.v : undefined);
                        if (!desired_value) {
                            desired_row = 8;
                            desired_cell = worksheet["C" + desired_row];
                            desired_value = (desired_cell ? desired_cell.v : undefined);
                        }
                        // let fin_pod = values.fin_pod[n];
                        let inicio_baglog = values.inicio_baglog[n];
                        const items_id = [];
                        const avances = [];
                        // let totalAvances = 0;
                        // let totalCumplimiento = 0;
                        let totalHHGanadas = 0;
                        let totalHHGanadasDia = 0;
                        let totalHHProgramadas = 0;
                        let totalHHProgramadasDia = 0;
                        let totalCantidadGanadas = 0;
                        let totalCantidadProgramadas = 0;
                        // let emptyRows = 0;
                        const hhRev0 = worksheet["L1"] ? worksheet["L1"].v : null;
                        while (desired_value) {
                            if (inicio_baglog && desired_row >= inicio_baglog) break;
                            if (desired_value !== "-") {
                                const nuevoAvance = {
                                    baglog: false,
                                    turno: values.turno[n],
                                    // turno: worksheet["F" + desired_row] ? worksheet["F" + desired_row].w : "Día",
                                    unidad: worksheet["G" + desired_row] ? worksheet["G" + desired_row].w : "",
                                    cantidad_programada: worksheet["H" + desired_row] ? stringtoNumber(worksheet["H" + desired_row].w) : 0,
                                    cantidad: worksheet["I" + desired_row] ? stringtoNumber(worksheet["I" + desired_row].w) : 0,
                                    horas_hombre_programadas: worksheet["L" + desired_row] ? stringtoNumber(worksheet["L" + desired_row].w) : 0,
                                    horas_hombre: worksheet["M" + desired_row] ? stringtoNumber(worksheet["M" + desired_row].w) : 0,
                                    mano_obra_programada: worksheet["N" + desired_row] ? worksheet["N" + desired_row].w : 0,
                                    equipo: worksheet["O" + desired_row] ? worksheet["O" + desired_row].w : "",
                                    responsable_otro: worksheet["P" + desired_row] ? worksheet["P" + desired_row].w : "",
                                    responsable_contratista: worksheet["Q" + desired_row] ? worksheet["Q" + desired_row].w : "",
                                    mano_obra: worksheet["R" + desired_row] ? worksheet["R" + desired_row].w : 0,
                                    cumplimiento_mano_obra: worksheet["S" + desired_row] ? stringtoNumber(worksheet["S" + desired_row].w) : 0,
                                    equipos_programados: worksheet["T" + desired_row] ? worksheet["T" + desired_row].w : 0,
                                    equipos: worksheet["U" + desired_row] ? worksheet["U" + desired_row].w : 0,
                                    cumplimiento_equipos: worksheet["V" + desired_row] ? stringtoNumber(worksheet["V" + desired_row].w) : 0,
                                    hora_inicio_programado: worksheet["W" + desired_row] ? (worksheet["W" + desired_row].w) : null,
                                    hora_inicio: worksheet["X" + desired_row] ? (worksheet["X" + desired_row].w) : null,
                                    desvio_inicio: (worksheet["Y" + desired_row] && moment(worksheet["Y" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["Y" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                    hora_colacion_programado: worksheet["Z" + desired_row] ? (worksheet["Z" + desired_row].w) : null,
                                    hora_colacion: worksheet["AA" + desired_row] ? (worksheet["AA" + desired_row].w) : null,
                                    avance_colacion_programado: worksheet["AB" + desired_row] ? stringtoNumber(worksheet["AB" + desired_row].w) : 0,
                                    avance_colacion: worksheet["AC" + desired_row] ? stringtoNumber(worksheet["AC" + desired_row].w) : 0,
                                    desvio_avance_colacion: worksheet["AD" + desired_row] ? stringtoNumber(worksheet["AD" + desired_row].w) : 0,
                                    hora_termino_programado: worksheet["AE" + desired_row] ? (worksheet["AE" + desired_row].w) : null,
                                    hora_termino: worksheet["AF" + desired_row] ? (worksheet["AF" + desired_row].w) : null,
                                    desvio_termino: (worksheet["AG" + desired_row] && moment(worksheet["AG" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["AG" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                    metodo_accion: worksheet["AH" + desired_row] ? worksheet["AH" + desired_row].w : "",
                                    responsable_accion: worksheet["AI" + desired_row] ? worksheet["AI" + desired_row].w : "",
                                    observacion: worksheet["AJ" + desired_row] ? worksheet["AJ" + desired_row].w : "",
                                    tiempo_efectivo_trabajo: (worksheet["AK" + desired_row] && moment(worksheet["AK" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["AK" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                    tiempo_real_jornada: (worksheet["AL" + desired_row] && moment(worksheet["AL" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["AL" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                    fecha_ingreso: new Date(),
                                    fecha: moment(values.fecha[n]).format("YYYY-MM-DD"),
                                    tipo_avance: "CON_HH",
                                    usuario_ref: usuarioSesion.ref
                                };
                                if (nuevoAvance.fecha) {
                                    nuevoAvance.hora_inicio_programado = nuevoAvance.hora_inicio_programado ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_inicio_programado, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                    nuevoAvance.hora_inicio = nuevoAvance.hora_inicio ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_inicio, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                    nuevoAvance.hora_colacion_programado = nuevoAvance.hora_colacion_programado ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_colacion_programado, "YYYY-MM-DD h:mm A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                    nuevoAvance.hora_colacion = nuevoAvance.hora_colacion ? moment(nuevoAvance.fecha + " " + + nuevoAvance.hora_colacion, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                    nuevoAvance.hora_termino_programado = nuevoAvance.hora_termino_programado ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_termino_programado, "YYYY-MM-DD h:mm A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                    nuevoAvance.hora_termino = nuevoAvance.hora_termino ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_termino, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                }
                                if (nuevoAvance.horas_hombre) totalHHGanadasDia = totalHHGanadasDia + nuevoAvance.horas_hombre;
                                if (nuevoAvance.horas_hombre_programadas) totalHHProgramadasDia = totalHHProgramadasDia + nuevoAvance.horas_hombre_programadas;
                                if (nuevoAvance.horas_hombre > 0 ||
                                    nuevoAvance.horas_hombre_programadas > 0 ||
                                    nuevoAvance.cantidad > 0 ||
                                    nuevoAvance.cantidad_programada > 0) {
                                    if (desired_value !== "SP") {
                                        items_id.push(worksheet["C" + desired_row].w.replace(/\s/g, ""));
                                        avances.push(nuevoAvance);
                                    }
                                    if (nuevoAvance.horas_hombre_programadas) {
                                        totalHHGanadas = totalHHGanadas + nuevoAvance.horas_hombre;
                                        totalHHProgramadas = totalHHProgramadas + nuevoAvance.horas_hombre_programadas;
                                    }
                                    if (nuevoAvance.cantidad_programada) {
                                        totalCantidadGanadas = totalCantidadGanadas + nuevoAvance.cantidad;
                                        totalCantidadProgramadas = totalCantidadProgramadas + nuevoAvance.cantidad_programada;
                                    }

                                }
                            }
                            desired_row++;
                            desired_cell = worksheet["C" + desired_row];
                            desired_value = (desired_cell ? desired_cell.v : undefined);
                        }

                        if (inicio_baglog) {
                            desired_row = inicio_baglog;
                            while (desired_value) {
                                if (desired_value !== "-") {
                                    const nuevoAvance = {
                                        baglog: true,
                                        turno: values.turno[n],
                                        // turno: worksheet["F" + desired_row] ? worksheet["F" + desired_row].w : "Día",
                                        unidad: worksheet["G" + desired_row] ? worksheet["G" + desired_row].w : "",
                                        cantidad_programada: worksheet["H" + desired_row] ? stringtoNumber(worksheet["H" + desired_row].w) : 0,
                                        cantidad: worksheet["I" + desired_row] ? stringtoNumber(worksheet["I" + desired_row].w) : 0,
                                        horas_hombre_programadas: worksheet["L" + desired_row] ? stringtoNumber(worksheet["L" + desired_row].w) : 0,
                                        horas_hombre: worksheet["M" + desired_row] ? stringtoNumber(worksheet["M" + desired_row].w) : 0,
                                        mano_obra_programada: worksheet["N" + desired_row] ? worksheet["N" + desired_row].w : 0,
                                        equipo: worksheet["O" + desired_row] ? worksheet["O" + desired_row].w : "",
                                        responsable_otro: worksheet["P" + desired_row] ? worksheet["P" + desired_row].w : "",
                                        responsable_contratista: worksheet["Q" + desired_row] ? worksheet["Q" + desired_row].w : "",
                                        mano_obra: worksheet["R" + desired_row] ? worksheet["R" + desired_row].w : 0,
                                        cumplimiento_mano_obra: worksheet["S" + desired_row] ? stringtoNumber(worksheet["S" + desired_row].w) : 0,
                                        equipos_programados: worksheet["T" + desired_row] ? worksheet["T" + desired_row].w : 0,
                                        equipos: worksheet["U" + desired_row] ? worksheet["U" + desired_row].w : 0,
                                        cumplimiento_equipos: worksheet["V" + desired_row] ? stringtoNumber(worksheet["V" + desired_row].w) : 0,
                                        hora_inicio_programado: worksheet["W" + desired_row] ? (worksheet["W" + desired_row].w) : null,
                                        hora_inicio: worksheet["X" + desired_row] ? (worksheet["X" + desired_row].w) : null,
                                        desvio_inicio: (worksheet["Y" + desired_row] && moment(worksheet["Y" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["Y" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                        hora_colacion_programado: worksheet["Z" + desired_row] ? (worksheet["Z" + desired_row].w) : null,
                                        hora_colacion: worksheet["AA" + desired_row] ? (worksheet["AA" + desired_row].w) : null,
                                        avance_colacion_programado: worksheet["AB" + desired_row] ? stringtoNumber(worksheet["AB" + desired_row].w) : 0,
                                        avance_colacion: worksheet["AC" + desired_row] ? stringtoNumber(worksheet["AC" + desired_row].w) : 0,
                                        desvio_avance_colacion: worksheet["AD" + desired_row] ? stringtoNumber(worksheet["AD" + desired_row].w) : 0,
                                        hora_termino_programado: worksheet["AE" + desired_row] ? (worksheet["AE" + desired_row].w) : null,
                                        hora_termino: worksheet["AF" + desired_row] ? (worksheet["AF" + desired_row].w) : null,
                                        desvio_termino: (worksheet["AG" + desired_row] && moment(worksheet["AG" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["AG" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                        metodo_accion: worksheet["AH" + desired_row] ? worksheet["AH" + desired_row].w : "",
                                        responsable_accion: worksheet["AI" + desired_row] ? worksheet["AI" + desired_row].w : "",
                                        observacion: worksheet["AJ" + desired_row] ? worksheet["AJ" + desired_row].w : "",
                                        tiempo_efectivo_trabajo: (worksheet["AK" + desired_row] && moment(worksheet["AK" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["AK" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                        tiempo_real_jornada: (worksheet["AL" + desired_row] && moment(worksheet["AL" + desired_row].w, "h:mm:ss A").isValid) ? moment(worksheet["AL" + desired_row].w, "h:mm:ss A").format("HH:mm:ss") : "",
                                        fecha_ingreso: new Date(),
                                        fecha: moment(values.fecha[n]).format("YYYY-MM-DD"),
                                        tipo_avance: "CON_HH",
                                        usuario_ref: usuarioSesion.ref
                                    };
                                    if (nuevoAvance.fecha) {
                                        nuevoAvance.hora_inicio_programado = nuevoAvance.hora_inicio_programado ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_inicio_programado, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                        nuevoAvance.hora_inicio = nuevoAvance.hora_inicio ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_inicio, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                        nuevoAvance.hora_colacion_programado = nuevoAvance.hora_colacion_programado ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_colacion_programado, "YYYY-MM-DD h:mm A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                        nuevoAvance.hora_colacion = nuevoAvance.hora_colacion ? moment(nuevoAvance.fecha + " " + + nuevoAvance.hora_colacion, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                        nuevoAvance.hora_termino_programado = nuevoAvance.hora_termino_programado ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_termino_programado, "YYYY-MM-DD h:mm A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                        nuevoAvance.hora_termino = nuevoAvance.hora_termino ? moment(nuevoAvance.fecha + " " + nuevoAvance.hora_termino, "YYYY-MM-DD h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss:SSS") : null;
                                    }
                                    if (nuevoAvance.horas_hombre) totalHHGanadasDia = totalHHGanadasDia + nuevoAvance.horas_hombre;
                                    if (nuevoAvance.horas_hombre_programadas) totalHHProgramadasDia = totalHHProgramadasDia + nuevoAvance.horas_hombre_programadas;
                                    if (nuevoAvance.horas_hombre > 0 ||
                                        nuevoAvance.horas_hombre_programadas > 0 ||
                                        nuevoAvance.cantidad > 0 ||
                                        nuevoAvance.cantidad_programada > 0) {
                                        if (desired_value !== "SP") {
                                            items_id.push(worksheet["C" + desired_row].w.replace(/\s/g, ""));
                                            avances.push(nuevoAvance);
                                        }
                                        if (nuevoAvance.horas_hombre_programadas) {
                                            if (nuevoAvance.horas_hombre > 0) {
                                                totalHHGanadas = totalHHGanadas + nuevoAvance.horas_hombre;
                                                totalHHProgramadas = totalHHProgramadas + nuevoAvance.horas_hombre_programadas;
                                            }
                                        }
                                        if (nuevoAvance.cantidad_programada) {
                                            if (nuevoAvance.cantidad > 0) {
                                                totalCantidadGanadas = totalCantidadGanadas + nuevoAvance.cantidad;
                                                totalCantidadProgramadas = totalCantidadProgramadas + nuevoAvance.cantidad_programada;
                                            }
                                        }

                                    }
                                }
                                desired_row++;
                                desired_cell = worksheet["C" + desired_row];
                                desired_value = (desired_cell ? desired_cell.v : undefined);
                            }
                        }
                        let porcentajeCumplimiento = totalHHProgramadas ? (totalHHGanadas / totalHHProgramadas) : (totalCantidadProgramadas ? totalCantidadGanadas / totalCantidadProgramadas : 0);
                        if (hhRev0 !== null) {
                            porcentajeCumplimiento = totalHHGanadas /hhRev0;
                        }
                        const nuevoPod = {
                            porcentaje_cumplimiento: reducirDecimales(porcentajeCumplimiento * 100),
                            horas_hombre_ganadas: parseFloat(totalHHGanadasDia.toFixed(4)),
                            horas_hombre_gastadas: values.horas_hombre_gastadas[n],
                            horas_hombre_programadas: parseFloat(totalHHProgramadasDia.toFixed(4)),
                            horas_hombre_rev0: hhRev0,
                            // porcentaje_cumplimiento: totalAvances ? totalCump0limiento / totalAvances : 0,
                            fecha: values.fecha[n],
                            nombre_hoja: values.nombre_hoja[n],
                            proyecto_ref: proyecto._id,
                            adjunto: null,
                            items_id: items_id,
                            turno: values.turno[n],
                            avances: avances
                        };
                        values.pods.push(nuevoPod);
                    }
                }
                // console.log(values);
                handleSubmit(values);
            };
            fileReader.readAsArrayBuffer(values.excel);
        }
    }

    const previewXLSX = async (file) => {
        if (file) {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                const binary = new Uint8Array(e.target.result);
                const workbook = XLSX.read(binary, { type: 'array' });

                //CARGA PREVIA POD

                const hojasXLSX = workbook.SheetNames.map((value, index) => ({
                    nombre: value,
                }));
                const nombreHoja = workbook.SheetNames;
                const fecha = workbook.SheetNames.map((value, index) => (new Date()));
                const turno = workbook.SheetNames.map((value, index) => ("Día"));
                const horas_hombre_gastadas = workbook.SheetNames.map((value, index) => (0));
                const inicio_baglog = workbook.SheetNames.map((value, index) => (""));
                formik.setFieldValue("nombre_hoja", nombreHoja);
                formik.setFieldValue("fecha", fecha);
                formik.setFieldValue("turno", turno);
                formik.setFieldValue("horas_hombre_gastadas", horas_hombre_gastadas);
                formik.setFieldValue("inicio_baglog", inicio_baglog);
                setHojas(hojasXLSX);
            };
            fileReader.readAsArrayBuffer(file);
        } else {
            setHojas(null);
        }
    }

    const handleDescargarFormato = async () => {
        const nombre = "Formato POD.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/13I9P7tfyH5ila74_XuXJA7z3IdI4In1w?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                pod={pod}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={pod ? true : false}
                render={props =>
                    <Page
                        open={open}
                        titulo="POD"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        pod={pod}
                        previewXLSX={previewXLSX}
                        hojas={hojas}
                        setHojas={setHojas}
                        validationSchema={validationSchema}
                        handleDescargarFormato={handleDescargarFormato}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}