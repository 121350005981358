import React, { useEffect, useState } from 'react';
import Page from './page';
import { useHistory, useParams } from 'react-router-dom';
import { getItemPrograma, agregarAvanceItemsPrograma, getPrograma, updateAvanceItemPrograna, quitarAvanceItemsPrograma, updateItemPrograma, updateEstadoItemPrograma } from '../../../services/database/programacion';
import ItemProgramaModel from '../../../model/programa/item_programa_model';
import { auth } from 'firebase';
import moment from 'moment';

export default function (props) {

    const history = useHistory();
    const { id } = useParams();

    const [avanceSeleccionado, setAvanceSeleccionado] = useState(null);
    const [avance, setAvance] = useState(0);
    const [data, setData] = useState(null);
    const [itemPrograma, setItemPrograma] = useState(null);
    const [programa, setPrograma] = useState(null);
    const [openDialogoAgregar, setOpenDialogoAgregar] = useState(false);
    const [openDialogoEditar, setOpenDialogoEditar] = useState(false);
    const [openEliminar, setOpenEliminar] = useState(false);
    const [openResolverConflicto, setOpenResolverConflicto] = useState(false);
    const [openRevisar, setOpenRevisar] = useState(false);
    const [openMarcarRevisado, setOpenMarcarRevisado] = useState(false);

    useEffect(() => {
        cargarData();
    }, []);

    const cargarData = async () => {
        if (id) {
            const itemProgramaSeleccionado = await getItemPrograma(id);
            const itemProgramaObj = new ItemProgramaModel(itemProgramaSeleccionado);
            const programaSeleccionado = await getPrograma(itemProgramaObj.programa_ref);
            const data = itemProgramaObj.listaAvances ? itemProgramaObj.listaAvances : [];
            let horasHombreReales = 0;
            let avanceReal = 0;
            for (var a in data) {
                horasHombreReales = horasHombreReales + (data[a].horas_hombre ? data[a].horas_hombre : 0);
                    if (data[a].tipo_avance === "CON_HH") {
                        data[a].porcentaje_avance = itemProgramaObj.horas_hombre ? Math.round(((data[a].horas_hombre ? data[a].horas_hombre : 0) / itemProgramaObj.horas_hombre) * 1000000) / 10000 : 0;
                    }
                    avanceReal = avanceReal + data[a].porcentaje_avance;
            }
            if (avanceReal > 100) avanceReal = 100;
            itemProgramaObj["horasHombreReales"] = horasHombreReales;
            itemProgramaObj.getFechas();
            itemProgramaObj.avance_real = avanceReal;
            itemProgramaObj.avance_programado = itemProgramaObj.calcularAvanceProgramado();
            setData(data);
            // console.log(itemProgramaObj);
            setItemPrograma(itemProgramaObj);
            setPrograma(programaSeleccionado);
        }

    }

    //AGREGAR
    const handleOpenDialogoAgregar = () => {
        setOpenDialogoAgregar(true);
    }

    const handleCloseDialogoAgregar = () => {
        setOpenDialogoAgregar(false);
    }

    const handleSubmitAgregar = async (values, formikBag) => {
        await agregarAvanceItemsPrograma(id, {
            ...values,
            usuario_ref: auth().currentUser.uid,
            fecha_ingreso: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
        });
        cargarData();
        handleCloseDialogoAgregar();
    }
    //EDITAR
    const handleOpenDialogoEditar = (rowData) => {
        setOpenDialogoEditar(true);
        setAvanceSeleccionado(rowData);
    }

    const handleCloseDialogoEditar = () => {
        setOpenDialogoEditar(false);
        setAvanceSeleccionado(null);
    }

    const handleSubmitEditar = async (values, formikBag) => {
        await updateAvanceItemPrograna(id, avanceSeleccionado.id, {
            cantidad: values.cantidad,
            cantidad_programada: values.cantidad_programada,
            estado: "Activo",
            fecha: new Date(values.fecha),
            horas_hombre: values.horas_hombre,
            horas_hombre_programadas: values.horas_hombre_programadas,
            metodo_accion: values.metodo_accion,
            observacion: values.observacion,
            pod: avanceSeleccionado.pod,
            porcentaje_avance: values.porcentaje_avance,
            responsable_accion: values.responsable_accion,
            tipo_avance: values.tipo_avance,
            turno: values.turno,
            usuario_ref: avanceSeleccionado.usuario_ref,
            fecha_ingreso: avanceSeleccionado.fecha_ingreso,
        });
        cargarData();
        handleCloseDialogoEditar();
    }

    //ELIMINAR
    const handleOpenEliminar = (rowData) => {
        setOpenEliminar(true);
        setAvanceSeleccionado(rowData);
    }

    const handleCloseEliminar = () => {
        setOpenEliminar(false);
        setAvanceSeleccionado(null);
    }

    const handleSubmitEliminar = async (values, formikBag) => {
        await quitarAvanceItemsPrograma(id, avanceSeleccionado.id);
        cargarData();
        handleCloseEliminar();
    }

    //RESOLVER CONFLICTO AVANCE
    const handleOpenResolverConflicto = (rowData) => {
        setOpenResolverConflicto(true);
        setAvanceSeleccionado({ ...rowData, estado: "Activo" });
    }

    const handleCloseResolverConflicto = () => {
        setOpenResolverConflicto(false);
        setAvanceSeleccionado(null);
    }

    const handleSubmitResolverConflicto = async (values) => {
        await updateAvanceItemPrograna(id, avanceSeleccionado.id, values);
        cargarData();
        handleCloseResolverConflicto();
    }

    //REVISAR AVANCE
    const handleOpenRevisar = (rowData) => {
        setOpenRevisar(true);
        setAvanceSeleccionado(rowData);
    }

    const handleCloseRevisar = () => {
        setOpenRevisar(false);
        setAvanceSeleccionado(null);
    }

    const handleSubmitRevisar = async (values, formikBag) => {
        // console.log(values);
        const data = {
            cantidad: values.cantidad,
            cantidad_programada: values.cantidad_programada,
            estado: "Activo",
            fecha: new Date(values.fecha),
            horas_hombre: values.horas_hombre,
            horas_hombre_programadas: values.horas_hombre_programadas,
            metodo_accion: values.metodo_accion,
            observacion: values.observacion,
            porcentaje_avance: values.porcentaje_avance,
            pod: avanceSeleccionado.pod,
            responsable_accion: values.responsable_accion,
            tipo_avance: values.tipo_avance,
            turno: values.turno,
            usuario_ref: avanceSeleccionado.usuario_ref,
            fecha_ingreso: avanceSeleccionado.fecha_ingreso,
        };
        // console.log(data);
        await updateAvanceItemPrograna(id, avanceSeleccionado.id, data);
        cargarData();
        handleCloseRevisar();
    }

    //RESOLVER CONFLICTO AVANCE
    const handleOpenMarcarRevisado = (rowData) => {
        setOpenMarcarRevisado(true);
    }

    const handleCloseMarcarRevisado = () => {
        setOpenMarcarRevisado(false);
    }

    const handleSubmitMarcarRevisado = async (values) => {
        await updateEstadoItemPrograma(id, values.estado);
        cargarData();
        handleCloseMarcarRevisado();
    }


    return (

        <React.Fragment>
            <Page
                avanceSeleccionado={avanceSeleccionado}
                data={data}
                goTo={(path) => history.push(path)}
                handleCloseDialogoEditar={handleCloseDialogoEditar}
                handleCloseDialogoAgregar={handleCloseDialogoAgregar}
                handleCloseEliminar={handleCloseEliminar}
                handleCloseMarcarRevisado={handleCloseMarcarRevisado}
                handleCloseResolverConflicto={handleCloseResolverConflicto}
                handleCloseRevisar={handleCloseRevisar}
                handleOpenDialogoEditar={handleOpenDialogoEditar}
                handleOpenDialogoAgregar={handleOpenDialogoAgregar}
                handleOpenEliminar={handleOpenEliminar}
                handleOpenMarcarRevisado={handleOpenMarcarRevisado}
                handleOpenResolverConflicto={handleOpenResolverConflicto}
                handleOpenRevisar={handleOpenRevisar}
                handleSubmitAgregar={handleSubmitAgregar}
                handleSubmitEditar={handleSubmitEditar}
                handleSubmitEliminar={handleSubmitEliminar}
                handleSubmitMarcarRevisado={handleSubmitMarcarRevisado}
                handleSubmitResolverConflicto={handleSubmitResolverConflicto}
                handleSubmitRevisar={handleSubmitRevisar}
                itemPrograma={itemPrograma}
                openDialogoAgregar={openDialogoAgregar}
                openDialogoEditar={openDialogoEditar}
                openEliminar={openEliminar}
                openMarcarRevisado={openMarcarRevisado}
                openResolverConflicto={openResolverConflicto}
                openRevisar={openRevisar}
                programa={programa}
            />
        </React.Fragment>
    );
}