import React, { useState, useEffect } from 'react';
import Page from './page';
import {
    getSolicitudesInformacionProyecto,
    getIncidentesProyecto,
    getNoConformidadesProyecto,
    getCartasProyecto,
    getInspeccionesProyecto,
    getEstadosPagosProyecto,
    getAvancesPeriodoPodProyecto,
    updateProyecto,
} from '../../services/database/proyecto';
import { getAvancesPeriodo } from '../../services/database/programacion';
import moment from 'moment';
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import axios from 'axios';
import formatNumber from 'format-number';
import { downloadUrlAsPromise, uploadFileToDrive } from '../../services/fileUpload';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { crearCarpetaDrive, postCarpeta } from '../../services/database/carpetas';
moment.locale('es');
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
export default function Reportabilidad(props) {

    const notisnack = useSnackbar();

    const { contrato, proyecto } = useSelector(state => ({ contrato: state.contratoSesion.contrato, proyecto: state.contratoSesion.proyecto }));

    const [cartas, setCartas] = useState(null);
    const [solicitudesInformacion, setSolicitudesInformacion] = useState(null);
    const [cargarIndicadores, setCargarIndicadores] = useState(false);
    const [conHorasHombre, setConHorasHombre] = useState(true);
    const [incidentes, setIncidentes] = useState(null);
    const [inicioSemana, setInicioSemana] = useState(moment().startOf("week"));
    const [inspecciones, setInspecciones] = useState(null);
    const [noConformidades, setNoConformidades] = useState(null);
    const [openReporteSemanal, setOpenReporteSemanal] = useState(false);
    const [reporteSemanal, setReporteSemanal] = useState(null);

    const loadData = async () => {
        const solicitudes_informacion = await getDataSolicitudesInformacion(proyecto);
        const incidentes = await getDataIncidentes(proyecto);
        const noConformidades = await getDataNoConformidades(proyecto);
        const cartas = await getDataCartas(proyecto);
        const inspecciones = await getDataInspecciones(proyecto, contrato);
        setSolicitudesInformacion(solicitudes_informacion);
        setIncidentes(incidentes);
        setNoConformidades(noConformidades);
        setCartas(cartas);
        setInspecciones(inspecciones);
        setCargarIndicadores(true);
    }

    const getDataSolicitudesInformacion = async (proyecto) => {
        const solicitudes_informacion = {
            cantidad: 0,
            espera_respuesta: 0
        };
        if (proyecto) {
            const data = await getSolicitudesInformacionProyecto(proyecto._id);
            let espera_respuesta = 0;
            for (let i = 0; i < data.length; i++) {
                if (data.estado === "En espera") espera_respuesta++;
            }
            solicitudes_informacion.cantidad = data.length;
            solicitudes_informacion.espera_respuesta = espera_respuesta;
        }
        return solicitudes_informacion;
    }

    const getDataIncidentes = async (proyecto) => {
        const incidentes = {
            cantidad: 0
        };
        if (proyecto) {
            const data = await getIncidentesProyecto(proyecto._id);
            incidentes.cantidad = data.length;
        }
        return incidentes;

    }


    const getDataNoConformidades = async (proyecto) => {
        const no_conformidades = {
            cantidad: 0,
            abiertas: 0
        };
        if (proyecto) {
            const data = await getNoConformidadesProyecto(proyecto._id);
            let abiertas = 0;
            for (let i = 0; i < data.length; i++) {
                if (data.estado === "Abierta") abiertas++;
            }
            no_conformidades.cantidad = data.length;
            no_conformidades.abiertas = abiertas;
        }
        return no_conformidades;
    }

    const getDataCartas = async (proyecto) => {
        const cartas = { cantidad: 0, abiertas: 0 };
        if (proyecto) {
            const data = await getCartasProyecto(proyecto._id);
            let abiertas = 0;
            for (let i = 0; i < data.length; i++) {
                if (data.estado === "Abierta") abiertas++;
            }
            cartas.cantidad = data.length;
            cartas.abiertas = abiertas;
        }
        return cartas;
    }

    const getDataInspecciones = async (proyecto, contrato) => {
        let data = {
            inspecciones_cerradas: 0,
            inspecciones_abiertas: 0
        };
        if (proyecto) {
            try {
                const response = await getInspeccionesProyecto({ nombre_proyecto: `${proyecto.codigo}-${proyecto.nombre}`, codigo_contrato: contrato.codigo, id_proyecto: proyecto._id });
                if (response) data = response;
            }
            catch (e) {
                console.log(e);
            }

        }
        return data;
    }

    const generarReporteSemanal = async () => {
        const key = notisnack.enqueueSnackbar("Generando Informe...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            const avancesPeriodo = await getAvancesPeriodo(proyecto.programa_actual_ref);
            const montoAcumulado = await getDataEstadosPago(proyecto);
            const solicitudesInformacion = await getDataSolicitudesInformacion(proyecto);
            const incidentes = await getDataIncidentes(proyecto);
            const noConformidades = await getDataNoConformidades(proyecto);
            const cartas = await getDataCartas(proyecto);
            const inspecciones = await getDataInspecciones(proyecto, contrato);
            const avancesChart = {
                avanceTempranoHH: [],
                avanceTardioHH: [],
                avanceRealHH: [],
                avanceProgramadoHH: [],
                avanceTempranoCostos: [],
                avanceTardioCostos: [],
                avanceRealCostos: [],
                avanceTempranoHH: [],
                avanceProgramadoCostos: [],
                hhReal: [],
                hhProgramado: [],
            }
            avancesPeriodo.dia.map((dia, index) => {
                avancesChart.avanceTempranoHH.push([dia, ((avancesPeriodo.avanceTempranoHH[index] || avancesPeriodo.avanceTempranoHH[index] === 0) ? parseFloat(avancesPeriodo.avanceTempranoHH[index].toFixed(3)) : null)]);
                avancesChart.avanceTardioHH.push([dia, ((avancesPeriodo.avanceTardioHH[index] || avancesPeriodo.avanceTardioHH[index] === 0) ? parseFloat(avancesPeriodo.avanceTardioHH[index].toFixed(3)) : null)]);
                avancesChart.avanceRealHH.push([dia, ((avancesPeriodo.avanceRealHH[index] || avancesPeriodo.avanceRealHH[index] === 0) ? parseFloat(avancesPeriodo.avanceRealHH[index].toFixed(3)) : null)]);
                avancesChart.avanceProgramadoHH.push([dia, ((avancesPeriodo.avanceProgramadoHH[index] || avancesPeriodo.avanceProgramadoHH[index] === 0) ? parseFloat(avancesPeriodo.avanceProgramadoHH[index].toFixed(3)) : null)]);

                avancesChart.avanceTempranoCostos.push([dia, ((avancesPeriodo.avanceTempranoCostos[index] || avancesPeriodo.avanceTempranoCostos[index] === 0) ? parseFloat(avancesPeriodo.avanceTempranoCostos[index].toFixed(3)) : null)]);
                avancesChart.avanceTardioCostos.push([dia, ((avancesPeriodo.avanceTardioCostos[index] || avancesPeriodo.avanceTardioCostos[index] === 0) ? parseFloat(avancesPeriodo.avanceTardioCostos[index].toFixed(3)) : null)]);
                avancesChart.avanceRealCostos.push([dia, ((avancesPeriodo.avanceRealCostos[index] || avancesPeriodo.avanceRealCostos[index] === 0) ? parseFloat(avancesPeriodo.avanceRealCostos[index].toFixed(3)) : null)]);
                avancesChart.avanceProgramadoCostos.push([dia, ((avancesPeriodo.avanceProgramadoCostos[index] || avancesPeriodo.avanceProgramadoCostos[index] === 0) ? parseFloat(avancesPeriodo.avanceProgramadoCostos[index].toFixed(3)) : null)]);

                avancesChart.hhReal.push([dia, ((avancesPeriodo.hhReal[index] || avancesPeriodo.hhReal[index] === 0) ? parseFloat(avancesPeriodo.hhReal[index].toFixed(3)) : null)]);
                avancesChart.hhProgramado.push([dia, ((avancesPeriodo.hhProgramado[index] || avancesPeriodo.hhProgramado[index] === 0) ? parseFloat(avancesPeriodo.hhProgramado[index].toFixed(3)) : null)]);
            });
            let xPlotLine = {};
            if (moment().valueOf() < avancesPeriodo.terminoPrograma) xPlotLine = {
                color: '#FF0000', // Red
                width: 2,
                value: moment().valueOf()
            };
            const chartOptions = {
                exporting: {
                    chartOptions: { // specific options for the exported image
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        }
                    },
                    fallbackToExportServer: false,
                    scale: 1,
                    sourceWidth: 1424,
                    sourceHeight: 400
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        day: '%e %b',
                        month: '%e %b %Y',
                        year: '%b %Y'
                    },
                    title: {
                        text: 'Tiempo'
                    },
                    plotLines: [
                        xPlotLine
                    ]
                },
                yAxis: [{
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Avance %'
                    },
                    labels: {
                        format: '{value}%',
                    },
                },
                {
                    min: 0,
                    title: {
                        text: 'Horas Hombre (HH)'
                    },
                    labels: {
                        format: '{value}',
                    },
                    opposite: true
                }
                ],
                title: {
                    text: 'Curva de Avance Físico'
                },
                series: [
                    {
                        name: "Avance Temprano",
                        type: 'spline',
                        yAxis: 0,
                        data: conHorasHombre ? avancesChart.avanceTempranoHH : avancesChart.avanceTempranoCostos,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                    },
                    {
                        name: "Avance Programado",
                        type: 'spline',
                        yAxis: 0,
                        data: conHorasHombre ? avancesChart.avanceProgramadoHH : avancesChart.avanceProgramadoCostos,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                    },
                    {
                        name: "Avance Tardío",
                        type: 'spline',
                        yAxis: 0,
                        data: conHorasHombre ? avancesChart.avanceTardioHH : avancesChart.avanceTardioCostos,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                    }
                    ,
                    {
                        name: "Avance Real",
                        type: 'spline',
                        yAxis: 0,
                        data: conHorasHombre ? avancesChart.avanceRealHH : avancesChart.avanceRealCostos,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                    }
                    ,
                    {
                        name: "HH Programado",
                        type: 'area',
                        yAxis: 1,
                        data: avancesChart.hhProgramado,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                    },
                    {
                        name: "HH Real",
                        yAxis: 1,
                        type: 'area',
                        data: avancesChart.hhReal,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                    }

                ],
                plotOptions: {
                    area: {
                        fillOpacity: 0.2,
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                margin: 0,

            };

            console.log(chartOptions);
            console.log(avancesPeriodo);

            const avancesPeriodoPodProyecto = await getAvancesPeriodoPodProyecto(
                proyecto._id,
                inicioSemana.format("YYYY-MM-DD"),
                inicioSemana.clone().endOf("week").format("YYYY-MM-DD")
            );

            avancesPeriodoPodProyecto["indicadores"] = avancesPeriodoPodProyecto.avancesSemana.reduce(
                (b, a) => {
                    b.avanceRealCostosPod = b.avanceRealCostosPod + a.avanceRealCostosPod;
                    b.avanceRealHHPod = b.avanceRealHHPod + a.avanceRealHHPod;
                    b.avanceProgramadoCostosPod = b.avanceProgramadoCostosPod + a.avanceProgramadoCostosPod;
                    b.avanceProgramadoHHPod = b.avanceProgramadoHHPod + a.avanceProgramadoHHPod;
                    b.avanceProgramadoCostos = b.avanceProgramadoCostos + a.avanceProgramadoCostos;
                    b.avanceProgramadoHH = b.avanceProgramadoHH + a.avanceProgramadoHH;
                    b.cumplimiento = b.cumplimiento + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
                    b.cumplimientoProgHH = b.cumplimientoProgHH + (a.cumplimientoProgHH ? parseFloat(a.cumplimientoProgHH) : 0);
                    b.cumplimientoProgCostos = b.cumplimientoProgCostos + (a.cumplimientoProgCostos ? parseFloat(a.cumplimientoProgCostos) : 0);
                    return b;
                }, {
                avanceRealCostosPod: 0,
                avanceRealHHPod: 0,
                avanceProgramadoCostosPod: 0,
                avanceProgramadoHHPod: 0,
                avanceProgramadoCostos: 0,
                avanceProgramadoHH: 0,
                cumplimiento: 0,
                cumplimientoProgHH: 0,
                cumplimientoProgHHCostos: 0,
            });

            if (avancesPeriodoPodProyecto.avancesSemana.length > 0) {
                avancesPeriodoPodProyecto.indicadores.cumplimiento = avancesPeriodoPodProyecto.indicadores.cumplimiento / avancesPeriodoPodProyecto.avancesSemana.length;
                avancesPeriodoPodProyecto.indicadores.cumplimientoProgHH = avancesPeriodoPodProyecto.indicadores.cumplimientoProgHH / avancesPeriodoPodProyecto.avancesSemana.length;
                avancesPeriodoPodProyecto.indicadores.cumplimientoProgCostos = avancesPeriodoPodProyecto.indicadores.cumplimientoProgCostos / avancesPeriodoPodProyecto.avancesSemana.length;
            }

            console.log(avancesPeriodoPodProyecto);
            // const avanceProgramadoHH = numberFormater((conHorasHombre ? avancesPeriodo.avanceProgramadoHH.toFixed(2) : avancesPeriodo.avanceProgramadoCostos.toFixed(2))) + "%"; 
            // console.log(avanceProgramadoHH);
            // return;
            const data = {
                fecha: moment().format("DD-MM-YYYY"),
                // parent: "",
                nombre_proyecto: proyecto.nombre,
                codigo_proyecto: proyecto.codigo,
                superintendencia: proyecto.superintendencia_ref ? proyecto.superintendencia_ref.nombre : "",
                ubicacion: proyecto.area,
                contratista: proyecto.contratista_ref ? proyecto.contratista_ref.nombre : "",
                monto_contrato: numberFormater(proyecto.monto_original),
                fecha_inicio: proyecto.fecha_inicio_original ? moment(new Date(proyecto.fecha_inicio_original)).format("DD-MM-YYYY") : "",
                fecha_termino: proyecto.fecha_termino_original ? moment(new Date(proyecto.fecha_termino_original)).format("DD-MM-YYYY") : "",
                inicio_programado: moment(avancesPeriodo.inicioProgramado).format("DD-MM-YYYY"),
                termino_programado: moment(avancesPeriodo.terminoProgramado).format("DD-MM-YYYY"),
                avance_programado: numberFormater((conHorasHombre ? avancesPeriodo.avanceProgramadoTotalHH.toFixed(2) : avancesPeriodo.avanceProgramadoTotalCostos.toFixed(2))) + "%",
                avance_real: numberFormater((conHorasHombre ? avancesPeriodo.avanceRealTotalHH.toFixed(2) : avancesPeriodo.avanceRealTotalCostos.toFixed(2))) + "%",
                desviacion: numberFormater((conHorasHombre ? avancesPeriodo.desviacionAvanceHH.toFixed(2) : avancesPeriodo.desviacionAvanceCostos.toFixed(2))),
                spi: numberFormater((conHorasHombre ? avancesPeriodo.spiAvanceHH.toFixed(2) : avancesPeriodo.spiAvanceCostos.toFixed(2))),
                duracion_programada: numberFormater(avancesPeriodo.duracionProgramada.toFixed(0)) + " días",
                duracion_real: numberFormater(avancesPeriodo.tiempoTranscurrido.toFixed(0)) + " días",
                horas_hombre_programadas: numberFormater(avancesPeriodo.hhProgramadasTotales.toFixed(0)),
                horas_hombre_reales: numberFormater(avancesPeriodo.hhRealesAcumuladas.toFixed(0)),
                monto_contrato: `$${numberFormater(proyecto.monto_original ? proyecto.monto_original.toFixed(0) : "")}`,
                monto_estados_pago: `$${numberFormater(montoAcumulado ? montoAcumulado.toFixed(0) : 0)}`,
                avance_financiero_programado: `${numberFormater((conHorasHombre ? avancesPeriodo.avanceFinancieroProgramadoHH.toFixed(0) : avancesPeriodo.avanceFinancieroProgramadoCostos.toFixed(0)))}`,
                avance_financiero_real: `${numberFormater(proyecto.monto_original ? ((montoAcumulado ? montoAcumulado : 0) / proyecto.monto_original * 100).toFixed(2) : 0)}`,
                avances: JSON.stringify(avancesPeriodoPodProyecto.avancesSemana.map(a => ({
                    fecha: moment(a.fecha).format("DD MMM"),
                    avance_programado: `${numberFormater((conHorasHombre ? a.avanceProgramadoHH.toFixed(2) : a.avanceProgramadoCostos.toFixed(2)))}%`,
                    avance_programado_pod: `${numberFormater((conHorasHombre ? a.avanceProgramadoHHPod.toFixed(2) : a.avanceProgramadoCostosPod.toFixed(2)))}%`,
                    avance_real_pod: `${numberFormater((conHorasHombre ? a.avanceRealHHPod.toFixed(2) : a.avanceRealCostosPod.toFixed(2)))}%`,
                    cumplimiento_programado: `${conHorasHombre ?
                        numberFormater((a.avanceProgramadoHH ? numberFormater((a.avanceRealHHPod / a.avanceProgramadoHH).toFixed(2)) : "0,00")) :
                        numberFormater((a.avanceProgramadoCostos ? numberFormater((a.avanceRealCostosPod / a.avanceProgramadoCostos).toFixed(2)) : "0,00"))}%`,
                    cumplimiento_programado_pod: `${numberFormater(a.cumplimiento)}%`,
                }))),
                avance_programado_semana: `${numberFormater((conHorasHombre ? avancesPeriodoPodProyecto.indicadores.avanceProgramadoHH.toFixed(2) : avancesPeriodoPodProyecto.indicadores.avanceProgramadoCostos.toFixed(2)))}%`,
                avance_programado_pod_semana: `${numberFormater((conHorasHombre ? avancesPeriodoPodProyecto.indicadores.avanceProgramadoHHPod.toFixed(2) : avancesPeriodoPodProyecto.indicadores.avanceProgramadoCostosPod.toFixed(2)))}%`,
                avance_real_pod_semana: `${numberFormater((conHorasHombre ? avancesPeriodoPodProyecto.indicadores.avanceRealHHPod.toFixed(2) : avancesPeriodoPodProyecto.indicadores.avanceRealCostosPod.toFixed(2)))}%`,
                cumplimiento_programado_semana: `${numberFormater((conHorasHombre ? avancesPeriodoPodProyecto.indicadores.cumplimientoProgHH.toFixed(2) : avancesPeriodoPodProyecto.indicadores.cumplimientoProgCostos.toFixed(2)))}%`,
                cumplimiento_programado_pod_semana: `${numberFormater(avancesPeriodoPodProyecto.indicadores.cumplimiento.toFixed(2))}%`,
                cumplimiento_promedio_pod_semana: `${numberFormater((avancesPeriodoPodProyecto.cumplimientoPromedio.toFixed(2) ? avancesPeriodoPodProyecto.cumplimientoPromedio.toFixed(2) : 0))}%`,
                cartas: cartas.cantidad,
                cartas_abiertas: cartas.abiertas,
                no_conformidades: noConformidades.cantidad,
                no_conformidades_abiertas: noConformidades.abiertas,
                solicitudes_informacion: solicitudesInformacion.cantidad,
                solicitudes_informacion_abiertas: solicitudesInformacion.espera_respuesta,
                incidentes: incidentes.cantidad,
                inspecciones_abiertas: inspecciones.inspecciones_abiertas,
                inspecciones_emitidas: inspecciones.inspecciones_cerradas + inspecciones.inspecciones_abiertas

            };
            console.log(data);
            // return;
            const bodyFormData = new FormData();
            for (let key in data) {
                bodyFormData.set(key, data[key]);
            }
            let carpeta = proyecto.carpetas.find(a => a.nombre === "Reportes");
            if (!carpeta) {
                const carpetaDriveApp = await crearCarpetaDrive({
                    name: `Reportes`,
                    parent: proyecto.carpeta_proyecto.carpeta_id
                });
                carpeta = await postCarpeta({
                    nombre: `Reportes`,
                    descripción: ``,
                    padre_ref: proyecto.carpeta_proyecto._id,
                    carpeta_id: carpetaDriveApp.id
                });
                proyecto.carpetas.push(carpeta);
                await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
            }
            // const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" };
            // data.parent = carpeta.carpeta_id;
            bodyFormData.append("parent", carpeta.carpeta_id);
            const exportUrl = 'https://export.highcharts.com/';
            axios.post(exportUrl,
                {
                    options: chartOptions,
                    fileName: "chart",
                    type: 'image/png',
                    async: true
                })
                .then(response => {
                    return downloadUrlAsPromise(exportUrl + response.data, "chart");
                })
                .then(response => {
                    return uploadFileToDrive(new File([response.blob], "chart.png"), "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", "chart.png");
                })
                .then(response => {
                    bodyFormData.append("file", response.id);
                    return axios({
                        method: 'post',
                        url: "https://script.google.com/macros/s/AKfycbzB7-i9TQTHpPlEwKkCNWaN1kFFNYrgzn1Ryggq9DnzDKtyVYRs/exec",
                        data: bodyFormData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                })
                .then(response => {
                    notisnack.closeSnackbar(key);
                    if (response.data) {
                        setReporteSemanal(response.data);
                        setOpenReporteSemanal(true);
                    } else {
                        const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible generar el Reporte semanal. Intente de nuevo o contacte con soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log(error);
            notisnack.closeSnackbar(key);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible generar el Reporte semanal. Intente de nuevo o contacte con soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });

        }
    }

    const getDataEstadosPago = async (proyecto) => {

        let monto = 0;
        if (proyecto) {
            const estadosPagoProyecto = await getEstadosPagosProyecto(proyecto._id);
            for (let key in estadosPagoProyecto) {
                monto = monto + estadosPagoProyecto[key].monto;
            }
        }
        return monto;
    }

    useEffect(() => {
        loadData();
    }, [proyecto]);

    return (
        <Page
            cartas={cartas}
            cargarIndicadores={cargarIndicadores}
            generarReporteSemanal={generarReporteSemanal}
            incidentes={incidentes}
            inspecciones={inspecciones}
            noConformidades={noConformidades}
            openReporteSemanal={openReporteSemanal}
            proyecto={proyecto}
            reporteSemanal={reporteSemanal}
            setOpenReporteSemanal={setOpenReporteSemanal}
            solicitudesInformacion={solicitudesInformacion}
        />
    );
}
