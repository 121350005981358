import axios from "axios";

export const getItemProgramaSemanal = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        axios.get(`${base}/items-programas-semanales/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const getItemsProgramasSemanales = async (params = {}) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        axios.get(`${base}/items-programas-semanales/`, {
            params: params
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}
export const updateItemProgramaSemanal = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        axios.put(`${base}/items-programas-semanales/${id}`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const deleteItemProgramaSemanal = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        axios.delete(`${base}/items-programas-semanales/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const postItemProgramaSemanal = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        axios.post(`${base}/items-programas-semanales/`, body)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}