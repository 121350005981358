import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from './page';
import { viewDriveFile } from '../../../services/fileUpload';
import { getReportesSemanalesPod, getReporteSemanalPod } from '../../../services/database/reportesSemanalesPod';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function ReportesSemanalesPod(props) {
    const [data, setData] = useState([]);
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [openGenerarReporteSemanal, setOpenGenerarReporteSemanal] = useState(false);
    // const [openEliminarReporteSemanal, setOpenEliminarReporteSemanal] = useState(false);
    const notisnack = useSnackbar();
    const { usuarioSesion, proyecto, inicioSemanaReportePod } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
        inicioSemanaReportePod: state.reducerPod.inicioSemanaReportePod
    }));
    const [openEliminarReporteSemanalPod, setOpenEliminarReporteSemanalPod] = useState(false);
    const [reporteSemanalPodSeleccionado, setReporteSemanalPodSeleccionado] = useState({});

    useEffect(() => {
        cargarData();
        setDataIsLoading(true);
    }, [proyecto]);

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (proyecto) {
            const reportesProyecto = await getReportesSemanalesPod({ proyecto_ref: proyecto._id });
            if (reportesProyecto) {
                setData(reportesProyecto);
            }else{
                setData([]);
                
            }
        }else{
            setData([]);
        }
        setDataIsLoading(false);
    }

    const handleViewExcel = (event, rowData) => viewDriveFile(rowData.archivo_excel_ref.archivo_id, rowData.archivo_excel_ref.nombre);

    const handleViewPDF = (event, rowData) => viewDriveFile(rowData.archivo_pdf_ref.archivo_id, rowData.archivo_pdf_ref.nombre);

    const handleGenerarInformeSemanalPod = async (values) => {
        setOpenGenerarReporteSemanal(false);
        cargarData();
    }

    const handleOpenGenerarReporteSemanal = async () => {
        const reporteAnterior = await getReportesSemanalesPod({ proyecto_ref: proyecto._id, fecha_inicio: inicioSemanaReportePod });
        if (reporteAnterior && reporteAnterior[0]) {
            const errorkey = notisnack.enqueueSnackbar("Ya existe un reporte generado para la fecha seleccionada.", {
                variant: 'warning',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
        else {
            setOpenGenerarReporteSemanal(true);
        }
    }

    const handleCloseEliminarReporteSemanalPod = ()=>{
        setReporteSemanalPodSeleccionado({});
        setOpenEliminarReporteSemanalPod(false);
    }
    const handleOpenEliminarReporteSemanalPod = (rowData)=>{
        setReporteSemanalPodSeleccionado(rowData);
        setOpenEliminarReporteSemanalPod(true);
    }
    const handleSubmitEliminarReporteSemanalPod = ()=>{
        setReporteSemanalPodSeleccionado({});
        setOpenEliminarReporteSemanalPod(false);
        cargarData();
    }

    return (
        <Page
            data={data}
            dataIsLoading={dataIsLoading}
            handleCloseEliminarReporteSemanalPod={handleCloseEliminarReporteSemanalPod}
            handleGenerarInformeSemanalPod={handleGenerarInformeSemanalPod}
            handleOpenEliminarReporteSemanalPod={handleOpenEliminarReporteSemanalPod}
            handleOpenGenerarReporteSemanal={handleOpenGenerarReporteSemanal}
            handleSubmitEliminarReporteSemanalPod={handleSubmitEliminarReporteSemanalPod}
            handleViewExcel={handleViewExcel}
            handleViewPDF={handleViewPDF}
            inicioSemanaReportePod={inicioSemanaReportePod}
            openEliminarReporteSemanalPod={openEliminarReporteSemanalPod}
            openGenerarReporteSemanal={openGenerarReporteSemanal}
            proyecto={proyecto}
            reporteSemanalPodSeleccionado={reporteSemanalPodSeleccionado}
            // semanaGenerarReportePod={semanaGenerarReportePod}
            // setOpenEliminarReporteSemanal={setOpenEliminarReporteSemanal}
            setOpenGenerarReporteSemanal={setOpenGenerarReporteSemanal}
            // setSemanaGenerarReportePod={setSemanaGenerarReportePod}
            usuarioSesion={usuarioSesion}
        />
    );
}