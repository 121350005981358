/**
 * Reducer de acciones de reportes pod diarios
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import {
  OPEN_AGREGAR_REPORTE_POD_DIARIO,
  CLOSE_AGREGAR_REPORTE_POD_DIARIO,
  OPEN_ELIMINAR_REPORTE_POD_DIARIO,
  CLOSE_ELIMINAR_REPORTE_POD_DIARIO,
  OPEN_AGREGAR_LINEA_BASE,
  CLOSE_AGREGAR_LINEA_BASE,
  OPEN_ELIMINAR_LINEA_BASE,
  CLOSE_ELIMINAR_LINEA_BASE,
  ACTUALIZAR_GRAFICO_LINEA_BASE_SEMANAL,
  OPEN_AGREGAR_DIA_LINEA_BASE,
  CLOSE_AGREGAR_DIA_LINEA_BASE,
  OPEN_EDITAR_DIA_LINEA_BASE,
  CLOSE_EDITAR_DIA_LINEA_BASE,
  OPEN_ELIMINAR_DIA_LINEA_BASE,
  CLOSE_ELIMINAR_DIA_LINEA_BASE,
  ACTUALIZAR_TABLA_LINEA_BASE,
  ACTUALIZAR_TABLA_DIA_LINEA_BASE,
  ACTUALIZAR_TABLA_REPORTE_POD_DIARIO
} from './actionsReportePodDiario';

const defaultState = {
  openAgregarReportePodDiario: false,
  handleSubmitAgregarReportePodDiario: null,
  openEliminarReportePodDiario: false,
  handleSubmitEliminarReportePodDiario: null,
  openAgregarLineaBase: false,
  handleSubmitAgregarLineaBase: null,
  reportePodDiarioSeleccionado: null,
  lineaBaseSeleccionada: null,
  openEliminarLineaBase: false,
  handleSubmitEliminarLineaBase: null,
  actualizarGraficoLineabaseSemanal: true,
  openAgregarDiaLineaBase: false,
  handleSubmitAgregarDiaLineaBase: null,
  openEliminarDiaLineaBase: false,
  handleSubmitEliminarDiaLineaBase: null,
  openEditarDiaLineaBase: false,
  handleSubmitEditarDiaLineaBase: null,
  diaLineaBaseSeleccionado: null,
  actualizarTablaLineaBase: false,
  actualizarTablaDiaLineaBase: false,
  actualizarTablaReportePodDiario: false
};

export default function reducerReporteDiarioPod(state = defaultState, {
  type,
  reportePodDiarioSeleccionado,
  lineaBaseSeleccionada,
  handleSubmitAgregarReportePodDiario,
  handleSubmitEliminarReportePodDiario,
  handleSubmitAgregarLineaBase,
  handleSubmitEliminarLineaBase,
  actualizarGraficoLineabaseSemanal,
  handleSubmitAgregarDiaLineaBase,
  handleSubmitEliminarDiaLineaBase,
  handleSubmitEditarDiaLineaBase,
  diaLineaBaseSeleccionado,
  actualizarTablaLineaBase,
  actualizarTablaDiaLineaBase,
  actualizarTablaReportePodDiario
}) {
  switch (type) {
    case OPEN_AGREGAR_REPORTE_POD_DIARIO: {
      return { ...state, openAgregarReportePodDiario: true, handleSubmitAgregarReportePodDiario: handleSubmitAgregarReportePodDiario };
    }
    case CLOSE_AGREGAR_REPORTE_POD_DIARIO: {
      return { ...state, openAgregarReportePodDiario: false, handleSubmitAgregarReportePodDiario: null };
    }
    case OPEN_ELIMINAR_REPORTE_POD_DIARIO: {
      return { ...state, openEliminarReportePodDiario: true, reportePodDiarioSeleccionado: reportePodDiarioSeleccionado, handleSubmitEliminarReportePodDiario: handleSubmitEliminarReportePodDiario };
    }
    case CLOSE_ELIMINAR_REPORTE_POD_DIARIO: {
      return { ...state, openEliminarReportePodDiario: false, reportePodDiarioSeleccionado: null, handleSubmitEliminarReportePodDiario: null };
    }
    case OPEN_AGREGAR_LINEA_BASE: {
      return { ...state, openAgregarLineaBase: true, handleSubmitAgregarLineaBase: handleSubmitAgregarLineaBase };
    }
    case CLOSE_AGREGAR_LINEA_BASE: {
      return { ...state, openAgregarLineaBase: false, handleSubmitAgregarLineaBase: null };
    }
    case OPEN_ELIMINAR_LINEA_BASE: {
      return { ...state, openEliminarLineaBase: true, lineaBaseSeleccionada: lineaBaseSeleccionada, handleSubmitEliminarLineaBase: handleSubmitEliminarLineaBase };
    }
    case CLOSE_ELIMINAR_LINEA_BASE: {
      return { ...state, openEliminarLineaBase: false, lineaBaseSeleccionada: null, handleSubmitEliminarLineaBase: null };
    }
    case OPEN_AGREGAR_DIA_LINEA_BASE: {
      return { ...state, openAgregarDiaLineaBase: true, handleSubmitAgregarDiaLineaBase: handleSubmitAgregarDiaLineaBase };
    }
    case CLOSE_AGREGAR_DIA_LINEA_BASE: {
      return { ...state, openAgregarDiaLineaBase: false, handleSubmitAgregarDiaLineaBase: null };
    }
    case OPEN_ELIMINAR_DIA_LINEA_BASE: {
      return { ...state, openEliminarDiaLineaBase: true, diaLineaBaseSeleccionado: diaLineaBaseSeleccionado, handleSubmitEliminarDiaLineaBase: handleSubmitEliminarDiaLineaBase };
    }
    case CLOSE_ELIMINAR_DIA_LINEA_BASE: {
      return { ...state, openEliminarDiaLineaBase: false, diaLineaBaseSeleccionado: null, handleSubmitEliminarDiaLineaBase: null };
    }
    case OPEN_EDITAR_DIA_LINEA_BASE: {
      return { ...state, openEditarDiaLineaBase: true, diaLineaBaseSeleccionado: diaLineaBaseSeleccionado, handleSubmitEditarDiaLineaBase: handleSubmitEditarDiaLineaBase };
    }
    case CLOSE_EDITAR_DIA_LINEA_BASE: {
      return { ...state, openEditarDiaLineaBase: false, diaLineaBaseSeleccionado: null, handleSubmitEditarDiaLineaBase: null };
    }
    case ACTUALIZAR_GRAFICO_LINEA_BASE_SEMANAL:{
      return{ ...state, actualizarGrafico: actualizarGraficoLineabaseSemanal}
    }
    case ACTUALIZAR_TABLA_DIA_LINEA_BASE:{
      return{ ...state, actualizarTablaDiaLineaBase: actualizarTablaDiaLineaBase}
    }
    case ACTUALIZAR_TABLA_LINEA_BASE:{
      return{ ...state, actualizarTablaLineaBase: actualizarTablaLineaBase}
    }
    case ACTUALIZAR_TABLA_REPORTE_POD_DIARIO:{
      return{ ...state, actualizarTablaReportePodDiario: actualizarTablaReportePodDiario}
    }
    default:
      return state;
  }
}