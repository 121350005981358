import React, { useState, Fragment } from 'react';
import {
    Button
} from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import { makeStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert } from '@material-ui/icons';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AgregarReprogramacion from '../agregarReprogramacion';
import AgregarReprogramacionDesdeArchivo from '../agregarReprogramacionDesdeArchivo';
import EliminarReprogramacion from '../eliminarReprogramacion';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Page(props) {

    const classes = useStyles();

    const {
        data,
        handleDescargarFormato,
        handleOpenAgregarReprogramacion,
        handleOpenEliminarReprogramacion,
        handleSubmitAgregarReprogramacion,
        handleViewArchivo,
        isLoading
    } = props;
    const [pageSize, setPageSize] = useState(10);



    return (
        <Fragment>
            <MaterialTable
                title={`Listado de Reprogramaciones`}
                options={{
                    filtering: false,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false,
                    columnsButton: true,
                    maxBodyHeight: "70vh",
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        border: "1px solid #E0E0E0",
                        fontWeight: 'bold',
                        color: '#731f1f',
                        position: 'sticky'
                    },
                    rowStyle: {
                        border: "1px solid #E0E0E0"
                    },
                    padding: 'dense',
                    actionsCellStyle: {
                        width: "2%",
                        textAlign: "center"
                    }
                }}
                onChangeRowsPerPage={size => { setPageSize(size) }}
                localization={location}
                isLoading={isLoading}
                actions={[
                    {
                        icon: "reprogramacion",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        icon: "formato_reprogramacion",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    // {
                    //     icon: "generar_pod",
                    //     isFreeAction: true,
                    //     onClick: (evt) => { }
                    // },
                    {
                        tooltip: 'Administrar',
                        icon: 'settings',
                        onClick: () => { }
                    },
                ]}
                components={{
                    Action:
                        props => {
                            const rowData = props.data;
                            if (props.action.icon === 'reprogramacion') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleOpenAgregarReprogramacion()}
                                    >
                                        Nueva Reprogramación
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'formato_reprogramacion') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleDescargarFormato(evt, null)}
                                    >
                                        Descargar Formato
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'settings') {
                                const accionesConfiguracion = [
                                    // {
                                    //     content: 'Ver Programa',
                                    //     onClick: (e) => goTo(`/programacion-semanal/${rowData._id}`)
                                    // }
                                ];
                                if (rowData.archivo) accionesConfiguracion.push({
                                    content: 'Ver Archivo',
                                    onClick: (e) => handleViewArchivo(rowData)
                                });
                            //     if (usuarioSesion && usuarioSesion.permisos) {
                                    accionesConfiguracion.push({
                                        content: 'Eliminar',
                                        onClick: (e) => handleOpenEliminarReprogramacion(rowData)
                                    });
                            //     }
                                if (accionesConfiguracion.length > 0) return (
                                    <MenuDropdown
                                        icon={
                                            <MoreVert />
                                        }
                                        tooltip="Opciones"
                                        items={accionesConfiguracion}
                                        hidden={accionesConfiguracion.length <= 0}
                                    />
                                );
                            }
                            return (<MTableAction {...props} />);
                        }
                }}
                columns={[
                    {
                        title: 'Fecha',
                        field: 'fecha',
                        render: rowData => {
                            return rowData.fecha ? generarFechaView(rowData.fecha, "DD/MM/YYYY") : "";
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Inicio',
                        field: 'inicio_reprogramacion',
                        render: rowData => {
                            return rowData.inicio_reprogramacion ? generarFechaView(rowData.inicio_reprogramacion, "DD/MM/YYYY") : "";
                        }
                    },
                    {
                        title: 'Término',
                        field: 'termino_reprogramacion',
                        render: rowData => {
                            return rowData.termino_reprogramacion ? generarFechaView(rowData.termino_reprogramacion, "DD/MM/YYYY") : "";
                        }
                    }
                ]}
                data={data}
            />
            <AgregarReprogramacion
                handleSubmit={handleSubmitAgregarReprogramacion}
            />
            <AgregarReprogramacionDesdeArchivo
                handleSubmit={handleSubmitAgregarReprogramacion}
            />
            <EliminarReprogramacion
                handleSubmit={handleSubmitAgregarReprogramacion}
            />
        </Fragment>
    );
}

function generarFechaView(data, format) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }

}