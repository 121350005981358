import React from 'react';
import Page from './page';
import moment from 'moment';
moment.locale('es');


export default function Reprogramaciones(props) {

    return (
        <Page
        />
    );
}