/**
 * Componente con formulario para agregar un nuevo reporte pod diario
 * Se carga la información desde un archivo excel que contiene la información
 * de cada día del reporte.
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { downloadUrlAsPromise, uploadFileToDrive } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { closeAgregarReportePodDiario } from '../actionsReportePodDiario';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { postReportePodDiario } from '../requestsResportesPodDiarios';
import { crearCarpetaDrive, postCarpeta } from '../../../services/database/carpetas';
import { updateProyecto } from '../../../services/database/proyecto';

/**
 * Reducir la cantidad de decimales de un número
 * @param {*} num 
 * @param {*} cantidadDecimales 
 */
function reducirDecimales(num, cantidadDecimales) {
    if (num) return parseFloat(num.toFixed(cantidadDecimales));
    else return null;
}

export default function AgregarReportePodDiario(props) {
    const { usuarioSesion, proyecto, open, handleSubmit } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
        open: state.reducerReportePodDiario.openAgregarReportePodDiario,
        handleSubmit: state.reducerReportePodDiario.handleSubmitAgregarReportePodDiario
    }));
    const notistack = useSnackbar();
    const dispatch = useDispatch();
    const [formik, setFormik] = useState(null);
    const [hojas, setHojas] = useState(null);
    const [file, setFile] = useState(null);
    const validationSchema = Yup.object({
        // hoja: Yup.string().required("Debe seleccionar una hoja"),
        excel: Yup.mixed().required("Debe seleccionar un archivo")
    });
    /**
     * Campos del formulario
     */
    const values = {
        cumplimiento_pod: 0,
        cumplimiento_trisemanal: 0,
        fecha: null,
        excel: null,
        fecha_emision: null,
        hh_ganado_pod: 0,
        hh_ganado_programa: 0,
        hh_programado_pod: 0,
        hh_programado_trisemanal: 0,
        hoja: "",
        porcentaje_ganado_programa: 0,
        porcentaje_programado_pod: 0,
        porcentaje_programado_trisemanal: 0,
        proyecto_ref: proyecto ? proyecto._id : null
    };

    /**
     * Al mostrar el dialago, se limpian los campos del formulario
     */
    const handleEnterDialog = async () => {
        formik.resetForm({
            cumplimiento_pod: 0,
            cumplimiento_trisemanal: 0,
            comentario: "",
            fecha: null,
            excel: null,
            fecha_emision: null,
            hh_ganado_pod: 0,
            hh_ganado_programa: 0,
            hh_programado_pod: 0,
            hh_programado_trisemanal: 0,
            hoja: "",
            porcentaje_ganado_programa: 0,
            porcentaje_programado_pod: 0,
            porcentaje_programado_trisemanal: 0,
            proyecto_ref: proyecto ? proyecto._id : null
        });
        setHojas(null);
        setFile(null);

    }
    /**
     * Manejar cambio de valores de campo de formulario
     * @param {String} name nombre del campo modificado
     * @param {Event} e event que gatilla el cambio de un valor
     * @param {*} e.target.value valor nuevo del campo
     */
    const handleChange = (name, e) => {
        formik.setFieldValue(name, e.target.value);
        formik.setFieldTouched(name, true);
        if (name === 'hoja') handleChangeHoja(e.target.value);
    };
    /**
     * Manejar cambio de valores de fechas de formulario
     * @param {String} name nombre del campo modificado
     * @param {Date} e nuevo valor de fecha
     */
    const handleDateChange = (name, e) => {
        formik.setFieldValue(name, e);
        formik.setFieldTouched(name, true);
    };
    /**
     * Manejar cambio de valores de campos file del formulario
     * @param {String} name nombre del campo modificado
     * @param {Event} e event que gatilla el cambio de un valor
     * @param {*} e.target.value valor nuevo del campo
    */
    const handelFileChange = (name, e) => {
        previewXLSX(e.target.files[0]);
        formik.setFieldValue(name, e.target.files[0]);
        formik.setFieldTouched(name, true, false);
        setFile(e.target.files[0]);
    };

    const handleChangeHoja = (hoja) => {
        if (hoja && file) {
            let fileReader = new FileReader();
            fileReader.onload = async (e) => {
                const binary = new Uint8Array(e.target.result);
                const workbook = XLSX.read(binary, { type: 'array' });
                const worksheet = workbook.Sheets[hoja];
                if (worksheet) {
                    const columns = ["D", "E", "F", "G", "H", "I", "J"];
                    const fecha = new Date(worksheet["D10"].w);
                    if (fecha) {
                        formik.setFieldValue("fecha", fecha);
                        formik.setFieldValue("fecha_emision", new Date(worksheet["I10"].w));
                        for (let i = 0; i < columns.length; i++) {
                            const column = columns[i];
                            let desired_row = "18";
                            let desired_cell = worksheet[column + desired_row];
                            let desired_value = (desired_cell ? desired_cell.w : undefined);
                            if (desired_value && moment(new Date(desired_value)).isSame(moment(fecha))) {
                                formik.setFieldValue("cumplimiento_pod", worksheet[column + "27"] ? reducirDecimales(worksheet[column + "27"].v, 4) * 100 : 0);
                                formik.setFieldValue("cumplimiento_trisemanal", worksheet[column + "26"] ? reducirDecimales(worksheet[column + "26"].v, 4) * 100 : 0);
                                formik.setFieldValue("hh_ganado_pod", worksheet[column + "25"] ? reducirDecimales(worksheet[column + "25"].v, 4) : 0);
                                formik.setFieldValue("hh_ganado_programa", worksheet[column + "23"] ? reducirDecimales(worksheet[column + "23"].v, 4) : 0);
                                formik.setFieldValue("hh_programado_pod", worksheet[column + "21"] ? reducirDecimales(worksheet[column + "21"].v, 4) : 0);
                                formik.setFieldValue("hh_programado_trisemanal", worksheet[column + "19"] ? reducirDecimales(worksheet[column + "19"].v, 4) : 0);
                                formik.setFieldValue("porcentaje_ganado_programa", worksheet[column + "24"] ? reducirDecimales(worksheet[column + "24"].v, 4) * 100 : 0);
                                formik.setFieldValue("porcentaje_programado_pod", worksheet[column + "22"] ? reducirDecimales(worksheet[column + "22"].v, 4) * 100 : 0);
                                formik.setFieldValue("porcentaje_programado_trisemanal", worksheet[column + "20"] ? reducirDecimales(worksheet[column + "20"].v, 4) * 100 : 0);
                                break;
                            }
                        }
                        for (let i = 30; i < 37; i++) {
                            let desired_row = i;
                            let desired_cell = worksheet["B" + desired_row];
                            let desired_value = (desired_cell ? desired_cell.w : undefined);
                            if (desired_value && moment(new Date(desired_value)).isSame(moment(fecha))) {
                                formik.setFieldValue("comentario", worksheet["D" + desired_row] ? worksheet["D" + desired_row].w : "");
                                break;
                            }
                        }
                    }
                }
            };
            fileReader.readAsArrayBuffer(file);
        }
    }

    /**
     * Comenzar carga de archivo a drive y guardar reporte en base de datos
     * @param {Object} file archivo excel con información del reporte pod diario
     * @param {OBject} reportePodDiario datos del reporte pod diario
     */
    const handleOnSubmit = async (values) => {
        const key = notistack.enqueueSnackbar("Guardando Reporte Diario...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            let carpeta = proyecto.carpetas.find(a => a.nombre === "Reportes Diarios");
            if (!carpeta) {
                const carpetaDriveApp = await crearCarpetaDrive({
                    name: `Reportes Diarios`,
                    parent: proyecto.carpeta_proyecto.carpeta_id
                });
                carpeta = await postCarpeta({
                    nombre: `Reportes Diarios`,
                    descripción: ``,
                    padre_ref: proyecto.carpeta_proyecto._id,
                    carpeta_id: carpetaDriveApp.id
                });
                proyecto.carpetas.push(carpeta);
                await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
            }
            // const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" }
            const uploadedFile = await uploadFileToDrive(values.excel, carpeta.carpeta_id, values.excel.name);
            return postReportePodDiario({
                ...values,
                file: null,
                archivo: {
                    nombre: uploadedFile.name,
                    archivo_id: uploadedFile.id,
                    carpeta_id: carpeta.carpeta_id,
                    carpeta_ref: carpeta._id,
                    size: values.excel.size,
                    repositorio: "DRIVE",
                    url: uploadedFile.url
                }
            })
                .then((response) => {
                    if (response.status === 500) {
                        console.log("error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Reporte Diario. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    } else if (response.status === 422) {
                        console.log("error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Reporte Diario. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    } else if (response.status === 201) {
                        notistack.closeSnackbar(key);
                        const successkey = notistack.enqueueSnackbar("Operanción exitosa: Reporte Diario guardado", {
                            variant: 'success',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
                        });
                        if (handleSubmit) handleSubmit();
                    } else {
                        console.log("unhandled error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Reporte Diario. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    notistack.closeSnackbar(key);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Reporte Diario. Contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                });
        }
        catch (error) {
            console.log(error);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Reporte Diario. Contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    /**
     * Precargar excel y extraer el nombre de sus hojas
     * @return {void}
     */
    const previewXLSX = async (file) => {
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.')[1];
            console.log(fileExtension)
            console.log(["xls", "xlsx", "xlsm"].findIndex((a) => fileExtension));
            if (["xls", "xlsx", "xlsm"].findIndex((a) => a === fileExtension) !== -1) {
                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    const binary = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(binary, { type: 'array' });
                    const hojasXLSX = workbook.SheetNames.map((value, index) => ({
                        nombre: value,
                        value: index
                    }));
                    setHojas(hojasXLSX);
                };
                fileReader.readAsArrayBuffer(file);
            }
            else setHojas(null);
        } else {
            setHojas(null);
        }
    }

    /**
     * Descargar formato de reporte pod diario
     * @return {void}
     */
    const handleDescargarFormato = async () => {
        const nombre = "Formato Reporte Pod Diario.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/13I9P7tfyH5ila74_XuXJA7z3IdI4In1w?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    /**
     * Cerrar dialogo
     */
    const handleClose = () => dispatch(closeAgregarReportePodDiario());

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                render={formikProps =>
                    <Page
                        {...formikProps}
                        open={open}
                        titulo="Reporte Diario"
                        handleChange={handleChange}
                        handleClose={handleClose}
                        handleDateChange={handleDateChange}
                        handleEnterDialog={handleEnterDialog}
                        handelFileChange={handelFileChange}
                        previewXLSX={previewXLSX}
                        hojas={hojas}
                        handleDescargarFormato={handleDescargarFormato}
                    />}
            />
        </React.Fragment>
    );
}