import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Button,
    Typography,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Grid,
    Container,
    Paper,
    FormControl,
    Select,
    InputLabel,
    TextField,
    InputAdornment
} from '@material-ui/core';
import MaterialTable, { MTableActions, MTableAction } from 'material-table';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert, Edit } from '@material-ui/icons';
import Moment from 'react-moment';
import * as ROUTES from '../../../constants/routes';
import './style.css';
import AgregarActividadPod from './AgregarActividadPod';
import EditarActividadPod from './EditarActividadPod';
import DialogoGenerPod from './dialogoGenerarPod';
import { Link } from 'react-router-dom';
import moment from 'moment';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        flexGrow: 1
    },
    mainPrograma: {
        flexGrow: 1
    },
    cardHeaderTypography: {
        minWidth: "360px",
        padding: theme.spacing(2)
    },
    gridCardTitle: {
        marginBottom: theme.spacing(2)
    },
    gridCardBody: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    paperContainerButtons: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),

    }
}));


function Tabla(props) {

    const classes = useStyles();

    const {
        data
    } = props;
    const [pageSize, setPageSize] = useState(10);

    return (
        <MaterialTable
            title={`Listado de items del POD`}
            options={{
                filtering: false,
                pageSizeOptions: [5, 10, 20, 50],
                pageSize: pageSize,
                grouping: false,
                columnsButton: true,
                toolbarButtonAlignment: "left",
                searchFieldAlignment: "left",
                padding: 'dense',
                maxBodyHeight: "70vh",
                // minBodyHeight: "450px",
                headerStyle: {
                    position: 'sticky',
                    // top: "64px",
                    textAlign: 'center',
                    border: "1px solid #E0E0E0",
                    fontWeight: 'bold',
                    color: '#731f1f',
                    minWidth: "150px"

                    // padding: '8px'
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.is_hoja ? '#fff' : '#f1f2f6'),
                    border: "1px solid #E0E0E0"
                }),
                // fixedColumns: {
                //     left: 2,
                // },
                // tableLayout: 'fixed',
                emptyRowsWhenPaging: false,

            }}
            // parentChildData={(row, rows) => {
            //     return rows.find(a => a.item === row.itemPadre)
            // }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={location}
            actions={[
                {
                    tooltip: 'Agregar Actividad',
                    icon: 'add_circle',
                    isFreeAction: true,
                    iconProps: {
                        className: classes.success
                    },
                    onClick: (evt) => props.setOpenAgregarActividad(true)
                },
                {
                    tooltip: 'Acciones',
                    icon: 'settings',
                    onClick: (evt) => { }
                }
            ]}
            components={{
                Action:
                    rowProps => {
                        const rowData = rowProps.data;
                        if (rowProps.action.icon === 'settings') {
                            const accionesConfiguracion = [
                                {
                                    content: 'Eliminar',
                                    onClick: (e) => props.handleOpenEliminarActividad(e, rowData),
                                    _id: rowData._id
                                },
                                {
                                    content: 'Editar',
                                    onClick: (e) => props.handleOpenEditarActividad(e, rowData),
                                    _id: rowData._id
                                }

                            ];
                            if (accionesConfiguracion.length > 0) return (
                                <MenuDropdown
                                    icon={
                                        <MoreVert />
                                    }
                                    tooltip="Acciones"
                                    items={accionesConfiguracion}
                                    hidden={!rowData.is_hoja}
                                />
                            );
                        }
                        return (<MTableAction {...rowProps} />);
                    }
            }}
            columns={[
                {
                    title: 'Ítem',
                    field: 'item',
                    cellStyle: {
                        width: '5%',
                        borderLeft: '1px solid #E0E0E0',
                    },
                    defaultSort: 'asc'
                },
                {
                    title: 'ID',
                    field: 'id_item',
                    cellStyle: {
                        width: '5%',
                        borderLeft: '1px solid #E0E0E0',
                    },
                },
                {
                    title: 'Actividad',
                    field: 'nombre',
                    cellStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'HH',
                    field: 'horas_hombre',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'Unidad',
                    field: 'unidad_cantidad_cumplir',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                    render: rowData => rowData.is_hoja ? rowData.unidad_cantidad_cumplir : ""
                },
                {
                    title: 'Cantidad',
                    field: 'cantidad',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render: rowData => rowData.is_hoja ? rowData.cantidad : ""
                },
                {
                    title: 'Cantidad Prog. Turno',
                    field: 'cantidad_programada',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => rowData.is_hoja ? (numberFormater((rowData.cantidad_programada ? parseFloat(rowData.cantidad_programada) : 0).toFixed(2))) : ""

                },
                {
                    title: 'Total HH asignadas',
                    field: 'horas_hombre_programadas',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => rowData.is_hoja ? numberFormater((rowData.horas_hombre_programadas ? parseFloat(rowData.horas_hombre_programadas) : 0).toFixed(2)) : ""

                }
            ]}
            data={data}
        />
    );
}

function Page(props) {
    const classes = useStyles();
    const {
        data,
        fechaNuevoPod,
        handleOpenDialogoGenerarPod,
        turnosPod
    } = props;

    return (
        <Container className={classes.gridCardBody}>
            {!data &&
                <Card className={classes.mainPrograma}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Cargando POD</Typography>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                        >
                            <Grid
                                item
                                lg={4}
                                className={classes.center}
                            >
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {turnosPod.length === 0 && data &&
                < Card className={classes.mainPrograma}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Ya no quedan turnos para generar POD el día de hoy.</Typography>
                                <Button component={Link} to={ROUTES.POD} color="primary">
                                    Volver
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {data && turnosPod && turnosPod.length > 0 &&
                <Paper
                    className={classes.paperContainerButtons}
                >
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            lg={4}
                        >
                            <TextField
                                value={fechaNuevoPod ? moment(fechaNuevoPod).format("DD/MM/YYYY") : "--/--/--"}
                                variant="outlined"
                                label="Fecha del POD"
                                fullWidth={true}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Button
                                                onClick={handleOpenDialogoGenerarPod}
                                                variant="outlined"
                                            >
                                                Cambiar
                                            </Button>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            lg={4}
                        >
                            <FormControl
                                margin="normal"
                                fullWidth
                                variant="outlined"

                            >
                                <InputLabel>Turno</InputLabel>
                                <Select
                                    native
                                    inputProps={{
                                        name: `turnoPod`,
                                    }}
                                    value={props.turnoPod}
                                    onChange={(e) => props.setTurnoPod(e.target.value)}
                                >
                                    {turnosPod.map((value, index) => (
                                        <option value={value} key={index}>{value}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            lg={4}
                            className={classes.center}
                        >
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!data}
                                onClick={props.handleSubmitPod}
                                style={{ marginRight: "8px" }}
                            >
                                Guardar
                            </Button>
                            <Button component={Link} to={ROUTES.POD} color="primary">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
            {data && turnosPod && turnosPod.length > 0 &&

                <Tabla
                    {...props}
                />

            }
            <AgregarActividadPod
                open={props.openAgregarActividad}
                handleClose={() => props.setOpenAgregarActividad(false)}
                handleSubmit={props.handleSubmitAgregarActividad}
            />
            <EditarActividadPod
                open={props.openEditarActividad}
                handleClose={() => props.setOpenEditarActividad(false)}
                handleSubmit={props.handleSubmitEditarActividad}
                data={props.actividadSeleccionada}
            />
            <DialogoGenerPod></DialogoGenerPod>

        </Container >
    );
}

export default Page;