import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from './page';
import { getPodsProyecto, agregarPodsProyecto, quitarPodsProyecto } from '../../../services/database/proyecto';
import { uploadFileToDrive, viewDriveFile } from '../../../services/fileUpload';
import { postPod, updatePod, getPod } from '../../../services/database/pods';
import { useHistory, useParams } from 'react-router';
import { getItemsPrograma, agregarAvanceItemsPrograma, getItemPrograma, getPrograma, getAvancesPeriodo, updateAvanceItemPrograna, quitarAvanceItemsPrograma } from '../../../services/database/programacion';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { auth } from 'firebase';

export default function VerPod(props) {
    const history = useHistory();
    const { id } = useParams();
    const notisnack = useSnackbar();

    const [actividadSeleccionada, setActividadSeleccionada] = useState(null);
    const [avanceAcumulado, setAvanceAcumulado] = useState(0);
    const [data, setData] = useState(null);
    const [openAgregarActividad, setOpenAgregarActividad] = useState(false);
    const [openEditarActividad, setOpenEditarActividad] = useState(false);
    const [openEliminarActividad, setOpenEliminarActividad] = useState(false);
    const [pod, setPod] = useState(null);
    const [turnoPod, setTurnoPod] = useState('Día');
    const [turnosPod, setTurnosPod] = useState([]);
    const { usuarioSesion, contrato, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto
    }));

    useEffect(() => {
        cargarData();
    }, [proyecto]);

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (id && proyecto && proyecto.programa_actual_ref) {
            const pod = await getPod(id);
            if (pod && proyecto.programa_actual_ref) {
                const programaActual = await getPrograma(proyecto.programa_actual_ref);
                const avances = await getAvancesPeriodo(proyecto.programa_actual_ref, { fechaInicio: moment(pod.fecha).subtract(1, 'days').format("YYYY-MM-DD"), fechaTermino: moment(pod.fecha).format("YYYY-MM-DD") });
                pod["costoTotalPrograma"] = 0;
                pod["horasHombreTotalPrograma"] = 0;
                pod["avanceProgramadoLineaBase"] = (avances.avanceProgramadoHH[1] ? avances.avanceProgramadoHH[1] : 1) - (avances.avanceProgramadoHH[0] ? avances.avanceProgramadoHH[0] : 0);
                pod["cumplimientoProgramado"] = 0;
                pod["horasHombreProgramadas"] = 0;
                pod["horasHombreReales"] = 0;
                pod["costoProgramado"] = 0;
                pod["costoReal"] = 0;
                if (programaActual) {
                    let listadoActividades = await getItemsPrograma(proyecto.programa_actual_ref);
                    for (var a in listadoActividades) {
                        const actividad = listadoActividades[a];
                        if (actividad.is_hoja) {

                            pod.costoTotalPrograma = pod.costoTotalPrograma + (actividad.costo_directo ? parseFloat(actividad.costo_directo) : 0);
                            pod.horasHombreTotalPrograma = pod.horasHombreTotalPrograma + (actividad.horas_hombre ? parseFloat(actividad.horas_hombre) : 0);
                        }
                    }
                }
                pod["avanceProgramadoHH"] = 0;
                pod["avanceRealHH"] = 0;
                pod["avanceProgramadoCostos"] = 0;
                pod["avanceRealCostos"] = 0;
                // console.log(pod);
                if (pod.items) {
                    for (let key = 0; key < pod.items.length; key++) {
                        if (pod.items[key].avances) {
                            const indexAvance = pod.items[key].avances.findIndex(a => a.pod === id);


                            if (indexAvance > -1) {
                                const avancePod = pod.items[key].avances[indexAvance];
                                // console.log(avancePod);
                                avancePod["_id"] = indexAvance;
                                avancePod["avanceProgramadoHH"] = 0;
                                avancePod["avanceRealHH"] = 0;
                                avancePod["avanceProgramadoCostos"] = 0;
                                avancePod["avanceRealCostos"] = 0;
                                // avancePod["actividad"] = pod.items[key];
                                if (avancePod.cantidad_programada && avancePod.cantidad) {
                                    avancePod["porcentajeCumplimiento"] = (avancePod.cantidad / avancePod.cantidad_programada * 100);
                                } else if (avancePod.horas_hombre_programadas && avancePod.horas_hombre) {
                                    avancePod["porcentajeCumplimiento"] = (avancePod.horas_hombre / avancePod.horas_hombre_programadas * 100);
                                }
                                const incidenciaCostos = pod.costoTotalPrograma ? pod.items[key].costo_directo / pod.costoTotalPrograma : 0;
                                const incidenciaHH = pod.horasHombreTotalPrograma ? pod.items[key].horas_hombre / pod.horasHombreTotalPrograma : 0;
                                if (!pod.items[key].inicio_ideal) pod.items[key].inicio_ideal = pod.items[key].termino_ideal;
                                if (!pod.items[key].termino_ideal) pod.items[key].termino_ideal = pod.items[key].inicio_ideal;
                                let duracionProgramada = 0;
                                if (pod.items[key].inicio_ideal) duracionProgramada = moment(pod.items[key].termino_ideal).diff(moment(pod.items[key].inicio_ideal), 'days') + 1;

                                if (avancePod) {
                                    let avanceRealPod = (pod.items[key].horas_hombre && avancePod.horas_hombre) ? avancePod.horas_hombre / pod.items[key].horas_hombre : 0;
                                    let avanceProgramado = (pod.items[key].horas_hombre && avancePod.horas_hombre_programadas) ? avancePod.horas_hombre_programadas / pod.items[key].horas_hombre : 0;
                                    if (avanceRealPod > 1) avanceRealPod = 1;
                                    if (avanceProgramado > 1) avanceProgramado = 1;
                                    avancePod.avanceProgramadoCostos = incidenciaCostos ? (avanceProgramado * (incidenciaCostos) * 100) : 0;
                                    avancePod.avanceRealCostos = incidenciaCostos ? (avanceRealPod * (incidenciaCostos) * 100) : 0;
                                    avancePod.avanceProgramadoHH = incidenciaHH ? (avanceProgramado * (incidenciaHH) * 100) : 0;
                                    avancePod.avanceRealHH = incidenciaHH ? (avanceRealPod * (incidenciaHH) * 100) : 0;
                                }

                                pod.horasHombreProgramadas = pod.horasHombreProgramadas + (avancePod.horas_hombre_programadas ? avancePod.horas_hombre_programadas : 0);
                                pod.horasHombreReales = pod.horasHombreReales + (avancePod.horas_hombre ? avancePod.horas_hombre : 0);

                                let cantidadAvanceProgramado = avancePod.cantidad_programada ? avancePod.cantidad_programada : 0;
                                let costoAvanceProgramado = (pod.items[key].cantidad ? cantidadAvanceProgramado / pod.items[key].cantidad : 0) * (pod.items[key].costo_directo ? pod.items[key].costo_directo : 0);
                                pod.costoProgramado = pod.costoProgramado + costoAvanceProgramado;
                                let cantidadAvanceReal = avancePod.cantidad ? avancePod.cantidad : 0;
                                let costoAvanceReal = (pod.items[key].cantidad ? cantidadAvanceReal / pod.items[key].cantidad : 0) * (pod.items[key].costo_directo ? pod.items[key].costo_directo : 0);
                                pod.costoReal = pod.costoReal + costoAvanceReal;

                                pod.items[key]["avancePod"] = avancePod;
                                pod.avanceProgramadoHH = pod.avanceProgramadoHH + avancePod.avanceProgramadoHH;
                                pod.avanceRealHH = pod.avanceRealHH + avancePod.avanceRealHH;
                                pod.avanceProgramadoCostos = pod.avanceProgramadoCostos + avancePod.avanceProgramadoCostos;
                                pod.avanceRealCostos = pod.avanceRealCostos + avancePod.avanceRealCostos;
                            }
                        }
                        if (pod.items[key].padre) {
                            let padre = pod.items.find(a => a._id === pod.items[key].padre);
                            if (!padre) {
                                const padreItem = await getItemPrograma(pod.items[key].padre);
                                pod.items.push({
                                    padre: padreItem.padre,
                                    item: padreItem.item,
                                    nombre: padreItem.nombre,
                                    id_item: padreItem.id_item,
                                    _id: padreItem._id,
                                    itemPadre: "",
                                    is_hoja: false,
                                    itemsHijos: [pod.items[key]._id]

                                });
                                pod.items[key]["itemPadre"] = padreItem.item;
                            } else {
                                if (!padre.itemsHijos) padre.itemsHijos = [];
                                padre.itemsHijos.push(pod.items[key]._id);
                                pod.items[key]["itemPadre"] = padre.item;
                            }

                        }
                    }
                }
                pod["cumplimientoProgramado"] = pod.avanceProgramadoLineaBase ? pod.avanceRealHH / pod.avanceProgramadoLineaBase : 0;
                if (pod.items) setData(pod.items);
                else setData([]);
                setPod(pod);
                return;
            } else {
                setPod(null);
            }
        } else {
            history.push("/pods");
        }
        // setData([]);
        // setPod(null);
    }

    const handleOpenEditarActividad = (e, rowData) => {
        setActividadSeleccionada(rowData);
        setOpenEditarActividad(true);
    }

    const handleOpenEliminarActividad = (e, rowData) => {
        setActividadSeleccionada(rowData);
        setOpenEliminarActividad(true);
    }

    const handleSubmitAgregarActividad = async (values) => {
        try {
            const actividad = values.actividad.value;
            await agregarAvanceItemsPrograma(actividad._id, {
                baglog: values.baglog,
                cantidad: 0,
                cantidad_programada: values.cantidad_programada,
                comentario_baglog: values.comentario_baglog,
                fecha: pod.fecha,
                fecha_ingreso: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                horas_hombre: 0,
                horas_hombre_programadas: values.horas_hombre_programadas,
                metodo_accion: "",
                observacion: "",
                responsable: "",
                usuario_ref: auth().currentUser.uid
            });
            const porcentajeCumplimiento = calcularPorcentajeCumplimiento(pod.items, actividad, {
                baglog: values.baglog,
                cantidad: 0,
                cantidad_programada: values.cantidad_programada,
                comentario_baglog: values.comentario_baglog,

                fecha_ingreso: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                horas_hombre: 0,
                horas_hombre_programadas: values.horas_hombre_programadas,
                metodo_accion: "",
                observacion: "",
                responsable: "",
                usuario_ref: auth().currentUser.uid
            });
            const itemsId = pod.items_id.slice();
            const item = itemsId.find(a => a === actividad.id_item);
            if (!item) {
                itemsId.push(actividad.id_item);
                await updatePod(pod._id, { items_id: itemsId });
            }
            await updatePod(pod._id, { porcentaje_cumplimiento: porcentajeCumplimiento });
            setOpenAgregarActividad(false);
            cargarData();
        }
        catch (error) {
            console.log(error);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible agregar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    const handleSubmitEditarActividad = async (values) => {
        try {
            const dataAvance = actividadSeleccionada.avancePod ? actividadSeleccionada.avancePod : {};
            const porcentajeCumplimiento = calcularPorcentajeCumplimiento(pod.items, actividadSeleccionada, {
                ...dataAvance,
                pod: pod._id,
                baglog: values.baglog,
                cantidad_programada: values.cantidad_programada,
                comentario_baglog: values.comentario_baglog,
                horas_hombre_programadas: values.horas_hombre_programadas,
                usuario_ref: auth().currentUser.uid
            });

            await updateAvanceItemPrograna(actividadSeleccionada._id, values._id, {
                ...dataAvance,
                pod: pod._id,
                baglog: values.baglog,
                cantidad_programada: values.cantidad_programada,
                comentario_baglog: values.comentario_baglog,
                horas_hombre_programadas: values.horas_hombre_programadas,
                usuario_ref: auth().currentUser.uid
            });
            await updatePod(pod._id, { porcentaje_cumplimiento: porcentajeCumplimiento });
            setOpenEditarActividad(false);
            cargarData();
        }
        catch (error) {
            console.log(error);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible modificar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    const handleSubmitEliminarActividad = async (e) => {
        const dataAvance = actividadSeleccionada.avancePod ? {} : actividadSeleccionada.avancePod;
        if (dataAvance && dataAvance._id) {
            await quitarAvanceItemsPrograma(actividadSeleccionada._id, dataAvance._id);
        }
        const itemsId = pod.items_id.slice();
        const porcentajeCumplimiento = calcularPorcentajeCumplimiento(itemsId);
        const item = itemsId.findIndex(a => a === actividadSeleccionada.id_item);
        if (item > -1) {
            itemsId.splice(item, 1);
            await updatePod(pod._id, {
                porcentaje_cumplimiento: porcentajeCumplimiento,
                items_id: itemsId
            });
        }
        setOpenEliminarActividad(false);
        cargarData();
    }

    function calcularPorcentajeCumplimiento(actividades, actividadNueva = {}, nuevoAvance = null) {
        let porcentajeCumplimiento = 0;
        if (actividades && actividades.length > 0) {
            let {
                totalCantidadProgramadas,
                totalCantidadGanadas,
                totalHHProgramadas,
                totalHHGanadas
            } = actividades.reduce((accumulator, currentValue) => {
                if (currentValue.is_hoja && currentValue.avancePod && currentValue._id !== actividadNueva._id) {
                    const avancePod = currentValue.avancePod;;
                    if (!avancePod.baglog || avancePod.horas_hombre) {
                        accumulator.totalHHGanadas = accumulator.totalHHGanadas + (avancePod.horas_hombre ? avancePod.horas_hombre : 0);
                        accumulator.totalHHProgramadas = accumulator.totalHHProgramadas + (avancePod.horas_hombre_programadas ? avancePod.horas_hombre_programadas : 0);
                    }
                    if (!avancePod.baglog || avancePod.cantidad) {
                        accumulator.totalCantidadGanadas = accumulator.totalCantidadGanadas + (avancePod.cantidad ? avancePod.cantidad : 0);
                        accumulator.totalCantidadProgramadas = accumulator.totalCantidadProgramadas + (avancePod.cantidad_programada ? avancePod.cantidad_programada : 0);
                    }
                }
                return accumulator;
            }, {
                totalCantidadProgramadas: 0,
                totalCantidadGanadas: 0,
                totalHHProgramadas: 0,
                totalHHGanadas: 0
            });
            if (nuevoAvance) {
                if (!nuevoAvance.baglog || nuevoAvance.horas_hombre) {
                    totalHHGanadas = totalHHGanadas + (nuevoAvance.horas_hombre ? parseFloat(nuevoAvance.horas_hombre) : 0);
                    totalHHProgramadas = totalHHProgramadas + parseFloat(nuevoAvance.horas_hombre_programadas ? nuevoAvance.horas_hombre_programadas : 0);
                }
                if (!nuevoAvance.baglog || nuevoAvance.cantidad) {
                    totalCantidadGanadas = totalCantidadGanadas + (nuevoAvance.cantidad ? parseFloat(nuevoAvance.cantidad) : 0);
                    totalCantidadProgramadas = totalCantidadProgramadas + parseFloat(nuevoAvance.cantidad_programada ? nuevoAvance.cantidad_programada : 0);
                }
            }
            porcentajeCumplimiento = (totalHHProgramadas ? (totalHHGanadas / totalHHProgramadas) : (totalCantidadProgramadas ? totalCantidadGanadas / totalCantidadProgramadas : 0)) * 100;
        }
        return porcentajeCumplimiento;
    };

    return (
        <Page
            actividadSeleccionada={actividadSeleccionada}
            avanceAcumulado={avanceAcumulado}
            data={data}
            goTo={(path) => history.push(path)}
            handleOpenEditarActividad={handleOpenEditarActividad}
            handleOpenEliminarActividad={handleOpenEliminarActividad}
            handleSubmitEliminarActividad={handleSubmitEliminarActividad}
            handleSubmitAgregarActividad={handleSubmitAgregarActividad}
            handleSubmitEditarActividad={handleSubmitEditarActividad}
            id={id}
            openAgregarActividad={openAgregarActividad}
            openEditarActividad={openEditarActividad}
            openEliminarActividad={openEliminarActividad}
            pod={pod}
            proyecto={proyecto}
            setActividadSeleccionada={setActividadSeleccionada}
            setOpenAgregarActividad={setOpenAgregarActividad}
            setOpenEditarActividad={setOpenEditarActividad}
            setOpenEliminarActividad={setOpenEliminarActividad}
            setTurnoPod={setTurnoPod}
            turnoPod={turnoPod}
            turnosPod={turnosPod}
            usuarioSesion={usuarioSesion}
        />
    );
}