import {
  CLOSE_REPROGRAMACION,
  CLOSE_REPROGRAMACION_DESDE_ARCHIVO,
  OPEN_REPROGRAMACION,
  OPEN_REPROGRAMACION_DESDE_ARCHIVO,
  SET_OPEN_REPROGRAMACION,
  SET_INICIO_PROGRAMA
} from './actionsPrograma';

const defaultState = {
  inicioPrograma: "", 
  openReprogramacion: false,
  openReprogramacionDesdeArchivo: false
};

export default  function reducerPod(state = defaultState, { type, 
  inicioPrograma,
  openReprogramacion }) {

  switch (type) {
    case SET_INICIO_PROGRAMA: {
      return { ...state, inicioPrograma: inicioPrograma };
    }
    case SET_OPEN_REPROGRAMACION: {
      return { ...state, openReprogramacion: openReprogramacion };
    }
    case CLOSE_REPROGRAMACION: {
      return { ...state, openReprogramacion: false };
    }
    case OPEN_REPROGRAMACION: {
      return { ...state, openReprogramacion: true };
    }
    case CLOSE_REPROGRAMACION_DESDE_ARCHIVO: {
      return { ...state, openReprogramacionDesdeArchivo: false };
    }
    case OPEN_REPROGRAMACION_DESDE_ARCHIVO: {
      return { ...state, openReprogramacionDesdeArchivo: true };
    }
    default:
      return state;
  }
}