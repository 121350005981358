
/**
 * @author Luis San Martín
 */

export default class ContratoModel {

    constructor(json) {
        this._id = json ? json._id : "";
        this.carpeta = json ? json.carpeta : {};
        this.codigo = json ? json.codigo : "";
        this.contratistas_ref = json ? json.contratistas_ref : [];
        this.estado = json ? json.estado : "";
        this.fecha_actualizacion = json ? json.fecha_actualizacion : "";
        this.fecha_creacion = json ? json.fecha_creacion : "";
        this.fecha_inicio_original = json && json.fecha_inicio_original ? json.fecha_inicio_original : "";
        this.fecha_termino_original = json && json.fecha_termino_original ? json.fecha_termino_original : "";
        this.gerencia_ref = json ? json.gerencia_ref : "";
        this.is_eliminado = json ? json.is_eliminado : false;
        this.nombre = json ? json.nombre : "";
        this.proyectos_ref = json ? json.proyectos_ref : [];
        this.responsable_ref = json ? json.responsable_ref : "";
        this.servicio = json ? json.servicio : "";
        this.superintendencias_ref = json ? json.superintendencias_ref : [];
        this.total_cartas = json ? json.total_cartas : 0;
    }


}