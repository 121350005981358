import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl,
    FormLabel,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    Select,
    InputLabel
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import FileInput from '../../../components/fileInput';
import MaterialTable from 'material-table';
import NumberInput from '../../../components/numberInput';
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        paddingTop: theme.spacing(3)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    }

}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        open,
        isValid,
        handleEnterDialog,
        handleSubmit,
        errors,
        touched,
        handleClose,
        pod,
        hojas,
        setHojas,
        previewXLSX,
        setFieldValue,
        handleDescargarFormato
    } = props;
    const [pageSize, setPageSize] = useState(10);
    const change = (name, e) => {
        const { setFieldTouched, setFieldValue, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        if (e.persist) e.persist();
    };

    const numberChange = (name, e) => {
        const { setFieldTouched, setFieldValue } = props;
        setFieldTouched(name, true, false);
        setFieldValue(name, e.target.value);
    };

    const fileChange = (name, e) => {
        const { setFieldTouched, setFieldValue, handleChange, previewXLSX } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        setFieldValue('excel', e.target.files[0]);
        previewXLSX(e.target.files[0]);
    };

    const dateChange = (name, e) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        handleChange(name);
        setFieldTouched(name, true, false);
        setFieldValue(name, e);
    };

    const switchChange = (event, name) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        setFieldValue(name, true, false);
        setFieldTouched(name, true, false);
        handleChange(event);
    };
    // console.log(touched);
    // console.log(errors);
    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}{}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg className={pod ? classes.hidden : ""}>
                            <FormControl
                                fullWidth={true}
                                margin="normal"
                            >
                                {/* <FormLabel>Adjunto</FormLabel> */}
                                <FileInput
                                    inputName="excel"
                                    meta={{ touched: touched.excel, errors: errors.excel }}
                                    buttonColor="primary"
                                    buttonVariant="outlined"
                                    label="Archivo Adjunto"
                                    value={values.excel}
                                    handleOnChange={fileChange.bind(null, "excel")}
                                    textVariant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {hojas &&
                        <MaterialTable
                            title={
                                <Typography variant="h6" style={{
                                    color: errors.nombre_hoja ? "#d00" : "#000"
                                }}
                                >
                                    {`Seleccionar las hojas que contienen Avances ${errors.nombre_hoja ? "(" + errors.nombre_hoja + ")" : ""}`}
                                </Typography>
                            }
                            style={{
                                boxShadow: errors.nombre_hoja ?
                                    "0px 3px 1px -2px #d00, 0px 2px 2px 0px #d00, 0px 1px 5px 0px #d00"
                                    :
                                    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                            }}
                            options={{
                                filtering: false,
                                pageSizeOptions: [5, 10, 20, 50],
                                pageSize: pageSize,
                                grouping: false

                            }}
                            onChangeRowsPerPage={size => { setPageSize(size) }}
                            localization={location}
                            actions={[
                                {
                                    tooltip: 'Quitar',
                                    icon: 'delete',
                                    onClick: (evt, rowData) => {
                                        const newHojas = hojas.filter((value, index) => {
                                            return index !== rowData.tableData.id
                                        })
                                        const newNombreHoja = values.nombre_hoja.filter((value, index) => {
                                            return index !== rowData.tableData.id
                                        })
                                        const newFecha = values.fecha.filter((value, index) => {
                                            return index !== rowData.tableData.id
                                        })
                                        const newTurno = values.turno.filter((value, index) => {
                                            return index !== rowData.tableData.id
                                        });
                                        const newInicioBaglog = values.inicio_baglog.filter((value, index) => {
                                            return index !== rowData.tableData.id
                                        });
                                        const newHHGastadas = values.horas_hombre_gastadas.filter((value, index) => {
                                            return index !== rowData.tableData.id
                                        });
                                        setFieldValue("nombre_hoja", newNombreHoja);
                                        setFieldValue("fecha", newFecha);
                                        setFieldValue("turno", newTurno);
                                        setFieldValue("inicio_baglog", newInicioBaglog);
                                        setFieldValue("horas_hombre_gastadas", newHHGastadas);
                                        setHojas(newHojas);
                                    }
                                },
                                {
                                    tooltip: 'Recargar',
                                    isFreeAction: true,
                                    icon: 'refresh',
                                    onClick: (evt) => {
                                        previewXLSX(values.excel);
                                    }
                                }
                            ]}
                            columns={[
                                {
                                    title: 'Nombre',
                                    field: 'nombre'
                                },
                                {
                                    title: 'Turno',
                                    field: 'turno',
                                    render: rowData =>
                                        <FormControl style={{ width: "100%" }}>
                                            <Select
                                                native
                                                inputProps={{
                                                    name: `turno[${rowData.tableData.id}]`,
                                                }}
                                                variant="outlined"
                                                // autoWidth={true}
                                                value={values.turno[rowData.tableData.id]}
                                                onChange={change.bind(null, `turno[${rowData.tableData.id}]`)}
                                            >
                                                <option value="Día">Día</option>
                                                <option value="Noche">Noche</option>
                                            </Select>
                                        </FormControl>
                                },
                                {
                                    title: 'Fecha',
                                    field: 'fecha',
                                    render: rowData =>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                            <KeyboardDatePicker
                                                name={`fecha[${rowData.tableData.id}]`}
                                                autoOk
                                                fullWidth
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.inputLabel,
                                                        shrink: "shrink"
                                                    }
                                                }}
                                                helperText={touched.fecha && errors.fecha && touched.fecha[rowData.tableData.id] ? errors.fecha[rowData.tableData.id] : ""}
                                                error={touched.fecha && errors.fecha && touched.fecha[rowData.tableData.id] && Boolean(errors.fecha[rowData.tableData.id])}
                                                value={values.fecha[rowData.tableData.id]}
                                                // margin="normal"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                className={classes.textField}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={dateChange.bind(null, `fecha[${rowData.tableData.id}]`)}
                                            />
                                        </MuiPickersUtilsProvider>
                                },
                                {
                                    title: 'HH Gastadas',
                                    field: 'horas_hombre_gastadas',
                                    render: rowData =>
                                        <NumberInput
                                            inputName={`horas_hombre_gastadas[${rowData.tableData.id}]`}
                                            type="text"
                                            variant="outlined"
                                            textVariant="outlined"
                                            value={values.horas_hombre_gastadas[rowData.tableData.id]}
                                            handleOnChange={numberChange.bind(null, `horas_hombre_gastadas[${rowData.tableData.id}]`)}
                                            meta={{
                                                error: errors.horas_hombre_gastadas && errors.horas_hombre_gastadas[rowData.tableData.id] ? errors.horas_hombre_gastadas[rowData.tableData.id] : null,
                                                touched: touched.horas_hombre_gastadas && touched.horas_hombre_gastadas[rowData.tableData.id] ? touched.horas_hombre_gastadas[rowData.tableData.id] : null
                                            }}
                                        />
                                },
                                {
                                    title: 'Primera fila Baglog (Dejar vacío si no incluye Baglog)',
                                    field: 'inicio_baglog',
                                    render: rowData =>
                                        <TextField
                                            name={`inicio_baglog[${rowData.tableData.id}]`}
                                            variant="outlined"
                                            value={values.inicio_baglog[rowData.tableData.id]}
                                            onChange={change.bind(null, `inicio_baglog[${rowData.tableData.id}]`)}
                                            error={touched.inicio_baglog && errors.inicio_baglog && touched.inicio_baglog[rowData.tableData.id] && Boolean(errors.inicio_baglog[rowData.tableData.id])}
                                            helperText={touched.inicio_baglog && errors.inicio_baglog && touched.inicio_baglog[rowData.tableData.id] ? errors.inicio_baglog[rowData.tableData.id] : ""}
                                        />
                                },
                            ]}
                            data={hojas}
                        />
                    }

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleDescargarFormato()}
                        color="secondary"
                        variant="outlined"
                    >
                        Descargar Formato POD
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || !values.excel}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
