import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        paddingTop: theme.spacing(3)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    }

}));


function Page(props) {
    const classes = useStyles();
    const {
        errors,
        isValid,
        handleClose,
        handleEnterDialog,
        handleSubmit,
        open,
        pod,
        titulo,
        touched,
        values
    } = props;
    const change = (name, e) => {
        const { setFieldTouched, setFieldValue, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        if (e.persist) e.persist();
    };

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}{}</DialogTitle>
                <DialogContent>
                <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <TextField
                                value={pod && pod.fecha ? moment(pod.fecha).format("DD/MM/YYYY"): ""}
                                name="fecha"
                                label="Fecha"
                                margin="normal"
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg>
                            <TextField
                                value={pod && pod.turno ? pod.turno: ""}
                                name="turno"
                                label="Turno"
                                margin="normal"
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <FormControl
                                margin="normal"
                                fullWidth
                                variant="outlined"

                            >
                                <InputLabel>Categorización</InputLabel>
                                <Select
                                    native
                                    inputProps={{
                                        name: `categorizacion`,
                                    }}
                                    value={values.categorizacion}
                                    onChange={change.bind(null, "categorizacion")}
                                >
                                    {props.categorizaciones.map((value, index) => (
                                        <option value={value} key={index}>{value}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <TextField
                                value={values.causa_cumplimiento}
                                name="causa_cumplimiento"
                                label="Causa (Cumplimiento POD/Prog.)"
                                helperText={errors.causa_cumplimiento && touched.causa_cumplimiento ? errors.causa_cumplimiento : ""}
                                error={(errors.causa_cumplimiento && touched.causa_cumplimiento) ? true : false}
                                margin="normal"
                                fullWidth={true}
                                rows={3}
                                variant="outlined"
                                multiline={true}
                                onChange={change.bind(null, 'causa_cumplimiento')}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
