import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";

export default function EditarPrograma(props) {

    const [formik, setFormik] = useState(null);
    const { open, handleClose, handleSubmit, programa } = props;
    const validationSchema = Yup.object({
        nombre: Yup.string().required("Campo requerido")
    });
    const values = {
        nombre: ''

    };

    const handleEnterDialog = async () => {
        formik.resetForm({
            nombre: programa ? programa.nombre : ""
        });
    }

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = (values, formikBag) => {
        handleSubmit(values);
    }
    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={true}
                render={props =>
                    <Page
                        open={open}
                        titulo="Editar Prorgama"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}