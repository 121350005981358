import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField,
    Dialog,
    Typography,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Switch,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import NumberInput from '../../../../components/numberInput';
import formatNumber from 'format-number';
import esLocale from 'date-fns/locale/es';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    dialogPaper: {
        overflow: 'auto'
    }
}));

function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        open,
        isValid,
        handleEnterDialog,
        handleSubmit,
        errors,
        touched,
        handleCloseDialog,
        itemPrograma,
        restante,
        disableAvance,
        setDisableAvance
    } = props;


    const change = (name, e) => {
        const { setFieldTouched, handleChange } = props;
        setFieldTouched(name, true, false);
        handleChange(e);
        e.persist();
        if (name === "horas_hombre") calcularPorcentajeAvance(e.target.value, values.tipo_avance);
    };
    const dateChange = (name, e) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        var date = new Date(e).getTime();
        handleChange(name);
        setFieldTouched(name, true, false);
        setFieldValue(name, date);
    };

    const switchChange = (event, name) => {
        const { setFieldValue } = props;
        if (event.target.value === "false") {
            setFieldValue(name, true);
            setFieldValue("tipo_avance", "CON_HH");
            calcularPorcentajeAvance(values.horas_hombre, "CON_HH");
            setDisableAvance(true);
        } else {
            setFieldValue(name, false);
            setFieldValue("tipo_avance", "");
            setDisableAvance(false);
        }

    };

    const calcularPorcentajeAvance = (horasHombre, tipo) => {
        // const { setFieldValue, setFieldTouched } = props;
        // // console.log(tipo);
        // if (tipo === "CON_HH") {
        //     if (horasHombre && itemPrograma.horas_hombre) {
        //         // console.log(horasHombre);
        //         // console.log(itemPrograma.horas_hombre);
        //         let avance = (horasHombre / itemPrograma.horas_hombre) * 100;
        //         // console.log(avance);
        //         setFieldValue("porcentaje_avance", avance);
        //         setFieldTouched("porcentaje_avance", true, false);
        //     } else {
        //         setFieldValue("porcentaje_avance", 0);
        //         setFieldTouched("porcentaje_avance", true, false);
        //     }
        // }
    };

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            PaperProps={{ className: classes.dialogPaper }}
            onClose={handleCloseDialog}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            {itemPrograma &&
                                <Typography>
                                    <b>Rango Programado</b> <br />
                                    Inicio: {`${moment(itemPrograma.inicio_ideal).format("DD/MM/YYYY")}`} - Termino: {`${moment(itemPrograma.termino_ideal).format("DD/MM/YYYY")}`} <br />
                                    <b>Avance</b> <br />
                                    Actual: {itemPrograma ? `${numberFormater(Math.round(itemPrograma.avance_real * 1000) / 1000)}%` : "0%"} - Restante : {numberFormater(Math.round(restante * 1000) / 1000) + "%"}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg={6}>
                                <KeyboardDatePicker
                                    name="fecha"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    label="Fecha"
                                    format="dd/MM/yyyy"
                                    helperText={touched.fecha ? errors.fecha : ""}
                                    error={touched.fecha && Boolean(errors.fecha)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "fecha")}
                                    value={values.fecha}
                                    inputVariant="outlined"
                                />
                            </Grid>
                            <Grid item lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <FormLabel component="legend">Solo HH</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="con_hh"
                                                    color="primary"
                                                    checked={values.con_hh}
                                                    onChange={(e) => switchChange(e, 'con_hh')}
                                                    value={values.con_hh}
                                                />
                                            }
                                            label="Sí">
                                        </FormControlLabel>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <NumberInput
                                    inputName="cantidad_programada"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    input={{
                                        InputProps: {
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                                        },
                                        margin: "normal",
                                        fullWidth: true
                                    }}
                                    meta={{
                                        touched: touched.cantidad_programada,
                                        error: errors.cantidad_programada

                                    }}
                                    error={touched.cantidad_programada && Boolean(errors.cantidad_programada)}
                                    label="Cantidad Programa"
                                    value={(values.cantidad_programada)}
                                    handleOnChange={change.bind(null, "cantidad_programada")}
                                    textVariant="outlined"
                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    inputName="horas_hombre_programadas"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    input={{
                                        InputProps: {
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                                        },
                                        margin: "normal",
                                        fullWidth: true
                                    }}
                                    meta={{
                                        touched: touched.horas_hombre_programadas,
                                        error: errors.horas_hombre_programadas

                                    }}
                                    error={touched.horas_hombre_programadas && Boolean(errors.horas_hombre_programadas)}
                                    label="HH Programas"
                                    value={(values.horas_hombre_programadas)}
                                    handleOnChange={change.bind(null, "horas_hombre_programadas")}
                                    textVariant="outlined"
                                />
                            </Grid>
                            <Grid item lg>
                                <FormControl
                                    style={{ width: "100%" }}
                                    margin="normal"
                                    variant="outlined"
                                    error={touched.turno && Boolean(errors.turno)}>
                                    <InputLabel>Turno</InputLabel>
                                    <Select
                                        autoWidth={true}
                                        value={values.turno}
                                        name="turno"
                                        onChange={change.bind(null, "turno")}
                                    >
                                        <MenuItem value="Día">Día</MenuItem>
                                        <MenuItem value="Noche">Noche</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ color: "red" }}>{touched.turno ? errors.turno : ""}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <NumberInput
                                    inputName="cantidad"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    input={{
                                        InputProps: {
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                                        },
                                        margin: "normal",
                                        fullWidth: true
                                    }}
                                    meta={{
                                        touched: touched.cantidad,
                                        error: errors.cantidad

                                    }}
                                    error={touched.cantidad && Boolean(errors.cantidad)}
                                    label="Cantidad"
                                    value={(values.cantidad)}
                                    handleOnChange={change.bind(null, "cantidad")}
                                    textVariant="outlined"

                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    inputName="horas_hombre"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    input={{
                                        margin: "normal",
                                        fullWidth: true
                                    }}
                                    meta={{
                                        touched: touched.horas_hombre,
                                        error: errors.horas_hombre3

                                    }}
                                    error={touched.horas_hombre && Boolean(errors.horas_hombre)}
                                    label="HH"
                                    value={(values.horas_hombre)}
                                    handleOnChange={change.bind(null, "horas_hombre")}
                                    textVariant="outlined"

                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    inputName="porcentaje_avance"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    input={{
                                        InputProps: {
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                                        },
                                        margin: "normal",
                                        fullWidth: true
                                    }}
                                    meta={{
                                        touched: touched.porcentaje_avance,
                                        error: errors.porcentaje_avance

                                    }}
                                    error={touched.porcentaje_avance && Boolean(errors.porcentaje_avance)}
                                    label="Avance"
                                    value={(values.porcentaje_avance)}
                                    handleOnChange={change.bind(null, "porcentaje_avance")}
                                    disabled={disableAvance}
                                    textVariant="outlined"

                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <TextField
                                    name="observacion"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    helperText={touched.observacion ? errors.observacion : ""}
                                    error={touched.observacion && Boolean(errors.observacion)}
                                    label="Causa Raíz"
                                    margin="normal"
                                    value={values.observacion}
                                    fullWidth
                                    rows={3}
                                    multiline={true}
                                    onChange={change.bind(null, "observacion")}
                                    variant="outlined"

                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <TextField
                                    name="responsable_accion"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    fullWidth
                                    helperText={touched.responsable_accion ? errors.responsable_accion : ""}
                                    error={touched.responsable_accion && Boolean(errors.responsable_accion)}
                                    label="Responsable plan de acción"
                                    margin="normal"
                                    value={values.responsable_accion}
                                    onChange={change.bind(null, "responsable_accion")}
                                    variant="outlined"

                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <TextField
                                    name="metodo_accion"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    helperText={touched.metodo_accion ? errors.metodo_accion : ""}
                                    error={touched.metodo_accion && Boolean(errors.metodo_accion)}
                                    label="Método de acción"
                                    margin="normal"
                                    value={values.metodo_accion}
                                    fullWidth
                                    rows={3}
                                    multiline={true}
                                    onChange={change.bind(null, "metodo_accion")}
                                    variant="outlined"

                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
}

export default Page;
