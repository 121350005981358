export const SET_OPEN_REPROGRAMACION = "SET_OPEN_REPROGRAMACION";
export const OPEN_REPROGRAMACION = "OPEN_REPROGRAMACION";
export const CLOSE_REPROGRAMACION = "CLOSE_REPROGRAMACION";
export const OPEN_REPROGRAMACION_DESDE_ARCHIVO = "OPEN_REPROGRAMACION_DESDE_ARCHIVO";
export const CLOSE_REPROGRAMACION_DESDE_ARCHIVO = "CLOSE_REPROGRAMACION_DESDE_ARCHIVO";
export const SET_INICIO_PROGRAMA = "SET_INICIO_PROGRAMA";

export const actionSetOpenReprogramacion = (openReprogramacion)=>({
  type: SET_OPEN_REPROGRAMACION,
  openReprogramacion: openReprogramacion
});

export const actionOpenReprogramacion = ()=>({
  type: OPEN_REPROGRAMACION
});

export const actionCloneReprogramacion = ()=>({
  type: CLOSE_REPROGRAMACION
});

export const actionOpenReprogramacionDesdeArchivo = ()=>({
  type: OPEN_REPROGRAMACION_DESDE_ARCHIVO
});

export const actionCloneReprogramacionDesdeArchivo = ()=>({
  type: CLOSE_REPROGRAMACION_DESDE_ARCHIVO
});

export const actionSetInicioPrograma = (inicioPrograma)=>({
  type: SET_INICIO_PROGRAMA,
  inicioPrograma: inicioPrograma
});