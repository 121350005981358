import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog
} from '@material-ui/core';
export default function Page(props) {


  const {
    handleClose,
    // handleEnterDialog,
    handleOnSubmit
  } = props;

  return (
    <Dialog
      open={props.open}
      // onEntered={handleEnterDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth={true}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">Eliminar Reporte Semanal POD</DialogTitle>
      <DialogContent>
        ¿Está seguro de eliminar el Reporte Semanal POD seleccionado?
        </DialogContent>
      <DialogActions>
        <Button
          onClick={handleOnSubmit}
          color="primary"
          variant="contained"
        >
          Aceptar
                </Button>
        <Button onClick={handleClose} color="primary">
          Cancelar
                </Button>
      </DialogActions>
    </Dialog >
  );
}