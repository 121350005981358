import React, { useState, Fragment } from 'react';
import {
    Button
} from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import { makeStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert } from '@material-ui/icons';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import AgregarProgramacionSemanal from '../agregarProgramacionSemanal';
import moment from 'moment';
import EliminarProgramacionSemanal from '../eliminarProgramacionSemanal';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Page(props) {

    const classes = useStyles();

    const {
        data,
        goTo,
        handleDescargarFormato,
        handleOpenAgregarProgramaSemanal,
        handleSubmitAgregarProgramaSemanal,
        handleOpenEliminarProgramaSemanal,
        handleSubmitEliminarProgramaSemanal,
        handleViewArchivo,
        isLoading,
        usuarioSesion,
    } = props;
    const [pageSize, setPageSize] = useState(10);



    return (
        <Fragment>
            <MaterialTable
                title={`Listado de Programas Semanales`}
                options={{
                    filtering: false,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false,
                    columnsButton: true,
                    maxBodyHeight: "70vh",
                    headerStyle: {
                        textAlign: 'center',
                        border: "1px solid #E0E0E0",
                        fontWeight: 'bold',
                        color: '#731f1f',
                        position: 'sticky'
                    },
                    rowStyle: {
                        border: "1px solid #E0E0E0"
                    },
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'center'
                    },
                    padding: 'dense',
                    actionsCellStyle: {
                        width: "2%",
                        textAlign: "center"
                    }
                }}
                onChangeRowsPerPage={size => { setPageSize(size) }}
                localization={location}
                isLoading={isLoading}
                actions={[
                    {
                        icon: "formato_programa",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        icon: "cargar",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        tooltip: 'Administrar',
                        icon: 'settings',
                        onClick: () => { }
                    },
                ]}
                components={{
                    Action:
                        props => {
                            const rowData = props.data;
                            if (props.action.icon === 'cargar') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleOpenAgregarProgramaSemanal()}
                                        hidden={!(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_POD])}
                                    >
                                        Cargar Prog. Semanal
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'formato_programa') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleDescargarFormato(evt, null)}
                                    >
                                        Descargar Formato
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'settings') {
                                const accionesConfiguracion = [
                                    {
                                        content: 'Ver Programa',
                                        onClick: (e) => goTo(`/programacion-semanal/${rowData._id}`)
                                    }
                                ];
                                if (rowData.archivo) accionesConfiguracion.push({
                                    content: 'Ver Archivo',
                                    // onClick: (e) => { }
                                    onClick: (e) => handleViewArchivo(rowData)
                                });
                                if (usuarioSesion && usuarioSesion.permisos) {
                                    accionesConfiguracion.push({
                                        content: 'Eliminar',
                                        onClick: (e) => handleOpenEliminarProgramaSemanal(rowData)
                                    });
                                }
                                if (accionesConfiguracion.length > 0) return (
                                    <MenuDropdown
                                        icon={
                                            <MoreVert />
                                        }
                                        tooltip="Opciones"
                                        items={accionesConfiguracion}
                                        hidden={accionesConfiguracion.length <= 0}
                                    />
                                );
                            }
                            return (<MTableAction {...props} />);
                        }
                }}
                columns={[
                    {
                        title: 'Inicio',
                        field: 'inicio',
                        render: rowData => {
                            if (rowData.inicio) {
                                let dia = moment(rowData.inicio).format("ddd");
                                dia = dia.charAt(0).toUpperCase() + dia.substr(1);
                                return dia + " " + moment(rowData.inicio).format("DD/MM/YYYY");
                            } else return "";
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Término',
                        field: 'termino',
                        render: rowData => {
                            if (rowData.termino) {
                                let dia = moment(rowData.termino).format("ddd");
                                dia = dia.charAt(0).toUpperCase() + dia.substr(1);
                                return dia + " " + moment(rowData.termino).format("DD/MM/YYYY");
                            } else return "";
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Día 1',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[0] && (rowData.dias_programa_semanal_ref[0].avance_programado || rowData.dias_programa_semanal_ref[0].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[0].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: 'Día 2',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[1] && (rowData.dias_programa_semanal_ref[1].avance_programado || rowData.dias_programa_semanal_ref[1].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[1].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: 'Día 3',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[2] && (rowData.dias_programa_semanal_ref[2].avance_programado || rowData.dias_programa_semanal_ref[2].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[2].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: 'Día 4',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[3] && (rowData.dias_programa_semanal_ref[3].avance_programado || rowData.dias_programa_semanal_ref[3].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[3].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },

                    {
                        title: 'Día 5',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[4] && (rowData.dias_programa_semanal_ref[4].avance_programado || rowData.dias_programa_semanal_ref[4].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[4].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: 'Día 6',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[5] && (rowData.dias_programa_semanal_ref[5].avance_programado || rowData.dias_programa_semanal_ref[5].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[5].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    },
                    {
                        title: 'Día 7',
                        field: 'dias_programa_semanal_ref',
                        render: rowData => {
                            if (rowData.dias_programa_semanal_ref && rowData.dias_programa_semanal_ref[6] && (rowData.dias_programa_semanal_ref[6].avance_programado || rowData.dias_programa_semanal_ref[6].avance_programado === 0)) {
                                return numberFormater(parseFloat(rowData.dias_programa_semanal_ref[6].avance_programado).toFixed(2)) + "%";
                            } else {
                                return "--";
                            }
                        },
                    }

                ]}
                data={data}
            />
            <AgregarProgramacionSemanal
                handleSubmit={handleSubmitAgregarProgramaSemanal}
            />
            <EliminarProgramacionSemanal
                handleSubmit={handleSubmitEliminarProgramaSemanal}
            />
        </Fragment>
    );
}

function generarFechaView(data, format) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }

}