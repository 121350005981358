/**
 * Acciones redux de reportes pod diarios
 * @since 1.2.0
 * @author Luis San Martín S.
 */

export const OPEN_AGREGAR_REPORTE_POD_DIARIO = "OPEN_AGREGAR_REPORTE_POD_DIARIO";
export const CLOSE_AGREGAR_REPORTE_POD_DIARIO = "CLOSE_AGREGAR_REPORTE_POD_DIARIO";

export const OPEN_ELIMINAR_REPORTE_POD_DIARIO = "OPEN_ELIMINAR_REPORTE_POD_DIARIO";
export const CLOSE_ELIMINAR_REPORTE_POD_DIARIO = "CLOSE_ELIMINAR_REPORTE_POD_DIARIO";

export const OPEN_AGREGAR_LINEA_BASE = "OPEN_AGREGAR_LINEA_BASE";
export const CLOSE_AGREGAR_LINEA_BASE = "CLOSE_AGREGAR_LINEA_BASE";

export const OPEN_ELIMINAR_LINEA_BASE = "OPEN_ELIMINAR_LINEA_BASE";
export const CLOSE_ELIMINAR_LINEA_BASE = "CLOSE_ELIMINAR_LINEA_BASE";

export const ACTUALIZAR_GRAFICO_LINEA_BASE_SEMANAL = "ACTUALIZAR_GRAFICO_LINEA_BASE_SEMANAL";
export const ACTUALIZAR_TABLA_DIA_LINEA_BASE = "ACTUALIZAR_TABLA_DIA_LINEA_BASE";
export const ACTUALIZAR_TABLA_LINEA_BASE = "ACTUALIZAR_TABLA_LINEA_BASE";
export const ACTUALIZAR_TABLA_REPORTE_POD_DIARIO = "ACTUALIZAR_TABLA_REPORTE_POD_DIARIO";

export const OPEN_AGREGAR_DIA_LINEA_BASE = "OPEN_AGREGAR_DIA_LINEA_BASE";
export const CLOSE_AGREGAR_DIA_LINEA_BASE = "CLOSE_AGREGAR_DIA_LINEA_BASE";

export const OPEN_EDITAR_DIA_LINEA_BASE = "OPEN_EDITAR_DIA_LINEA_BASE";
export const CLOSE_EDITAR_DIA_LINEA_BASE = "CLOSE_EDITAR_DIA_LINEA_BASE";

export const OPEN_ELIMINAR_DIA_LINEA_BASE = "OPEN_ELIMINAR_DIA_LINEA_BASE";
export const CLOSE_ELIMINAR_DIA_LINEA_BASE = "CLOSE_ELIMINAR_DIA_LINEA_BASE";

/**
 * Abrir dialogo para agregar reporte pod diario
 * @param {function} handleSubmitAgregarReportePodDiario Evento que se dispara una vez agregado el reporte pod diario
 */
export const openAgregarReportePodDiario = (handleSubmitAgregarReportePodDiario = null) => ({
    type: OPEN_AGREGAR_REPORTE_POD_DIARIO,
    handleSubmitAgregarReportePodDiario: handleSubmitAgregarReportePodDiario
});
/**
 * Cerrar dialogo para agregar reporte pod diario
 */
export const closeAgregarReportePodDiario = () => ({
    type: CLOSE_AGREGAR_REPORTE_POD_DIARIO
});
/**
 * Abrir dialogo para eliminar reporte pod diario
 * @param {Object} reportePodDiarioSeleccionado reporte pod diario a eliminar
 * @param {function} handleSubmitEliminarReportePodDiario Evento que se dispara una vez eliminado el reporte pod diario
 */
export const openEliminarReportePodDiario = (reportePodDiarioSeleccionado, handleSubmitEliminarReportePodDiario = null) => ({
    reportePodDiarioSeleccionado: reportePodDiarioSeleccionado,
    handleSubmitEliminarReportePodDiario: handleSubmitEliminarReportePodDiario,
    type: OPEN_ELIMINAR_REPORTE_POD_DIARIO
});
/**
 * Cerrar dialogo para eliminar reporte pod diario
 */
export const closeEliminarReportePodDiario = () => ({
    type: CLOSE_ELIMINAR_REPORTE_POD_DIARIO
});

/**
 * Abrir dialogo para agregar linea base
 * @param {function} handleSubmitAgregarLineaBase Evento que se dispara una vez agregado la linea base
 */
export const openAgregarLineaBase = (handleSubmitAgregarLineaBase = null) => ({
    type: OPEN_AGREGAR_LINEA_BASE,
    handleSubmitAgregarLineaBase: handleSubmitAgregarLineaBase
});
/**
 * Cerrar dialogo para agregar linea base
 */
export const closeAgregarLineaBase = () => ({
    type: CLOSE_AGREGAR_LINEA_BASE
});

/**
 * Abrir dialogo para eliminar linea base
 * @param {Object} lineaBaseSeleccionada linea base a eliminar
 * @param {function} handleSubmitEliminarLineaBase Evento que se dispara una vez eliminado el linea base
 */
export const openEliminarLineaBase = (lineaBaseSeleccionada, handleSubmitEliminarLineaBase = null) => ({
    lineaBaseSeleccionada: lineaBaseSeleccionada,
    handleSubmitEliminarLineaBase: handleSubmitEliminarLineaBase,
    type: OPEN_ELIMINAR_LINEA_BASE
});
/**
 * Cerrar dialogo para eliminar linea base
 */
export const closeEliminarLineaBase = () => ({
    type: CLOSE_ELIMINAR_LINEA_BASE
});

/**
 * Actualizar gráfico linea base semanal
 */
export const actualizarGraficoLineaBaseSemanal = (actualizarGraficoLineabaseSemanal) => ({
    actualizarGraficoLineabaseSemanal: actualizarGraficoLineabaseSemanal,
    type: ACTUALIZAR_GRAFICO_LINEA_BASE_SEMANAL
});

/**
 * Abrir dialogo para agregar dia linea base
 * @param {function} handleSubmitAgregarDiaLineaBase Evento que se dispara una vez agregado el reporte pod diario
 */
export const openAgregarDiaLineaBase = (handleSubmitAgregarDiaLineaBase = null) => ({
    type: OPEN_AGREGAR_DIA_LINEA_BASE,
    handleSubmitAgregarDiaLineaBase: handleSubmitAgregarDiaLineaBase
});
/**
 * Cerrar dialogo para agregar dia linea base
 */
export const closeAgregarDiaLineaBase = () => ({
    type: CLOSE_AGREGAR_DIA_LINEA_BASE
});

/**
 * Abrir dialogo para eliminar linea base
 * @param {Object} diaLineaBaseSeleccionado dia linea base a eliminar
 * @param {function} handleSubmitEliminarLineaBase Evento que se dispara una vez eliminado el dia linea base
 */
export const openEliminarDiaLineaBase = (diaLineaBaseSeleccionado, handleSubmitEliminarDiaLineaBase = null) => ({
    diaLineaBaseSeleccionado: diaLineaBaseSeleccionado,
    handleSubmitEliminarDiaLineaBase: handleSubmitEliminarDiaLineaBase,
    type: OPEN_ELIMINAR_DIA_LINEA_BASE
});
/**
 * Cerrar dialogo para eliminar linea base
 */
export const closeEliminarDiaLineaBase = () => ({
    type: CLOSE_ELIMINAR_DIA_LINEA_BASE
});

/**
 * Abrir dialogo para editar linea base
 * @param {Object} diaLineaBaseSeleccionado dia linea base a editar
 * @param {function} handleSubmitEditarLineaBase Evento que se dispara una vez editado el dia linea base
 */
export const openEditarDiaLineaBase = (diaLineaBaseSeleccionado, handleSubmitEditarDiaLineaBase = null) => ({
    diaLineaBaseSeleccionado: diaLineaBaseSeleccionado,
    handleSubmitEditarDiaLineaBase: handleSubmitEditarDiaLineaBase,
    type: OPEN_EDITAR_DIA_LINEA_BASE
});
/**
 * Cerrar dialogo para editar linea base
 */
export const closeEditarDiaLineaBase = () => ({
    type: CLOSE_EDITAR_DIA_LINEA_BASE
});

/**
 * Actualizar tabla linea base semanal
 */
export const actualizarTablaLineaBase = (actualizarTablaLineaBase) => ({
    actualizarTablaLineaBase: actualizarTablaLineaBase,
    type: ACTUALIZAR_TABLA_LINEA_BASE
});
/**
 * Actualizar tabla dia linea base semanal
 */
export const actualizarTablaDiaLineaBase = (actualizarTablaDiaLineaBase) => ({
    actualizarTablaDiaLineaBase: actualizarTablaDiaLineaBase,
    type: ACTUALIZAR_TABLA_DIA_LINEA_BASE
});
/**
 * Actualizar tabla reporte pod diario
 */
export const actualizarTablaReportePodDiario = (actualizarTablaReportePodDiario) => ({
    actualizarTablaReportePodDiario: actualizarTablaReportePodDiario,
    type: ACTUALIZAR_TABLA_REPORTE_POD_DIARIO
});
