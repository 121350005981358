import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import moment from 'moment';
function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}
function stringtoNumber(value) {
    if (value) {
        const nospaces = value.replace(/\s/g, "");
        const number = parseFloat(nospaces);
        if (number) return number;
    }
    return 0;

}

export default function EditarPod(props) {
    const { open, handleClose, pod } = props;
    const [formik, setFormik] = useState(null);
    const [categorizaciones, setCategorizaciones] = useState([
        "",
        "Acreditación",
        "Liberación de área",
        "Falta de acceso obra",
        "Suministro",
        "Falta de equipo",
        "Suministro Empresa",
        "Interferencia operaciones",
        "Cambio de turno",
        "Mala programación/ Bajos rendimientos",
        "Falta de mano de obra",
        "Clima",
        "Indefnición en el proyecto"
    ]);
    const validationSchema = Yup.object({
        causa_cumplimiento: Yup.string(),
        categorizacion: Yup.string()
    });
    const { usuarioSesion, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto
    }));
    const values = {
        causa_cumplimiento: "",
        categorizacion: ""
    };

    const handleEnterDialog = async () => {
        if (!pod) {
            formik.resetForm(values);
        } else {
            formik.resetForm({
                causa_cumplimiento: pod.causa_cumplimiento ? pod.causa_cumplimiento : "",
                categorizacion: pod.categorizacion ? pod.categorizacion : ""
            });
        }

    }

    const handleChange = (e, { name, value }) => {
        props.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = (values, formikBag) => {
        const { handleSubmit } = props;
        handleSubmit(values);
    }
    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                pod={pod}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={pod ? true : false}
                render={props =>
                    <Page
                        open={open}
                        titulo="POD"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        categorizaciones={categorizaciones}
                        pod={pod}
                        validationSchema={validationSchema}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}