import React, { useState } from 'react';
import {
    CircularProgress,
    Typography,
    CardContent,
    Button,
    Grid,
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Container
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableAction } from 'material-table';
import './style.css';
import SelectorPresupuesto from './selectorPresupuesto';
import DialogoAgregarActividad from './agregar';
import EditarActividadPrograma from './editarActividadPrograma';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import DialogoConfirmar from '../../components/dialogoConfirmar';
import Moment from 'react-moment';
import moment from 'moment';
import formatNumber from 'format-number';
import MenuDropdown from '../../components/menuDropDown';
import { Settings } from '@material-ui/icons';
// import DetallesActividad from './detallesActividad';
import * as PERMISIONS from '../../constants/permissions';
import * as ROUTES from '../../constants/routes';
import EditarPrograma from './editarPrograma';
import ConfigurarBaseReal from './configurarBaseReal';
const localization = require('../../data/location.json');
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const currencyFormater = formatNumber({ prefix: "$", integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableContainer: {
        // display: "flex",
        // flexGrow: 0
    },
    fullWidth: {
        width: "100%",
        display: "flex"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    paperCenter: {
        textAlign: "center"
    },
    tableActions: {
        display: "inline-flex"
    },
    card: {
        overflow: "unset",
        width: "100%",
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    header: {

    },
    subHeader: {
        flex: "0 0 auto"
    },
    center: {
        textAlign: "center",
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    mainPrograma: {
        flexGrow: 1
    },
    containerPrograma: {
        margin: theme.spacing(1),
        maxWidth: "100%",
        display: "inline-block"

    },
    cardHeaderTypography: {
        // display: "inline-flex",
        minWidth: "360px",
        padding: theme.spacing(2)
    },
    cardMenuDropDown: {
        // display: "inline-flex",
        // marginLeft: theme.spacing(9)
    },
    gridCardTitle: {
        // width: "100%",
        // position: "fixed",
        // top: theme.spacing(9)
        marginBottom: theme.spacing(2)
    },
    gridCardBody: {
        // paddingTop: theme.spacing(32),
        // padding: "0px",
        overflow: "auto",
        maxWidth: "95vw"
    }
}));

localization.header.actions = "";

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

function Tabla(props) {

    const classes = useStyles();

    const {
        handleDescargarExcel,
        handleOpenSelectorPresupuesto,
        handleOpenAgregarActividad,
        handleOpenEditarActividad,
        handleOpenEliminarActividad,
        handleVerAvances,
        programa,
        proyecto,
        usuarioSesion,
    } = props;
    const [pageSize, setPageSize] = useState(50);

    // const handleVerDetalles = (e, rowData) => {
    //     const { data } = props;
    //     const itemPrograma = data.find((a) => a._id === rowData._id);
    //     itemPrograma.avance_programado = itemPrograma.calcularAvanceProgramado();
    //     itemPrograma.avance_real = itemPrograma.calcularAvanceReal();
    //     return itemPrograma;
    // }

    return (
        <MaterialTable
            title="Tabla Actividades"
            options={{
                filtering: false,
                pageSizeOptions: [50, 100, 200, 500, 1000],
                pageSize: pageSize,
                grouping: false,
                rowStyle: rowData => ({
                    backgroundColor: (rowData.is_hoja ? '#fff' : '#f1f2f6')
                }),
                maxBodyHeight: "70vh",
                // detailPanelType: 'single',
                columnsButton: true,
                toolbarButtonAlignment: "left",
                searchFieldAlignment: "left",
                headerStyle: {
                    position: 'sticky',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#731f1f'
                },
                emptyRowsWhenPaging: false,
                // paging: false,
                // tableLayout: 'fixed',
                padding: 'dense',

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={localization}
            // parentChildData={(row, rows) => {
            //     return rows.find(a => a.item === row.itemPadre)
            // }}
            actions={[
                {
                    tooltip: 'Administrar',
                    icon: 'settings',
                    onClick: () => { }
                },
                rowData => ({
                    tooltip: 'Avances',
                    icon: (rowData.estado === "Conflicto" || rowData.estado === "HH requieren revisión" || rowData.estado === "Cantidad requiere revisión") ? 'error' : 'today',
                    iconProps: {
                        className: (rowData.estado === "Conflicto" || rowData.estado === "HH requieren revisión" || rowData.estado === "Cantidad requiere revisión") ? classes.error : ""
                    },
                    hidden: !(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.VER_AVANCES_ACTIVIDAD] && rowData.is_hoja),
                    onClick: (e, rowData) => handleVerAvances(e, rowData),
                }),
                {
                    tooltip: 'Agregar Actividad al Programa',
                    icon: 'add_circle',
                    isFreeAction: true,
                    iconProps: {
                        className: classes.success
                    },
                    onClick: (e) => handleOpenAgregarActividad(e, null),
                    hidden: !(!proyecto.programa_cerrado && usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ACTIVIDAD])
                },
                rowData => ({
                    tooltip: rowData.estado,
                    icon: 'error',
                    hidden: ((rowData.estado === "Conflicto" || rowData.estado === "HH requieren revisión" || rowData.estado === "Cantidad requiere revisión") && !rowData.is_hoja) ? false : true,
                    disabled: true
                }),
                {
                    icon: "descargar_excel",
                    isFreeAction: true,
                    onClick: (evt) => { }
                },
            ]}
            components={{
                Action:
                    props => {
                        const rowData = props.data;
                        if (props.action.icon === 'settings') {
                            const accionesConfiguracion = [];
                            if (usuarioSesion && usuarioSesion.permisos) {
                                if (!proyecto.programa_cerrado && usuarioSesion.permisos[PERMISIONS.AGREGAR_ACTIVIDAD]) accionesConfiguracion.push({
                                    content: 'Agregar Actividad',
                                    onClick: (e) => handleOpenAgregarActividad(e, rowData)
                                });
                                if (!proyecto.programa_cerrado && usuarioSesion.permisos[PERMISIONS.EDITAR_ACTIVIDAD]) accionesConfiguracion.push({
                                    content: 'Editar',
                                    onClick: (e) => handleOpenEditarActividad(e, rowData)
                                });
                                if (!proyecto.programa_cerrado && usuarioSesion.permisos[PERMISIONS.ELIMINAR_ACTIVIDAD]) accionesConfiguracion.push({
                                    content: 'Eliminar',
                                    onClick: (e) => handleOpenEliminarActividad(e, rowData)
                                });
                                if (!proyecto.programa_cerrado && usuarioSesion.permisos[PERMISIONS.CARGAR_PROGRAMA] && rowData.is_hoja && !rowData.es_hito) accionesConfiguracion.push({
                                    content: 'Presupuesto',
                                    onClick: (e) => handleOpenSelectorPresupuesto(e, rowData)
                                });
                            }
                            if (accionesConfiguracion.length > 0) return (
                                <MenuDropdown
                                    icon={
                                        <Settings />
                                    }
                                    padding="small_padding"
                                    tooltip="Administrar"
                                    items={accionesConfiguracion}
                                    hidden={accionesConfiguracion.length <= 0}
                                />
                            );
                            return (<MTableAction {...props} disabled={true} />);
                        } else if (props.action.icon === 'descargar_excel') {
                            return (
                                <Button
                                    variant="outlined"
                                    onClick={(evt) => handleDescargarExcel(evt, null)}
                                >
                                    Descargar Excel
                                </Button>
                            );
                        } else {
                            return (<MTableAction {...props} />);
                        }
                    }
            }}
            // detailPanel={
            //     [
            //         {
            //             icon: () => <Description />,
            //             openIcon: () => <Description style={{ color: "#000" }} />,
            //             tooltip: "Detalles",
            //             render: rowData => {
            //                 const obj = handleVerDetalles(null, rowData);
            //                 return (
            //                     <DetallesActividad
            //                         data={obj}
            //                     />
            //                 )

            //             }
            //         }
            //     ]
            // }
            columns={[
                {
                    title: 'Ítem',
                    field: 'item',
                    cellStyle: {
                        width: '5%',
                    },
                    headerStyle: {
                        width: '5%',
                    },
                    defaultSort: 'asc'
                },
                {
                    title: 'ID',
                    field: 'id_item',
                    cellStyle: {
                        width: '5%',
                    },
                    headerStyle: {
                        width: '5%',
                    }
                },
                {
                    title: 'Actividad',
                    field: 'nombre',
                    cellStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px'
                    },
                    headerStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px'
                    },
                },
                {
                    title: 'Estado',
                    field: 'estado',
                    hidden: true,
                },
                {
                    title: 'Inicio Temprano',
                    field: 'inicio_temprano',
                    filtering: false,
                    hidden: true,
                    render: rowData => generarFechaView(rowData.inicio_temprano)
                },
                {
                    title: 'Inicio Programado',
                    field: 'inicio_ideal',
                    filtering: false,
                    render: rowData => rowData.inicio_reprogramado ? generarFechaView(rowData.inicio_reprogramado) : generarFechaView(rowData.inicio_ideal)
                },
                {
                    title: 'Inicio Tardío',
                    field: 'inicio_tardio',
                    filtering: false,
                    hidden: true,
                    render: rowData => generarFechaView(rowData.inicio_tardio)
                },
                {
                    title: 'Término Temprano',
                    field: 'termino_temprano',
                    filtering: false,
                    hidden: true,
                    render: rowData => generarFechaView(rowData.termino_temprano)
                },
                {
                    title: 'Término Programado',
                    field: 'termino_ideal',
                    filtering: false,
                    render: rowData => rowData.termino_reprogramado ? generarFechaView(rowData.termino_reprogramado) : generarFechaView(rowData.termino_ideal)
                },
                {
                    title: 'Término Tardío',
                    field: 'termino_tardio',
                    filtering: false,
                    hidden: true,
                    render: rowData => generarFechaView(rowData.termino_tardio)
                },
                {
                    title: 'Inicio Real',
                    field: 'inicio_real',
                    type: 'date',
                    filtering: false,
                    render: rowData => generarFechaView(rowData.inicio_real)
                },
                {
                    title: 'Término Real',
                    field: 'termino_real',
                    type: 'date',
                    filtering: false,
                    render: rowData => {
                        if (rowData.avanceRealCostos >= 100 || rowData.avanceRealHH >= 100) {
                            return (generarFechaView(rowData.termino_real) ? generarFechaView(rowData.termino_real) : "--");
                        } else {
                            return "--";
                        }
                    }
                },
                {
                    title: 'Duración Prog.',
                    field: 'duracionProgramada',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    filtering: false,
                    render: rowData => {
                        return rowData.duracionProgramada ? (rowData.duracionProgramada + "d") : "--";
                    }
                },
                {
                    title: 'Duración Real',
                    field: 'duracionReal',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    filtering: false,
                    render: rowData => {
                        return rowData.duracionReal ? (rowData.duracionReal + "d") : "--";
                    }
                },
                {
                    title: 'Costo ',
                    field: 'costo_directo',
                    filtering: false,
                    cellStyle: {
                        width: '10px',
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return "$" + numberFormater(rowData.costo_directo) + " CLP"
                    }
                },
                // {
                //     title: 'Ítem Presupuesto',
                //     field: 'itemPresupuesto',
                //     filtering: false,
                //     render: rowData => {
                //         return (rowData.item_presupuesto ? rowData.item_presupuesto : rowData.itemPresupuesto)
                //     }
                // },
                {
                    title: 'Incidencia (Costos)',
                    field: 'incidencia',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    filtering: false,
                    render: rowData => (rowData.incidencia || rowData.incidencia == 0) ? numberFormater(rowData.incidencia.toFixed(2)) : ""
                },
                {
                    title: 'HH Programadas',
                    field: 'horas_hombre',
                    filtering: false,
                    cellStyle: {
                        width: '10px',
                        textAlign: 'right'
                    },
                    render: rowData => numberFormater(rowData.horas_hombre ? rowData.horas_hombre.toFixed(0) : 0)
                },
                {
                    title: 'HH Ganadas',
                    field: 'horas_hombre_ganadas',
                    filtering: false,
                    cellStyle: {
                        width: '10px',
                        textAlign: 'right'
                    },
                    render: rowData => numberFormater(rowData.horas_hombre_ganadas ? rowData.horas_hombre_ganadas.toFixed(0) : 0)
                },
                {
                    title: 'Incidencia (HH)',
                    field: 'incidenciaHorasHombre',
                    cellStyle: {
                        textAlign: 'right'
                    },
                    filtering: false,
                    render: rowData => (rowData.incidenciaHorasHombre || rowData.incidenciaHorasHombre == 0) ? numberFormater(rowData.incidenciaHorasHombre.toFixed(2)) : ""
                },
                {
                    title: 'Av. HH Prog.',
                    field: 'avanceProgramadoHH',
                    filtering: false,
                    cellStyle: {
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return numberFormater((rowData.avanceProgramadoHH ? rowData.avanceProgramadoHH : 0).toFixed(2)) + "%";
                    }
                },
                {
                    title: 'Av. HH Ganado',
                    field: 'avanceRealHH',
                    filtering: false,
                    cellStyle: {
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return numberFormater((rowData.avanceRealHH ? rowData.avanceRealHH : 0).toFixed(2)) + "%";
                    }
                },
                {
                    title: 'Av. Costos Prog.',
                    field: 'avanceProgramadoCostos',
                    filtering: false,
                    cellStyle: {
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return numberFormater((rowData.avanceProgramadoCostos ? rowData.avanceProgramadoCostos : 0).toFixed(2)) + "%";
                    }
                },
                {
                    title: 'Av. Costos Ganado',
                    field: 'avanceRealCostos',
                    filtering: false,
                    cellStyle: {
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return numberFormater((rowData.avanceRealCostos ? rowData.avanceRealCostos : 0).toFixed(2)) + "%";
                    }
                }
            ]}
            data={programa.items_programas_ref}
        />
    );
}

function generarFechaView(data) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format="DD/MM/YYYY">{date}</Moment>
        }
    } else {
        return null;
    }

}

function Page(props) {
    const classes = useStyles();
    const {
        // abrirPrograma,
        cargaRapidaDePresupuesto,
        // cerrarPrograma,
        // data,
        presupuesto,
        programa,
        proyecto,
        handleCloseAgregarActividad,
        handleCloseConfigurarBaseRealPrograma,
        handleCloseEditarActividad,
        handleCloseEliminarActividad,
        handleCloseSelectorPresupuesto,
        handleOpenConfigurarBaseRealPrograma,
        handleOpenReprogramacion,
        handleSubmitAgregarActividad,
        handleSubmitConfigurarBaseRealPrograma,
        handleSubmitEditarActividad,
        handleSubmitEditarPrograma,
        handleSubmitEliminarActividad,
        handleSubmitReprogramacion,
        handleSubmitSelectorPresupuesto,
        history,
        itemSeleccionado,
        openAgregarActividad,
        openConfigurarBaseRealPrograma,
        openEditarActividad,
        openEliminarActividad,
        openSelectorPresupuesto,
        usuarioSesion
    } = props;

    // const [openCerrarPrograma, setOpenCerrarPrograma] = useState(false);
    const [openEditarPrograma, setOpenEditarPrograma] = useState(false);
    return (
        <Container className={classes.main}>
            {(programa === null) &&
                <Card className={classes.mainPrograma}>
                    <CardContent className={classes.paperCenter}>
                        <Typography variant={"h4"}> No se le ha cargado ningún Programa al Proyecto</Typography>
                        <br />
                        <Button variant={"outlined"} component={Link} to={ROUTES.CARGAR_PROGRAMA}>Cargar Programa</Button>
                    </CardContent>
                </Card>
            }
            {(programa && !programa.items_programas_ref) &&
                <Card className={classes.mainPrograma}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Cargando Programa</Typography>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {(programa && programa.items_programas_ref) &&
                <Card className={classes.gridCardTitle}>
                    {/* <CardHeader
                            action={accionesAdministrarPrograma}
                            titleTypographyProps={{
                                align: "left"
                            }}
                            title={(programa && programa.nombre ? ` ${programa.nombre}` : "")}
                            /> */}

                    <CardContent>
                        <Grid container
                            alignContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item lg style={{ textAlign: "left" }}>
                                <Typography
                                    align="left"
                                    variant={"h5"}
                                    className={classes.cardHeaderTypography}
                                >
                                    {(proyecto && proyecto.nombre ? ` ${proyecto.nombre}` : "")}
                                </Typography>
                                <Table size="small" style={{ width: "unset", display: "inline-flex" }}>
                                    <TableBody>
                                        {/* <TableRow>
                                            <CustomCell1 colSpan="4" align="center"><b>Rango Real</b></CustomCell1>
                                        </TableRow> */}
                                        {/* <TableRow>
                                            <CustomCell1 align="right"><b>Versión</b></CustomCell1>
                                            <CustomCell1 align="right">{programa.version}</CustomCell1>
                                        </TableRow>
                                        <TableRow>
                                            {programa.fecha_reprogramacion && <CustomCell1 align="right"><b>Fecha Reprogramación</b></CustomCell1>}
                                            {programa.fecha_reprogramacion && <CustomCell1 align="right">{moment(programa.fecha_reprogramacion).format("DD/MM/YYYY")}</CustomCell1>}
                                        </TableRow> */}
                                        <TableRow>
                                            <CustomCell1 align="right"><b>Fecha de Inicio Programado</b></CustomCell1>
                                            <CustomCell1 align="right">{moment(programa.inicio_reprogramado ? programa.inicio_reprogramado : programa.inicio_ideal).format("DD/MM/YYYY")}</CustomCell1>
                                        </TableRow>
                                        <TableRow>
                                            <CustomCell1 align="right"><b>Fecha de Término Programado</b></CustomCell1>
                                            <CustomCell1 align="right">{moment(programa.termino_reprogramado ? programa.termino_reprogramado : programa.termino_ideal).format("DD/MM/YYYY")}</CustomCell1>
                                        </TableRow>
                                        <TableRow>
                                            <CustomCell1 align="right"><b>Costo Total</b></CustomCell1>
                                            <CustomCell1 align="right">{currencyFormater(programa.costo_directo)}</CustomCell1>
                                        </TableRow>
                                        <TableRow>
                                            <CustomCell1 align="right"><b>Total HH</b></CustomCell1>
                                            <CustomCell1 align="right">{numberFormater(programa.horas_hombre)}</CustomCell1>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item lg style={{ textAlign: "right" }}>
                                {(proyecto && usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.CARGAR_PROGRAMA]) &&

                                    <MenuDropdown
                                        // button={
                                        //     "Administrar"
                                        // }
                                        icon={
                                            <Settings />
                                        }
                                        tooltip="Administrar"
                                        padding="default_padding"
                                        items={[
                                            {
                                                content: 'Cargar Programa',
                                                onClick: (e) => history.push(`${ROUTES.CARGAR_PROGRAMA}`),
                                            },
                                            {
                                                content: 'Cargar POD',
                                                onClick: (e) => history.push(`${ROUTES.POD}`)
                                            },
                                            {
                                                content: 'Configurar Base Real Programa',
                                                onClick: (e) => handleOpenConfigurarBaseRealPrograma(e)
                                            },
                                            {
                                                content: 'Reprogramación',
                                                onClick: () => history.push(ROUTES.REPROGRAMACION)
                                            },
                                            {
                                                content: 'Editar',
                                                onClick: (e) => setOpenEditarPrograma(true)
                                            },
                                            {
                                                content: 'Carga Rápida de presupuesto',
                                                onClick: (e) => cargaRapidaDePresupuesto(),
                                                hidden: !usuarioSesion.permisos[PERMISIONS.CARGA_RAPIDA_PRESUPUESTO]
                                            }
                                        ]}
                                        hidden={false}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {(programa && programa.items_programas_ref) &&
                <Tabla

                    {...props}
                />
            }
            {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ACTIVIDAD] &&
                <DialogoAgregarActividad
                    open={openAgregarActividad}
                    padre={itemSeleccionado ? itemSeleccionado._id : null}
                    items_programa={programa.items_programas_ref}
                    proyecto={proyecto}
                    programa={programa}
                    handleCloseDialog={handleCloseAgregarActividad}
                    handleSubmit={handleSubmitAgregarActividad}
                />
            }
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.EDITAR_ACTIVIDAD] &&
                <EditarActividadPrograma
                    open={openEditarActividad}
                    itemPrograma={itemSeleccionado}
                    padre={itemSeleccionado ? itemSeleccionado.padre : null}
                    items_programa={programa.items_programas_ref}
                    proyecto={proyecto}
                    programa={programa}
                    handleCloseDialog={handleCloseEditarActividad}
                    handleSubmit={handleSubmitEditarActividad}
                />
            }
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.CARGAR_PROGRAMA] &&
                <SelectorPresupuesto
                    open={openSelectorPresupuesto}
                    item_programa={itemSeleccionado}
                    proyecto={proyecto}
                    programa={programa}
                    presupuesto={presupuesto}
                    handleCloseDialog={handleCloseSelectorPresupuesto}
                    handleSubmit={handleSubmitSelectorPresupuesto}
                />
            }
            <DialogoConfirmar
                open={openEliminarActividad}
                message={"¿Está seguro de eliminar la actividad?"}
                action={handleSubmitEliminarActividad}
                handleClose={handleCloseEliminarActividad}
            />
            {/* <DialogoConfirmar
                open={openCerrarPrograma}
                message={proyecto && proyecto.programa_cerrado ?
                    "Para volver abrir el programa se debe realizar una reprogramación. El proceso tomará un par de minutos en llevarse a cabo ¿Está seguro de que desea continuar?" :
                    "Se cerrará el programa actual y ya no podrá realizar más cambios en la programación. ¿Está seguro de que desea continuar?"
                }
                action={async () => {
                    proyecto.programa_cerrado ? await abrirPrograma() : await cerrarPrograma();
                    setOpenCerrarPrograma(false);
                }}
                handleClose={() => setOpenCerrarPrograma(false)}
            /> */}
            <EditarPrograma
                open={openEditarPrograma}
                handleClose={() => setOpenEditarPrograma(false)}
                programa={programa}
                handleSubmit={(values) => {
                    handleSubmitEditarPrograma(values);
                    setOpenEditarPrograma(false);
                }}
            />
            <ConfigurarBaseReal
                open={openConfigurarBaseRealPrograma}
                handleCloseDialog={handleCloseConfigurarBaseRealPrograma}
                handleSubmit={handleSubmitConfigurarBaseRealPrograma}
                proyecto={proyecto}
            />
        </Container >
    );
}

export default Page;