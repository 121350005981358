import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import moment from 'moment';
import { accionCloseGenerarPod, accionSetFechaGenerarPod } from '../../actionsPod';
moment.locale('es');
export default function DialogoGenerarPod(props) {

    const dispatch = useDispatch();

    const {
        open,
        fechaNuevoPod
    } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
        open: state.reducerPod.openGenerarPod,
        fechaNuevoPod: state.reducerPod.fechaNuevoPod,

    }));
    const [fecha, setFecha] = useState(new Date());

    const submit = () => {
        dispatch(accionSetFechaGenerarPod(fecha));
        dispatch(accionCloseGenerarPod());
    }

    const onChangeDate = (e) =>{
        setFecha(e);
    }
    
    const onEnterDialog = () =>{
        // console.log();
    }

    const handleClose = () => {

    }

    useEffect(()=>{
        if(fechaNuevoPod) setFecha(fechaNuevoPod);
    },[fechaNuevoPod]);


    return (
        <Page
            fecha={fecha}
            onChangeDate={onChangeDate}
            onEnterDialog={onEnterDialog}
            open={open}
            submit={submit}
        />
    );
}