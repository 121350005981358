/**
 * Lógica de listado reportes diarios pod
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import moment from 'moment';
import Axios from 'axios';
import { getAllLineaBase } from '../requestsLineaBase';
import { openAgregarLineaBase, closeAgregarLineaBase, openEliminarLineaBase, closeEliminarLineaBase, actualizarGraficoLineaBaseSemanal } from '../actionsReportePodDiario';
moment.locale('es');
export default function ListadoLineaBase() {

    const dispatch = useDispatch();

    const {
        proyecto,
        usuarioSesion
    } = useSelector(state => ({
        proyecto: state.contratoSesion.proyecto,
        usuarioSesion: state.usuarioSesion
    }));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Cargar reportes pod diarios de un proyecto
     * @param {Object} proyecto
     */
    const cargarData = (proyecto) => {
        if (proyecto) {
            console.log("cargar data");
            const CancelToken = Axios.CancelToken;
            let cancelRequest = null;
            let cancelToken = new CancelToken(function executor(c) {
                cancelRequest = c;
            });
            getAllLineaBase({ proyecto_ref: proyecto._id }, cancelToken)
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 204) {
                        setData([]);
                        setIsLoading(false);
                    } else if (response.status == 200) {
                        console.log(response.data);
                        setData(response.data);
                        setIsLoading(false);
                    } else {
                        setData([]);
                        setIsLoading(false);
                    }
                })
                .catch(e => {
                    setData([]);
                    console.log(e);
                    setIsLoading(false);
                });
            return cancelRequest;
        }
        setData([]);
        setIsLoading(false);
        return null;
    }

    /**
     * Abrir dialogo para agregar una linea base
     */
    const handleOpenAgregarLineaBase = () => {
        dispatch(openAgregarLineaBase(
            () => {
                dispatch(closeAgregarLineaBase());
                dispatch(actualizarGraficoLineaBaseSemanal(true));
                cargarData(proyecto);
            }
        ));
    }
    /**
     * Abrir dialogo para eliminar un reporte pod diario
     * @param {Object} data Reporte pod diario a eliminar
     */
    const handleOpenEliminarLineaBase = (data) => {
        dispatch(openEliminarLineaBase(data,
        () => {
            dispatch(actualizarGraficoLineaBaseSemanal(true));
            dispatch(closeEliminarLineaBase());
            cargarData(proyecto);
        }
    ));
    }



    useEffect(() => {
        setIsLoading(true);
        const cancelRequest = cargarData(proyecto);
        return () => {
            if (cancelRequest) cancelRequest();
            setIsLoading(false);
        }
    }, [proyecto]);


    return (
        <Page
            data={data}
            isLoading={isLoading}
            handleOpenAgregarLineaBase={handleOpenAgregarLineaBase}
            handleOpenEliminarLineaBase={handleOpenEliminarLineaBase}
            usuarioSesion={usuarioSesion}
        />
    );
}