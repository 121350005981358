import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getProgramasSemanales } from '../requestsProgramasSemanales';
import {
    accionOpenAgregarProgramaSemanal,
    accionCloseAgregarProgramaSemanal,
    accionOpenEliminarProgramaSemanal,
    accionCloseEliminarProgramaSemanal
} from '../actionsProgramaSemanal';
import { getPrograma } from '../../../services/database/programacion';
import { downloadUrlAsPromise } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
moment.locale('es');
export default function ListadoProgramasSemanales(props) {

    const history = useHistory();
    const notisnack = useSnackbar();
    const dispatch = useDispatch();
    const {
        usuarioSesion,
        proyecto,
    } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
    }));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (proyecto && proyecto.programa_actual_ref) {
            const programasSemanales = await getProgramasSemanales({ proyecto_ref: proyecto._id });
            const programaProyecto = await getPrograma(proyecto.programa_actual_ref);
            if (programasSemanales) {
                for (let p in programasSemanales) {
                    if (programasSemanales[p].dias_programa_semanal_ref) {
                        for (let d in programasSemanales[p].dias_programa_semanal_ref) {
                            const dia = programasSemanales[p].dias_programa_semanal_ref[d];
                            dia.avance_programado = programaProyecto.horas_hombre && dia.horas_hombre_programadas ? (dia.horas_hombre_programadas / programaProyecto.horas_hombre*100) : 0;
                            dia.avance_real = programaProyecto.horas_hombre && dia.horas_hombre_reales ? (dia.horas_hombre_reales / programaProyecto.horas_hombre*100) : 0;
                        }
                    }
                }
                setData(programasSemanales);
            } else {
                setData([]);
            }

        } else {
            setData([]);
        }
        setIsLoading(false);
    }

    const handleOpenAgregarProgramaSemanal = async () => {
        dispatch(accionOpenAgregarProgramaSemanal());
    }

    const handleSubmitAgregarProgramaSemanal = async () => {
        dispatch(accionCloseAgregarProgramaSemanal());
        cargarData();
    }

    const handleOpenEliminarProgramaSemanal = async (rowData) => {
        dispatch(accionOpenEliminarProgramaSemanal(rowData));
    }

    const handleSubmitEliminarProgramaSemanal = async () => {
        dispatch(accionCloseEliminarProgramaSemanal());
        cargarData();
    }

    const handleDescargarFormato = async () => {
        const nombre = "Formato Programa Semanal.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/1DzLSQNnspSjGFiwsVlS5xvAvBWCoK3OW?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    const handleViewArchivo = async (rowData) => {
        if (rowData && rowData.archivo) {
            const nombre = rowData.archivo.nombre;
            const response = await downloadUrlAsPromise(`https://www.googleapis.com/drive/v3/files/${rowData.archivo.archivo_id}?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung`, nombre);
            if (response) saveAs(response.blob, nombre);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        cargarData();
    }, [proyecto]);

    return (
        <Page
            data={data}
            goTo={(path) => history.push(path)}
            handleDescargarFormato={handleDescargarFormato}
            handleOpenAgregarProgramaSemanal={handleOpenAgregarProgramaSemanal}
            handleOpenEliminarProgramaSemanal={handleOpenEliminarProgramaSemanal}
            handleSubmitAgregarProgramaSemanal={handleSubmitAgregarProgramaSemanal}
            handleSubmitEliminarProgramaSemanal={handleSubmitEliminarProgramaSemanal}
            handleViewArchivo={handleViewArchivo}
            isLoading={isLoading}
            usuarioSesion={usuarioSesion}
        />
    );
}