import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";

function AgregarRecurso(props) {

    const {
        handleSubmit,
        handleClose,
        open
    } = props;

    const [formik, setFormik] = useState(null);

    const validationSchema = Yup.object({
        nombre: Yup.string().required("Campo requerido"),
        descripcion: Yup.string().required("Campo requerido"),
        cantidad: Yup.number().typeError("Debe ser un valor numérico").min(0, "Debe ser mayor que cero").required("Campo requerido"),

    });
    const values = {
        nombre: '',
        descripcion: '',
        cantidad: 0
    };
    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };
    const handleEnterDialog = () => {
        formik.resetForm({
            nombre: '',
            descripcion: '',
            cantidad: 0
        });
    }

    return (
        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                render={props =>
                    <Page
                        titulo="Nuevo Recurso"
                        open={open}
                        handleClose={handleClose}
                        handleEnterDialog={handleEnterDialog}
                        {...props}
                    />}
            />
        </React.Fragment>
    );


    // handleSubmit = async (values, formikBag) => {
    // const { proyecto } = this.props;
    // const newData = await postRecurso(values);
    // await addRecursosProyecto(proyecto._id, [newData._id]);
    // this.handleVolver();
    // }



}

export default AgregarRecurso;