import React from 'react';

//import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';


import List from '@material-ui/core/List';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

import { redirecTo } from '../../services/auth.js';

import logo from '../../img/logo.png'


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    avatar: {
        width: 32,
        height: 32,
    },
    avatarButton: {
        padding: 0
    },
    bigAvatar: {
        margin: 10,
    },
    title_logo: {
        textAlign: 'left',
        paddingRight: "15px"
    },
    title_container: {
        flexGrow: 9,
        textAlign: 'left',
    },
    title: {
        margin: 0
    },
    sub_title: {
        margin: 0
    },
    root: {
        display: 'flex',
        padding: '0px',
        margin: '0px',
        width: 240

    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    logo: {
        height: '46px',
        verticalAlign: "middle"
    }
}));

export default function PersistentDrawerLeft(props) {
    // const { usuarioAutenticado, handleLogin, handleLogout, handleRedirect } = props
    const classes = useStyles();
    // const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState({
        top: false,
        open: false,
        open2: false,
        open3: false,
        form: {
            email: "",
            password: "",
        },
    });





    const handleClick = menu => e => {
        setState({
            ...state,
            open: menu === "admin" ? !state.open : false,
            open2: menu === "proyectos" ? !state.open2 : false,
            open3: menu === "clientes" ? !state.open3 : false,
        })
    }


    return (
        <div className={classes.root}>
            <List style={{ width: 240 }}>
                <ListItem>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <img src={logo} alt="Logo" />
                    </Grid>

                </ListItem>
                <ListItem button>
                    {false && <ListItemIcon></ListItemIcon>}
                    <ListItemText primary="CyDocs.cl" onClick={() => { redirecTo("https://www.cydocs.cl") }} />
                </ListItem>

                <ListItem button onClick={handleClick("admin")}>
                    {false && <ListItemIcon></ListItemIcon>}
                    <ListItemText primary="Administración" />
                    {state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Gestión de Personas" />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Portafolio de Contratos" onClick={() => { redirecTo("https://portafolio.cydocs.cl") }} />
                        </ListItem>
                        <ListItem button className={classes.nested} component="a" onClick={() => { redirecTo("https://sgc.cydocs.cl") }}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Sistema de Adquisiciones" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={handleClick("proyectos")}>
                    {false && <ListItemIcon></ListItemIcon>}
                    <ListItemText primary="Proyectos" />
                    {state.open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={state.open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={() => { redirecTo("https://proyectos.cydocs.cl") }}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Proyectos" />
                        </ListItem>
                        <ListItem button className={classes.nested} onClick={() => { redirecTo("https://sgi.cydocs.cl") }}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="SGI" />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Gestión del Conocimiento" />
                        </ListItem>
                        <ListItem button className={classes.nested} onClick={() => { redirecTo("https://apps.cydocs.cl") }}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Descargas App" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={handleClick("clientes")}>
                    {false && <ListItemIcon></ListItemIcon>}
                    <ListItemText primary="Clientes" />
                    {state.open3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={state.open3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Proyectos" />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText inset secondary="Dashboard" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button>
                    {false && <ListItemIcon></ListItemIcon>}
                    <ListItemText primary="Contactos" />
                </ListItem>
            </List>


        </div>
    );
}