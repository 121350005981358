/**
 * Funciones de consulta a base de datos para lineas base
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import Axios from "axios";
/**
 * Obtener una linea base de la base de datos
 * @param {String} id id de la linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getLineaBase = (id, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/${id}`;
    const promise = Axios.get(url, {
        canceltoken: cancelToken
    });
    return promise
}
/**
 * Obtener todos las lineas base de la base de datos
 * @param {Object} filters filtros para aplicar a los resultados de la búsqueda
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getAllLineaBase = (filters, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/`;
    const promise = Axios.get(url, {
        params: filters,
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Actualizar los datos una linea base en la base de datos
 * @param {String} id id de la linea base
 * @param {Object} data campos actualizados
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const updateLineaBase = (id, data, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/${id}`;
    const promise = Axios.put(url, data, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Eliminar una linea base de la base de datos
 * @param {String} id id de la linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deleteLineaBase = (id, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/${id}`;
    const promise = Axios.delete(url, {
        canceltoken: cancelToken
    });
    return promise;
}
/**
 * Crear una nueva linea base en la base de datos
 * @param {Object} data Campos de la nueva linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postLineaBase = (data, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/`;
    const promise = Axios.post(url, data, {
        canceltoken: cancelToken
    });
    return promise
}

/**
 * Eliminar los dias de una linea base de la base de datos
 * @param {String} id id de la linea base
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deleteDiasLineaBase = (id, cancelToken=null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/delete/dias`;
    const promise = Axios.delete(url, {
        canceltoken: cancelToken,
        params: {
            linea_base_ref: id
        }
    });
    return promise;
}

/**
 * Obtener indicadores de linea base de un proyecto para un periodo de tiempo
 * @param {String} proyecto_ref id del proyecto
 * @param {Date} fechaInicio fecha inicio del periodo
 * @param {Date} fechaTermino fecha termino del periodo
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getIndicadoresPeriodoLineaBase = (proyecto_ref, fechaInicio, fechaTermino, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/utils/indicadores-periodo`;
    const promise = Axios.post(url, {
        proyecto_ref: proyecto_ref,
        fechaInicio: fechaInicio,
        fechaTermino: fechaTermino
    },
        {
            canceltoken: cancelToken
        });
    return promise;
}

/**
 * Obtener indicadores de linea base de un proyecto para un periodo de tiempo
 * @param {String} proyecto_ref id del proyecto
 * @param {Date} fechaInicio fecha inicio del periodo
 * @param {Date} fechaTermino fecha termino del periodo
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getIndicadoresAcumuladosPeriodoLineaBase = (proyecto_ref, fechaInicio = null, fechaTermino = null, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const url = `${base}/lineas-base/utils/indicadores-acumulados-periodo`;
    const promise = Axios.post(url, {
        proyecto_ref: proyecto_ref,
        fechaInicio: fechaInicio,
        fechaTermino: fechaTermino
    },
        {
            canceltoken: cancelToken
        });
    return promise;
}