import Axios from "../axios";

export const getPod = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/pods/${id}`, {
            
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const getPods = async (filter = {}) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/pods/`, {
            params: filter
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}


export const updatePod = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/pods/${id}`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}
/**
 * Request para eliminar la información de un pod
 * * @param {String} id id del pod
 * * @param {Boolean} conAvances si es true se eliminan lo avances
 */

export const deletePod = async (id, conAvances = false) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/pods/${id}`, {
            params: {
                avances: conAvances
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const postPod = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/pods/`, body)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}