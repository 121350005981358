
import Axios from "axios";
import { saveAs } from 'file-saver';
import axios from "./database/axios";

function getLocationUrl(file, folderId, name) {
  return new Promise((resolve, reject) => {
    try {
      var chunk = (file.size > 262144) ? 262144 : file.size;
      var url = "https://cydocs-drive-api.herokuapp.com/get-location";
      var data = {
        'parent': folderId,
        'mime_type': file.type ? file.type : "application/octet-stream",
        'size': file.size,
        'chunk': chunk,
        'name': name,
      }
      Axios.post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    }
    catch (error) {
      reject(error);
    }

  });
}

function uploadChunks(file, name, location, progressCallback) {
  return new Promise((resolve, reject) => {
    try {
      const chunkSize = (file.size > 262144) ? 262144 : file.size;
      const chunksQuantity = Math.ceil(file.size / chunkSize);
      recursiveUploadChunks(0, chunksQuantity, file, name, location, null, chunkSize, resolve, reject, progressCallback);
    }
    catch (error) {
      reject(error);
    }
  });
}

function recursiveUploadChunks(index, length, file, name, location, finalResponse, size, resolve, reject, progressCallback) {
  progressCallback({ loaded: index + 1, total: length, lengthComputable: true });
  if (index == length) {
    resolve(finalResponse.body);
  } else {
    var headers = (finalResponse && finalResponse.headers) ? finalResponse.headers : null;
    var currentRange = 0;
    if (headers && headers.range) currentRange = headers.range[0];
    if (headers && headers.Range) currentRange = headers.Range[0];
    var begining = (currentRange != 0) ? (parseInt(currentRange.split("=")[1].split("-")[1]) + 1) : 0;
    var nextChunk = begining + size - 1;
    nextChunk = (nextChunk > file.size) ? file.size - 1 : nextChunk;
    var final = nextChunk + 1;
    var chunk = file.slice(begining, final);
    var chunkRange = (begining) + "-" + nextChunk + "/" + file.size;
    var chunkSize = nextChunk - begining + 1;
    var mimeType = file.type ? file.type : "application/octet-stream";
    uploadChunk(chunk, chunkSize, chunkRange, mimeType, location, name)
      .then((response) => {
        recursiveUploadChunks(index + 1, length, file, name, location, response, size, resolve, reject, progressCallback);
      })
      .catch(reject);
  }
}

function uploadChunk(chunk, chunkSize, chunkRange, mimeType, location, name) {
  return new Promise((resolve, reject) => {
    var form = new FormData();
    form.set('file', chunk, name);
    form.append('range', chunkRange);
    form.append('mime_type', mimeType);
    form.append('size', chunkSize);
    form.append('location', location.location);
    var url = "https://cydocs-drive-api.herokuapp.com/chunked-resumable-upload";
    Axios.post(url, form)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function uploadFile(file, name, location, progressCallback) {
  return new Promise((resolve, reject) => {
    var form = new FormData();
    form.set('file', file, name);
    form.append('mime_type', (file.type ? file.type : "application/octet-stream"));
    form.append('size', file.size);
    form.append('location', location.location);
    var url = "https://cydocs-drive-api.herokuapp.com/resumable-upload";
    Axios.post(url, form, { onUploadProgress: progressCallback })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function uploadFileToDrive(file, folderId, name, progressCallback = (event) => { }) {
  return new Promise((resolve, reject) => {
    getLocationUrl(file, folderId, name)
      .then((response) => {
        return (file.size > 262144) ? uploadChunks(file, name, response, progressCallback) : uploadFile(file, name, response, progressCallback);
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function downloadUrlAsPromise(url, fileName, token = null) {
  try {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function (evt) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve({
              blob: xhr.response,
              name: fileName
            });
          } else {
            reject(new Error("Ajax error for " + url + ": " + xhr.status));
          }
        }
      };
      xhr.send();
    });
  } catch (e) {
    console.log(e)
  }
}

export function viewDriveFile(id, nombre) {

  const request = "https://www.googleapis.com/drive/v3/files/" + id + "?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    return axios.get("https://cydocs-drive-api.herokuapp.com/getToken")
      .then(tokenResponse => {
        const token = tokenResponse.data.access_token;
        return downloadUrlAsPromise(request, nombre, token)
      })
      .then(function (response) {
        saveAs(response.blob, nombre);
        window.close();
      })
      .catch(function (error) {
        console.log(error);
      });

  } else {
    const windowRef = window.open('', '_blank');
    return axios.get("https://cydocs-drive-api.herokuapp.com/getToken")
      .then(tokenResponse => {
        const token = tokenResponse.data.access_token;
        return downloadUrlAsPromise(request, nombre, token)
      })
      .then(function (response) {
        const blobUrl = URL.createObjectURL(response.blob);
        windowRef.location.href = blobUrl;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

}