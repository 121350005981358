import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,

} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

export default function Page(props) {

    const {
        fecha,
        onChangeDate,
        onEnterDialog,
        open,
        submit

    } = props;


    return (
        <Dialog
            open={open}
            maxWidth="md"
            onEntered={onEnterDialog}
        >
            <DialogTitle id="alert-dialog-title">Generar POD</DialogTitle>
            <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <KeyboardDatePicker
                                name="fecha"
                                autoOk
                                fullWidth
                                InputLabelProps={{
                                    classes: {
                                        shrink: "shrink"
                                    }
                                }}
                                margin="normal"
                                inputVariant="outlined"
                                label="Seleccionar Fecha para el POD"
                                format="dd/MM/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                                onChange={onChangeDate}
                                value={fecha}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={fecha === null}
                    onClick={() => submit(fecha)}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}