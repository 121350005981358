import Axios from "../axios";
import RegistroAvance from "../../../model/avances/avance_model";

export const getPrograma = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/programas/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const getItemsPrograma = async (id, query = {}) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        if (id) {
            Axios.get(`${base}/items-programas`, { params: { ...query, programa_ref: id } })
                .then((res) => {
                    if (res.data) resolve(res.data);
                    else resolve(null);
                })
                .catch((error) => {
                    console.log(error);
                    resolve(error);
                });
        } else {
            Axios.get(`${base}/items-programas`, { params: query })
                .then((res) => {
                    if (res.data) resolve(res.data);
                    else resolve(null);
                })
                .catch((error) => {
                    console.log(error);
                    resolve(error);
                });
        }
    });
}

export const getItemPrograma = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/items-programas/${id}/`)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}


export const getAvancesPeriodo = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/programas/${id}/avance-periodo`, { params: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}
/**
 * Obtener avance de hh por actividad para un periodo y proyecto
 * @param {string} id id del proyecto
 * @param {params}
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getAvancesPeriodoHH = async (id, params, cancelToken = null) => {
    const base = process.env.REACT_APP_DB_URL;
    const promise = Axios.get(`${base}/programas/${id}/avance-periodo-hh`,
        {
            params: params,
            cancelToken: cancelToken
        });
    return promise;
}


/**
 * Request para obtener un item programa/actividad de un programa por el valor de su item
 * @programa id del programa
 * @item item del item programa/actividad
 */

export const getItemProgramaByItem = async (programa, item) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/items-programas/`, {
            params: {
                programa_ref: programa,
                item: item
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request los datos de un programa, sus items/actividades e indicadores
 * @id id del programa
 * @item item del item programa/actividad
 */

export const getBuildPrograma = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/programas/${id}/build`)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const postPrograma = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/programas/`, body)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const postItemPrograma = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/items-programas/`, body, {
            params: {
                recurcibly: true
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const agregarItemsPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/programas/${id}/items-programas`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const agregarAvanceItemsPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/items-programas/${id}/avances`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const agregarHijoItemPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/items-programas/${id}/hijos`, params)
            .then((res) => {
                // console.log(res.data);
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const updatePrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/programas/${id}`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const updateItemPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/items-programas/${id}`, params, {
            params: {
                recurcibly: true
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const updateAvanceItemPrograna = async (item, id, params) => {
    const avance = new RegistroAvance(params);
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/items-programas/${item}/avances/${id}`, avance)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const deleteItemPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/items-programas/${id}`)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const eliminarItemProgramaSoloLogica = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/items-programas/${id}/logica`, { data: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const updateEstadoItemPrograma = async (id, estado) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/items-programas/${id}/estado`, { estado: estado })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const quitarItemPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/programas/${id}/items-programas`, { data: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}



export const quitarHijoItemPrograma = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/items-programas/${id}/hijos`, { data: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const quitarAvanceItemsPrograma = async (item, id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/items-programas/${item}/avances/${id}`)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const reprogramarItemsPrograma = async (item, fechaReprogramacion, diasReprogramados) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/items-programas/${item}/reprogramar-actividad`, {
            fechaReprogramacion: fechaReprogramacion,
            diasReprogramados: diasReprogramados
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}

export const reprogramarItemsProgramaDesdeArchivo = async (id, reprogramacion) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/items-programas/${id}/reprogramar-actividad-desde-archivo`, reprogramacion)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                resolve(error);
            });
    });
}