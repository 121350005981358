import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Page from './page'; 

const useStyles = makeStyles({
    list: {
        width: 450,
    },
    fullList: {
        width: 'auto',
    },
  
});



class DrawerApps extends Component {
    state = {
        left: true,
    };
    constructor(props) {
        super(props);

        this.state = {
            openClose: false,
            click_menu: false
        };
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(event) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        return;
    }

    render() {
        //console.log("RE-Remder drawer", this.state.openClose)
        //this.state.openClose  = ! this.state.openClose;

        const sideList = <div className={useStyles.list} role="presentation">
            <Page ></Page>
        </div>;




        return <div>
            <Drawer style={{width: 240}} open={this.props.click_menu} anchor="right" onClose={this.handleClick}>
                {sideList}
            </Drawer>
        </div>;
    }
}

export default DrawerApps;
