import React, { Component } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from "yup";
import { getRecurso, updateRecurso } from '../../../services/database/recursos';

class Editar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contrato: null
        };

    }

    render() {
        const validationSchema = Yup.object({
            nombre: Yup.string().required("Campo requerido"),
            descripcion: Yup.string().required("Campo requerido"),
            cantidad: Yup.number("Debe ser un valor numérico").min(0, "Debe ser mayor que cero").required("Campo requerido"),

        });
        const values = {
            nombre: '',
            descripcion: '',
            cantidad: ''
        };
        return (

            <React.Fragment>
                <Formik
                    ref={(ref) => this.formik = ref}
                    onSubmit={this.handleSubmit}
                    initialValues={values}
                    validationSchema={validationSchema}
                    onChange={this.handleChange}
                    render={props =>
                        <Page
                            titulo="Nuevo Recurso"
                            handleVolver={this.handleVolver}
                            {...props}
                        />}
                />
            </React.Fragment>
        );
    }

    componentDidMount = () =>{
        this.handleReset();
    }

    handleReset = async () => {
        const id = this.props.match.params.id;
        const data = await getRecurso(id);
        this.formik.resetForm({
            nombre: data.nombre,
            descripcion: data.descripcion,
            cantidad: data.cantidad
        });
    
    }


    handleSubmit = async (values, formikBag) => {
        const id = this.props.match.params.id;
        await updateRecurso(id, values);
        this.handleVolver();
    }

    handleVolver = () => {
        this.props.history.push("/recursos");
    };


    handleChange = (e, { name, value }) => {
        this.props.setFieldValue(name, value);
        e.persist();
    };

}

function mapStateToProps(state) {
    const props = {
        contrato: null,
        proyecto: null,
        permisos:{
            verActividades: true
        }
    };
    if (state.accionSelectorContrato.accion === 'CLOSE') {
        props["contrato"] = state.accionSelectorContrato.payload.contrato;
        props["proyecto"] = state.accionSelectorContrato.payload.proyecto;
        props["actualizar"] = state.accionSelectorContrato.payload.proyecto;
    }
    return props;

}

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Editar)
);