import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { accionDialogoMensaje } from '../../redux/reducers/reducerDialogoMensaje';

export default function DialogoMensaje(props) {
  const {open, mensaje, titulo} = useSelector(state =>({
    open: state.dialogoMensaje.open,
    mensaje: state.dialogoMensaje.mensaje,
    titulo: state.dialogoMensaje.titulo
  }));
  const dispatch = useDispatch();
  const handleClose = () =>{
    dispatch(accionDialogoMensaje("CLOSE", "", ""));
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth={true}
      onClose={handleClose}
    >
      <DialogTitle>{titulo ? titulo : ""}</DialogTitle>
      <DialogContent>
        {mensaje ? mensaje : ""}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          Aceptar
          </Button>
        <Button onClick={handleClose} color="primary">
          Cancelar
          </Button>
      </DialogActions>
    </Dialog >
  );
}