import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { accionOpenAgregarReprogramacionArchivo, accionCloseAgregarReprogramacion } from '../actionsReprogramacion';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import { getPrograma, reprogramarItemsPrograma, updatePrograma } from '../../../services/database/programacion';

export default function AgregarReprogramacion(props) {

    const notisnack = useSnackbar();
    const dispatch = useDispatch();
    const { open, proyecto, inicioPrograma } = useSelector(state => ({
        open: state.reducerReprogramacion.openAgregarReprogramacion,
        proyecto: state.contratoSesion.proyecto
    }));

    const [formik, setFormik] = useState(null);

    const validationSchema = Yup.object({
        fecha_reprogramacion: Yup.date()
    });

    const values = {
        fecha_reprogramacion: new Date(),
        file: null
    };

    const handleEnterDialog = async () => {
        formik.resetForm(values);
    }

    const handleChangeDate = (name, e) => {
        formik.setFieldTouched(name, true, false);
        if (e) formik.setFieldValue(name, e);
        else formik.setFieldValue(name, null);

    };

    const handleFileChange = (name, e) => {
        formik.setFieldTouched(name, true, false);
        formik.setFieldValue(name, e.target.files[0]);
    };

    const handleOnSubmit = async (values, formikBag) => {
        const { handleSubmit } = props;
        const key = notisnack.enqueueSnackbar("Reprogramación en proceso...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            const programa = await getPrograma(proyecto.programa_actual_ref);
            const momentInicioProgramado = moment(inicioPrograma);
            const diasReprogramacion = moment(values.fecha_reprogramacion).diff(momentInicioProgramado, 'days');
            // await reprogramarItemsPrograma("5e46eabec7300c0030350552", moment(values.fecha_reprogramacion).format("YYYY-MM-DD 00:00:00.000"), diasReprogramacion);
            for (let i = 0; i < programa.items_programas_ref.length; i++) {
                await reprogramarItemsPrograma(programa.items_programas_ref[i], moment(values.fecha_reprogramacion).format("YYYY-MM-DD 00:00:00.000"), diasReprogramacion);
            }
            await updatePrograma(proyecto.programa_actual_ref, {fecha_reprogramacion: values.fecha_reprogramacion});
            notisnack.closeSnackbar(key);
            const successkey = notisnack.enqueueSnackbar("Reprogramación exitosa", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            if (handleSubmit) handleSubmit(values);
        }
        catch (error) {
            console.log(error);
            notisnack.closeSnackbar(key);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible realizar la reprogramación. Contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });

        }
    }

    const handleClose = () => {
        dispatch(accionCloseAgregarReprogramacion());
    }

    const handleDesdeArchivo = () => {
        dispatch(accionCloseAgregarReprogramacion());
        dispatch(accionOpenAgregarReprogramacionArchivo());
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                isInitialValid={true}
                render={props =>
                    <Page
                        open={open}
                        titulo="Reprogramación desde Fecha"
                        handleDesdeArchivo={handleDesdeArchivo}
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        handleChangeDate={handleChangeDate}
                        handleFileChange={handleFileChange}
                        // categorizaciones={categorizaciones}
                        validationSchema={validationSchema}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}