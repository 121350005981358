import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import {
    getAvancesPeriodoPodProyectoHH,
    getHorasEfectivasPeriodoProyecto,
    getAvancePeriodoProgramaSemanal
} from '../../../services/database/proyecto';
import moment from 'moment';
import { accionSetInicioSemanaReportePod } from './../actionsPod';
moment.locale('es');
export default function ReportabilidadPod(props) {

    const chartRef = useRef(null);
    const dispatch = useDispatch();
    const {
        // usuarioSesion,
        // contrato,
        proyecto,
        inicioSemana
    } = useSelector(state => ({
        // usuarioSesion: state.usuarioSesion,
        // contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto,
        inicioSemana: state.reducerPod.inicioSemanaReportePod
    }));
    const [avancesPeriodoPodProyecto, setAvancesPeriodoPodProyecto] = useState({});
    const [avancesPeriodoProgramaSemanal, setAvancesPeriodoProgramaSemanal] = useState(null);
    const [cumplimientoPeriodo, setCumplimientoPeriodo] = useState(0);
    const [cumplimientoPeriodoAnterior, setCumplimientoPeriodoAnterior] = useState(0);
    const [configChart, setConfigChart] = useState(null);
    const [indiceEficienciaHorasEfectivasPeriodo, setIndiceEficienciaHorasEfectivasPeriodo] = useState(0);
    const [indiceEficienciaHorasEfectivasAnterior, setIndiceEficienciaHorasEfectivasAnterior] = useState(0);
    const [isloading, setIsLoading] = useState(true);
    const [horaEfectivasProyecto, setHoraEfectivasProyecto] = useState([]);


    const loadDataPodSemana = async () => {
        setAvancesPeriodoPodProyecto({});
        if (proyecto && proyecto.programa_actual_ref) {
            const avancesPeriodoPodProyecto = await getAvancesPeriodoPodProyectoHH(
                proyecto._id,
                moment(inicioSemana).subtract(7, 'day').format("YYYY-MM-DD"),
                moment(inicioSemana).endOf("week").format("YYYY-MM-DD")
            );
            const horaEfectivasProyecto = await getHorasEfectivasPeriodoProyecto(
                proyecto._id,
                moment(inicioSemana).subtract(7, 'day').format("YYYY-MM-DD"),
                moment(inicioSemana).endOf("week").format("YYYY-MM-DD")
            );
            const avancesSemanales = await getAvancePeriodoProgramaSemanal(
                proyecto._id,
                moment(inicioSemana).subtract(7, 'day').format("YYYY-MM-DD"),
                moment(inicioSemana).endOf("week").format("YYYY-MM-DD")
            );
            let cumplimientoPeriodoActual = 0;
            let cumplimientoPeriodoAnterior = 0;
            let indiceEficienciaHorasEfectivasActual = 0;
            let indiceEficienciaHorasEfectivasAnterior = 0;
            let diasCumplimientoActual = 0;
            let diasCumplimientoAnterior = 0;

            if (avancesPeriodoPodProyecto) {
                avancesPeriodoPodProyecto["indicadores"] = avancesPeriodoPodProyecto.avancesSemana.reduce(
                    (b, a, index) => {
                        b.avanceRealPod = b.avanceRealPod + a.avanceRealPod;
                        b.avanceProgramadoPod = b.avanceProgramadoPod + a.avanceProgramadoPod;
                        b.avanceProgramado = b.avanceProgramado + a.avanceProgramado;
                        b.cumplimiento = b.cumplimiento + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
                        b.cumplimientoProg = b.cumplimientoProg + (a.cumplimientoProg ? parseFloat(a.cumplimientoProg) : 0);
                        
                        b.horasHombreProgramadas = b.horasHombreProgramadas + (a.horas_hombre_programadas ? a.horas_hombre_programadas : (a.horasHombreProgramadas ? parseFloat(a.horasHombreProgramadas) : 0));
                        b.horasHombreReales = b.horasHombreReales + (a.horasHombreReales ? parseFloat(a.horasHombreReales) : 0);
                        if (horaEfectivasProyecto) b.horasReales = b.horasReales + horaEfectivasProyecto[index].horas_efectivas;
                        b.horasMeta = b.horasMeta + 10;
                        const indicadorHorasProgMeta = (a.horasHombreProgramadas && 10 ? parseFloat(a.horasHombreProgramadas) / 10 : 0);
                        const indicadorHorasGanReal = (a.horasHombreReales && horaEfectivasProyecto[index].horas_efectivas ? parseFloat(a.horasHombreReales) / parseFloat(horaEfectivasProyecto[index].horas_efectivas) : 0);
                        const indiceEficienciaHorasEfectivas = (indicadorHorasProgMeta ? (indicadorHorasGanReal / indicadorHorasProgMeta) : 0)
                        if (horaEfectivasProyecto) horaEfectivasProyecto[index]["indiceEficienciaHorasEfectivas"] = indiceEficienciaHorasEfectivas;
                        b.indiceEficienciaHorasEfectivas = b.indiceEficienciaHorasEfectivas + indiceEficienciaHorasEfectivas;
                        if (moment(a.fecha).isSameOrAfter(inicioSemana)) {
                            indiceEficienciaHorasEfectivasActual = indiceEficienciaHorasEfectivasActual + indiceEficienciaHorasEfectivas;
                            cumplimientoPeriodoActual = cumplimientoPeriodoActual + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
                            diasCumplimientoActual++;
                        }
                        if (moment(a.fecha).isBefore(inicioSemana)) {
                            indiceEficienciaHorasEfectivasAnterior = indiceEficienciaHorasEfectivasAnterior + indiceEficienciaHorasEfectivas;
                            cumplimientoPeriodoAnterior = cumplimientoPeriodoAnterior + (a.cumplimiento ? parseFloat(a.cumplimiento) : 0);
                            diasCumplimientoAnterior++;
                        }
                        if(avancesSemanales && avancesSemanales[index] && avancesSemanales){
                            b.avancesPeriodoProgramaSemanal = avancesSemanales[index].avance_programado;
                        }
                        return b;
                    }, {
                    avancesPeriodoProgramaSemanal: 0,
                    avanceRealPod: 0,
                    avanceProgramadoPod: 0,
                    avanceProgramado: 0,
                    cumplimiento: 0,
                    cumplimientoProg: 0,
                    horasHombreProgramadas: 0,
                    horasHombreReales: 0,
                    horasReales: 0,
                    horasMeta: 0,
                    indiceEficienciaHorasEfectivas: 0
                });
                if (diasCumplimientoActual) {
                    indiceEficienciaHorasEfectivasActual = indiceEficienciaHorasEfectivasActual / diasCumplimientoActual;
                    cumplimientoPeriodoActual = cumplimientoPeriodoActual / diasCumplimientoActual;
                }
                if (diasCumplimientoAnterior) {
                    indiceEficienciaHorasEfectivasAnterior = indiceEficienciaHorasEfectivasAnterior / diasCumplimientoAnterior;
                    cumplimientoPeriodoAnterior = cumplimientoPeriodoAnterior / diasCumplimientoAnterior;
                }
                if (avancesPeriodoPodProyecto.avancesSemana.length > 0) {
                    avancesPeriodoPodProyecto.indicadores.cumplimiento = avancesPeriodoPodProyecto.indicadores.cumplimiento / avancesPeriodoPodProyecto.avancesSemana.length;
                    avancesPeriodoPodProyecto.indicadores.cumplimientoProg = avancesPeriodoPodProyecto.indicadores.cumplimientoProg / avancesPeriodoPodProyecto.avancesSemana.length;
                    avancesPeriodoPodProyecto.indicadores.indiceEficienciaHorasEfectivas = avancesPeriodoPodProyecto.indicadores.indiceEficienciaHorasEfectivas / avancesPeriodoPodProyecto.avancesSemana.length;
                    avancesPeriodoPodProyecto.indicadores.avancesPeriodoProgramaSemanal = avancesPeriodoPodProyecto.indicadores.avancesPeriodoProgramaSemanal / avancesPeriodoPodProyecto.avancesSemana.length;
                }
                setAvancesPeriodoPodProyecto(avancesPeriodoPodProyecto);
                setHoraEfectivasProyecto(horaEfectivasProyecto);
                loadChart(avancesPeriodoPodProyecto, avancesSemanales);
                setIndiceEficienciaHorasEfectivasPeriodo(indiceEficienciaHorasEfectivasActual);
                setCumplimientoPeriodo(cumplimientoPeriodoActual);
                setIndiceEficienciaHorasEfectivasAnterior(indiceEficienciaHorasEfectivasAnterior);
                setCumplimientoPeriodoAnterior(cumplimientoPeriodoAnterior);
            }
            setAvancesPeriodoProgramaSemanal(avancesSemanales);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        loadDataPodSemana();
    }, [proyecto, inicioSemana]);

    const loadChart = async (avancePeriodo, avancesSemanales) => {
        if (avancePeriodo !== null && avancePeriodo.avancesSemana) {
            const chartData = {
                avanceProgramadoPOD: [],
                avanceRealPOD: [],
                avanceProgramado: [],
                avanceProgramaSemanal: []
            }
            let maxValue = 0;

            avancePeriodo.avancesSemana.map((a, index) => {
                if (a.avanceProgramadoPod > maxValue) maxValue = a.avanceProgramadoPod;
                if (a.avanceRealPod > maxValue) maxValue = a.avanceRealPod;
                if (a.avanceProgramado > maxValue) maxValue = a.avanceProgramado;

                chartData.avanceProgramadoPOD.push([
                    moment(a.fecha).valueOf(),
                    a.avanceProgramadoPod
                ]);
                chartData.avanceRealPOD.push([
                    moment(a.fecha).valueOf(),
                    a.avanceRealPod
                ]);
                chartData.avanceProgramado.push([
                    moment(a.fecha).valueOf(),
                    a.avanceProgramado
                ]);
                if (avancesSemanales && avancesSemanales[index] && avancesSemanales) {
                    chartData.avanceProgramaSemanal.push([
                        moment(a.fecha).valueOf(),
                        avancesSemanales[index].avance_programado
                    ]);
                    if (avancesSemanales[index].avance_programado > maxValue) maxValue = avancesSemanales[index].avance_programado;
                } else chartData.avanceProgramaSemanal.push([moment(a.fecha).valueOf(), null]);

                return a;
            });
            console.log(chartData);
            const options = {
                xAxis: {
                    labels: {
                        step: 1
                    },
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year
                        day: '%e %b',
                        month: '%e %b %Y',
                        year: '%b %Y'
                    },
                    title: {
                        text: 'Semana'
                    },
                    // plotLines: [
                    //     xPlotLine
                    // ],
                    plotBands: [{
                        color: "#cecece",
                        from: moment(inicioSemana).utc().startOf('day').subtract(12, "hours").valueOf(),
                        to: moment(inicioSemana).utc().startOf('day').subtract(7, "days").subtract(12, "hours").valueOf()
                    }]
                },
                yAxis: [{
                    min: 0,
                    max: maxValue,
                    title: {
                        text: 'Porcentaje Avance'
                    },
                    labels: {
                        format: '{value}%',
                    },
                }
                ],
                title: {
                    text: ""
                },
                series: [
                    {
                        name: "Avance Programado L.B.",
                        type: 'column',
                        data: chartData.avanceProgramado,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                        color: 'rgb(124, 181, 236)'
                    },
                    {
                        name: "Avance Programa Semanal",
                        type: 'column',
                        data: chartData.avanceProgramaSemanal,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                        color: 'rgb(247, 163, 92)'
                    },
                    {
                        name: "Avance Programado POD",
                        type: 'column',
                        data: chartData.avanceProgramadoPOD,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
                        },
                        color: 'rgb(67, 67, 72)'
                    },
                    {
                        name: "Avance Ganado",
                        type: 'spline',
                        data: chartData.avanceRealPOD,
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
                        },
                        color: 'rgb(144, 237, 125)'
                    }

                ],
                plotOptions: {
                    area: {
                        fillOpacity: 0.2,
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                margin: 0,
                exporting: {
                    scale: 1,
                    sourceWidth: 1424,
                    sourceHeight: 400
                }


            };
            if (setConfigChart) setConfigChart(options);
            setIsLoading(false);
        }
    }

    const setInicioSemana = (value) => {
        dispatch(accionSetInicioSemanaReportePod(value));
    }

    return (
        <Page
            avancesPeriodoPodProyecto={avancesPeriodoPodProyecto}
            avancesPeriodoProgramaSemanal={avancesPeriodoProgramaSemanal}
            chartRef={chartRef}
            configChart={configChart}
            cumplimientoPeriodo={cumplimientoPeriodo}
            cumplimientoPeriodoAnterior={cumplimientoPeriodoAnterior}
            horaEfectivasProyecto={horaEfectivasProyecto}
            indiceEficienciaHorasEfectivasPeriodo={indiceEficienciaHorasEfectivasPeriodo}
            indiceEficienciaHorasEfectivasAnterior={indiceEficienciaHorasEfectivasAnterior}
            inicioSemana={inicioSemana}
            isloading={isloading}
            setInicioSemana={setInicioSemana}
        />
    );
}