/**
 * Reducer raíz del proyecto que combina todos los demás reducers
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import { combineReducers } from 'redux';
import contratoSesion from './reducerContratoUsuario';
import openSelectorContrato from './reducerSelectorContrato';
import reducerDialogoMensaje from './reducerDialogoMensaje';
import reducerPod from '../../containers/pods/reducerPod';
import reducerPrograma from '../../containers/programa/reducerPrograma';
import reducerProgramaSemanal from '../../containers/programacionSemanal/reducerProgramaSemanal';
import reducerReportePodDiario from '../../containers/reportesPodDiarios/reducerReportePodDiario';
import reducerReprogramacion from '../../containers/reprogramacion/reducerReprogramacion';
import usuarioSesion from './reducerDataUsuario';

const rootReducer = combineReducers({
    contratoSesion: contratoSesion,
    dialogoMensaje: reducerDialogoMensaje,
    openSelectorContrato: openSelectorContrato,
    reducerPod: reducerPod,
    reducerPrograma: reducerPrograma,
    reducerProgramaSemanal: reducerProgramaSemanal,
    reducerReportePodDiario: reducerReportePodDiario,
    reducerReprogramacion: reducerReprogramacion,
    usuarioSesion: usuarioSesion,
});


export default rootReducer;