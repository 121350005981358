import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Tooltip,
    IconButton,
    Grid,
    Container,
    Tabs,
    Tab
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import formatNumber from 'format-number';
import * as ROUTES from '../../constants/routes';
import './style.css';
import { Link } from 'react-router-dom';
import ReportesSemanalesPod from './reportesSemanlesPod';
import ReportabilidadPod from './reportabilidadPod';
import ListadoPods from './listadoPods';
import { ArrowBack } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    // mainPrograma: {
    //     flexGrow: 1
    // },
    card: {
        // width: "100%",
        // position: "fixed",
        // top: theme.spacing(9)
        marginBottom: theme.spacing(2)
    },
    tablaAvances: {
        marginTop: "8px",

    },
    tableBodyAvances: {
        // maxWidth: '100vw',
        // width: "100%",
        [theme.breakpoints.down('md')]: { // eslint-disable-line no-useless-computed-key
            overflow: "auto",
            display: 'block',
            maxWidth: "88vw",
        }
    },
    cardContendAvances: {
        // overflow: 'auto'
    }

}));

function Page(props) {
    const classes = useStyles();
    const {
        goTo,
        proyecto,
    } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Container className={classes.main}>
                <Card className={classes.card}>
                    <CardHeader
                        action={
                            <Tooltip title="Ir al Programa">
                                <IconButton onClick={() => goTo(`${ROUTES.PROGRAMA}`)} aria-label="settings">
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip>
                        }
                        titleTypographyProps={{
                            align: "center"
                        }}
                        title={proyecto ? (proyecto.codigo ? `[${proyecto.codigo}] ` : '') + (proyecto.nombre ? proyecto.nombre : '') : ""}
                    />
                    <CardContent className={classes.cardContendAvances}>
                      <ReportabilidadPod/>  
                    </CardContent>
                </Card>
                <Card className={classes.mainPrograma}>
                    <CardContent>
                        <Grid container>
                            <Grid item lg>
                                <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} aria-label="content-tabs">
                                    <Tab label="Listado de POD" id="tabListadoPod" aria-controls="panelListadoPod" />
                                    <Tab label="Reportes Semanales POD" id="tabReportesSemana" aria-controls="panelReportesSemana" />
                                </Tabs>
                            </Grid>
                        </Grid>
                        {selectedTab === 0 &&
                            <div id="panelListadoPod">
                                <ListadoPods
                                />
                            </div>
                        }
                        {selectedTab === 1 &&
                            <div id="panelReportesSemana">
                                <ReportesSemanalesPod />
                            </div>
                        }
                    </CardContent>
                </Card>
        </Container >
    );
}

export default Page;