/**
 * Componente con formulario para agregar una linea base
 * Se carga la información desde un archivo excel que contiene la información
 * de cada día del reporte.
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { downloadUrlAsPromise, uploadFileToDrive } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { closeAgregarLineaBase } from '../actionsReportePodDiario';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { postLineaBase, deleteDiasLineaBase } from '../requestsLineaBase';
import { postDiaLineaBase } from '../requestsDiaLineaBase';
import { crearCarpetaDrive, postCarpeta } from '../../../services/database/carpetas';
import { updateProyecto } from '../../../services/database/proyecto';

/**
 * Reducir la cantidad de decimales de un número
 * @param {*} num 
 * @param {*} cantidadDecimales 
 */
function reducirDecimales(num, cantidadDecimales = 4) {
    if (num || num === 0) return parseFloat(num.toFixed(cantidadDecimales));
    else return null;
}

export default function AgregarLineaBase(props) {
    const { usuarioSesion, proyecto, open, handleSubmit } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto,
        open: state.reducerReportePodDiario.openAgregarLineaBase,
        handleSubmit: state.reducerReportePodDiario.handleSubmitAgregarLineaBase
    }));
    const notistack = useSnackbar();
    const dispatch = useDispatch();
    const [formik, setFormik] = useState(null);
    const [hojas, setHojas] = useState(null);
    const validationSchema = Yup.object({
        hoja: Yup.string().required("Debe seleccionar una hoja"),
        excel: Yup.mixed().required("Debe seleccionar un archivo"),
        fila_final: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerica"),
        fila_inicio: Yup.number().required("Debe seleccionar una hoja").typeError("Debe ingresar un valor númerica"),
        columna_curva_programada: Yup.string().required("Debe ingresar columna").matches(/^[A-Za-z]+$/, "Solo deben ser letras"),
        columna_curva_programada_acumulada: Yup.string().required("Debe ingresar columna").matches(/^[A-Za-z]+$/, "Solo deben ser letras"),
        columna_curva_programada_pod: Yup.string().required("Debe ingresar columna").matches(/^[A-Za-z]+$/, "Solo deben ser letras"),
        columna_hh_ganada: Yup.string().required("Debe ingresar columna").matches(/^[A-Za-z]+$/, "Solo deben ser letras"),
        columna_hh_ganada_pod: Yup.string().required("Debe ingresar columna").matches(/^[A-Za-z]+$/, "Solo deben ser letras")
    });
    /**
     * Campos del formulario
     */
    const values = {
        fila_final: 0,
        fila_inicio: 0,
        columna_curva_programada: "",
        columna_curva_programada_acumulada: "",
        columna_curva_programada_pod: "",
        columna_hh_ganada: "",
        columna_hh_ganada_pod: "",
        excel: null,
        hoja: "",
        proyecto_ref: proyecto ? proyecto._id : null
    };

    /**
     * Al mostrar el dialago, se limpian los campos del formulario
     */
    const handleEnterDialog = async () => {
        formik.resetForm({
            fila_final: 0,
            fila_inicio: 0,
            columna_curva_programada: "",
            columna_curva_programada_acumulada: "",
            columna_curva_programada_pod: "",
            columna_hh_ganada: "",
            columna_hh_ganada_pod: "",
            excel: null,
            hoja: "",
            proyecto_ref: proyecto ? proyecto._id : null
        });
        setHojas(null);

    }
    /**
     * Manejar cambio de valores de campo de formulario
     * @param {String} name nombre del campo modificado
     * @param {Event} e event que gatilla el cambio de un valor
     * @param {*} e.target.value valor nuevo del campo
     */
    const handleChange = (name, e) => {

        formik.setFieldTouched(name, true);
        // const value = e.target.value;
        if (name === "hoja") formik.setFieldValue("fila_final", hojas[e.target.value].celda_final);
        if (name === "columna_curva_programada" || name === "columna_curva_programada_acumulada" || name === "columna_hh_ganada" || name === "columna_hh_ganada_pod" || name === "columna_curva_programada_pod") {
            formik.setFieldValue(name, e.target.value.toUpperCase());
        } else {
            formik.setFieldValue(name, e.target.value);
        }
    };
    /**
     * Manejar cambio de valores de campos file del formulario
     * @param {String} name nombre del campo modificado
     * @param {Event} e event que gatilla el cambio de un valor
     * @param {*} e.target.value valor nuevo del campo
    */
    const handelFileChange = (name, e) => {
        previewXLSX(e.target.files[0]);
        formik.setFieldValue(name, e.target.files[0]);
        formik.setFieldTouched(name, true, false);
    };


    /**
     * Comenzar carga de archivo a drive y guardar reporte en base de datos
     * @param {Object} file archivo excel con información del reporte pod diario
     * @param {OBject} reportePodDiario datos del reporte pod diario
     */
    const handleOnSubmit = (values, formikBag) => {
        try {
            // console.log(values);
            if (values.excel && usuarioSesion.ref) {
                let fileReader = new FileReader();
                fileReader.onload = async (e) => {
                    const binary = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(binary, { type: 'array' });
                    const lineabase = {
                        archivo: null,
                        fecha: new Date(),
                        horas_hombre_ganadas: 0,
                        horas_hombre_programadas: 0,
                        inicio_programado: null,
                        proyecto_ref: proyecto._id,
                        termino_programado: 0
                    }
                    const dias = []
                    const worksheet = workbook.Sheets[hojas[values.hoja].nombre];
                    // console.log(values.fila_final);
                    // return;
                    if (worksheet) {
                        let inicio = parseInt(values.fila_inicio);
                        let termino = parseInt(values.fila_final);
                        if (inicio > termino) termino = inicio + 1;
                        let desired_row = inicio;
                        let desired_cell = worksheet["B" + desired_row];
                        let desired_value = (desired_cell ? desired_cell.w : undefined);
                        while (desired_row <= termino) {
                            if (desired_value) {
                                const dia = {
                                    fecha: new Date(desired_value),
                                    // contratista: worksheet["C" + desired_row] ? (worksheet["C" + desired_row].w) : 0,
                                    horas_hombre_ganadas: worksheet[values.columna_hh_ganada + desired_row] && worksheet[values.columna_hh_ganada + desired_row].t !== "e" ? (worksheet[values.columna_hh_ganada + desired_row].v) : 0,
                                    horas_hombre_ganadas_pod: worksheet[values.columna_hh_ganada_pod + desired_row] && worksheet[values.columna_hh_ganada_pod + desired_row].t !== "e" ? (worksheet[values.columna_hh_ganada_pod + desired_row].v) : 0,
                                    horas_hombre_programadas: worksheet[values.columna_curva_programada + desired_row] && worksheet[values.columna_curva_programada + desired_row].t !== "e" ? (worksheet[values.columna_curva_programada + desired_row].v) : 0,
                                    horas_hombre_programadas_acumuladas: worksheet[values.columna_curva_programada_acumulada + desired_row] && worksheet[values.columna_curva_programada_acumulada + desired_row].t !== "e" ? (worksheet[values.columna_curva_programada_acumulada + desired_row].v) : 0,
                                    horas_hombre_programadas_pod: worksheet[values.columna_curva_programada_pod + desired_row] && worksheet[values.columna_curva_programada_pod + desired_row].t !== "e" ? (worksheet[values.columna_curva_programada_pod + desired_row].v) : 0,
                                    proyecto_ref: proyecto._id
                                };
                                // console.log(dia, dia.horas_hombre_programadas);
                                dia.horas_hombre_programadas_acumuladas = reducirDecimales(dia.horas_hombre_programadas_acumuladas, 4);
                                dia.horas_hombre_programadas = reducirDecimales(dia.horas_hombre_programadas, 4);
                                dia.horas_hombre_ganadas = reducirDecimales(dia.horas_hombre_ganadas, 4);
                                // console.log(dia, dia.horas_hombre_programadas);
                                dias.push(dia);
                                if (!lineabase.inicio_programado || moment(dia.fecha).isSameOrBefore(moment(lineabase.inicio_programado))) lineabase.inicio_programado = dia.fecha;
                                if (!lineabase.termino_programado || moment(dia.fecha).isSameOrAfter(moment(lineabase.termino_programado))) lineabase.termino_programado = dia.fecha;
                                // lineabase.horas_hombre_programadas = lineabase.horas_hombre_programadas + dia.horas_hombre_programadas;
                                lineabase.horas_hombre_ganadas = lineabase.horas_hombre_ganadas + dia.horas_hombre_ganadas;
                            }
                            desired_row++;
                            desired_cell = worksheet["B" + desired_row];
                            desired_value = (desired_cell ? desired_cell.w : undefined);
                        }
                    }

                    lineabase.horas_hombre_ganadas = reducirDecimales(lineabase.horas_hombre_ganadas, 4);
                    lineabase.horas_hombre_programadas = dias[dias.length - 1].horas_hombre_programadas_acumuladas;
                    initSubmit(values.excel, lineabase, dias);

                };
                fileReader.readAsArrayBuffer(values.excel);
            }
        }
        catch (error) {
            console.log(error);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la Línea Base. Contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });

        }
    }
    const initSubmit = async (file, lineabase, dias) => {
        try {
            const key = notistack.enqueueSnackbar("Guardando Línea Base...", {
                persist: true,
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                }
            });

            let carpeta = proyecto.carpetas.find(a => a.nombre === "Líneas Base");
            if (!carpeta) {
                const carpetaDriveApp = await crearCarpetaDrive({
                    name: `Líneas Base`,
                    parent: proyecto.carpeta_proyecto.carpeta_id
                });
                carpeta = await postCarpeta({
                    nombre: `Líneas Base`,
                    descripción: ``,
                    padre_ref: proyecto.carpeta_proyecto._id,
                    carpeta_id: carpetaDriveApp.id
                });
                proyecto.carpetas.push(carpeta);
                await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
            }
            // const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" }
            const uploadedFile = await uploadFileToDrive(file, carpeta.carpeta_id, file.name);
            if (proyecto.linea_base_ref) await deleteDiasLineaBase(proyecto.linea_base_ref);
            return postLineaBase({
                ...lineabase,
                archivo: {
                    nombre: uploadedFile.name,
                    archivo_id: uploadedFile.id,
                    carpeta_id: carpeta.carpeta_id,
                    carpeta_ref: carpeta._id,
                    size: file.size,
                    repositorio: "DRIVE",
                    url: uploadedFile.url
                }})
                .then(async (response) => {
                    if (response.status === 500) {
                        console.log("error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la Línea Base. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    } else if (response.status === 422) {
                        console.log("error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la Línea Base. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    } else if (response.status === 201) {

                        for (let d = 0; d < dias.length; d++) {
                            await postDiaLineaBase({ ...dias[d], linea_base_ref: response.data._id });
                        }
                        notistack.closeSnackbar(key);
                        const successkey = notistack.enqueueSnackbar("Operanción exitosa: Línea Base guardado", {
                            variant: 'success',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
                        });
                        if (handleSubmit) handleSubmit();
                    } else {
                        console.log("unhandled error", response);
                        notistack.closeSnackbar(key);
                        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la Línea Base. Contacte con soporte el equipo de soporte", {
                            variant: 'error',
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom"
                            },
                            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    notistack.closeSnackbar(key);
                    const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la Línea Base. Contacte con soporte el equipo de soporte", {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom"
                        },
                        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
                    });
                });
        }
        catch (error) {
            console.log(error);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar la Línea Base. Contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });

        }
    }

    /**
     * Precargar excel y extraer el nombre de sus hojas
     * @return {void}
     */
    const previewXLSX = async (file) => {
        if (file) {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                const binary = new Uint8Array(e.target.result);
                const workbook = XLSX.read(binary, { type: 'array' });
                const hojasXLSX = workbook.SheetNames.map((value, index) => {
                    const ws = workbook.Sheets[value];
                    let range = XLSX.utils.decode_range(ws['!ref']);
                    let num_rows = range.e.r;
                    return ({
                        nombre: value,
                        value: index,
                        celda_final: num_rows,
                    })
                });
                setHojas(hojasXLSX);
            };
            fileReader.readAsArrayBuffer(file);
        } else {
            setHojas(null);
        }
    }

    /**
     * Descargar formato de reporte pod diario
     * @return {void}
     */
    const handleDescargarFormato = async () => {
        const nombre = "Formato Línea Base.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/13I9P7tfyH5ila74_XuXJA7z3IdI4In1w?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    /**
     * Cerrar dialogo
     */
    const handleClose = () => dispatch(closeAgregarLineaBase());

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                render={formikProps =>
                    <Page
                        {...formikProps}
                        open={open}
                        titulo="Línea Base"
                        handleChange={handleChange}
                        handleClose={handleClose}
                        handleEnterDialog={handleEnterDialog}
                        handelFileChange={handelFileChange}
                        previewXLSX={previewXLSX}
                        hojas={hojas}
                        handleDescargarFormato={handleDescargarFormato}
                    />}
            />
        </React.Fragment>
    );
}