import React, { useState } from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { accionCloseEliminarReprogramacion } from '../actionsReprogramacion';
import { deleteReprogramacion } from '../requestsReprogramacion';

export default function EliminarReprogramacion(props) {

    const dispatch = useDispatch();

    const { open, reprogramacionSeleccionada } = useSelector(state => ({
        open: state.reducerReprogramacion.openEliminarReprogramacion,
        reprogramacionSeleccionada: state.reducerReprogramacion.reprogramacionSeleccionada
    }));

    const handleOnSubmit = async () => {
        const { handleSubmit } = props;
        if (reprogramacionSeleccionada) {
            await deleteReprogramacion(reprogramacionSeleccionada._id);
        }
        handleClose();
        if (handleSubmit) handleSubmit();


    }

    const handleClose = async () => {
        dispatch(accionCloseEliminarReprogramacion());
    }


    return (

        <React.Fragment>
            <Page
                open={open}
                titulo="Eliminar Reprogramación"
                handleClose={handleClose}
                handleOnSubmit={handleOnSubmit}
            />
        </React.Fragment>
    );
}