import React, { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell as MaterialTableCell,
  TableBody,
  CircularProgress,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formatNumber from 'format-number';
import moment from 'moment';
import 'moment/locale/es';
import esLocale from 'date-fns/locale/es';
import OnOffSwitch from '../../../components/onOffSwitch';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'react-moment';
import CurvaAvanceFisico from '../curvaAvanceFisico';
import * as ROUTES from '../../../constants/routes';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const currencyFormater = formatNumber({ prefix: "$", integerSeparator: ".", decimal: "," });
const TableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    border: "1px solid #E0E0E0",
    backgroundColor: "#ddd",
    color: theme.palette.primary.main,
  },
  body: {
    border: "1px solid #E0E0E0",
    fontSize: 14,
  },
}))(MaterialTableCell);
const TableCell1 = withStyles(theme => ({
  head: {
    fontSize: 14,
    border: "1px solid #E0E0E0",
    backgroundColor: "#ddd",
    color: theme.palette.primary.main,
  },
  body: {
    fontSize: 14,
    border: "1px solid #E0E0E0",
    backgroundColor: "#ddd",
    color: theme.palette.primary.main,
  },
}))(MaterialTableCell);

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "auto",
    maxWidth: "95vw"
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  // card: {
  //     height: "100%"
  // },
  title: {
    width: "100%",
    color: theme.palette.primary.main,
    backgroundColor: "#FFF"

  },
  cardContainer: {
    minHeight: "76px"
  },
  progress: {
    margin: theme.spacing(2),
  },
  fullwith: {
    width: "100%",
    height: "100%"
  },
  button: {
    marginLeft: 'auto',
  },
  appsHeader: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main
  },
  card: {
    marginBottom: theme.spacing(2)
  }
}));

const cardHeaderTitleTypographyProps = {
  align: "center"
}

function generarFechaView(data) {
  if (data) {
    var date = new Date(data);
    if (date.getFullYear() === 2100) {
      return "indefinido";
    } else {
      return <Moment format="DD/MM/YYYY">{date}</Moment>
    }
  } else {
    return null;
  }

}

function Page(props) {
  const classes = useStyles();
  const {
    avancesPeriodoPodProyecto,
    avanceProgramado,
    avanceReal,
    conHorasHombre,
    data,
    hhGastadas,
    isLoading,
    inicioProgramado,
    inicioSemana,
    montoAcumulado,
    proyecto,
    setConHorasHombre,
    setInicioProgramado,
    setInicioSemana,
    setTerminoProgramado,
    terminoProgramado
  } = props;
  
  return (
    <Fragment>
      {(isLoading) &&
        <Grid container justify="center" alignItems="center">
          <Grid item lg style={{ textAlign: "center" }}>
            <Typography variant="h5">Cargando Información</Typography>
            <br />
            <CircularProgress size="88px" color="primary"></CircularProgress>
          </Grid>
        </Grid>
      }
      {(!isLoading) &&
        <div>
          {data &&
            <Grid
              container
              direction="row"
            >
              <Grid
                item
                lg={4}
              >
                <Grid
                  container
                  spacing={2}
                  className={classes.gridRow}
                >
                  <Grid
                    item
                    lg
                  >
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className={classes.gridRow}
                >
                  <Grid
                    item
                    lg
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center"><b>Avance Físico</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center"><b>Avance Programado</b></TableCell>
                          <TableCell>{(data.avanceProgramadoTotal ? data.avanceProgramadoTotal.toFixed(2) + "%" : "0%")}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="center"><b>Avance Real (HH gastadas)</b></TableCell>
                          <TableCell>
                            {((hhGastadas && data.hhProgramadasTotales) ? (hhGastadas / data.hhProgramadasTotales * 100) : 0).toFixed(2) + "%"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>Avance Real(HH ganadas)</b></TableCell>
                          <TableCell>
                            {(data.hhRealesAcumuladas && data.hhProgramadasTotales ? (data.hhRealesAcumuladas / data.hhProgramadasTotales * 100).toFixed(2) + "%" : "0%")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>Desviación</b></TableCell>
                          <TableCell style={{ color: avanceReal - avanceProgramado < 0 ? "red" : "black" }}>
                            {(data.desviacionAvance ? data.desviacionAvance.toFixed(2) : "0")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>SPI</b></TableCell>
                          <TableCell>
                            {(data.spiAvance ? data.spiAvance.toFixed(2) : "0")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>Duración Programada</b></TableCell>
                          <TableCell>{data.duracionProgramada + " días"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>Tiempo Transcurrido</b></TableCell>
                          <TableCell>{data.tiempoTranscurrido + " días"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>HH Programadas Totales</b></TableCell>
                          <TableCell>{data && data.hhProgramadasTotales ? numberFormater(data.hhProgramadasTotales.toFixed(0)) : 0}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>HH Programadas Acumuladas</b></TableCell>
                          <TableCell>{data && data.hhProgramadasAcumuladas ? numberFormater(data.hhProgramadasAcumuladas.toFixed(0)) : 0}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>HH Reales Acumuladas</b></TableCell>
                          <TableCell>{data && data.hhRealesAcumuladas ? numberFormater(data.hhRealesAcumuladas.toFixed(0)) : 0}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className={classes.gridRow}
                >
                  <Grid
                    item
                    lg
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center"><b>Avance Financiero</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center"><b>Monto Contrato</b></TableCell>
                          <TableCell>{currencyFormater(proyecto.monto_original ? proyecto.monto_original.toFixed(0) : 0)}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="center"><b>Monto Acumulado E. de Pago</b></TableCell>
                          <TableCell>{currencyFormater(montoAcumulado ? montoAcumulado.toFixed(0) : 0)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><b>Avance Programado</b></TableCell>
                          <TableCell>$
                              {(data.avanceFinancieroProgramado ? numberFormater(parseFloat(data.avanceFinancieroProgramado).toFixed(0)) : "0")}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="center"><b>Avance Ganado</b></TableCell>
                          <TableCell>{numberFormater((proyecto.monto_original && montoAcumulado) ? (montoAcumulado / proyecto.monto_original * 100).toFixed(2) : 0)}%</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                </Grid>

                <Grid container
                  className={classes.gridRow}
                  spacing={2}>

                  <Grid item lg>
                    {(data.inicioProgramado || data.terminoProgramado) &&
                      <Table size="small">

                        <TableBody>
                          <TableRow>
                            {data.inicioProgramado && <TableCell1 align="center"><b>Inicio Programado</b></TableCell1>}
                            {data.terminoProgramado && <TableCell1 align="center"><b>Término Programado</b></TableCell1>}
                          </TableRow>
                          <TableRow>
                            {data.inicioProgramado && <TableCell align="center">{data.inicioProgramado ? generarFechaView(data.inicioProgramado) : ""}</TableCell>}
                            {data.terminoProgramado && <TableCell align="center">{data.terminoProgramado ? generarFechaView(data.terminoProgramado) : ""}</TableCell>}
                          </TableRow>
                        </TableBody>

                      </Table>
                    }
                  </Grid>
                </Grid>
                <Grid
                  container
                >
                  <Grid
                    item
                    lg
                  >
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell1 align="center" colSpan="2"><b>Seleccionar rango de Gráfico</b></TableCell1>
                        </TableRow>


                        <TableRow>
                          <TableCell>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                              <KeyboardDatePicker
                                name="inicioProgramado"
                                autoOk
                                fullWidth
                                // margin="normal"
                                label="Inicio"
                                format="dd/MM/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                                value={inicioProgramado ? inicioProgramado.format("YYYY-MM-DD HH:mm:ss") : ""}
                                onChange={(e) => {
                                  setInicioProgramado(moment(e))
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </TableCell>

                          <TableCell>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                              <KeyboardDatePicker
                                name="terminoProgramado"
                                autoOk
                                fullWidth
                                // margin="normal"
                                label="Término"
                                format="dd/MM/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                                value={terminoProgramado ? terminoProgramado.format("YYYY-MM-DD HH:mm:ss") : ""}
                                className={classes.textField}
                                onChange={(e) => {
                                  setTerminoProgramado(moment(e))
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={8}
              >
                <Grid container
                  direction="row">
                  <Grid item lg style={{ textAlign: "right" }}>
                    <Typography><b>Fecha:</b> {moment().format("DD-MM-YYYY")}</Typography>
                  </Grid>
                </Grid>
                <CurvaAvanceFisico
                  data={data}
                  inicio={inicioProgramado}
                  termino={terminoProgramado}
                  plotLine={moment().valueOf()}
                // chartRef={props.chartRef}
                />

                {(avancesPeriodoPodProyecto.cumplimientoPromedio || avancesPeriodoPodProyecto.cumplimientoPromedio == 0) &&
                  <Grid container
                    direction="row">
                    <Grid item lg>
                      <Table size="small"
                        style={{ marginTop: "8px" }}>
                        <TableBody>
                          <TableRow>
                            <TableCell1 align="center" style={{ borderBottomColor: "#E0E0E0", width: "25%" }}><b>Cumplimiento Promedio POD</b></TableCell1>
                            <TableCell style={{ width: "25%" }} align="center">
                              {numberFormater((avancesPeriodoPodProyecto.cumplimientoPromedio ? avancesPeriodoPodProyecto.cumplimientoPromedio : 0).toFixed(2))}%
                                        </TableCell>
                            <TableCell1 style={{ borderBottomColor: "#E0E0E0", width: "25%" }} align="center"><b>Seleccionar Semana</b></TableCell1>
                            <TableCell>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                  name="semanaSeleccionada"
                                  autoOk
                                  fullWidth
                                  format="dd/MM/yyyy"
                                  InputAdornmentProps={{ position: "start" }}
                                  value={inicioSemana.format("YYYY-MM-DD HH:mm:ss")}
                                  onChange={(e) => {
                                    console.log(e);
                                    setInicioSemana(moment(e).startOf("week"));
                                  }}
                                />

                              </MuiPickersUtilsProvider>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                }
                {avancesPeriodoPodProyecto.avancesSemana &&
                  <Grid container
                    direction="row">
                    <Grid item lg style={{
                      overflow: "auto"
                    }}>
                      {avancesPeriodoPodProyecto.indicadores &&
                        <Table size="small"
                          style={{ marginTop: "8px" }}>
                          <TableBody>
                            <TableRow>
                              <TableCell1 align="center"><b>Fecha</b></TableCell1>
                              {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => <TableCell align="center" key={`${index}`}><b>{moment(a.fecha).format("DD MMM")}</b></TableCell>)}
                              <TableCell1 align="center"><b>Resumen</b></TableCell1>
                            </TableRow>
                            <TableRow>
                              <TableCell1 align="center"><b>Av. Prog.</b></TableCell1>
                              {avancesPeriodoPodProyecto.avancesSemana.map((a, index) =>
                                <TableCell align="center" key={index}>
                                  {numberFormater((a.avanceProgramado).toFixed(2))}%
                                            </TableCell>)}
                              <TableCell align="center" >
                                <b>{numberFormater((avancesPeriodoPodProyecto.indicadores.avanceProgramado).toFixed(2))}%</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell1 align="center"><b>Av. Prog. POD</b></TableCell1>
                              {avancesPeriodoPodProyecto.avancesSemana.map((a, index) =>
                                <TableCell align="center" key={index}>
                                  {numberFormater((a.avanceProgramadoPod).toFixed(2))}%
                                </TableCell>)}
                              <TableCell align="center">
                                <b>{numberFormater((avancesPeriodoPodProyecto.indicadores.avanceProgramadoPod).toFixed(2))}%</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell1 align="center"><b>Av. Real POD</b></TableCell1>
                              {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => <TableCell align="center" key={index}>
                                {numberFormater((a.avanceRealPod).toFixed(2))}%
                                        </TableCell>)}
                              <TableCell align="center">
                                <b>{numberFormater((avancesPeriodoPodProyecto.indicadores.avanceRealPod).toFixed(2))}%</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell1 align="center" style={{ borderBottomColor: "#E0E0E0" }}><b>Cump. Prog L. Base</b></TableCell1>
                              {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => <TableCell align="center" key={index}>
                                {(a.cumplimientoProg ? a.cumplimientoProg : 0).toFixed(2)}%</TableCell>)}
                              <TableCell align="center">
                                <b>{numberFormater((avancesPeriodoPodProyecto.indicadores.cumplimientoProg).toFixed(2))}%</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell1 align="center"><b>Cump. POD</b></TableCell1>
                              {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => <TableCell align="center" key={index}>{a.cumplimiento}%</TableCell>)}
                              <TableCell align="center">
                                <b>{numberFormater(avancesPeriodoPodProyecto.indicadores.cumplimiento.toFixed(2))}%</b>
                              </TableCell>
                            </TableRow>

                          </TableBody>
                        </Table>}
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          }
          {!data &&
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                lg
                style={{ textAlign: "center" }}
              >
                <Typography variant={"h4"}> No se le ha cargado ningún Programa al Proyecto</Typography>
                <br />
                <Button variant={"outlined"} component={Link} to={ROUTES.CARGAR_PROGRAMA}>Cargar Programa</Button>
              </Grid>
            </Grid>
          }
        </div>
      }

    </Fragment>

  );
}

export default Page;