import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { updateItemPrograma, quitarHijoItemPrograma, agregarHijoItemPrograma } from '../../../services/database/programacion';

export default function EditarActividadPrograma(props) {
    const notistack = useSnackbar();
    const { open, handleCloseDialog, itemPrograma, programa, proyecto, padre } = props;
    const [titulos, setTitulos] = useState([]);
    const [fechaInicioFija, setFechaInicioFija] = useState(false);
    const [fechaTerminoFija, setFechaTerminoFija] = useState(false);
    const [formik, setFormik] = useState(null);
    const validationSchema = Yup.object({
        item: Yup.string().matches(/^[A-Za-z0-9]+$/, "Solo pueden ser letras y números, sin espacios ni carácteres especiales").required("Campo requerido"),
        nombre: Yup.string().required("Campo requerido"),
        inicio_ideal: Yup.date().required("Campo requerido"),
        inicio_temprano: Yup.date(),
        inicio_tardio: Yup.date(),
        termino_ideal: Yup.date().min(Yup.ref('inicio_ideal'), ({ min }) => `La fecha debe ser posterior o igual al ${moment(min).format("DD/MM/YYYY")}`).required("Campo requerido"),
        termino_temprano: Yup.date().min(Yup.ref('inicio_temprano'), ({ min }) => `La fecha debe ser posterioro igual al ${moment(min).format("DD/MM/YYYY")}`).required("Campo requerido"),
        termino_tardio: Yup.date().min(Yup.ref('inicio_tardio'), ({ min }) => `La fecha debe ser posterior o igual al ${moment(min).format("DD/MM/YYYY")}`).required("Campo requerido"),
        horas_hombre: Yup.number().typeError("Debe ingresar un valor númerico"),
        duracion_reprogramada: Yup.number().typeError("Debe ingresar un valor númerico"),
        inicio_reprogramado: Yup.date()
            .min(programa && programa.fecha_reprogramacion ? programa.fecha_reprogramacion : Yup.ref('inicio_ideal'), ({ min }) => `La fecha debe ser posterior o igual al ${moment(min).format("DD/MM/YYYY")}`)
            .nullable(),
        id_item: Yup.string().matches(/^\S+$/, "Sin espacios ni carácteres especiales")
    });
    const values = {
        titulo: '',
        item: '',
        id_item: '',
        nombre: '',
        inicio_ideal: new Date(),
        termino_ideal: new Date(),
        inicio_temprano: null,
        inicio_tardio: null,
        termino_temprano: null,
        termino_tardio: null,
        is_hoja: true,
        horas_hombre: 0,
        es_reprogramada: false,
        inicio_reprogramado: null,
        duracion_reprogramada: 0,
        es_hito: false,

    };

    const handleEnterDialog = async () => {
        const suggestionsTitulo = [{
            value: '', label: 'Sin Título', item: ''
        }];
        let suggestionTitulo = {
            value: '', label: 'Sin Título', item: ''
        };

        const agregarSugerencia = (data) => {
            if (itemPrograma && itemPrograma._id === data._id) return;
            const suggestion = { label: data.item + " " + data.nombre, value: data._id, item: data.item };
            if (itemPrograma && itemPrograma.padre && data._id === itemPrograma.padre) suggestionTitulo = suggestion;
            suggestionsTitulo.push(suggestion);
            if (data.hijos_ref) {
                for (let i = 0; i < data.hijos_ref.length; i++) {
                    if (itemPrograma && itemPrograma._id === programa.items_programas_ref[data.hijos_ref[i]]._id) continue;
                    agregarSugerencia(programa.items_programas_ref[data.hijos_ref[i]]);
                }
            }
        }

        for (let i = 0; i < programa.titulos.length; i++) {
            agregarSugerencia(programa.items_programas_ref[programa.titulos[i]]);
        }
        setTitulos(suggestionsTitulo);
        if (!itemPrograma) {
            formik.resetForm(values);
        } else {
            const padreObj = programa.items_programas_ref.find(a => a._id === itemPrograma.padre);
            let itemPadre = padreObj ? padreObj.item + "." : "";
            formik.resetForm({
                titulo: suggestionTitulo,
                item: (itemPadre) ? itemPrograma.item.replace(itemPadre, "") : itemPrograma.item,
                id_item: itemPrograma.id_item,
                nombre: itemPrograma.nombre,
                inicio_ideal: itemPrograma.inicio_ideal,
                termino_ideal: itemPrograma.termino_ideal,
                inicio_temprano: itemPrograma.inicio_temprano,
                inicio_tardio: itemPrograma.inicio_tardio,
                termino_temprano: itemPrograma.termino_temprano,
                termino_tardio: itemPrograma.termino_tardio,
                is_hoja: !(itemPrograma.hijos && itemPrograma.hijos.length > 0),
                horas_hombre: itemPrograma.horas_hombre,
                es_reprogramada: itemPrograma.es_reprogramada,
                inicio_reprogramado: itemPrograma.inicio_reprogramado,
                duracion_reprogramada: itemPrograma.duracion_reprogramada,
                es_hito: itemPrograma.es_hito,
            });
        }

    }

    const handleChange = (e, { name, value }) => {
        props.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = async (values, formikBag) => {
        const { handleSubmit } = props;
        const key = notistack.enqueueSnackbar("Guardando Cambios", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            let inicio = moment(new Date(values.inicio_ideal));
            let termino = moment(new Date(values.termino_ideal));
            values["proyecto_ref"] = proyecto._id;
            values["programa_ref"] = programa._id;
            // values["is_hoja"] = true;
            values["duracion"] = (termino.diff(inicio, "days") + 1);
            values.inicio_ideal = new Date(values.inicio_ideal);
            values.termino_ideal = new Date(values.termino_ideal);
            values.inicio_temprano = values.inicio_temprano ? new Date(values.inicio_temprano) : null;
            values.inicio_tardio = values.inicio_tardio ? new Date(values.inicio_tardio) : null;
            values.termino_temprano = values.termino_temprano ? new Date(values.termino_temprano) : null;
            values.termino_tardio = values.termino_tardio ? new Date(values.termino_tardio) : null;
            if (values.titulo.value || values.titulo.value === 0) {
                values["padre"] = values.titulo.value;
                values.item = values.titulo.item + "." + values.item;
            }

            await updateItemPrograma(itemPrograma._id, values);
            // await itemPrograma.update(values);
            await agregarHijoItemPrograma(values.padre, [itemPrograma._id]);
            if (values.padre !== itemPrograma.padre) {
                await quitarHijoItemPrograma(itemPrograma.padre, [itemPrograma._id]);
            }
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar("Operación exitosa: Actividad actualizada", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });

        }
        catch (error) {
            console.log(error);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
        if(handleSubmit) handleSubmit(values);
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={itemPrograma ? true : false}
                render={props =>
                    <Page
                        open={open}
                        titulo="Actividad"
                        titulos={titulos}
                        handleEnterDialog={handleEnterDialog}
                        handleCloseDialog={handleCloseDialog}
                        fechaInicioFija={fechaInicioFija}
                        fechaTerminoFija={fechaTerminoFija}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}