import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    InputLabel,
    Switch,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormLabel,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ReactSelect from 'react-select';
import formatNumber from 'format-number';
import NumberInput from '../../../components/numberInput';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        paddingTop: theme.spacing(3)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    }

}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        open,
        isValid,
        handleEnterDialog,
        handleSubmit,
        errors,
        touched,
        handleCloseDialog,
        titulos,
        fechaInicioFija,
        fechaTerminoFija
    } = props;

    const [isChecked, setIsChecked] = React.useState({
        checked: true,
    });

    const change = (name, e) => {
        const { setFieldTouched, setFieldValue, handleChange } = props;
        setFieldTouched(name, true, false);
        if (name === "titulo") {
            setFieldValue(name, e);
            handleChange(e);
        } else {
            handleChange(e);
            e.persist();
        }
    };

    const dateChange = (name, e) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        // var date = new Date(e).getTime();
        handleChange(name);
        setFieldTouched(name, true, false);
        if (e) setFieldValue(name, e);
        else setFieldValue(name, null);

    };

    const switchChange = (event, name) => {
        const { setFieldTouched, handleChange, setFieldValue } = props;
        setFieldValue(name, true, false);
        handleChange(event);
        if (name === "es_hito" && event.target.value) {
            setFieldValue("termino_ideal", values.inicio_ideal);
            setFieldValue("termino_temprano", values.inicio_temprano);
            setFieldValue("termino_tardio", values.inicio_tardio);
            setFieldValue("duracion_reprogramada", 0);
            setFieldValue("horas_hombre", 0);
        }
        // setIsChecked({ ...state, [name]: event.target.checked });

    };

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleCloseDialog}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <InputLabel htmlFor="titulo">Título</InputLabel>
                            <ReactSelect
                                menuPortalTarget={document.body}
                                searchable={true}
                                value={values.titulo}
                                onChange={change.bind(null, "titulo")}
                                name="titulo"
                                placeholder="Asociar a un título"
                                options={titulos}
                                noOptionsMessage={() => "No hay resultados"}
                                menuPosition="absolute"
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg={2}>
                            <TextField
                                name="item"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                className={classes.textField}
                                helperText={touched.item ? errors.item : ""}
                                error={touched.item && Boolean(errors.item)}
                                label="Ítem"
                                margin="normal"
                                value={values.item}
                                fullWidth
                                variant="outlined"
                                onChange={change.bind(null, "item")}

                            />
                        </Grid>
                        <Grid item lg={2}>
                            <TextField
                                name="id_item"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                className={classes.textField}
                                helperText={(touched.id_item || errors.id_item) ? errors.id_item : ""}
                                error={(touched.id_item || errors.id_item) && Boolean(errors.id_item)}
                                label="ID"
                                margin="normal"
                                value={values.id_item}
                                fullWidth
                                variant="outlined"
                                onChange={change.bind(null, "id_item")}

                            />
                        </Grid>
                        <Grid item lg={8}>
                            <TextField
                                name="nombre"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                className={classes.textField}
                                helperText={touched.nombre ? errors.nombre : ""}
                                error={touched.nombre && Boolean(errors.nombre)}
                                label="Nombre"
                                margin="normal"
                                value={values.nombre}
                                fullWidth
                                variant="outlined"
                                onChange={change.bind(null, "nombre")}

                            />
                        </Grid>
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="inicio_ideal"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de inicio programado"
                                    format="dd/MM/yyyy"
                                    helperText={touched.inicio_ideal ? errors.inicio_ideal : ""}
                                    error={touched.inicio_ideal && Boolean(errors.inicio_ideal)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "inicio_ideal")}
                                    value={values.inicio_ideal}
                                    disabled={fechaInicioFija}
                                />
                            </Grid>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="inicio_temprano"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de inicio temprano"
                                    format="dd/MM/yyyy"
                                    helperText={touched.inicio_temprano ? errors.inicio_temprano : ""}
                                    error={touched.inicio_temprano && Boolean(errors.inicio_temprano)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "inicio_temprano")}
                                    value={values.inicio_temprano}
                                    disabled={fechaInicioFija}
                                />
                            </Grid>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="inicio_tardio"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de inicio tardio"
                                    format="dd/MM/yyyy"
                                    helperText={touched.inicio_tardio ? errors.inicio_tardio : ""}
                                    error={touched.inicio_tardio && Boolean(errors.inicio_tardio)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "inicio_tardio")}
                                    value={values.inicio_tardio}
                                    disabled={fechaInicioFija}
                                />
                            </Grid>

                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="termino_ideal"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de término programado"
                                    format="dd/MM/yyyy"
                                    helperText={touched.termino_ideal ? errors.termino_ideal : ""}
                                    error={touched.termino_ideal && Boolean(errors.termino_ideal)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "termino_ideal")}
                                    value={values.termino_ideal}
                                    disabled={fechaTerminoFija || values.es_hito}
                                />
                            </Grid>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="termino_temprano"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de término temprano"
                                    format="dd/MM/yyyy"
                                    helperText={touched.termino_temprano ? errors.termino_temprano : ""}
                                    error={touched.termino_temprano && Boolean(errors.termino_temprano)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "termino_temprano")}
                                    value={values.termino_temprano}
                                    disabled={fechaTerminoFija || values.es_hito}
                                />
                            </Grid>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="termino_tardio"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de término tardio"
                                    format="dd/MM/yyyy"
                                    helperText={touched.termino_tardio ? errors.termino_tardio : ""}
                                    error={touched.termino_tardio && Boolean(errors.termino_tardio)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "termino_tardio")}
                                    value={values.termino_tardio}
                                    disabled={fechaTerminoFija || values.es_hito}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg={2}>
                                <NumberInput
                                    inputName="horas_hombre"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    meta={{
                                        touched: touched.horas_hombre,
                                        error: errors.horas_hombre

                                    }}
                                    label="HH"
                                    custom={{
                                        margin: "normal"
                                    }}
                                    value={(values.horas_hombre)}
                                    textVariant="outlined"
                                    handleOnChange={change.bind(null, "horas_hombre")}
                                    disabled={values.es_hito}
                                />
                            </Grid>
                            <Grid item lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <FormLabel component="legend">Hito</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="es_hito"
                                                    color="primary"
                                                    checked={values.es_hito}
                                                    onChange={(e) => switchChange(e, 'es_hito')}
                                                    value={values.es_hito}
                                                />
                                            }
                                            label="Sí">
                                        </FormControlLabel>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item lg={2}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <FormLabel component="legend">Reprogramar</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="es_reprogramada"
                                                    color="primary"
                                                    checked={values.es_reprogramada}
                                                    onChange={(e) => switchChange(e, 'es_reprogramada')}
                                                    value={values.es_reprogramada}
                                                />
                                            }
                                            label="Sí">
                                        </FormControlLabel>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}
                            className={!values.es_reprogramada ? classes.hidden : ""}>
                            <Grid item lg>
                                <KeyboardDatePicker
                                    name="inicio_reprogramado"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha de inicio reprogramado"
                                    format="dd/MM/yyyy"
                                    helperText={touched.inicio_reprogramado ? errors.inicio_reprogramado : ""}
                                    error={touched.inicio_reprogramado && Boolean(errors.inicio_reprogramado)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={dateChange.bind(null, "inicio_reprogramado")}
                                    value={values.inicio_reprogramado}
                                />
                            </Grid>
                            <Grid item lg>
                                <TextField
                                    name="duracion_reprogramada"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    className={classes.textField}
                                    helperText={touched.duracion_reprogramada ? errors.duracion_reprogramada : ""}
                                    error={touched.duracion_reprogramada && Boolean(errors.duracion_reprogramada)}
                                    label="Duración Reprogramada"
                                    margin="normal"
                                    value={values.duracion_reprogramada}
                                    fullWidth
                                    variant="outlined"
                                    onChange={change.bind(null, "duracion_reprogramada")}
                                    disabled={values.es_hito}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>

                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
