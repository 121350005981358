import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import AgregarPod from './../agregarPod';
import { makeStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert} from '@material-ui/icons';
import Moment from 'react-moment';
import CustomizedSnackbars from '../../../components/customizedSnackbar';
import DialogoConfirmar from '../../../components/dialogoConfirmar';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';
import { viewDriveFile } from '../../../services/fileUpload';
import EditarPod from '.././editarPod';
import EditarHorasHombrePod from '../editarHorasHombrePod';
const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Page(props) {

    const classes = useStyles();

    const {
        data,
        goTo,
        handleCloseAgregarPod,
        handleCloseEditarPod,
        handleCloseEliminarPod,
        handleCloseSnackbar,
        handleDescargarFormato,
        handleGenerarPOD,
        handleOpenAgregarPod,
        handleOpenEditarHHPod,
        handleOpenEditarPod,
        handleOpenEliminarPod,
        handleSubmitAgregarPod,
        handleSubmitEditarHorasHombrePod,
        handleSubmitEditarPod,
        handleSubmitEliminarPod,
        handleViewPod,
        hideSnackbarButton,
        isLoading,
        openAgregarPod,
        openEditarPod,
        openEliminarPod,
        openReporteSemanal,
        openSnackbar,
        podSeleccionado,
        reporteSemanal,
        setOpenReporteSemanal,
        snackbarVariant,
        snackbarMessage,
        usuarioSesion,
    } = props;
    const [pageSize, setPageSize] = useState(10);

    return (
        <div>
            <MaterialTable
                title={`Listado de POD`}
                options={{
                    filtering: false,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false,
                    columnsButton: true,
                    maxBodyHeight: "70vh",
                    headerStyle: {
                        textAlign: 'center',
                        border: "1px solid #E0E0E0",
                        fontWeight: 'bold',
                        color: '#731f1f',
                        position: 'sticky'
                    },
                    rowStyle: {
                        border: "1px solid #E0E0E0"
                    },
                    padding: 'dense',
                    actionsCellStyle: {
                        width: "2%",
                        textAlign: "center"
                    }
                }}
                onChangeRowsPerPage={size => { setPageSize(size) }}
                localization={location}
                isLoading={isLoading}
                actions={[
                    {
                        icon: "cargar_pod",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        icon: "formato_pod",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        icon: "generar_pod",
                        isFreeAction: true,
                        onClick: (evt) => { }
                    },
                    {
                        tooltip: 'Administrar',
                        icon: 'settings',
                        onClick: () => { }
                    },
                ]}
                components={{
                    Action:
                        props => {
                            const rowData = props.data;
                            if (props.action.icon === 'cargar_pod') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleOpenAgregarPod(evt, null)}
                                        hidden={!(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_POD])}
                                    >
                                        Cargar POD
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'formato_pod') {
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleDescargarFormato(evt, null)}
                                    >
                                        Descargar Formato POD
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'generar_pod') {
                                // const podDia = data.find(a => moment(a.fecha).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"));
                                return (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => handleGenerarPOD(evt, null)}
                                        hidden={!(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_POD])}
                                    >
                                        Generar POD
                                    </Button>
                                );
                            }
                            if (props.action.icon === 'settings') {
                                const accionesConfiguracion = [
                                    {
                                        content: 'Ver POD',
                                        onClick: (e) => goTo(`/pods/${rowData._id}`)
                                    }
                                ];
                                if (rowData.adjunto) accionesConfiguracion.push({
                                    content: 'Ver Archivo',
                                    onClick: (e) => handleViewPod(e, rowData)
                                });
                                if (usuarioSesion && usuarioSesion.permisos) {
                                    if (usuarioSesion.permisos[PERMISIONS.EDITAR_POD]) {
                                        accionesConfiguracion.push({
                                            content: 'Comentarios',
                                            onClick: (e) => handleOpenEditarPod(e, rowData)
                                        });
                                        accionesConfiguracion.push({
                                            content: 'Editar HH',
                                            onClick: (e) => handleOpenEditarHHPod(rowData)
                                        });
                                        accionesConfiguracion.push({
                                            content: 'Cerrar',
                                            onClick: (e) => goTo(`/pods/${rowData._id}/cerrar`)
                                        });
                                    }
                                    if (usuarioSesion.permisos[PERMISIONS.ELIMINAR_POD]) accionesConfiguracion.push({
                                        content: 'Eliminar',
                                        onClick: (e) => handleOpenEliminarPod(e, rowData)
                                    });
                                }
                                if (accionesConfiguracion.length > 0) return (
                                    <MenuDropdown
                                        icon={
                                            <MoreVert />
                                        }
                                        tooltip="Opciones"
                                        items={accionesConfiguracion}
                                        hidden={accionesConfiguracion.length <= 0}
                                    />
                                );
                            }
                            return (<MTableAction {...props} />);
                        }
                }}
                columns={[
                    {
                        title: 'Fecha',
                        field: 'fecha',
                        render: rowData => generarFechaView(rowData.fecha, "DD-MM-YY"),
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            borderLeft: '1px solid #E0E0E0'
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Turno',
                        field: 'turno',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                    },
                    {
                        title: 'Cumplimiento',
                        field: 'porcentaje_cumplimiento',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            borderLeft: '1px solid #E0E0E0'
                        },
                        render: rowData => {
                            if (!data[rowData.tableData.id].porcentaje_cumplimiento === null) {
                                return `Error`
                            } else {
                                return `${numberFormater(((data[rowData.tableData.id] && data[rowData.tableData.id].porcentaje_cumplimiento) ? data[rowData.tableData.id].porcentaje_cumplimiento : 0).toFixed(2))}%`
                            }

                        }
                    },
                    {
                        title: 'Hoja',
                        field: 'nombre_hoja',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            borderLeft: '1px solid #E0E0E0'
                        },
                        hidden: true,
                    },
                    {
                        title: 'Estado',
                        field: 'estado',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                    },
                    {
                        title: 'Causa (Cump. POD/Prog.)',
                        field: 'causa_cumplimiento',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center',
                            miWidth: "20%",
                        },
                    },
                    {
                        title: 'Categorización',
                        field: 'categorizacion',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                    },
                    // {
                    //     title: 'HH Ganadas',
                    //     field: 'horas_hombre_ganadas',
                    //     cellStyle: {
                    //         borderLeft: '1px solid #E0E0E0',
                    //         textAlign: 'center'
                    //     },
                    // },
                    {
                        title: 'HH Gastadas',
                        field: 'horas_hombre_gastadas',
                        cellStyle: {
                            borderLeft: '1px solid #E0E0E0',
                            textAlign: 'center'
                        },
                    }
                    // {
                    //     title: 'POD',
                    //     field: 'adjunto',
                    //     render: rowData => {
                    //         if (rowData.estado && rowData.estado === "Abierto") {
                    //             return <div>
                    //                 <Button variant="outlined" component={Link} to={`/pods/${rowData._id}`}>Ver</Button>
                    //                 <Button variant="outlined" component={Link} to={`/pods/${rowData._id}/cerrar`}>Cerrar</Button>
                    //             </div>;
                    //         }
                    //         else return <Button variant="outlined" component={Link} to={`/pods/${rowData._id}`}>Ver</Button>;
                    //     },
                    //     cellStyle: {
                    //         borderLeft: '1px solid #E0E0E0',
                    //         textAlign: 'center'
                    //     },
                    //     headerStyle: {
                    //         borderLeft: '1px solid #E0E0E0'
                    //     },
                    //     sorting: false
                    // },
                    // {
                    //     title: 'Archivo POD',
                    //     field: 'adjunto',
                    //     render: rowData => {
                    //         if (rowData.adjunto) return <Button variant="outlined" onClick={(e) => handleViewPod(e, rowData)}>Ver</Button>;
                    //         else return "Sin Archivo";
                    //     },
                    //     cellStyle: {
                    //         borderLeft: '1px solid #E0E0E0',
                    //         textAlign: 'center'
                    //     },
                    //     headerStyle: {
                    //         borderLeft: '1px solid #E0E0E0'
                    //     },
                    //     sorting: false
                    // },
                ]}
                data={data}
            />
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ITEM_PRESUPUESTO] &&
                <AgregarPod
                    handleClose={handleCloseAgregarPod}
                    open={openAgregarPod}
                    handleSubmit={handleSubmitAgregarPod}
                />
            }
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.EDITAR_POD] &&
                <EditarPod
                    handleClose={handleCloseEditarPod}
                    open={openEditarPod}
                    handleSubmit={handleSubmitEditarPod}
                    pod={podSeleccionado}
                />
            }
            {
                usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.ELIMINAR_ITEM_PRESUPUESTO] &&
                <DialogoConfirmar
                    open={openEliminarPod}
                    message={"¿Está seguro de eliminar el POD?"}
                    action={handleSubmitEliminarPod}
                    handleClose={handleCloseEliminarPod}
                />
            }
            <EditarHorasHombrePod 
                handleSubmit={handleSubmitEditarHorasHombrePod}
            />
            <CustomizedSnackbars
                open={openSnackbar}
                variant={snackbarVariant}
                handleClose={handleCloseSnackbar}
                message={snackbarMessage}
                hideButton={hideSnackbarButton}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            />
            <Dialog
                open={openReporteSemanal}
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Reporte Semanal</DialogTitle>
                <DialogContent>
                    Elegir formato de reporte
                    </DialogContent>
                <DialogActions>
                    {reporteSemanal &&
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            component={AdapterLink}
                            to={`${ROUTES.REDIRECT}/?destiny=${reporteSemanal.excel.url}`} target="_blank" rel="noopener"
                        >
                            Excel
                            </Button>
                    }
                    {reporteSemanal &&
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={() => viewDriveFile(reporteSemanal.pdf.id, reporteSemanal.pdf.nombre)}
                        >
                            PDF
                            </Button>
                    }
                    <Button onClick={() => setOpenReporteSemanal(false)} color="primary">
                        Cerrar
                        </Button>
                </DialogActions>
            </Dialog>
        
        </div>
    );
}

function generarFechaView(data, format) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }
}