import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    LinearProgress,
    InputAdornment,
} from '@material-ui/core';
import ReactSelect from 'react-select';
import NumberInput from '../../../components/numberInput';
import formatNumber from 'format-number';
import * as PERMISSIONS from '../../../constants/permissions';

const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));


function Page(props) {
    const classes = useStyles();
    const {
        values,
        titulo,
        open,
        itemsPresupuesto,
        isValid,
        handleEnterDialog,
        handleSubmit,
        errors,
        touched,
        handleChangeItem,
        handleChangeNumber,
        handleCloseDialog,
        presupuestoDisponible,
        cantidadDisponible,
        presupuestoTotal,
        cantidadTotal,
        usuarioSesion
    } = props;
    // const change = (name, e) => {
    //     const { setFieldTouched, handleChange, setFieldValue, handleOnChangeItemPresupuesto } = props;
    //     setFieldTouched(name, true, false);
    //     if (name === "item_presupuesto") {
    //         setFieldValue(name, e);
    //         handleChange(e);
    //         handleOnChangeItemPresupuesto(e);
    //     } else
    //         if (name === "costo_directo") {
    //             console.log(e.target.value);
    //             handleChange(e);
    //             e.persist(e);
    //         } else {
    //             handleChange(e);
    //             e.persist();
    //         }

    // };
    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleCloseDialog}
        >
            {(itemsPresupuesto) &&
                <form onSubmit={handleSubmit} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                    <DialogContent
                    >
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <InputLabel htmlFor="item_presupuesto">Item</InputLabel>
                                <ReactSelect
                                    searchable={true}
                                    value={values.item_presupuesto}
                                    onChange={handleChangeItem.bind(null, "item_presupuesto")}
                                    name="item_presupuesto"
                                    placeholder="Buscar Item"
                                    options={itemsPresupuesto}
                                    noOptionsMessage={() => "No hay resultados"}
                                    menuPosition="absolute"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <NumberInput
                                    inputName="costo_directo"
                                    type="text"
                                    inputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    label="Costo Directo"
                                    value={(values.costo_directo)}
                                    meta={{
                                        touched: touched.costo_directo,
                                        error: errors.costo_directo

                                    }}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal",
                                        InputProps: {
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }

                                    }}
                                    handleOnChange={handleChangeNumber.bind(null, "costo_directo")}
                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    type="text"
                                    label="Presupuesto Disponible"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal",
                                        InputProps: {
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }

                                    }}
                                    value={(presupuestoDisponible)}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    type="text"
                                    label="Presupuesto Total"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal",
                                        InputProps: {
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }

                                    }}
                                    value={(presupuestoTotal)}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg>
                                <NumberInput
                                    inputName="cantidad"
                                    type="text"
                                    inputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    label="Cantidad"
                                    value={(values.cantidad)}
                                    meta={{
                                        touched: touched.cantidad,
                                        error: errors.cantidad

                                    }}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal"
                                    }}
                                    handleOnChange={handleChangeNumber.bind(null, "cantidad")}
                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    type="text"
                                    label="Cantidad Disponible"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    value={(cantidadDisponible)}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal"
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item lg>
                                <NumberInput
                                    type="text"
                                    label="Cantidad Total"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    value={(cantidadTotal)}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal"
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg={4}>
                                <NumberInput
                                    inputName="horas_hombre"
                                    type="text"
                                    inputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    label="HH"
                                    value={(values.horas_hombre)}
                                    meta={{
                                        touched: touched.horas_hombre,
                                        error: errors.horas_hombre

                                    }}
                                    textVariant="outlined"
                                    custom={{
                                        margin: "normal"
                                    }}
                                    handleOnChange={handleChangeNumber.bind(null, "horas_hombre")}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!(isValid || (usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISSIONS.CARGAR_PRESUPUESTO]))}
                        >
                            Aceptar
                        </Button>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </form>
            }
            {!itemsPresupuesto &&
                <div>
                    <DialogTitle id="alert-dialog-title">
                        Cargando Presupuestos
                    </DialogTitle>
                    <DialogContent>
                        <LinearProgress color="primary" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="secondary" variant="contained">
                            Cancelar
                        </Button>
                    </DialogActions>
                </div>
            }
        </Dialog >
    );
}

export default Page;
