/**
 * @author Javier Malebran
 */

export default class PresupuestoModel {
    constructor(json) {
        // this._fechaActualizacion = null
        // this._fechaCreacion = null;
        this.is_eliminado = false;
        this.nombre = "";
        this.proyecto_ref = ""
        this._id = "";
        this.items_presupuestos_ref = [];
        this.listadoItems = [];
        this.listadoTitulos = [];
        this.totalPresupuesto = 0;
        if(json) this.build(json);
    }

    build(json){
        this.is_eliminado = json.is_eliminado ? json.is_eliminado : false;
        this.nombre = json.nombre ? json.nombre : "";
        this.proyecto_ref = json.proyecto_ref ? json.proyecto_ref : ""
        this._id = json._id ? json._id : "";
        this.items_presupuestos_ref = json.items_presupuestos_ref ? json.items_presupuestos_ref: [];
        this.listadoItems = json.listadoItems ? json.listadoItems : [];
        this.listadoTitulos = json.listadoTitulos ? json.listadoTitulos : [];
        this.totalPresupuesto = json.totalPresupuesto ? json.totalPresupuesto : 0;
    }

    pushTitulo(titulo){
        this.listadoTitulos.push(titulo);
    }

    pushItem(item){
        this.listadoItems.push(item);
    }
}