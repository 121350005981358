import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Error401 from './containers/401';
import LoadPage from './containers/loadPage';
import Reportabilidad from './containers/reportabilidad';
import Programa from './containers/programa';
import Presupuesto from './containers/presupuesto';
// import { getCanPermisosGeneral } from './services/auth';
import DrawerApps from './containers/drawerApps';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import SelectorContratos from './containers/selectorContrato';
import clsx from 'clsx';
import { CssBaseline } from '@material-ui/core';
import AvancesActividadPrograma from './containers/programa/avances';
import Recursos from './containers/recursos';
import MainDrawer from './components/mainDrawer';
import AppBar from './components/appBar';
import LoginPage from './containers/loginPage';
import { accionSetUsuario } from './redux/actions/dataUsuario/index';
import { auth, onAuthStateChange } from './services/firebase';
import * as ROUTES from './constants/routes';
import * as PERMISSIONS from './constants/permissions';
import { getContrato } from './services/database/contrato';
import { getProyecto } from './services/database/proyecto';
import { accionSetContrato } from './redux/actions/contratoUsuario';
import ContratoModel from './model/ContratoModel';
import ProyectoModel from './model/ProyectoModel';
import EstadosPago from './containers/estadosPago';
import { getUsuario } from './services/database/usuario';
import DialogoMensaje from './components/dialogoMensaje';
import Pod from './containers/pods';
import CargarPrograma from './containers/programa/cargaProgramacion';
import CargarPresupuesto from './containers/presupuesto/cargaPresupuesto';
// import PDF from './containers/reportabilidad/pdf';
import history from './components/history'
import RedirectExternal from './containers/redirectExternal';
import VerPod from './containers/pods/verPod';
import CerrarPod from './containers/pods/cerrarPod';
import GenerarPod from './containers/pods/generarPod';
import ProgramacionSemanal from './containers/programacionSemanal';
import VerProgramaSemanal from './containers/programacionSemanal/verProgramaSemanal';
import Reprogramacion from './containers/reprogramacion';
import ReportesPodDiarios from './containers/reportesPodDiarios';

const drawerWidth = 300;
const appbarHeight = 18;

const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none',
    },
    main: {
        display: 'flex',
        // width: "100%"
    },
    root: {
        display: 'flex',
    },
    content: {
        // display: 'flex',
        padding: theme.spacing(11, 1, 3, 1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#dddddd",
        minHeight: `calc(100vh - ${appbarHeight}px)`,
        width: "100vw",
    },
    contentUnShift: {
        width: "100vw",
    },
    contentShift: {
        width: `calc(100vw - ${drawerWidth}px)`,
    },
    // drawerHeader: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     padding: '0 8px',
    //     ...theme.mixins.toolbar,
    //     justifyContent: 'flex-end',
    // }
}));


export default function App(props) {

    const classes = useStyles();

    // const history = useHistory();

    const dispatch = useDispatch();

    const { contrato, proyecto, usuarioSesion } = useSelector(state => ({
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto,
        usuarioSesion: state.usuarioSesion
    }));

    const [openMainDrawer, setOpenMainDrawer] = useState(false);
    const [openSelectorContrato, setOpenSelectorContrato] = useState(false);
    const [loading, setLoading] = useState(true);
    const [login, setLogin] = useState(false);
    const [accessError, setAccessError] = useState(false);

    const getQueryVariable = function (variable) {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }


    const checkSession = isLogged => {
        try {
            if (isLogged) {
                const user = auth.currentUser;
                let contrato = getQueryVariable("contrato");
                let proyecto = getQueryVariable("proyecto");
                if (contrato && proyecto) {
                    localStorage.setItem("contrato", contrato);
                    localStorage.setItem("proyecto", proyecto);
                    history.push("/");
                }
                getUsuario(user.uid)
                    .then(usuario => {
                        dispatch(accionSetUsuario({
                            ref: usuario._id,
                            photoUrl: user.photoURL,
                            nombre: user.displayName,
                            email: user.email,
                            permisos: usuario.permisos,
                            aplicaciones: {
                                logCartas: true,
                                logIncidentes: true,
                                logCalidad: true,
                                logSolicitudesInformacion: true,
                                logInspecciones: true,
                                gestorInformacion: true,
                                bitacora: true
                            }
                        }));
                        setLogin(false);
                        loadContrato()
                            .then(() => {
                                setLoading(false);
                                setOpenSelectorContrato(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoading(true);
                                setOpenSelectorContrato(true);
                            });
                    })
                    .catch(error => {
                        console.log(error);
                        setLogin(true);
                    });
            } else {
                setLogin(true);
            }
        } catch (error) {
            console.log(error);
            setLogin(true);
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChange(checkSession);
        return () => {
            unsubscribe();
        }
    }, []);

    const loadContrato = async () => {
        const contrato = localStorage.getItem("contrato");
        const proyecto = localStorage.getItem("proyecto");
        if (!contrato || !proyecto || contrato === 'undefined' || proyecto === 'undefined') {
            throw new Error("Usuario sin contrato o proyecto");
        } else {
            const responses = await Promise.all([
                getContrato(contrato),
                getProyecto(proyecto)
            ]);
            if (!responses[0] || !responses[1]) {
                throw new Error("Contrato o proyecto no existen");
            } else {
                const contratoObj = new ContratoModel(responses[0]);
                const proyectoObj = new ProyectoModel(responses[1]);
                dispatch(accionSetContrato(contratoObj, proyectoObj));
            }
        }
        return;
    }

    const handleOpenMainDrawer = () => {
        setOpenMainDrawer(true);
    }

    const handleCloseMainDrawer = () => {
        setOpenMainDrawer(false);
    }

    const theme = createMuiTheme({
        typography: {
            // In Chinese and Japanese the characters are usually larger,
            // so a smaller fontsize may be appropriate.
            fontSize: 14,
            htmlFontSize: 17,
            fontFamily: ['Open Sans', 'sans-serif']

        },
        palette: {
            primary: {
                light: '#a64c46',
                main: '#731f1f',
                dark: '#430000',
                contrastText: '#fff',
            },
            secondary: {
                light: '#4f5b62',
                main: '#263238',
                dark: '#000a12',
                contrastText: '#fff',
            },
            blanco: {
                light: '#fff',
                main: '#fff',
                dark: '#fff',
                contrastText: '#fff',
            }
        },
    });

    const privateRoute = ({ component: Component, permission, path, ...rest }) => {
        return (
            <Route
                path={path}
                {...rest}
                render={(props) => {
                    if (accessError) return (<Redirect to='/accessError' />);
                    if (!accessError && path === ROUTES.ACCESS_ERROR) return (<Redirect to='/' />);
                    if (login) return (<Redirect to='/login' />);
                    if ((permission && usuarioSesion && (!usuarioSesion.permisos || !usuarioSesion.permisos[permission]))) {
                        return withTemplate(<Redirect to='/' />);
                    }
                    return withTemplate(<Component {...props} />);
                }}
            />

        );
    }

    const withTemplate = (component) => (
        <div className={classes.root}>
            {!loading &&
                <main className={classes.main}>
                    {(!login && !accessError) && <AppBar
                        proyecto={proyecto}
                        contrato={contrato}
                        nombreModulo="Gestor de Proyectos"
                        usuarioSesion={usuarioSesion}
                        handleOpenSelector={() => setOpenSelectorContrato(true)}
                        openMainDrawer={openMainDrawer}
                        handleOpenMainDrawer={handleOpenMainDrawer}
                        handleCloseMainDrawer={handleCloseMainDrawer}
                    />}
                    {(!login && !accessError) && <DrawerApps />}
                    {(!login && !accessError) && <MainDrawer
                        usuarioSesion={usuarioSesion}
                        open={openMainDrawer}
                        handleClose={handleCloseMainDrawer}
                    />}
                    <div name="Content"
                        className={clsx(
                            {
                                [classes.content]: true,
                                [classes.contentUnShift]: !openMainDrawer,
                                [classes.contentShift]: openMainDrawer,
                            })}
                        // className={classes.content}
                    >
                        {component}
                    </div>
                </main>
            }
            {loading &&
                <LoadPage />
            }
        </div>
    );

    const mainApp = (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
                <Route exact={true} path={ROUTES.LOGIN} component={() => <LoginPage setLogin={setLogin} login={login} />} />
                <Route exact={true} path={ROUTES.REDIRECT} component={() => <RedirectExternal />} />
                {/* <Route exact={true} path={ROUTES.ACCESS_ERROR} component={Error401} /> */}
                {/* <Route exact={true} path={ROUTES.PDF} component={PDF} /> */}
                {privateRoute({
                    component: Error401,
                    path: ROUTES.ACCESS_ERROR,
                    exact: true
                })}
                {privateRoute({
                    component: Reportabilidad,
                    path: ROUTES.LANDING,
                    exact: true
                })}
                {privateRoute({
                    component: Reportabilidad,
                    path: ROUTES.REPORTABILIDAD,
                    exact: true
                })}
                {privateRoute({
                    component: Programa,
                    permission: PERMISSIONS.VER_ACTIVIDAD,
                    path: ROUTES.PROGRAMA,
                    exact: true
                })}
                {privateRoute({
                    component: Presupuesto,
                    permission: PERMISSIONS.VER_ITEM_PRESUPUESTO,
                    path: ROUTES.PRESUPUESTO,
                    exact: true
                })}
                {privateRoute({
                    component: AvancesActividadPrograma,
                    permission: PERMISSIONS.VER_AVANCES_ACTIVIDAD,
                    path: ROUTES.AVANCES_ACTIVIDAD,
                    exact: true
                })}

                {privateRoute({
                    component: Recursos,
                    permission: PERMISSIONS.VER_RECURSO,
                    path: ROUTES.RECURSOS,
                    exact: true
                })}
                {privateRoute({
                    component: EstadosPago,
                    // permission: PERMISSIONS.VER_RECURSO,
                    path: ROUTES.ESTADOS_PAGO,
                    exact: true
                })}
                {privateRoute({
                    component: Pod,
                    permission: PERMISSIONS.VER_POD,
                    path: ROUTES.POD,
                    exact: true
                })}
                {privateRoute({
                    component: VerPod,
                    permission: PERMISSIONS.VER_POD,
                    path: ROUTES.VER_POD,
                    exact: true
                })}
                {privateRoute({
                    component: CerrarPod,
                    permission: PERMISSIONS.EDITAR_POD,
                    path: ROUTES.CERRAR_POD,
                    exact: true
                })}
                {privateRoute({
                    component: CargarPrograma,
                    permission: PERMISSIONS.CARGAR_PROGRAMA,
                    path: ROUTES.CARGAR_PROGRAMA,
                    exact: true
                })}
                {privateRoute({
                    component: CargarPresupuesto,
                    permission: PERMISSIONS.CARGAR_PRESUPUESTO,
                    path: ROUTES.CARGAR_PRESUPUESTO,
                    exact: true
                })}
                {privateRoute({
                    component: GenerarPod,
                    permission: PERMISSIONS.AGREGAR_POD,
                    path: ROUTES.GENERAR_POD,
                    exact: true
                })}
                {privateRoute({
                    component: ProgramacionSemanal,
                    permission: PERMISSIONS.VER_ACTIVIDAD,
                    path: ROUTES.PROGRAMACION_SEMANAL,
                    exact: true
                })}
                {privateRoute({
                    component: VerProgramaSemanal,
                    permission: PERMISSIONS.VER_ACTIVIDAD,
                    path: ROUTES.VER_PROGRAMACION_SEMANAL,
                    exact: true
                })}
                {privateRoute({
                    component: ReportesPodDiarios,
                    permission: PERMISSIONS.VER_POD,
                    path: ROUTES.REPORTES_POD_DIAROS,
                    exact: true
                })}
                {privateRoute({
                    component: Reprogramacion,
                    permission: PERMISSIONS.VER_ACTIVIDAD,
                    path: ROUTES.REPROGRAMACION,
                    exact: true
                })}
            </Switch>
            <SelectorContratos
                open={openSelectorContrato}
                setOpenSelectorContrato={setOpenSelectorContrato}
                setLoading={setLoading}
                setAccessError={setAccessError}
            />
            <DialogoMensaje />
        </MuiThemeProvider>

    );
    return (mainApp);
}




