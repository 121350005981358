import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl
} from '@material-ui/core';
import FileInput from '../../../components/fileInput';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        paddingTop: theme.spacing(3)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    }

}));


function Page(props) {
    const classes = useStyles();
    const {
        errors,
        handleFileChange,
        isValid,
        handleChangeDate,
        handleClose,
        handleDesdeFecha,
        handleEnterDialog,
        handleSubmit,
        open,
        titulo,
        touched,
        values
    } = props;


    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    name="fecha"
                                    autoOk
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputLabel,
                                            shrink: "shrink"
                                        }
                                    }}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label="Fecha para Reprogramación"
                                    format="dd/MM/yyyy"
                                    helperText={touched.fecha ? errors.fecha : ""}
                                    error={touched.fecha && Boolean(errors.fecha)}
                                    className={classes.textField}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={handleChangeDate.bind(null, "fecha")}
                                    value={values.fecha}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <FormControl
                                fullWidth={true}
                                margin="normal"
                            >
                                <FileInput
                                    inputName="file"
                                    meta={{ touched: touched.file, errors: errors.file }}
                                    buttonColor="primary"
                                    buttonVariant="outlined"
                                    label="Archivo Adjunto"
                                    value={values.file}
                                    handleOnChange={handleFileChange.bind(null, "file")}
                                    textVariant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!values.file}
                    >
                        Aceptar
                        </Button>
                    {/* <Button
                        color="primary"
                        onClick={handleDesdeFecha}
                    >
                        Desde Fecha
                    </Button> */}
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
