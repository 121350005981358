import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { updateProyecto } from '../../../services/database/proyecto';

export default function ConfigurarBaseReal(props) {

    const { open, handleCloseDialog, proyecto } = props;
    const [formik, setFormik] = useState(null);
    const notistack = useSnackbar();
    const validationSchema = Yup.object({
        fecha_base_real: Yup.date().nullable(),
        // porcentaje_horas_hombre_base_real: Yup.number().typeError("Debe ingresar un valor númerico"),
        horas_hombre_base_real: Yup.number().typeError("Debe ingresar un valor númerico"),
        // porcentaje_costo_base_real: Yup.number().typeError("Debe ingresar un valor númerico"),
        // costo_base_real: Yup.number().typeError("Debe ingresar un valor númerico"),

    });
    const values = {
        fecha_base_real: null,
        porcentaje_horas_hombre_base_real: 0,
        horas_hombre_base_real: 0,
        porcentaje_costo_base_real: 0,
        costo_base_real: 0,
    };

    const handleEnterDialog = async () => {
        const { proyecto } = props;
        if (!proyecto) formik.resetForm(values);
        else {
            formik.resetForm({
                fecha_base_real: proyecto.fecha_base_real ? proyecto.fecha_base_real : null,
                porcentaje_horas_hombre_base_real: proyecto.porcentaje_horas_hombre_base_real ? proyecto.porcentaje_horas_hombre_base_real : 0,
                horas_hombre_base_real: proyecto.horas_hombre_base_real ? proyecto.horas_hombre_base_real : 0,
                porcentaje_costo_base_real: proyecto.porcentaje_costo_base_real ? proyecto.porcentaje_costo_base_real : 0,
                costo_base_real: proyecto.costo_base_real ? proyecto.costo_base_real : 0,
            });
        }
    }

    const handleOnSubmit = async (values, formikBag) => {
        const { handleSubmit } = props;
        const key = notistack.enqueueSnackbar("Guardando Cambios...", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            await updateProyecto(proyecto._id, values);
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar("Operación exitosa: Actividades y programa actualizados", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });
        }
        catch (error) {
            console.log(error);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
        if (handleSubmit) handleSubmit(values);
    }

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                render={props =>
                    <Page
                        {...props}
                        open={open}
                        titulo={proyecto ? proyecto.nombre : ""}
                        handleCloseDialog={handleCloseDialog}
                        handleEnterDialog={handleEnterDialog}
                    />}
            />
        </React.Fragment>
    );
}