import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";

export default function AgregarEstadoPago(props) {
    const { open, handleClose, estadoPago } = props;
    const [formik, setFormik] = useState(null);
    const validationSchema = Yup.object({
        periodo: Yup.date().required("Campo requerido"),
        inicio: Yup.date().required("Campo requerido"),
        termino: Yup.date().required("Campo requerido"),
        monto: Yup.number().required("Campo requerido").typeError("Debe ingresar un valor númerico")
    });
    const values = {
        periodo: new Date(),
        inicio: new Date(),
        termino: new Date(),
        monto: 0,
        adjunto: null
    };

    const handleEnterDialog = async () => {
        if (!estadoPago) {
            formik.resetForm({
                periodo: new Date(),
                inicio: new Date(),
                termino: new Date(),
                monto: 0,
                adjunto: null
            });
        } else {
            formik.resetForm({
                periodo: estadoPago.periodo,
                inicio: estadoPago.inicio,
                termino: estadoPago.termino,
                monto: estadoPago.monto,
                adjunto: null
            });
        }

    }

    const handleChange = (e, { name, value }) => {
        props.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = (values, formikBag) => {
        const { handleSubmit} = props;
        handleSubmit(values);
    }

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                estadoPago={estadoPago}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                isInitialValid={estadoPago ? true : false}
                render={props =>
                    <Page
                        open={open}
                        titulo="Estado Pago"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        estadoPago={estadoPago}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}