import React, { useState, useEffect } from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from "yup";
// import { accionCloseSelectorContrato } from '../../redux/actions/selectorContratos';
import { accionSetContrato } from '../../redux/actions/contratoUsuario';
import { getContratosUsuario, getUsuario } from '../../services/database/usuario';
import { getProyectosContrato, getContrato } from '../../services/database/contrato';
import { getProyecto } from '../../services/database/proyecto';
import { auth } from '../../services/firebase';
import ContratoModel from '../../model/ContratoModel';
import ProyectoModel from '../../model/ProyectoModel';
import { accionDialogoMensaje } from '../../redux/reducers/reducerDialogoMensaje';

export default function SelectorContrato(props) {

    const [suggestionsContrato, setSuggestionsContrato] = useState(null);
    const [suggestionsProyecto, setSuggestionsProyecto] = useState(null);
    const [proyectos, setProyectos] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [formik, setFormik] = useState(null);

    const { contrato, proyecto } = useSelector(state => ({
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto,
    }));

    const dispatch = useDispatch();

    const handleEnterDialog = async () => {
        const { setAccessError } = props;
        try {
            // dispatch(accionDialogoMensaje("OPEN", "No se han podido cargar los contratos. Contactese con el administrador", "Error"));
            const uid = auth.currentUser.uid;
            if(!uid){
                throw new Error("No se ha recurar usuario de firebase");
            }
            const usuario = await getUsuario(uid);
            if(!usuario){
                throw new Error("No se ha recurar usuario de proyectos");
            }
            const contratosUsuario = await getContratosUsuario(usuario._id);
            if(!contratosUsuario || contratosUsuario.length <= 0){
                dispatch(accionDialogoMensaje("OPEN", "El usuario no tiene ningún contrato asignado. Contactese con el administrador.", "Error"));
                setAccessError(true);
                handleCloseDialog();
                // return;
            }
            let proyectosContrato = [];
            const suggestionsContratoUsuario = [{ label: "", value: "" }];
            const suggestionsProyectoUsuario = [{ label: "", value: "" }];
            let contratoSelecionado = { label: "", value: "" };
            let proyectoSeleccionado = { label: "", value: "" };
            for (let i = 0; i < contratosUsuario.length; i++) {
                const suggestion = { label: contratosUsuario[i].codigo + " " + contratosUsuario[i].nombre, value: i };
                suggestionsContratoUsuario.push(suggestion);
                if (contrato && contratosUsuario[i]._id === contrato._id) {
                    contratoSelecionado = suggestion;
                }
            }
            if (contrato && contrato._id) {
                proyectosContrato = await getProyectosContrato(contrato._id);
                for (let i = 0; i < proyectosContrato.length; i++) {
                    const suggestion = { label: proyectosContrato[i].codigo + " " + proyectosContrato[i].nombre, value: i };
                    suggestionsProyectoUsuario.push(suggestion);
                    if (proyecto && proyectosContrato[i]._id === proyecto._id) {
                        proyectoSeleccionado = suggestion;
                    }
                }
            }
            formik.resetForm({
                contrato: contratoSelecionado,
                proyecto: proyectoSeleccionado
            });
            setContratos(contratosUsuario);
            setSuggestionsContrato(suggestionsContratoUsuario);
            setProyectos(proyectosContrato);
            setSuggestionsProyecto(suggestionsProyectoUsuario);
        }
        catch(e){
            console.log(e);
            dispatch(accionDialogoMensaje("OPEN", "No se han podido cargar los contratos. Contactese con el administrador", "Error"));
        }
    }


    const handleSubmit = async (values, formikBag) => {
        const { setLoading } = props;
        const proyectoDB = await getProyecto(proyectos[values.proyecto.value]._id);
        const contratoDB = await getContrato(contratos[values.contrato.value]._id);
        const proyecto = new ProyectoModel(proyectoDB);
        const contrato = new ContratoModel(contratoDB);
        localStorage.setItem("proyecto", proyecto._id);
        localStorage.setItem("contrato", contrato._id);
        dispatch(accionSetContrato(contrato, proyecto));
        setLoading(false);
        handleCloseDialog();
    }

    const handleOnChange = async ({ name, value }) => {
        if (name === "contrato") {
            if (contratos && contratos.length > 0 && contratos[value]) {
                const id = contratos[value]._id;
                console.log(contratos[value]);
                // const proyectosContrato = await getProyectosContrato(id);
                const proyectosContrato = contratos[value].proyectos_ref ? contratos[value].proyectos_ref : [];
                const suggestionsProyectoUsuario = [];
                for (let i = 0; i < proyectosContrato.length; i++) {
                    const suggestion = { label: proyectosContrato[i].codigo + " " + proyectosContrato[i].nombre, value: i };
                    suggestionsProyectoUsuario.push(suggestion);
                }
                setProyectos(proyectosContrato);
                setSuggestionsProyecto(suggestionsProyectoUsuario);
            }
        }
    };

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const handleCloseDialog = () => {
        const { setOpenSelectorContrato } = props;
        setOpenSelectorContrato(false);
    }

    const { open } = props;
    const validationSchema = Yup.object({
        contrato: Yup.string("Seleccionar Contrato").required("Campo requerido"),
        proyecto: Yup.string("Seleccionar Proyecto").required("Campo requerido")
    });
    const values = {
        contrato: { label: "", value: "" },
        proyecto: { label: "", value: "" }
    };

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                render={props =>
                    <Page
                        handleEnter={handleEnterDialog}
                        suggestionsContrato={suggestionsContrato}
                        suggestionsProyecto={suggestionsProyecto}
                        contrato={contrato}
                        proyecto={proyecto}
                        titulo={"Seleccionar Contrato"}
                        open={open}
                        handleOnChange={handleOnChange}
                        handleClose={handleCloseDialog}
                        {...props}
                    />}
            />
        </React.Fragment>
    );

}