import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Grid,
    Container
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import formatNumber from 'format-number';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
Highcharts.setOptions({
    lang: {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortWeekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
    }
});
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    // mainPrograma: {
    //     flexGrow: 1
    // },
    card: {
        // width: "100%",
        // position: "fixed",
        // top: theme.spacing(9)
        marginBottom: theme.spacing(2)
    },
    tablaAvances: {
        marginTop: "8px",

    },
    tableBodyAvances: {
        // maxWidth: '100vw',
        // width: "100%",
        [theme.breakpoints.down('md')]: { // eslint-disable-line no-useless-computed-key
            overflow: "auto",
            display: 'block',
            maxWidth: "88vw",
        }
    },
    cardContendAvances: {
        // overflow: 'auto'
    }

}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);
const TableCell1 = withStyles(theme => ({
    head: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
    },
    body: {
        border: "1px solid #E0E0E0",
        fontSize: 12,
    },
}))(TableCell);
const TableCell2 = withStyles(theme => ({
    body: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
        borderBottomColor: "#FFF"
    },
}))(TableCell);

const TableCell3 = withStyles(theme => ({
    body: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
        backgroundColor: "#cecece",
        borderBottomColor: "#FFF"
    },
}))(TableCell);


const TableCell4 = withStyles(theme => ({
    body: {
        fontSize: 12,
        border: theme.palette.primary.main,
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        borderBottomColor: "#FFF"
    },
}))(TableCell);

const BlankCell = withStyles(theme => ({
    body: {
        border: "none",
    }
}))(TableCell);

function Page(props) {
    const classes = useStyles();
    const {
        avancesPeriodoPodProyecto,
        avancesPeriodoProgramaSemanal,
        chartRef,
        configChart,
        cumplimientoPeriodo,
        cumplimientoPeriodoAnterior,
        horaEfectivasProyecto,
        indiceEficienciaHorasEfectivasPeriodo,
        indiceEficienciaHorasEfectivasAnterior,
        inicioSemana,
        isloading,
        setInicioSemana
    } = props;

    return (
        <Container className={classes.main}>
            {isloading &&
                <Grid container alignContent="center" alignItems="center">
                    <Grid item lg className={classes.center}>
                        <CircularProgress color="primary" size={"70px"} />
                    </Grid>
                </Grid>
            }



            {!isloading &&
                <HighchartsReact
                    highcharts={Highcharts}
                    ref={chartRef}
                    options={
                        configChart
                    }
                    containerProps={{ style: { width: "100%" } }}
                >
                </HighchartsReact>
            }
            {!isloading &&
                <Grid container alignItems="center" justify="center">
                    <Grid item lg={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDatePicker
                                name="semanaSeleccionada"
                                autoOk
                                fullWidth
                                label="Período Seleccionado (Fecha de Inicio)"
                                format="dd/MM/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                                value={inicioSemana}
                                onChange={(e) => {
                                    setInicioSemana(moment(e).startOf("week").format("YYYY-MM-DD HH:mm:ss"));
                                }}
                                inputVariant="outlined"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            }
            {!isloading &&
                <Table
                    size="small"
                    className={classes.tablaAvances}>
                    <TableBody className={classes.tableBodyAvances}>
                        <TableRow>
                            <TableCell2 align="center"><b>Fecha</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{moment(a.fecha).format("DD/MM")}</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{moment(a.fecha).format("DD/MM")}</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>Resumen</b></TableCell2>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Avance Programado</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.avanceProgramado.toFixed(2)}%</TableCell3>;
                                return <TableCell1 align="center" key={index}>{ a.avanceProgramado.toFixed(2)}%</TableCell1>;
                            })}
                            <TableCell1 align="center" >
                                <b>{(avancesPeriodoPodProyecto.indicadores.avanceProgramado).toFixed(2)}%</b>
                            </TableCell1>
                        </TableRow>
                        {avancesPeriodoProgramaSemanal && <TableRow>
                            <TableCell2 align="center"><b>Avance Prog. Semanal</b></TableCell2>
                            {avancesPeriodoProgramaSemanal.map((a, index) => {
                                if(index < 7) return <TableCell3 align="center" key={index}>{(a && a.avance_programado ? a.avance_programado : 0).toFixed(2)}%</TableCell3>;
                                else return <TableCell1 align="center" key={index}>{(a && a.avance_programado ? a.avance_programado : 0).toFixed(2)}%</TableCell1>;
                                
                            })}
                            <TableCell1 align="center" >
                                <b>{(avancesPeriodoPodProyecto.indicadores.avancesPeriodoProgramaSemanal).toFixed(2)}%</b>
                            </TableCell1>
                        </TableRow>}
                        <TableRow>
                            <TableCell2 align="center"><b>Avance Prog. POD</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.avanceProgramadoPod.toFixed(2)}%</TableCell3>
                                return <TableCell1 align="center" key={index}>{a.avanceProgramadoPod.toFixed(2)}%</TableCell1>
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.avanceProgramadoPod).toFixed(2)}%</b>
                            </TableCell1>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Avance Ganado POD</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.avanceRealPod.toFixed(2)}%</TableCell3>;
                                return <TableCell1 align="center" key={index}>{a.avanceRealPod.toFixed(2)}%</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.avanceRealPod).toFixed(2)}%</b>
                            </TableCell1>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Cump. Prog. Línea Base</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{(a.cumplimientoProg ? a.cumplimientoProg : 0).toFixed(2)}%</TableCell3>;
                                return <TableCell1 align="center" key={index}>{(a.cumplimientoProg ? a.cumplimientoProg : 0).toFixed(2)}%</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.cumplimientoProg).toFixed(2)}%</b>
                            </TableCell1>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Cumplimiento POD</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{parseFloat(a.cumplimiento ? a.cumplimiento : 0).toFixed(2)}%</TableCell3>;
                                return <TableCell1 align="center" key={index}>{parseFloat(a.cumplimiento ? a.cumplimiento : 0).toFixed(2)}%</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{parseFloat(avancesPeriodoPodProyecto.indicadores.cumplimiento ? avancesPeriodoPodProyecto.indicadores.cumplimiento : 0).toFixed(2)}%</b>
                            </TableCell1>
                        </TableRow>

                        <TableRow>
                            <BlankCell colSpan="16"></BlankCell>
                        </TableRow>

                        {horaEfectivasProyecto && <TableRow>
                            <TableCell2 align="center"><b>Horas Meta</b></TableCell2>
                            {horaEfectivasProyecto.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{(10).toFixed(2)}</TableCell3>;
                                return <TableCell1 align="center" key={index}>{(10).toFixed(2)}</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.horasMeta).toFixed(2)}</b>
                            </TableCell1>
                        </TableRow>}
                        {horaEfectivasProyecto && <TableRow>
                            <TableCell2 align="center"><b>Horas Reales</b></TableCell2>
                            {horaEfectivasProyecto.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.horas_efectivas.toFixed(2)}</TableCell3>;
                                return <TableCell1 align="center" key={index}>{a.horas_efectivas.toFixed(2)}</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.horasReales).toFixed(2)}</b>
                            </TableCell1>
                        </TableRow>}
                        <TableRow>
                            <TableCell2 align="center"><b>HH Programadas</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.horas_hombre_programadas ? a.horas_hombre_programadas : a.horasHombreProgramadas.toFixed(2)}</TableCell3>;
                                return <TableCell1 align="center" key={index}>{a.horasHombreProgramadas.toFixed(2)}</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.horasHombreProgramadas).toFixed(2)}</b>
                            </TableCell1>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>HH Ganadas</b></TableCell2>
                            {avancesPeriodoPodProyecto.avancesSemana.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.horasHombreReales.toFixed(2)}</TableCell3>;
                                return <TableCell1 align="center" key={index}>{a.horasHombreReales.toFixed(2)}</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{(avancesPeriodoPodProyecto.indicadores.horasHombreReales).toFixed(2)}</b>
                            </TableCell1>
                        </TableRow>
                        {horaEfectivasProyecto && <TableRow>
                            <TableCell2 align="center"><b>Indice Eficiencia</b></TableCell2>
                            {horaEfectivasProyecto.map((a, index) => {
                                if (moment(a.fecha).isBefore(moment(inicioSemana))) return <TableCell3 align="center" key={index}>{a.indiceEficienciaHorasEfectivas.toFixed(2)}</TableCell3>;
                                return <TableCell1 align="center" key={index}>{a.indiceEficienciaHorasEfectivas.toFixed(2)}</TableCell1>;
                            })}
                            <TableCell1 align="center">
                                <b>{avancesPeriodoPodProyecto.indicadores.indiceEficienciaHorasEfectivas.toFixed(2)}</b>
                            </TableCell1>
                        </TableRow>}

                    </TableBody>
                </Table>
            }
            <br />
            {!isloading &&
                <Table size="small">
                    <TableBody className={classes.tableBodyAvances}>
                        <TableRow>
                            <TableCell4 align="center" colSpan={4}><b>Período/Semana Anterior</b></TableCell4>
                            <BlankCell></BlankCell>
                            <TableCell4 align="center" colSpan={4}><b>Período/Semana Actual</b></TableCell4>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Cumplimiento promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((cumplimientoPeriodoAnterior).toFixed(2)) + "%"}</CustomCell1>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Indice Eficiencia promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((indiceEficienciaHorasEfectivasAnterior).toFixed(2))}</CustomCell1>
                            <BlankCell></BlankCell>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Cumplimiento promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((cumplimientoPeriodo).toFixed(2)) + "%"}</CustomCell1>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Indice Eficiencia promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((indiceEficienciaHorasEfectivasPeriodo).toFixed(2))}</CustomCell1>
                        </TableRow>
                    </TableBody>
                </Table>
            }


        </Container >
    );
}

export default Page;