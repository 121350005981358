import React, { useState, useEffect } from 'react';
import Page from './page';
import { getAvancesPeriodo } from '../../../services/database/programacion';
import moment from 'moment';

export default function CurvaAvanceFisico(props) {

  const [configChart, setConfigChart] = useState(null);

  const { conHorasHombre, data, inicio, termino, plotLine } = props;

  useEffect(() => {
    loadData();
  }, [data, conHorasHombre, termino, inicio]);

  const loadData = async () => {
    if (data) {
      const avancePeriodoAcotado = {
        avanceTemprano: [],
        avanceProgramado: [],
        avanceTardio: [],
        avanceReal: [],
        hhProgramado: [],
        hhReal: [],
      };

      if (data && data.dia) {
        for (let index = 0; index < data.dia.length; index++) {
          const dia = data.dia[index];
          if (moment(dia).isAfter(termino)) break;
          if (moment(dia).isBefore(inicio)) continue;
          avancePeriodoAcotado.avanceTemprano.push([dia, (data.avanceTemprano[index]) || data.avanceTemprano[index] === 0 ? parseFloat(data.avanceTemprano[index].toFixed(3)) : null]);
          avancePeriodoAcotado.avanceTardio.push([dia, (data.avanceTardio[index]) || data.avanceTardio[index] === 0 ? parseFloat(data.avanceTardio[index].toFixed(3)) : null]);
          avancePeriodoAcotado.avanceReal.push([dia, (data.avanceReal[index]) || data.avanceReal[index] === 0 ? parseFloat(data.avanceReal[index].toFixed(3)) : null]);
          avancePeriodoAcotado.avanceProgramado.push([dia, (data.avanceProgramado[index]) || data.avanceProgramado[index] === 0 ? parseFloat(data.avanceProgramado[index].toFixed(3)) : null]);
          avancePeriodoAcotado.hhReal.push([dia, (data.hhReal[index]) || data.hhReal[index] === 0 ? parseFloat(data.hhReal[index].toFixed(3)) : null]);
          avancePeriodoAcotado.hhProgramado.push([dia, (data.hhProgramado[index]) || data.hhProgramado[index] === 0 ? parseFloat(data.hhProgramado[index].toFixed(3)) : null]);
        }
      }
      loadChart(avancePeriodoAcotado);
    }
  }

  const loadChart = async (avancePeriodo) => {
    if (avancePeriodo !== null && termino) {
      let xPlotLine = {};
      if (plotLine && plotLine < termino.valueOf()) xPlotLine = {
        color: '#FF0000', // Red
        width: 2,
        value: plotLine
      };
      const options = {
        exporting: {
          chartOptions: { // specific options for the exported image
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            }
          },
          fallbackToExportServer: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            day: '%e %b',
            month: '%e %b %Y',
            year: '%b %Y'
          },
          title: {
            text: 'Tiempo'
          },
          plotLines: [
            xPlotLine
          ]
        },
        yAxis: [{
          min: 0,
          max: data.valorMaximoGrafico ? data.valorMaximoGrafico : 100,
          title: {
            text: 'Avance %'
          },
          labels: {
            format: '{value}%',
          },
        },
        {
          min: 0,
          title: {
            text: 'Horas Hombre (HH)'
          },
          labels: {
            format: '{value}',
          },
          opposite: true
        }
        ],
        title: {
          text: 'Curva de Avance Físico'
        },
        series: [
          {
            name: "Avance Temprano",
            type: 'spline',
            yAxis: 0,
            data: avancePeriodo.avanceTemprano,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          },
          {
            name: "Avance Programado",
            type: 'spline',
            yAxis: 0,
            data: avancePeriodo.avanceProgramado,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          },
          {
            name: "Avance Tardío",
            type: 'spline',
            yAxis: 0,
            data: avancePeriodo.avanceTardio,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          }
          ,
          {
            name: "Avance Real",
            type: 'spline',
            yAxis: 0,
            data: avancePeriodo.avanceReal,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}%'
            },
          }
          ,
          {
            name: "HH Programado",
            type: 'area',
            yAxis: 1,
            data: avancePeriodo.hhProgramado,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
            },
          },
          {
            name: "HH Real",
            yAxis: 1,
            type: 'area',
            data: avancePeriodo.hhReal,
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '{point.x:%e/%m/%Y} -  {point.y:f}'
            },
          }

        ],
        plotOptions: {
          area: {
            fillOpacity: 0.2,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        margin: 0,

      };
      if (setConfigChart) setConfigChart(options);
    }
  }

  return (
    <div>
      <Page
        configChart={configChart}
        chartRef={props.chartRef}
      />
    </div>
  );
}