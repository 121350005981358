import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Button,
    Typography,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Grid,
    Container
} from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert, ArrowBack } from '@material-ui/icons';
import Moment from 'react-moment';
import * as ROUTES from '../../../constants/routes';
import moment from 'moment';
import './style.css';
import EditarActividadPod from './EditarActividadPod';
import { Link } from 'react-router-dom';
import DialogoConfirmar from '../../../components/dialogoConfirmar';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        flexGrow: 1
    },
    mainPrograma: {
        flexGrow: 1
    },
    cardHeaderTypography: {
        minWidth: "360px",
        padding: theme.spacing(2)
    },
    cardMenuDropDown: {
    },
    gridCardTitle: {
        marginBottom: theme.spacing(2)
    },
    gridCardBody: {
        overflow: "auto",
        maxWidth: "95vw"
    }
}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

function Tabla(props) {

    const classes = useStyles();

    const {
        data,
        usuarioSesion
    } = props;
    const [pageSize, setPageSize] = useState(10);

    return (
        <MaterialTable
            title={`Listado de Actividades`}
            options={{
                filtering: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
                pageSize: pageSize,
                grouping: false,
                columnsButton: true,
                toolbarButtonAlignment: "left",
                searchFieldAlignment: "left",
                padding: 'dense',
                maxBodyHeight: "70vh",
                // minBodyHeight: "450px",
                headerStyle: {
                    position: 'sticky',
                    // top: "64px",
                    textAlign: 'center',
                    border: "1px solid #E0E0E0",
                    fontWeight: 'bold',
                    color: '#731f1f',
                    // minWidth: "150px"

                    // padding: '8px'
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.is_hoja ? (rowData.listoCierre ? '#fff': amber[200]) : '#f1f2f6'),
                    border: "1px solid #E0E0E0"
                }),
                actionsCellStyle:{
                    width: '5%',
                },
                emptyRowsWhenPaging: false,
                // tableLayout: 'fixed'

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={location}
            columns={[
                {
                    title: '',
                    field: 'item',
                    cellStyle: {
                        textAlign: "center",
                        width: '5%',
                    },
                    headerStyle:{
                        width: '5%',    
                    },
                    render:
                        rowData => {
                            if (rowData.is_hoja) return (
                                <Button
                                    variant="outlined"
                                    onClick={(evt) => props.handleOpenEditarActividad(evt, rowData)}
                                >
                                    Cierre
                                </Button>
                            );
                            else return "";
                        }
                },
                {
                    title: 'Ítem',
                    field: 'item',
                    cellStyle: {
                        width: '5%',
                        borderLeft: '1px solid #E0E0E0',
                    },
                    headerStyle:{
                        width: '5%',    
                    },
                    defaultSort: 'asc'
                },
                {
                    title: 'ID',
                    field: 'id_item',
                    cellStyle: {
                        width: '5%',
                        borderLeft: '1px solid #E0E0E0',
                    },
                    headerStyle:{
                        width: '5%',    
                    },
                },
                {
                    title: 'Actividad',
                    field: 'nombre',
                    cellStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'HH',
                    field: 'horas_hombre',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'Unidad',
                    field: 'unidad_cantidad_cumplir',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'Cantidad',
                    field: 'cantidad',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                },
                {
                    title: 'Cantidad Prog. Turno',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // console.log(rowData);
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.cantidad_programada) {
                                // console.log(rowData.avancePod);
                                return numberFormater((rowData.avancePod.cantidad_programada).toFixed(2))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Cantidad Real Turno',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.cantidad) {
                                return numberFormater((rowData.avancePod.cantidad).toFixed(2))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Total HH asignadas',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.horas_hombre_programadas) {
                                return numberFormater((rowData.avancePod.horas_hombre_programadas).toFixed(0))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Total HH ganadas',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.horas_hombre) {
                                return numberFormater((rowData.avancePod.horas_hombre).toFixed(0))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Cumplimiento (Con excesos)',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.porcentajeCumplimiento) {
                                return numberFormater((rowData.avancePod.porcentajeCumplimiento).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD HH Prog.',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.avanceProgramadoHH) {
                                return numberFormater((rowData.avancePod.avanceProgramadoHH).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD HH Real',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.avanceRealHH) {
                                return numberFormater((rowData.avancePod.avanceRealHH).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD Costos Prog.',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    hidden: true,
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.avanceProgramadoCostos) {
                                return numberFormater((rowData.avancePod.avanceProgramadoCostos).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD Costos Real',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    hidden: true,
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.avanceRealCostos) {
                                return numberFormater((rowData.avancePod.avanceRealCostos).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Causa Raíz',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.observacion) {
                                return rowData.avancePod.observacion;
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Método de acción',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.metodo_accion) {
                                return rowData.avancePod.metodo_accion;
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Responsable acción',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.responsable_accion) {
                                return rowData.avancePod.responsable_accion;
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Baglog',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                    render:
                        rowData => {
                            if(!rowData.is_hoja) return "";
                            if (rowData.avancePod && rowData.avancePod.baglog) {
                                return "Sí";
                            } else {
                                return "No";
                            }
                        }

                },
                {
                    title: 'Comentario',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                    render:
                        rowData => {
                            if(!rowData.is_hoja) return "";
                            if (rowData.avancePod && rowData.avancePod.comentario_baglog) {
                                return rowData.avancePod.comentario_baglog;
                            }
                        }

                },
                
            ]}
            data={data}
        />
    );
}

function generarFechaView(data, format) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }

}

function Page(props) {
    const classes = useStyles();
    const {
        goTo,
        data,
        pod
    } = props;

    return (
        <Container className={classes.gridCardBody}>
            {!data &&
                <Card className={classes.mainPrograma}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Cargando POD</Typography>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {data && pod &&
                <Grid container spacing={2} className={classes.gridCardTitle}>
                    <Grid item lg>
                        <Card className={classes.mainPrograma}>

                            <CardContent>
                                <Grid container
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item lg style={{ textAlign: "left" }}>
                                        <Typography
                                            align="left"
                                            variant={"h5"}
                                            className={classes.cardHeaderTypography}
                                        >
                                            {pod ? `Cierre POD ${moment(pod.fecha).format("DD/MM/YYYY")} - Turno ${pod.turno}` : "POD"}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg style={{ textAlign: "right" }}>
                                        <Tooltip title="Volver">
                                            <IconButton onClick={() => goTo(`${ROUTES.POD}`)} aria-label="settings">
                                                <ArrowBack />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item lg style={{ textAlign: "left" }}>
                                        <Table size="small" style={{ display: "inline-flex" }}>
                                            <TableBody>
                                                <TableRow>
                                                    <CustomCell1 align="center" colSpan={2}><b>Línea Base</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}><b>Cumplimiento POD</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}><b>Avance POD HH</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}><b>Avance POD Costos</b></CustomCell1>

                                                </TableRow>
                                                <TableRow>
                                                    <CustomCell1 align="center"><b>HH</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Costos</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}>{numberFormater(pod.porcentaje_cumplimiento ? (pod.porcentaje_cumplimiento).toFixed(2) : 0) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center"><b>Programado</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Real</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Programado</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Real</b></CustomCell1>
                                                </TableRow>
                                                <TableRow>
                                                    <CustomCell1 align="center" rowSpan={2}>{numberFormater((pod.horasHombreTotalPrograma).toFixed(2))}</CustomCell1>
                                                    <CustomCell1 align="center" rowSpan={2}>{numberFormater((pod.costoTotalPrograma).toFixed(0))}</CustomCell1>
                                                    <CustomCell1 align="center"><b>Avance Programado Línea Base</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Cumplimiento del Programa</b></CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceProgramadoHH).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceRealHH).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceProgramadoCostos).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceRealCostos).toFixed(2)) + "%"}</CustomCell1>
                                                </TableRow>
                                                <TableRow>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceProgramadoLineaBase).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.cumplimientoProgramado).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.horasHombreProgramadas).toFixed(2))}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.horasHombreReales).toFixed(2))}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.costoProgramado).toFixed(0))}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.costoReal).toFixed(0))}</CustomCell1>
                                                </TableRow>
                                                {/* <TableRow>
                                                    
                                                </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    </Grid>

                                </Grid>
                                <br />
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        lg={4}
                                        className={classes.center}
                                    >
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={!data}
                                            onClick={() => props.setOpenCierrePod(true)}
                                            style={{ marginRight: "8px" }}
                                            disabled={!pod.listoCierre}
                                        >
                                            Cerrar POD
                                        </Button>
                                        <Button component={Link} variant="outlined" to={ROUTES.POD} color="primary">
                                            Volver
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {
                data && pod &&

                <Tabla
                    {...props}
                />

            }
            <EditarActividadPod
                open={props.openEditarActividad}
                handleClose={() => props.setOpenEditarActividad(false)}
                handleSubmit={props.handleSubmitEditarActividad}
                data={props.actividadSeleccionada}
            />
            <DialogoConfirmar
                open={props.openCierrePod}
                message={"¿Está seguro de cerrar el POD?"}
                action={(e) => props.handleSubmitCierrePod(e)}
                handleClose={() => props.setOpenCierrePod(false)}
            />
        </Container>
    );
}

export default Page;