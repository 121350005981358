import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Grid,
    Container
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import formatNumber from 'format-number';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
Highcharts.setOptions({
    lang: {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortWeekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
    }
});
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const useStyles = makeStyles(theme => ({
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    },
    tablaAvances: {
        marginTop: "8px",

    },
    tableBodyAvances: {
        [theme.breakpoints.down('md')]: { // eslint-disable-line no-useless-computed-key
            overflow: "auto",
            display: 'block',
            maxWidth: "88vw",
        }
    }
}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);
const TableCell1 = withStyles(theme => ({
    head: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
    },
    body: {
        border: "1px solid #E0E0E0",
        fontSize: 12,
    },
}))(TableCell);
const TableCell2 = withStyles(theme => ({
    body: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
        backgroundColor: "#ddd",
        color: theme.palette.primary.main,
        borderBottomColor: "#FFF"
    },
}))(TableCell);

const TableCell3 = withStyles(theme => ({
    body: {
        fontSize: 12,
        border: "1px solid #E0E0E0",
        backgroundColor: "#cecece",
        borderBottomColor: "#FFF"
    },
}))(TableCell);


const TableCell4 = withStyles(theme => ({
    body: {
        fontSize: 12,
        border: theme.palette.primary.main,
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        borderBottomColor: "#FFF"
    },
}))(TableCell);

const BlankCell = withStyles(theme => ({
    body: {
        border: "none",
    }
}))(TableCell);

function Page(props) {
    const classes = useStyles();
    const {
        avancesPeriodo,
        configChart,
        indicadores,
        inicioSemana,
        isloading,
        setInicioSemana
    } = props;

    return (
        <Container className={classes.main}>
            {isloading &&
                <Grid container alignContent="center" alignItems="center">
                    <Grid item lg className={classes.center}>
                        <CircularProgress color="primary" size={"70px"} />
                    </Grid>
                </Grid>
            }
            {!isloading &&
                <HighchartsReact
                    highcharts={Highcharts}
                    options={
                        configChart
                    }
                    containerProps={{ style: { width: "100%" } }}
                >
                </HighchartsReact>
            }

            {!isloading &&
                <Grid container alignItems="center" justify="center">
                    <Grid item lg={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDatePicker
                                name="semanaSeleccionada"
                                autoOk
                                fullWidth
                                label="Período Seleccionado (Fecha de Inicio)"
                                format="dd/MM/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                                value={inicioSemana.format("YYYY-MM-DD 00:00:00")}
                                onChange={(e) => {
                                    setInicioSemana(moment(e).startOf("week"));
                                }}
                                inputVariant="outlined"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            }
            {!isloading && avancesPeriodo &&
                <Table
                    size="small"
                    className={classes.tablaAvances}>
                    <TableBody className={classes.tableBodyAvances}>
                        <TableRow>
                            <TableCell2 align="center"><b>Fecha</b></TableCell2>
                            {avancesPeriodo.avanceProgramado.map((a, index) => {
                                if (moment(a[0]).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{moment(a[0]).utc().format("DD/MM")}</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{moment(a[0]).utc().format("DD/MM")}</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>Résumen</b></TableCell2>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Av. Prog.</b></TableCell2>
                            {avancesPeriodo.avanceProgramado.map((a, index) => {
                                if (moment(a[0]).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}%</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}%</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>{numberFormater((indicadores ? indicadores.totalAvanceProgramado : 0).toFixed(2))}%</b></TableCell2>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Av. Ganado</b></TableCell2>
                            {avancesPeriodo.avanceReal.map((a, index) => {
                                if (moment(a[0]).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}%</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}%</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>{numberFormater((indicadores ? indicadores.totalAvanceReal : 0).toFixed(2))}%</b></TableCell2>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>HH. Prog.</b></TableCell2>
                            {avancesPeriodo.hhProgramado.map((a, index) => {
                                if (moment(a[0]).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>{numberFormater((indicadores ? indicadores.totalHHProgramado : 0).toFixed(2))}</b></TableCell2>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>HH. Ganado</b></TableCell2>
                            {avancesPeriodo.hhReal.map((a, index) => {
                                if (moment(a[0]).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>{numberFormater((indicadores ? indicadores.totalHHReal : 0).toFixed(2))}</b></TableCell2>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center"><b>Cump. POD</b></TableCell2>
                            {avancesPeriodo.cumplimientoPod.map((a, index) => {
                                if (moment(a[0]).isBefore(moment(inicioSemana))) return (<TableCell3 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}%</b></TableCell3>);
                                return (<TableCell1 align="center" key={`${index}`}><b>{numberFormater((a[1] ? a[1] : 0).toFixed(2))}%</b></TableCell1>);
                            })
                            }
                            <TableCell2 align="center"><b>{numberFormater((indicadores ? indicadores.promedioCumplimientoPod : 0).toFixed(2))}%</b></TableCell2>
                        </TableRow>
                    </TableBody>
                </Table>
            }
            <br />
            {/* {!isloading &&
                <Table size="small">
                    <TableBody className={classes.tableBodyAvances}>
                        <TableRow>
                            <TableCell4 align="center" colSpan={4}><b>Período/Semana Anterior</b></TableCell4>
                            <BlankCell></BlankCell>
                            <TableCell4 align="center" colSpan={4}><b>Período/Semana Actual</b></TableCell4>
                        </TableRow>
                        <TableRow>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Cumplimiento promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((cumplimientoPeriodoAnterior).toFixed(2)) + "%"}</CustomCell1>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Indice Eficiencia promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((indiceEficienciaHorasEfectivasAnterior).toFixed(2))}</CustomCell1>
                            <BlankCell></BlankCell>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Cumplimiento promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((cumplimientoPeriodo).toFixed(2)) + "%"}</CustomCell1>
                            <TableCell2 align="center" style={{ width: "13%" }}><b>Indice Eficiencia promedio</b></TableCell2>
                            <CustomCell1 align="center" style={{ width: "12%" }}>{numberFormater((indiceEficienciaHorasEfectivasPeriodo).toFixed(2))}</CustomCell1>
                        </TableRow>
                    </TableBody>
                </Table>
            } */}
        </Container >
    );
}

export default Page;