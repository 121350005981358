import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import { getPodsProyecto, agregarPodsProyecto, quitarPodsProyecto, getItemsProgramaDia } from '../../../services/database/proyecto';
// import { uploadFileToDrive, viewDriveFile } from '../../../services/fileUpload';
import { postPod, updatePod, getPod, getPods } from '../../../services/database/pods';
import { useHistory, useParams } from 'react-router';
import { getItemsPrograma, agregarAvanceItemsPrograma, getItemPrograma, getPrograma, getAvancesPeriodo } from '../../../services/database/programacion';
import { getProgramasSemanales } from '../../programacionSemanal/requestsProgramasSemanales';
import { getDiasItemsProgramasSemanales } from '../../programacionSemanal/requestsDiaItemProgramaSemanal';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { accionOpenGenerarPod, accionSetFechaGenerarPod } from '../actionsPod';

export default function GenerarPod(props) {
    const history = useHistory();
    const notisnack = useSnackbar();
    const dispatch = useDispatch();
    const [actividadSeleccionada, setActividadSeleccionada] = useState(null);
    const [data, setData] = useState(null);
    const [openAgregarActividad, setOpenAgregarActividad] = useState(false);
    const [openEditarActividad, setOpenEditarActividad] = useState(false);
    const [turnoPod, setTurnoPod] = useState('Día');
    const [turnosPod, setTurnosPod] = useState([]);

    const { usuarioSesion, contrato, proyecto, fechaNuevoPod } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto,
        fechaNuevoPod: state.reducerPod.fechaNuevoPod,
    }));

    //CARGAR DATOS TABLA
    const cargarData = async (fechaNuevoPod) => {
        try {
            const turnos = [];
            let podDia = null;
            let podNoche = null;
            let pods = await getPodsProyecto(proyecto._id);
            if (pods) {
                podDia = pods.find(a => moment(a.fecha).format("YYYY-MM-DD") === moment(fechaNuevoPod).format("YYYY-MM-DD") && a.turno === "Día");
                podNoche = pods.find(a => moment(a.fecha).format("YYYY-MM-DD") === moment(fechaNuevoPod).format("YYYY-MM-DD") && a.turno === "Noche");
            }
            if (!podDia) turnos.push("Día");
            if (!podNoche) turnos.push("Noche");
            setTurnosPod(turnos);
            setTurnoPod(turnos[0]);
            const response = await getItemsProgramaDia(proyecto._id, moment(fechaNuevoPod).startOf("day").toDate());
            if (response) {
                for (let r = 0; r < response.length; r++) {
                    const actividad = response[r];
                    if (actividad.padre) {
                        let padre = response.find(a => a._id === actividad.padre);
                        if (!padre) {
                            padre = await getItemPrograma(actividad.padre);
                            padre["hijos"] = [];
                            response.push(padre);
                        }
                        padre.hijos.push(actividad._id);
                    }

                    if (!actividad.item_programa_semanal_ref && actividad.is_hoja) {
                        if (!actividad.cantidad_programada) actividad["cantidad_programada"] = 0;
                        if (!actividad.horas_hombre_programadas) actividad["horas_hombre_programadas"] = 0;
                        actividad["unidad_cantidad_cumplir"] = actividad.unidad ? actividad.unidad : "";
                        let avanceRestante = 100;
                        let hhRestantes = actividad.horas_hombre ? actividad.horas_hombre : 0;
                        if (!actividad.inicio_ideal) actividad.inicio_ideal = actividad.termino_ideal;
                        if (!actividad.termino_ideal) actividad.termino_ideal = actividad.inicio_ideal;
                        if (actividad.inicio_ideal && actividad.termino_ideal) {
                            if (moment(fechaNuevoPod).isAfter(actividad.inicio_ideal) && moment(fechaNuevoPod).isSameOrBefore(actividad.termino_ideal)) {
                                const duracionProgramada = moment(actividad.termino_ideal).diff(moment(actividad.inicio_ideal), "days") + 1;
                                let tiempoTranscurrido = 0;

                                if (moment(fechaNuevoPod).isAfter(actividad.termino_ideal)) {
                                    tiempoTranscurrido = duracionProgramada;
                                } else {
                                    tiempoTranscurrido = moment(fechaNuevoPod).diff(moment(actividad.inicio_ideal), "days") + 1;
                                }
                                if (!actividad.porcentaje_avance_reprogramado) actividad["porcentaje_avance_reprogramado"] = 0;
                                if (!actividad.horas_hombre_reprogramadas) actividad["horas_hombre_reprogramadas"] = 0;
                                const hhProgramadas = actividad.horas_hombre ? actividad.horas_hombre - actividad.horas_hombre_reprogramadas : 0;
                                const hhDiaria = actividad.horas_hombre && duracionProgramada ? actividad.horas_hombre / duracionProgramada : 0;
                                let hhProgramadasAcumuladas = hhDiaria * tiempoTranscurrido;
                                if (hhProgramadasAcumuladas < hhProgramadas) {
                                    actividad.horas_hombre_programadas = hhDiaria;
                                    actividad.cantidad_programada = (actividad.cantidad && actividad.horas_hombre) ? actividad.horas_hombre_programadas * actividad.cantidad / actividad.horas_hombre : 0;
                                }
                            }
                        }

                        // Si La actividad ha sido reprogramada

                        if (actividad.items_reprogramacion_ref && actividad.items_reprogramacion_ref.length > 0) {

                            for (let r = 0; r < actividad.items_reprogramacion_ref.length; r++) {
                                const reprogramacionItem = actividad.items_reprogramacion_ref[r];
                                //AVANCE REPROGRAMADO IDEAL
                                if (reprogramacionItem.inicio_ideal && reprogramacionItem.termino_ideal && moment(fechaNuevoPod).isAfter(reprogramacionItem.inicio_ideal)) {
                                    const duracionReprogramacion = moment(reprogramacionItem.termino_ideal).diff(moment(reprogramacionItem.inicio_ideal), "days") + 1;
                                    let tiempoTranscurrido = 0;
                                    const hhProgramadas = hhRestantes - reprogramacionItem.horas_hombre_reprogramadas > 0 ? hhRestantes - reprogramacionItem.horas_hombre_reprogramadas : hhRestantes;
                                    const hhDiarias = hhProgramadas / duracionReprogramacion;
                                    if (moment(fechaNuevoPod).isAfter(reprogramacionItem.termino_ideal)) {
                                        tiempoTranscurrido = duracionReprogramacion;
                                    } else {
                                        tiempoTranscurrido = moment(fechaNuevoPod).diff(moment(reprogramacionItem.inicio_ideal), "days") + 1;
                                    }
                                    let hhProgramadasAcumuladas = hhDiarias * tiempoTranscurrido;
                                    if (hhProgramadasAcumuladas < hhProgramadas) {
                                        actividad.horas_hombre_programadas = hhDiarias;
                                        actividad.cantidad_programada = (actividad.cantidad && actividad.horas_hombre) ? actividad.horas_hombre_programadas * actividad.cantidad / actividad.horas_hombre : 0;
                                    }
                                }
                            }
                        }
                    }

                }
                setData(response);
            }
        }
        catch (e) {
            console.log(e);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible generar el POD. Intente nuevamente o contacte con soporte", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
            setData([]);
        }
    }

    function eliminacionRecursiva(newData, index) {
        if (index > -1) {
            const copy = newData[index];
            newData.splice(index, 1);
            if (copy.padre) {
                const parentIndex = newData.findIndex(a => a._id === copy.padre);
                if (parentIndex > -1) {
                    const hijosIndex = newData[parentIndex].hijos.findIndex(a => a === copy._id);
                    if (hijosIndex > -1) newData[parentIndex].hijos.splice(hijosIndex, 1);
                    if (newData[parentIndex].hijos.length === 0) eliminacionRecursiva(newData, parentIndex);
                }
            }
        }
    }

    const handleOpenEditarActividad = (e, rowData) => {
        setActividadSeleccionada(rowData);
        setOpenEditarActividad(true);
    }

    const handleOpenEliminarActividad = (e, rowData) => {
        const newData = data.slice();
        const index = newData.findIndex(a => a._id === rowData._id);
        eliminacionRecursiva(newData, index);
        setData(newData);
    }

    const handleSubmitAgregarActividad = async (values) => {
        const actividad = values.actividad.value;
        actividad["cantidad_programada"] = parseFloat(values.cantidad_programada);
        actividad["horas_hombre_programadas"] = parseFloat(values.horas_hombre_programadas);
        const newData = data.slice();
        await insercionRecursiva(newData, actividad);
        setData(newData);
        setOpenAgregarActividad(false);
    }

    const handleSubmitEditarActividad = async (values) => {
        try {
            const newData = data.slice();
            actividadSeleccionada.cantidad_programada = parseFloat(values.cantidad_programada);
            actividadSeleccionada.horas_hombre_programadas = parseFloat(values.horas_hombre_programadas);
            setData(newData);
            setOpenEditarActividad(false);
            setActividadSeleccionada(null);
        }
        catch (error) {
            console.log(error);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible agregar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    const handleSubmitPod = async () => {
        const key = notisnack.enqueueSnackbar("Guardando Cambios", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            const itemsId = [];
            const pod = await postPod({
                porcentaje_cumplimiento: 0,
                fecha: moment(fechaNuevoPod).startOf("day").toDate(),
                nombre_hoja: "",
                proyecto_ref: proyecto._id,
                adjunto: null,
                items_id: itemsId,
                turno: turnoPod
            });
            await agregarPodsProyecto(proyecto._id, [pod._id]);
            for (let i = 0; i < data.length; i++) {
                if (data[i].is_hoja) {
                    const nuevoAvance = {
                        avance_colacion: 0,
                        avance_colacion_programado: 0,
                        baglog: data[i].baglog ? data[i].baglog : false,
                        cantidad: 0,
                        cantidad_programada: data[i].cantidad_programada,
                        comentario_baglog: data[i].comentario_baglog ? data[i].comentario_baglog : "",
                        cumplimiento_equipos: 0,
                        cumplimiento_mano_obra: 0,
                        desvio_avance_colacion: 0,
                        desvio_inicio: "",
                        desvio_termino: "",
                        equipo: "",
                        equipos: 0,
                        equipos_programados: 0,
                        fecha: moment(fechaNuevoPod).startOf("day").toDate(),
                        fecha_ingreso: moment(fechaNuevoPod).startOf("day").toDate(),
                        hora_colacion: null,
                        hora_colacion_programado: null,
                        horas_hombre: 0,
                        horas_hombre_programadas: data[i].horas_hombre_programadas,
                        hora_inicio: null,
                        hora_inicio_programado: null,
                        hora_termino: null,
                        hora_termino_programado: null,
                        mano_obra: 0,
                        mano_obra_programada: 0,
                        metodo_accion: "",
                        observacion: "",
                        pod: pod._id,
                        unidad: data[i].unidad ? data[i].unidad : "",
                        responsable_accion: "",
                        responsable_otro: "",
                        responsable_contratista: "",
                        tiempo_efectivo_trabajo: "",
                        tiempo_real_jornada: "",
                        tipo_avance: "CON_HH",
                        turno: turnoPod,
                        usuario_ref: usuarioSesion.ref
                    };
                    await agregarAvanceItemsPrograma(data[i]._id, nuevoAvance);
                    itemsId.push(data[i].id_item);
                }
            }
            await updatePod(pod._id, { items_id: itemsId });
            notisnack.closeSnackbar(key);
            const successkey = notisnack.enqueueSnackbar("Operación exitosa: Actividad creada", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            history.push("/pods");
        }
        catch (error) {
            console.log(error);
            notisnack.closeSnackbar(key);
            const errorkey = notisnack.enqueueSnackbar("Error: No ha sido posible agregar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notisnack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }
    }

    async function insercionRecursiva(newData, value) {
        newData.push(value);
        if (value.padre) {
            let padre = newData.find(a => a._id === value.padre);
            if (padre) {
                padre.hijos.push(value._id);
            } else {
                padre = await getItemPrograma(value.padre);
                padre["hijos"] = [];
                await insercionRecursiva(newData, padre);
            }
        }
    }


    const handleOpenDialogoGenerarPod = () => {
        dispatch(accionOpenGenerarPod());
    }
    // useEffect(() => {
    //     cargarData();
    // }, [proyecto]);

    useEffect(() => {
        if (!fechaNuevoPod) dispatch(accionOpenGenerarPod());
        else cargarData(fechaNuevoPod);
    }, [fechaNuevoPod]);


    return (
        <Page
            actividadSeleccionada={actividadSeleccionada}
            data={data}
            fechaNuevoPod={fechaNuevoPod}
            handleOpenDialogoGenerarPod={handleOpenDialogoGenerarPod}
            handleOpenEditarActividad={handleOpenEditarActividad}
            handleOpenEliminarActividad={handleOpenEliminarActividad}
            handleSubmitAgregarActividad={handleSubmitAgregarActividad}
            handleSubmitEditarActividad={handleSubmitEditarActividad}
            handleSubmitPod={handleSubmitPod}
            openAgregarActividad={openAgregarActividad}
            openEditarActividad={openEditarActividad}
            proyecto={proyecto}
            setOpenAgregarActividad={setOpenAgregarActividad}
            setOpenEditarActividad={setOpenEditarActividad}
            setTurnoPod={setTurnoPod}
            turnoPod={turnoPod}
            turnosPod={turnosPod}
            usuarioSesion={usuarioSesion}
        />
    );
}