import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    LinearProgress,
    Tooltip,
    IconButton
} from '@material-ui/core';
import MaterialTable from 'material-table';
import './style.css';
import FileInput from '../../../components/fileInput';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const style = makeStyles(theme => ({
    main: {
        flexGrow: 1
    },
    gridCenter: {
        textAlign: "center"
    },
    actionButtons: {
        marginRight: theme.spacing(2)
    }
}));

function TablaVistaPrevia(props) {
    const {
        itemsPresupuesto
    } = props;
    const [pageSize, setPageSize] = useState(10);
    return (
        <div>
            <MaterialTable
                title="Presupuesto"
                options={{
                    filtering: true,
                    pageSizeOptions: [5, 10, 20, 50],
                    pageSize: pageSize,
                    grouping: false
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar"
                    },
                    pagination: {
                        labelRowsSelect: "Filas",
                        labelDisplayedRows: '{from}-{to} de {count}'
                    },
                    grouping: {
                        placeholder: " Arrastre los encabezados aquí para agrupar "
                    }

                }}
                parentChildData={(row, rows) => rows.find(a => a.item === row.padre_item)}
                onChangeRowsPerPage={size => { setPageSize(size) }}
                columns={[
                    {
                        title: 'Ítem',
                        field: 'item',
                        // cellStyle: {
                        //     fontSize: '0.9em',
                        //     width: '15px',
                        // }
                    },
                    {
                        title: 'Nombre',
                        field: 'nombre',
                        // cellStyle: {

                        //     width: '7px',
                        // }
                    },
                    {
                        title: 'Precio U.',
                        field: 'precio_unitario_mostrar',
                        // cellStyle: {
                        //     // fontSize: '0.9em'
                        // },
                        // type: 'date'
                    },
                    {
                        title: 'Cantidad',
                        field: 'cantidad_mostrar',
                        // cellStyle: {
                        //     // fontSize: '0.9em'
                        // },
                        // type: 'date'
                    },
                    {
                        title: 'Unidad',
                        field: 'unidad',
                        // cellStyle: {
                        //     // fontSize: '0.9em'
                        // },
                        // type: 'date'
                    },
                    {
                        title: 'Precio Total',
                        field: 'precio_total_mostrar',
                        // cellStyle: {
                        //     // fontSize: '0.9em'
                        // },
                        // type: 'date'
                    }
                ]}
                data={itemsPresupuesto}
            />
        </div>
    );
}

function Page(props) {
    const classes = style();

    const {
        handleSubmit,
        isValid,
        errors,
        touched,
        values,
        openVistaPrevia,
        handleEnterVistaPrevia,
        handleCloseVistaPrevia,
        loadingVistaPrevia,
        itemsPresupuesto,
        verVistaPrevia,
        handleDescargarFormato
    } = props;

    const change = (name, e) => {
        const {
            setFieldTouched,
            handleChange,
            setFieldValue,
        } = props;
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        if (name === "file") {
            setFieldValue('file', e.target.files[0]);
        }
    };

    return (
        <Card className={classes.main}>
            <CardHeader
                title="Nuevo Presupuesto"
                subheader="Formulario para la carga de Presupuesto desde archivo Excel"
                action={
                    <Tooltip title="Ir al Presupuesto">
                        <IconButton component={Link} to={ROUTES.PRESUPUESTO} aria-label="settings">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent className={classes.main}>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Typography variant="h6" gutterBottom>
                        Archivo XLSX
                    </Typography>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item xs={12} lg={12}>
                            <FileInput
                                type="submit"
                                buttonColor="primary"
                                buttonVariant="outlined"
                                textVariant="outlined"
                                inputName="file"
                                label="Seleccionar Archivo XLSX"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                handleOnChange={change.bind(null, "file")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}>
                        <Grid item className={classes.gridCenter}>
                            <Button
                                onClick={() => handleDescargarFormato()}
                                color="secondary"
                                variant="outlined"
                                className={classes.actionButtons}
                            >
                                Descargar Formato
                                    </Button>
                            <Button
                                onClick={() => verVistaPrevia(values)}
                                color="secondary"
                                variant="outlined"
                                disabled={!isValid}
                                className={classes.actionButtons}
                            >
                                Vista Previa
                                        </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!isValid}
                                className={classes.actionButtons}
                            >
                                Guardar
                                        </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
            <Dialog
                onClose={handleCloseVistaPrevia}
                open={openVistaPrevia}
                onEntered={handleEnterVistaPrevia}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"lg"}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Vista Previa
                    </DialogTitle>
                <DialogContent>
                    {loadingVistaPrevia &&
                        <LinearProgress color="primary" />
                    }
                    {!loadingVistaPrevia &&
                        <TablaVistaPrevia
                            itemsPresupuesto={itemsPresupuesto}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleCloseVistaPrevia}
                    >
                        Cerrar
                        </Button>
                </DialogActions>
            </Dialog >

        </Card>
    );
}

export default Page;