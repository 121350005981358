import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Button,
    Typography,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Grid,
    Container
} from '@material-ui/core';
import MaterialTable, { MTableAction } from 'material-table';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../../components/menuDropDown';
import { MoreVert, ArrowBack } from '@material-ui/icons';
import Moment from 'react-moment';
import * as ROUTES from '../../../constants/routes';
import moment from 'moment';
import './style.css';
import AgregarActividadPod from './AgregarActividadPod';
import EditarActividadPod from './EditarActividadPod';
import DialogoConfirmar from '../../../components/dialogoConfirmar';
import { Link } from 'react-router-dom';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        flexGrow: 1
    },
    mainPrograma: {
        flexGrow: 1
    },
    cardHeaderTypography: {
        minWidth: "360px",
        padding: theme.spacing(2)
    },
    cardMenuDropDown: {
    },
    gridCardTitle: {
        marginBottom: theme.spacing(2)
    },
    gridCardBody: {
        overflow: "auto",
        maxWidth: "95vw"
    }
}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

function Tabla(props) {

    const classes = useStyles();

    const {
        data,
        usuarioSesion
    } = props;
    const [pageSize, setPageSize] = useState(50);

    return (
        <MaterialTable
            title={`Listado de items del POD`}
            options={{
                filtering: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
                pageSize: pageSize,
                grouping: false,
                columnsButton: true,
                toolbarButtonAlignment: "left",
                searchFieldAlignment: "left",
                padding: 'dense',
                maxBodyHeight: "70vh",
                // minBodyHeight: "450px",
                headerStyle: {
                    position: 'sticky',
                    // top: "64px",
                    textAlign: 'center',
                    border: "1px solid #E0E0E0",
                    fontWeight: 'bold',
                    color: '#731f1f',
                    minWidth: "150px"

                    // padding: '8px'
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.is_hoja ? '#fff' : '#f1f2f6'),
                    border: "1px solid #E0E0E0"
                }),
                emptyRowsWhenPaging: false,

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={location}
            actions={[
                {
                    tooltip: 'Agregar Actividad',
                    icon: 'add_circle',
                    isFreeAction: true,
                    iconProps: {
                        className: classes.success
                    },
                    hidden: !(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_POD]),
                    onClick: (evt) => props.setOpenAgregarActividad(true)
                },
                {
                    tooltip: 'Acciones',
                    icon: 'settings',
                    onClick: (evt) => { }
                }
            ]}
            components={{
                Action:
                    rowProps => {
                        const rowData = rowProps.data;
                        if (rowProps.action.icon === 'settings') {
                            const accionesConfiguracion = [];
                            if (usuarioSesion && usuarioSesion.permisos) {
                                if (usuarioSesion.permisos[PERMISIONS.EDITAR_POD]) accionesConfiguracion.push({
                                    content: 'Editar',
                                    onClick: (e) => props.handleOpenEditarActividad(e, rowData),
                                    _id: rowData._id
                                });
                                if (usuarioSesion.permisos[PERMISIONS.ELIMINAR_POD]) accionesConfiguracion.push(
                                    {
                                        content: 'Eliminar',
                                        onClick: (e) => props.handleOpenEliminarActividad(e, rowData),
                                        _id: rowData._id
                                    }
                                );
                            }
                            return (<MenuDropdown
                                icon={
                                    <MoreVert />
                                }
                                tooltip="Acciones"
                                items={accionesConfiguracion}
                                hidden={!rowData.is_hoja || accionesConfiguracion.length === 0}
                            />);
                        }
                        return (<MTableAction {...rowProps} />);
                    }
            }}
            columns={[
                {
                    title: 'Ítem',
                    field: 'item',
                    cellStyle: {
                        width: '5%',
                        borderLeft: '1px solid #E0E0E0'
                    },
                    defaultSort: 'asc'
                },
                {
                    title: 'ID',
                    field: 'id_item',
                    cellStyle: {
                        width: '5%',
                        borderLeft: '1px solid #E0E0E0',
                    },
                },
                {
                    title: 'Actividad',
                    field: 'nombre',
                    cellStyle: {
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'HH',
                    field: 'horas_hombre',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'Unidad',
                    field: 'unidad_cantidad_cumplir',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                },
                {
                    title: 'Cantidad',
                    field: 'cantidad',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                },
                {
                    title: 'Cantidad Prog. Turno',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // console.log(rowData);
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.cantidad_programada) {
                                // console.log(rowData.avancePod);
                                return numberFormater((rowData.avancePod.cantidad_programada).toFixed(2))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Cantidad Real Turno',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.cantidad) {
                                return numberFormater((rowData.avancePod.cantidad).toFixed(2))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Total HH asignadas',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.horas_hombre_programadas) {
                                return numberFormater((rowData.avancePod.horas_hombre_programadas).toFixed(0))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Total HH ganadas',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.horas_hombre) {
                                return numberFormater((rowData.avancePod.horas_hombre).toFixed(0))
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Cumplimiento (Con excesos)',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.porcentajeCumplimiento) {
                                return numberFormater((rowData.avancePod.porcentajeCumplimiento).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD HH Prog.',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            // rowData.
                            // numberFormater(data[rowData.tableData.id].horas_hombre)
                            if (rowData.avancePod && rowData.avancePod.avanceProgramadoHH) {
                                return numberFormater((rowData.avancePod.avanceProgramadoHH).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD HH Real',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.avanceRealHH) {
                                return numberFormater((rowData.avancePod.avanceRealHH).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD Costos Prog.',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    hidden: true,
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.avanceProgramadoCostos) {
                                return numberFormater((rowData.avancePod.avanceProgramadoCostos).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Avance POD Costos Real',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        textAlign: 'right'
                    },
                    hidden: true,
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.avanceRealCostos) {
                                return numberFormater((rowData.avancePod.avanceRealCostos).toFixed(2)) + "%"
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Causa Raíz',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.observacion) {
                                return rowData.avancePod.observacion;
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Método de acción',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0',
                        width: '450px',
                        maxWidth: '450px',
                        minWidth: '450px',
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.metodo_accion) {
                                return rowData.avancePod.metodo_accion;
                            } else {
                                return "";
                            }
                        }

                },
                {
                    title: 'Responsable acción',
                    field: 'avancePod',
                    cellStyle: {
                        borderLeft: '1px solid #E0E0E0'
                    },
                    render:
                        rowData => {
                            if (rowData.avancePod && rowData.avancePod.responsable_accion) {
                                return rowData.avancePod.responsable_accion;
                            } else {
                                return "";
                            }
                        }

                }
            ]}
            data={data}
        />
    );
}

function Page(props) {
    const classes = useStyles();
    const {
        data,
        pod,
        openEliminarActividad,
        setActividadSeleccionada,
        setOpenEliminarActividad
    } = props;

    return (
        <Container className={classes.gridCardBody}>
            {data === null &&
                <Card className={classes.mainPrograma}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Cargando POD</Typography>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {data !== null && pod &&
                <Grid container spacing={2} className={classes.gridCardTitle}>
                    <Grid item lg>
                        <Card className={classes.mainPrograma}>

                            <CardContent>
                                <Grid container
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item lg style={{ textAlign: "left" }}>
                                        <Typography
                                            align="left"
                                            variant={"h5"}
                                            className={classes.cardHeaderTypography}
                                        >
                                            {pod ? `POD ${moment(pod.fecha).format("DD/MM/YYYY")} - Turno ${pod.turno}` : "POD"}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg style={{ textAlign: "right" }}>
                                        <Tooltip title="Volver">
                                            <IconButton component={Link} to={`${ROUTES.POD}`} aria-label="settings">
                                                <ArrowBack />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item lg style={{ textAlign: "left" }}>
                                        <Table size="small" style={{ display: "inline-flex" }}>
                                            <TableBody>
                                                <TableRow>
                                                    <CustomCell1 align="center" colSpan={2}><b>Línea Base</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}><b>Cumplimiento POD</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}><b>Avance POD HH</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}><b>Avance POD Costos</b></CustomCell1>

                                                </TableRow>
                                                <TableRow>
                                                    <CustomCell1 align="center"><b>HH</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Costos</b></CustomCell1>
                                                    <CustomCell1 align="center" colSpan={2}>{numberFormater(pod.porcentaje_cumplimiento ? (pod.porcentaje_cumplimiento).toFixed(2) : 0) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center"><b>Programado</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Real</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Programado</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Real</b></CustomCell1>
                                                </TableRow>
                                                <TableRow>
                                                    <CustomCell1 align="center" rowSpan={2}>{numberFormater((pod.horasHombreTotalPrograma).toFixed(2))}</CustomCell1>
                                                    <CustomCell1 align="center" rowSpan={2}>{numberFormater((pod.costoTotalPrograma).toFixed(0))}</CustomCell1>
                                                    <CustomCell1 align="center"><b>Avance Programado Línea Base</b></CustomCell1>
                                                    <CustomCell1 align="center"><b>Cumplimiento del Programa</b></CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceProgramadoHH).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceRealHH).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceProgramadoCostos).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceRealCostos).toFixed(2)) + "%"}</CustomCell1>
                                                </TableRow>
                                                <TableRow>
                                                    <CustomCell1 align="center">{numberFormater((pod.avanceProgramadoLineaBase).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.cumplimientoProgramado).toFixed(2)) + "%"}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.horasHombreProgramadas).toFixed(2))}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.horasHombreReales).toFixed(2))}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.costoProgramado).toFixed(0))}</CustomCell1>
                                                    <CustomCell1 align="center">{numberFormater((pod.costoReal).toFixed(0))}</CustomCell1>
                                                </TableRow>
                                                {/* <TableRow>
                                                    
                                                </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {
                data !== null &&

                <Tabla
                    {...props}
                />

            }
            <AgregarActividadPod
                open={props.openAgregarActividad}
                handleClose={() => props.setOpenAgregarActividad(false)}
                handleSubmit={props.handleSubmitAgregarActividad}
            />
            <EditarActividadPod
                open={props.openEditarActividad}
                handleClose={() => props.setOpenEditarActividad(false)}
                handleSubmit={props.handleSubmitEditarActividad}
                data={props.actividadSeleccionada}
            />
            <DialogoConfirmar
                open={openEliminarActividad}
                message={"¿Está seguro de eliminar la actividad del POD?"}
                action={(e) => props.handleSubmitEliminarActividad(e)}
                handleClose={() => {
                    setActividadSeleccionada(null);
                    setOpenEliminarActividad(false);
                }}
            />
        </Container>
    );
}

export default Page;