import React, { Fragment, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress, Typography, Grid,
} from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);
Highcharts.setOptions({
    lang: {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortWeekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
    }
});

const style = makeStyles(theme => ({
    main: {
        flexGrow: 2
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    card: {
        height: "100%"
    },
    title: {
        width: "100%",
        color: theme.palette.primary.main,
        backgroundColor: "#FFF"

    },
    cardContainer: {
        minHeight: "76px"
    },
    progress: {
        margin: theme.spacing(2),
    },
    fullwith: {
        width: "100%",
        height: "100%"
    },
    loadDetalles: {
        // height: "100%",
        // width: "100%",
        paddingLeft: "50%"
    },
    button: {
        marginLeft: 'auto',
    },
    appsHeader: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main
    }
}));



function Page(props) {
    const classes = style();
    const {
        configChart,
        callback,
        chartRef
    } = props;
    const chart = useRef(null);
    return (
        <Fragment>
            {(configChart) &&
                <HighchartsReact
                    ref={chartRef ? chartRef : chart}
                    highcharts={Highcharts}
                    options={
                        configChart
                    }
                    containerProps={{ style: { width: "100%" } }}
                    callback={() => { if (callback) callback(); }}
                >
                </HighchartsReact>

            }
            {(!configChart) &&
                <div>
                    <Typography variant="h6" align="center">Generando Curva de Avance Físico</Typography>
                    <Grid container alignContent="center" alignItems="center">
                        <Grid item lg style={{ textAlign: "center" }}>
                            <CircularProgress size={"70px"} className={classes.progress} />
                        </Grid>
                    </Grid>
                </div>
            }
        </Fragment >
    );
}

export default Page;