import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from './page';
import { getRecursosProyecto } from '../../services/database/proyecto';

class Recursos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            proyecto: props.proyecto,
            nodata: false,
            openAgregar: false
        };
        this.goTo = this.goTo.bind(this);
    }

    goTo = (path) => {
        this.props.history.push(path);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.proyecto && nextProps.proyecto !== prevState.proyecto) {
            return { proyecto: nextProps.proyecto, data: null, nodata: false };
        }
        return null;
    }

    cargarData = async () => {
        const { proyecto } = this.props;
        if (proyecto) {
            const response = await getRecursosProyecto(proyecto._id);
            if (response) {
                this.setState({ data: response });
                return;
            }
        }
        this.setState({ data: [] });
    }

    handleOpenAgregar = () => {
        this.setState({openAgregar: true});
    }

    handleCloseAgregar = () => {
        this.setState({openAgregar: false});
        this.cargarData();
    }

    handleSubmitAgregar = (values) => {
        // const { proyecto } = this.props;
        console.log(values);
        // const newData = await postRecurso(values);
        // await addRecursosProyecto(proyecto._id, [newData._id]);
        this.handleCloseAgregar();
    }

    handleEliminar = async (data) => {
        // const contratoId = this.props.match.params.id;
        // await updateSuperintendencia(data._id, {is_eliminado: true});
        // const oldContrato = await getContrato(contratoId);
        // const indexProyecto = oldContrato.proyectos_ref.indexOf(data._id);
        // if(indexProyecto > -1) {
        //     oldContrato.proyectos_ref.splice(indexProyecto, 1);
        //     await updateContrato(oldContrato._id, {proyectos_ref: oldContrato.proyectos_ref});
        // }
        // this.setState({ data: null});
        // this.cargarData();
    }

    handleEditar = (data) => {
        this.props.history.push(`/recursos/${data._id}/editar`);
    }

    render() {
        const { data, openAgregar } = this.state;
        return (
            <Page
                data={data}
                handleLoadData={this.cargarData}
                handleOpenAgregar={this.handleOpenAgregar}
                handleCloseAgregar={this.handleCloseAgregar}
                handleSubmitAgregar={this.handleSubmitAgregar}
                openAgregar={openAgregar}
                handleEliminar={this.handleEliminar}
                handleEditar={this.handleEditar}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const props = {
        contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto
    };
    return props;
};

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Recursos)
);

export { default as AgregarRecurso } from './agregar';
export { default as EditarRecurso } from './editar';