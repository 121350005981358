import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@material-ui/core';
import NumberInput from '../../../components/numberInput';

const useStyles = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    formControl: {
        paddingTop: theme.spacing(3)
    },
    dialogPaper: {
        overflow: 'visible'
    },
    hidden: {
        display: "none"
    }

}));


function Page(props) {
    const classes = useStyles();
    const {
        errors,
        isValid,
        handleClose,
        handleEnterDialog,
        handleOnChange,
        handleSubmit,
        open,
        titulo,
        touched,
        values
    } = props;

    return (
        <Dialog
            open={open}
            onEntered={handleEnterDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle id="alert-dialog-title">{titulo}{}</DialogTitle>
                <DialogContent>
                    <Grid container
                        direction="row"
                        spacing={1}>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_ganadas"
                                type="text"
                                inputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                textVariant="outlined"
                                error={touched.horas_hombre_ganadas && Boolean(errors.horas_hombre_ganadas)}
                                label="HH Ganadas"
                                value={(values.horas_hombre_ganadas)}
                                custom={{
                                    margin: "normal"
                                }}
                                meta={{
                                    touched: touched.horas_hombre_ganadas,
                                    error: errors.horas_hombre_ganadas

                                }}
                                handleOnChange={handleOnChange.bind(null, "horas_hombre_ganadas")}
                            />
                        </Grid>
                        <Grid item lg>
                            <NumberInput
                                inputName="horas_hombre_gastadas"
                                type="text"
                                inputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel,
                                        shrink: "shrink"
                                    }
                                }}
                                textVariant="outlined"
                                error={touched.horas_hombre_gastadas && Boolean(errors.horas_hombre_gastadas)}
                                label="HH Gastadas"
                                value={(values.horas_hombre_gastadas)}
                                custom={{
                                    margin: "normal"
                                }}
                                meta={{
                                    touched: touched.horas_hombre_gastadas,
                                    error: errors.horas_hombre_gastadas

                                }}
                                handleOnChange={handleOnChange.bind(null, "horas_hombre_gastadas")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Aceptar
                        </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                        </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default Page;
