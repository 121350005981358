import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from './page';
import {
    getPodsProyecto,
    agregarPodsProyecto,
    quitarPodsProyecto,
    updateProyecto
} from '../../../services/database/proyecto';
import { uploadFileToDrive, viewDriveFile } from '../../../services/fileUpload';
import { postPod, updatePod, deletePod } from '../../../services/database/pods';
import { useHistory } from 'react-router';
import moment from 'moment';
import { downloadUrlAsPromise } from '../../../services/fileUpload';
import { saveAs } from 'file-saver';
import { getItemsPrograma, agregarAvanceItemsPrograma } from '../../../services/database/programacion';
import { useSnackbar } from 'notistack';
import { accionOpenEditarHHPod } from '../actionsPod';
import { crearCarpetaDrive, postCarpeta } from '../../../services/database/carpetas';
moment.locale('es');
export default function ListadoPods(props) {

    // const chartRef = useRef(null);
    // const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const notisnack = useSnackbar();
    const {
        usuarioSesion,
        // contrato,
        proyecto,
        // inicioSemana
    } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        // contrato: state.contratoSesion.contrato,
        proyecto: state.contratoSesion.proyecto,
        // inicioSemana: state.reducerPod.inicioSemanaReportePod
    }));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hideSnackbarButton, setHideSnackbarButton] = useState(false);
    const [openAgregarPod, setOpenAgregarPod] = useState(false);
    const [openEditarPod, setOpenEditarPod] = useState(false);
    const [openEliminarPod, setOpenEliminarPod] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openReporteSemanal, setOpenReporteSemanal] = useState(false);
    const [podSeleccionado, setPodSeleccionado] = useState(null);
    const [reporteSemanal, setReporteSemanal] = useState(null);
    const [snackbarVariant, setSnackbarVariant] = useState("default");
    const [snackbarMessage, setSnackbarMessage] = useState("");

    //CARGAR DATOS TABLA
    const cargarData = async () => {
        if (proyecto) {
            const podsProyecto = await getPodsProyecto(proyecto._id);
            if (podsProyecto) {
                let acumulado = 0;
                let total = 0;
                for (let key in podsProyecto) {
                    if (!podsProyecto[key].estado) podsProyecto[key]["estado"] = "Abierto";
                    acumulado = acumulado + podsProyecto[key].porcentaje_cumplimiento;
                    total++;
                }
                setData(podsProyecto);
                // setAvanceAcumulado(total ? acumulado / total : 0);
                setIsLoading(false);
                return;
            }
        }
        setData([]);
        setIsLoading(false);
    }
    //EVENTOS AGREGAR POD
    const handleOpenAgregarPod = (e, pod) => {
        setOpenAgregarPod(true);
    }
    const handleCloseAgregarPod = (e, pod) => {
        setOpenAgregarPod(false);
    }
    const handleSubmitAgregarPod = async (values) => {
        try {
            // const carpeta = { carpeta_id: "1RwXkaVgFPM3-ZILU-HpRQ2_asg-4nCyX", _id: "5e6942c9e07c460023de784e" }
            let carpeta = proyecto.carpetas.find(a => a.nombre === "POD");
            if (!carpeta) {
                const carpetaDriveApp = await crearCarpetaDrive({
                    name: `Programas Semanales`,
                    parent: proyecto.carpeta_proyecto.carpeta_id
                });
                carpeta = await postCarpeta({
                    nombre: `Programas Semanales`,
                    descripción: ``,
                    padre_ref: proyecto.carpeta_proyecto._id,
                    carpeta_id: carpetaDriveApp.id
                });
                proyecto.carpetas.push(carpeta);
                await updateProyecto(proyecto._id, { carpetas: proyecto.carpetas });
            }
            setSnackbarMessage("Subiendo Archivo");
            setSnackbarVariant("default");
            setOpenSnackbar(true);
            setHideSnackbarButton(true);
            if (carpeta) {
                handleCloseAgregarPod();
                await uploadFileToDrive(values.excel, carpeta.carpeta_id, values.excel.name, (event) => {
                    if (event.lengthComputable) {
                        const porcentaje = Math.round(event.loaded / event.total * 100);
                        setSnackbarMessage(`Subiendo Archivo ${porcentaje}%`);
                    }
                }).then(async (response) => {
                    setSnackbarMessage("Archivo POD almacenado... Creando PODs");
                    const adjunto = {
                        nombre: response.name,
                        archivo_id: response.id,
                        carpeta_id: carpeta.carpeta_id,
                        size: values.excel.size
                    }
                    for (let i = 0; i < values.pods.length; i++) {
                        const oldPod = data.find(a => moment(a.fecha).format("YYYY-MM-DD") === moment(values.pods[i].fecha).format("YYYY-MM-DD") && a.turno === values.pods[i].turno);
                        if (!oldPod) {
                            setSnackbarMessage(`Creando PODs ${(i + 1) + "/" + values.pods.length}`);
                            values.pods[i].adjunto = adjunto;
                            const nuevoPod = await postPod(values.pods[i]);
                            await agregarPodsProyecto(proyecto._id, [nuevoPod]);
                            for (let a = 0; a < values.pods[i].avances.length; a++) {
                                if (values.pods[i].avances[a].horas_hombre > 0 ||
                                    values.pods[i].avances[a].horas_hombre_programadas > 0 ||
                                    values.pods[i].avances[a].cantidad > 0 ||
                                    values.pods[i].avances[a].cantidad_programada > 0) {
                                    values.pods[i].avances[a]["pod"] = nuevoPod._id;
                                    const actividad = await getItemsPrograma(null, {
                                        programa_ref: proyecto.programa_actual_ref,
                                        id_item: values.pods[i].items_id[a]
                                    });
                                    if (actividad && actividad[0]) {
                                        await agregarAvanceItemsPrograma(actividad[0]._id, values.pods[i].avances[a]);
                                    }
                                }
                            }
                            // }
                        } else {
                            setSnackbarMessage(`Actualizando PODs ${(i + 1) + "/" + values.pods.length}`);
                            const update = {
                                adjunto: adjunto,
                                nombre: values.pods[i].nombre_hoja,
                                items_id: values.pods[i].items_id,
                                turno: values.pods[i].turno,
                                porcentaje_cumplimiento: values.pods[i].porcentaje_cumplimiento,
                                horas_hombre_ganadas: values.pods[i].horas_hombre_ganadas,
                                horas_hombre_gastadas: values.pods[i].horas_hombre_gastadas
                            }
                            await updatePod(oldPod._id, update);

                            for (let a = 0; a < values.pods[i].avances.length; a++) {
                                if (values.pods[i].avances[a].horas_hombre > 0 ||
                                    values.pods[i].avances[a].horas_hombre_programadas > 0 ||
                                    values.pods[i].avances[a].cantidad > 0 ||
                                    values.pods[i].avances[a].cantidad_programada > 0) {
                                    values.pods[i].avances[a]["pod"] = oldPod._id;
                                    const actividad = await getItemsPrograma(null, {
                                        programa_ref: proyecto.programa_actual_ref,
                                        id_item: values.pods[i].items_id[a]
                                    });
                                    if (actividad && actividad[0]) {
                                        await agregarAvanceItemsPrograma(actividad[0]._id, values.pods[i].avances[a]);
                                    }
                                }
                            }
                            // }
                        }

                    }
                    setSnackbarMessage("Operación exitosa: POD cargada");
                    setSnackbarVariant("success");
                    setHideSnackbarButton(false);
                    cargarData();
                })
                    .catch(error => {
                        console.log(error);
                        setSnackbarMessage("Error al intentar subir el POD");
                        setSnackbarVariant("error");
                        setHideSnackbarButton(false);
                        setOpenSnackbar(true);
                    });

            } else {
                // console.log("Sin carpeta de POD");
                setSnackbarMessage("Error: La carpeta de POD del proyecto no esta creada. Contacte con soporte");
                setSnackbarVariant("error");
                setHideSnackbarButton(false);
                setOpenSnackbar(true);
            }
        }
        catch (error) {
            console.log(error);
            handleCloseAgregarPod();
        }

    }

    //EVENTOS EDITAR POD
    const handleOpenEditarPod = (e, pod) => {
        setOpenEditarPod(true);
        setPodSeleccionado(pod);
    }
    const handleCloseEditarPod = (e, pod) => {
        setOpenEditarPod(false);
        setPodSeleccionado(null);
    }
    const handleSubmitEditarPod = async (values) => {
        await updatePod(podSeleccionado._id, values);
        handleCloseEditarPod();
        cargarData();
    }
    //EVENTOS ELIMINAR POD
    const handleOpenEliminarPod = (e, pod) => {
        setOpenEliminarPod(true);
        setPodSeleccionado(pod);
    }
    const handleCloseEliminarPod = (e, pod) => {
        setOpenEliminarPod(false);
        setPodSeleccionado(null);
    }
    const handleSubmitEliminarPod = async () => {
        await deletePod(podSeleccionado._id, true);
        await quitarPodsProyecto(proyecto._id, [podSeleccionado._id]);
        handleCloseEliminarPod();
        cargarData();
    }

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const handleViewPod = async (event, rowData) => {
        if (rowData && rowData.adjunto) {
            const nombre = rowData.adjunto.nombre;
            const response = await downloadUrlAsPromise(`https://www.googleapis.com/drive/v3/files/${rowData.adjunto.archivo_id}?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung`, nombre);
            if (response) saveAs(response.blob, nombre);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        cargarData();
    }, [proyecto]);

    const handleGenerarPOD = async () => {
        history.push("/generar-pod");
    }

    const handleDescargarFormato = async () => {
        const nombre = "Formato POD.xlsx";
        const response = await downloadUrlAsPromise("https://www.googleapis.com/drive/v3/files/13I9P7tfyH5ila74_XuXJA7z3IdI4In1w?alt=media&key=AIzaSyDThfGVR8mbxbmaYSkQFuHzBx0DuVv-Ung", nombre);
        if (response) saveAs(response.blob, nombre);
    }

    const handleSubmitEditarHorasHombrePod = async (values) => {
        cargarData();
    }

    const handleOpenEditarHHPod = (pod) => {
        dispatch(accionOpenEditarHHPod(pod));
    }


    return (
        <Page
            data={data}
            goTo={(path) => history.push(path)}
            handleCloseAgregarPod={handleCloseAgregarPod}
            handleCloseEditarPod={handleCloseEditarPod}
            handleCloseEliminarPod={handleCloseEliminarPod}
            handleCloseSnackbar={handleCloseSnackbar}
            handleDescargarFormato={handleDescargarFormato}
            // handleGenerarInformeSemanalPod={handleGenerarInformeSemanalPod}
            handleGenerarPOD={handleGenerarPOD}
            handleOpenAgregarPod={handleOpenAgregarPod}
            handleOpenEditarHHPod={handleOpenEditarHHPod}
            handleOpenEditarPod={handleOpenEditarPod}
            handleOpenEliminarPod={handleOpenEliminarPod}
            handleSubmitAgregarPod={handleSubmitAgregarPod}
            handleSubmitEditarHorasHombrePod={handleSubmitEditarHorasHombrePod}
            handleSubmitEditarPod={handleSubmitEditarPod}
            handleSubmitEliminarPod={handleSubmitEliminarPod}
            handleViewPod={handleViewPod}
            hideSnackbarButton={hideSnackbarButton}
            isLoading={isLoading}
            openAgregarPod={openAgregarPod}
            openEditarPod={openEditarPod}
            openEliminarPod={openEliminarPod}
            openSnackbar={openSnackbar}
            openReporteSemanal={openReporteSemanal}
            podSeleccionado={podSeleccionado}
            reporteSemanal={reporteSemanal}
            setOpenReporteSemanal={setOpenReporteSemanal}
            snackbarVariant={snackbarVariant}
            snackbarMessage={snackbarMessage}
            usuarioSesion={usuarioSesion}
        />
    );
}