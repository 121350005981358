import React, { Fragment, useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    TableCell,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableRow,
    Container
} from '@material-ui/core';
import MaterialTable from 'material-table';
import AgregarEstadoPago from './agregarEstadoPago';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green, red, blue, amber } from '@material-ui/core/colors';
import * as PERMISIONS from '../../constants/permissions';
import formatNumber from 'format-number';
import MenuDropdown from '../../components/menuDropDown';
import { MoreVert } from '@material-ui/icons';
import Moment from 'react-moment';
import CustomizedSnackbars from '../../components/customizedSnackbar';
import DialogoConfirmar from '../../components/dialogoConfirmar';
import * as ROUTES from '../../constants/routes';
const numberFormater = formatNumber({ integerSeparator: ".", decimal: "," });
const location = require('../../data/location.json');
const useStyles = makeStyles(theme => ({
    success: {
        color: green[600]
    },
    error: {
        color: red[500]
    },
    info: {
        color: blue[500]
    },
    warning: {
        color: amber[700]
    },
    title: {
        margin: theme.spacing(1, 1, 3, 1)
    },
    center: {
        textAlign: "center",
    },
    main: {
        overflow: "auto",
        maxWidth: "95vw"
    },
    card: {
        marginBottom: theme.spacing(2)
    },
    tableActions: {
        display: "inline-flex"
    },
    headerRoot: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    headerContent: {
        display: "flex",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    paperCenter: {
        textAlign: "center"
    },
    loadingCenter: {
        paddingLeft: "50%"
    },
}));

const CustomCell1 = withStyles(theme => ({
    head: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
    body: {
        fontSize: 14,
        border: "1px solid #E0E0E0",
    },
}))(TableCell);

function Tabla(props) {

    const classes = useStyles();

    const {
        data,
        usuarioSesion,
        handleOpenAgregarEstadoPago,
        handleOpenEditarEstadoPago,
        handleOpenEliminarEstadoPago,
        handleViewEstadoPago
    } = props;
    const [pageSize, setPageSize] = useState(10);

    return (
        <MaterialTable
            title={`Listado de Estados de pago`}
            options={{
                filtering: false,
                pageSizeOptions: [5, 10, 20, 50],
                pageSize: pageSize,
                grouping: false,
                padding: 'dense',
                maxBodyHeight: "70vh",
                headerStyle: {
                    textAlign: 'center',
                    border: "1px solid #E0E0E0",
                    fontWeight: 'bold',
                    color: '#731f1f',
                    position: 'sticky'
                },

            }}
            onChangeRowsPerPage={size => { setPageSize(size) }}
            localization={location}
            actions={(usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ITEM_PRESUPUESTO]) ? [
                {
                    tooltip: 'Agregar Estado de Pago',
                    icon: 'add_circle',
                    isFreeAction: true,
                    iconProps: {
                        className: classes.success
                    },
                    onClick: (evt) => handleOpenAgregarEstadoPago(evt, null)
                }
            ] : []}
            columns={[
                {
                    title: '',
                    field: '_id',
                    render: rowData => {
                        const accionesConfiguracion = [{
                            content: "Ver Adjunto",
                            onClick: (e) => handleViewEstadoPago(e, rowData)
                        }];
                        if (usuarioSesion && usuarioSesion.permisos) {
                            if (usuarioSesion.permisos[PERMISIONS.EDITAR_ITEM_PRESUPUESTO]) accionesConfiguracion.push({
                                content: 'Editar',
                                onClick: (e) => handleOpenEditarEstadoPago(e, rowData)
                            });
                            if (usuarioSesion.permisos[PERMISIONS.ELIMINAR_ITEM_PRESUPUESTO]) accionesConfiguracion.push({
                                content: 'Eliminar',
                                onClick: (e) => handleOpenEliminarEstadoPago(e, rowData)
                            });
                        }
                        return (<div className={classes.tableActions}>
                            {accionesConfiguracion.length > 0 &&
                                <MenuDropdown
                                    icon={
                                        <MoreVert />
                                    }
                                    tooltip="Acciones"
                                    items={accionesConfiguracion}
                                />
                            }
                        </div>);
                    },
                    cellStyle: {
                        width: '48px',
                        maxWidth: '48px',
                        minWidth: '48px',
                        padding: '0px',
                        border: "1px solid #E0E0E0",
                    },
                    headerStyle: {
                        width: '48px',
                        maxWidth: '48px',
                        minWidth: '48px',
                        
                    }
                },
                {
                    title: 'Mes/Año',
                    field: 'periodo',
                    cellStyle: {
                        border: "1px solid #E0E0E0",
                    },
                    render: rowData => generarFechaView(rowData.periodo, "MM/YYYY")
                },
                {
                    title: 'Desde',
                    field: 'inicio',
                    cellStyle: {
                        border: "1px solid #E0E0E0",
                    },
                    render: rowData => generarFechaView(rowData.inicio, "DD-MM-YY")
                },
                {
                    title: 'Hasta',
                    field: 'termino',
                    cellStyle: {
                        border: "1px solid #E0E0E0",
                    },
                    render: rowData => generarFechaView(rowData.termino, "DD-MM-YY")
                },
                {
                    title: 'Monto',
                    field: 'monto',
                    cellStyle: {
                        border: "1px solid #E0E0E0",
                        textAlign: 'right'
                    },
                    render: rowData => {
                        return `$${numberFormater(data[rowData.tableData.id].monto)}`
                    }
                }
            ]}
            data={data}
        />
    );
}

function generarFechaView(data, format) {
    if (data) {
        var date = new Date(data);
        if (date.getFullYear() === 2100) {
            return "indefinido";
        } else {
            return <Moment format={format}>{date}</Moment>
        }
    } else {
        return null;
    }

}

function Page(props) {
    const classes = useStyles();
    const {
        data,
        proyecto,
        usuarioSesion,
        montoAcumulado,
        estadoPagoSeleccionado,
        openAgregarEstadoPago,
        openEditarEstadoPago,
        openEliminarEstadoPago,
        handleCloseAgregarEstadoPago,
        handleCloseEditarEstadoPago,
        handleCloseEliminarEstadoPago,
        handleSubmitAgregarEstadoPago,
        handleSubmitEditarEstadoPago,
        handleSubmitEliminarEstadoPago,
        openSnackbar,
        snackbarVariant,
        snackbarMessage,
        hideSnackbarButton,
        handleCloseSnackbar,

    } = props;

    return (

        <Container className={classes.main}>

            {!data &&
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item lg className={classes.center}>
                                <Typography className={classes.title} variant="h5">Cargando Estados de Pago</Typography>
                                <CircularProgress color="primary" size={"70px"} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
            {data &&
                <Grid container spacing={2}>
                    <Grid item lg>
                        <Card className={classes.card}>
                            <CardHeader
                                titleTypographyProps={{
                                    align: "center"
                                }}
                                title={"Estados de Pago"}
                            />

                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <CustomCell1 align="center"><b>Monto Acumulado</b></CustomCell1>
                                            <CustomCell1 align="center">${numberFormater(montoAcumulado)}</CustomCell1>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {data &&
                <Tabla
                    {...props}
                />
            }

            {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.AGREGAR_ITEM_PRESUPUESTO] &&
                <AgregarEstadoPago
                    handleClose={handleCloseAgregarEstadoPago}
                    open={openAgregarEstadoPago}
                    handleSubmit={handleSubmitAgregarEstadoPago}
                />
            }
            {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.EDITAR_ITEM_PRESUPUESTO] &&
                <AgregarEstadoPago
                    handleClose={handleCloseEditarEstadoPago}
                    open={openEditarEstadoPago}
                    handleSubmit={handleSubmitEditarEstadoPago}
                    estadoPago={estadoPagoSeleccionado}
                />
            }
            {usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos[PERMISIONS.ELIMINAR_ITEM_PRESUPUESTO] &&
                <DialogoConfirmar
                    open={openEliminarEstadoPago}
                    message={"¿Está seguro de eliminar el estado de pago?"}
                    action={handleSubmitEliminarEstadoPago}
                    handleClose={handleCloseEliminarEstadoPago}
                />
            }
            <CustomizedSnackbars
                open={openSnackbar}
                variant={snackbarVariant}
                handleClose={handleCloseSnackbar}
                message={snackbarMessage}
                hideButton={hideSnackbarButton}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            />
        </Container>
    );
}

export default Page;