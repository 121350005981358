import Axios from "../axios";

/**
 * Request para obtener los valores de un presupuesto
 * @id id del presupuesto
 */

export const getPresupuesto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/presupuestos/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener los valores de los item de un presupuesto
 * @id id del presupuesto
 */

export const getItemsPresupuesto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/presupuestos/${id}/items-presupuestos`, {
        })
            .then((res) => {
                if (res.data && res.data.items_presupuestos_ref) resolve(res.data.items_presupuestos_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener un item presupuesto
 * @id id del item presupuesto
 */

export const getItemPresupuesto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/items-presupuestos/${id}/`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener un item de un presupuesto
 * @id id del item presupuesto
 */

export const getItemPresupuestoByItem = async (presupuesto, item) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/items-presupuestos/`, {
            params: {
                presupuesto_ref: presupuesto,
                item: item
            }
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener los items programas de un item presupuesto
 * @id id del presupuesto
 */

export const getItemsProgramaItemPresupuesto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/items-presupuestos/${id}/items-programas`, {
        })
            .then((res) => {
                if (res.data && res.data.items_programas_ref) resolve(res.data.items_programas_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener los hijos de un item presupuesto
 * @id id del presupuesto
 */

export const getHijosItemPresupuesto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/items-presupuestos/${id}/hijos`, {
        })
            .then((res) => {
                if (res.data && res.data.hijos) resolve(res.data.hijos);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para crear un nuevo presupuesto
 * @body valores del presupuesto
 */

export const postPresupuesto = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        // const formData = jsonToFormData(body);
        Axios.post(`${base}/presupuestos/`, body)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para crear un nuevo item presupuesto
 * @body valores del item presupuesto
 */

export const postItemPresupuesto = async (body) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/items-presupuestos/`, body)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para actualizar los valores de un presupuesto
 * @id id del presupuesto
 * @params valores actualizados
 */

export const updatePresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/presupuestos/${id}`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para actualizar los valores de un item presupuesto
 * @id id del item presupuesto
 * @params valores actualizados
 */

export const updateItemPresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.put(`${base}/items-presupuestos/${id}`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de items de presupuesto a un presupuesto
 * @id id del  presupuesto
 * @params array de id de items presupuesto
 */

export const agregarItemsPresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/presupuestos/${id}/items-presupuestos`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto item de programa a la lista de referencias de un item de presupuesto
 * @id id del item presupuesto
 * @params array de id de items programa
 */

export const agregarItemsProgramaItemPresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/items-presupuestos/${id}/items-programas`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto item de presupuesto a la lista de hijos de un item de presupuesto
 * @id id del item presupuesto
 * @params array de id de items presupuesto
 */

export const agregarHijosItemPresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/items-presupuestos/${id}/hijos`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar un conjunto item de programa de la lista de referencias de un item de presupuesto
 * @id id del item presupuesto
 * @params array de id de items programa
 */

export const quitarItemsPrograItemPresupesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/items-presupuestos/${id}/items-programas`, { data: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar un conjunto item de presupuesto de la lista de hijos de un item de presupuesto
 * @id id del item presupuesto
 * @params array de id de items presupuesto
 */

export const quitarHijosItemPresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/items-presupuestos/${id}/hijos`, { data: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar un conjunto de items de presupuesto a un presupuesto
 * @id id del  presupuesto
 * @params array de id de items presupuesto
 */

export const quitarItemsPresupuesto = async (id, params) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/presupuestos/${id}/items-presupuestos`, { data: params })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener un presupuesot y sus items
 * @id id del  presupuesto
 * @params filtros
 */

export const buildPresupuesto = async (id, params = {}) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/presupuestos/${id}/build`, params)
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}