import React, { useState, useEffect } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { updateItemPrograma, getItemPrograma, getItemsPrograma } from '../../../services/database/programacion';
import { buildPresupuesto } from '../../../services/database/presupuesto';

export default function SelectorPresupuesto(props) {
    const notistack = useSnackbar();
    const [formik, setFormik] = useState(null);
    const [itemsPresupuesto, setItemsPresupuesto] = useState(null);
    const [presupuestoTotal, setPresupuestoTotal] = useState(0);
    const [cantidadTotal, setCantidadTotal] = useState(0);
    const [presupuestoDisponible, setPresupuestoDisponible] = useState(0);
    const [presupuesto, setPresupuesto] = useState(null);
    const [cantidadDisponible, setCantidadDisponible] = useState(0);
    const { usuarioSesion, proyecto } = useSelector(state => ({
        usuarioSesion: state.usuarioSesion,
        proyecto: state.contratoSesion.proyecto
    }));
    const validationSchema = Yup.object({
        item_presupuesto: Yup.string("Seleccionar Item").required("Campo requerido"),
        costo_directo: Yup.number().typeError("Debe ser un valor Númerico").required("Campo requerido").max(presupuestoDisponible, "No puede ser mayor que el presupuesto disponible"),
        horas_hombre: Yup.number().typeError("Debe ser un valor Númerico"),
        cantidad: Yup.number().typeError("Debe ser un valor Númerico").max(cantidadDisponible, "No puede ser mayor que la cantidad disponible"),
    });

    const { open, handleCloseDialog, item_programa, handleSubmit } = props;

    const values = {
        item_presupuesto: '',
        costo_directo: 0,
        cantidad: 0,
        horas_hombre: 0
    };

    const handleOnSubmit = async (values, formikBak) => {
        const key = notistack.enqueueSnackbar("Guardando Cambios", {
            persist: true,
            anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
            }
        });
        try {
            values.costo_directo = values.costo_directo ? parseFloat(parseFloat(values.costo_directo).toFixed(4)) : 0;
            values.cantidad = values.cantidad ? parseFloat(parseFloat(values.cantidad).toFixed(4)) : 0;
            values["item_presupuesto_ref"] = (values.item_presupuesto && presupuesto.items_presupuestos_ref[values.item_presupuesto.value]) ? presupuesto.items_presupuestos_ref[values.item_presupuesto.value]._id : null;
            await updateItemPrograma(item_programa._id, {
                ...values,
                item_presupuesto: values.item_presupuesto.item
            });
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar("Operación exitosa: Actividad actualizada", {
                variant: 'success',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });

        }
        catch (error) {
            console.log(error);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar la actividad", {
                variant: 'error',
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
        }


        if (handleSubmit) handleSubmit(values);
    }

    const handleEnterDialog = async () => {
        const suggestions = [{ label: "", value: "" }];
        let seleccionado = { label: "", value: "" };
        if (presupuesto) {
            for (let i = 0; i < presupuesto.items_presupuestos_ref.length; i++) {
                const itemPresupuesto = presupuesto.items_presupuestos_ref[i];
                const item = { label: itemPresupuesto.item + " " + itemPresupuesto.nombre, value: i, item: itemPresupuesto.item };
                if (itemPresupuesto.is_hoja) suggestions.push(item);
                if (itemPresupuesto._id === item_programa.item_presupuesto_ref) seleccionado = item;
            }

        }
        formik.resetForm({
            costo_directo: item_programa && item_programa.costo_directo ? item_programa.costo_directo : 0,
            item_presupuesto: seleccionado,
            cantidad: item_programa && item_programa.cantidad ? item_programa.cantidad : 0,
            horas_hombre: item_programa && item_programa.horas_hombre ? item_programa.horas_hombre : 0
        });
        setItemsPresupuesto(suggestions);
        handleOnChangeItemPresupuesto(seleccionado);

    }

    const handleChangeNumber = (name, e) => {
        formik.setFieldValue(name, e.target.value);
    };

    const handleChangeItem = (name, e) => {
        formik.setFieldValue(name, e);
        handleOnChangeItemPresupuesto(e);
    };

    const handleOnChangeItemPresupuesto = async ({ label, value }) => {
        let presupuestoTotal = 0;
        let presupuestoDisponible = 0;
        let cantidadDisponible = 0;
        let cantidadTotal = 0;
        setPresupuestoTotal(0);
        setPresupuestoDisponible(0);
        setCantidadTotal(0);
        setCantidadDisponible(0);
        if (presupuesto) {
            if (value) {
                const actividadesPresupuesto = await getItemsPrograma(proyecto.programa_actual_ref, { item_presupuesto_ref: presupuesto.items_presupuestos_ref[value]._id });
                if (presupuesto.items_presupuestos_ref[value] && presupuesto.items_presupuestos_ref[value].precio_total) {
                    presupuestoTotal = presupuesto.items_presupuestos_ref[value].precio_total;
                    cantidadTotal = presupuesto.items_presupuestos_ref[value].cantidad;
                    presupuestoDisponible = presupuesto.items_presupuestos_ref[value].precio_total;
                    cantidadDisponible = presupuesto.items_presupuestos_ref[value].cantidad;
                }
                if (actividadesPresupuesto) {
                    for (let a in actividadesPresupuesto) {
                        const actividad = actividadesPresupuesto[a];
                        if (actividad._id === item_programa._id) continue;
                        if (actividad.costo_directo) presupuestoDisponible = presupuestoDisponible - actividad.costo_directo;
                        if (actividad.cantidad) cantidadDisponible = cantidadDisponible - actividad.cantidad;
                    }
                }
                setPresupuestoTotal(parseFloat(presupuestoTotal.toFixed(4)));
                setPresupuestoDisponible(parseFloat(presupuestoDisponible.toFixed(4)));
                setCantidadTotal(parseFloat(cantidadTotal.toFixed(4)));
                setCantidadDisponible(parseFloat(cantidadDisponible.toFixed(4)));
            }
        }
    }

    const loadPresupuesto = async () => {
        if (proyecto && proyecto.presupuesto_actual_ref) {
            const presupuestoActual = await buildPresupuesto(proyecto.presupuesto_actual_ref);
            setPresupuesto(presupuestoActual);
        }
    };


    useEffect(() => {
        loadPresupuesto();
    }, [proyecto]);

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                render={props =>
                    <Page
                        open={open}
                        titulo="Seleccionar Item de Presupuesto"
                        itemsPresupuesto={itemsPresupuesto}
                        handleChangeItem={handleChangeItem}
                        handleChangeNumber={handleChangeNumber}
                        handleEnterDialog={handleEnterDialog}
                        handleCloseDialog={handleCloseDialog}
                        handleOnChangeItemPresupuesto={handleOnChangeItemPresupuesto}
                        presupuestoDisponible={presupuestoDisponible}
                        cantidadDisponible={cantidadDisponible}
                        presupuestoTotal={presupuestoTotal}
                        cantidadTotal={cantidadTotal}
                        usuarioSesion={usuarioSesion}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}